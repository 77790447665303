import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';
import { RegistraDDTComponent } from './components/registra-ddt/registra-ddt.component';
import { DDTPagelistComponent } from './components/ddtpagelist/ddtpagelist.component';

@NgModule({
  declarations: [ 
    RegistraDDTComponent, DDTPagelistComponent
  ],

  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule
  ]
})
export class FeatureDDTModule { }
