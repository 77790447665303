import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: "app-input-field-str",
  templateUrl: "./input-field-str.component.html",
  styleUrls: ["./input-field-str.component.css"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class InputFieldStrComponent {
  @Input() type = "text";
  @Input() display = "";
  @Input() name = "";
  @Input() placeholder = "";
  @Input() required = false;
  @Input() readonly = false;
  @Input() maxlength = 99999;
  @Input() errorRequired = " *";
  @Input() errorMaxlength = "campo lunghezza massima";
  @Input() errorSymbol = "carattere non ammesso";
  @Input() value = "";
  @Input() class = "";
  @Output() valueChange = new EventEmitter<string>();

  change(newValue) {
    this.valueChange.emit(newValue);
  }

  constructor() {}
}
