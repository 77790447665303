import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ordiniAcquistoDto } from "src/app/_models/ordiniAcquisto/ordiniAcquistoDto";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { Utility } from "src/app/_zCore/utility";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { map } from "rxjs/operators";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { Observable } from "rxjs";
import { OrdiniAcquistoService } from "../../service/ordini-acquisto.service";

@Component({
  selector: "app-ordini-acquisto-edit",
  templateUrl: "./ordini-acquisto-edit.component.html",
  styleUrls: ["./ordini-acquisto-edit.component.css"],
})
export class OrdiniAcquistoEditComponent implements OnInit, OnDestroy {
  @Output() confirm = new EventEmitter<ordiniAcquistoDto>();

  typeop = "";
  alltype: AllComboboxDataDto;
  currentItem: ordiniAcquistoDto;
  delete: boolean = false;
  sviluppoTaglia;
  scalaturaTaglia;


  constructor(
    public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    public ordiniAcquistoSrv : OrdiniAcquistoService
  ) {
  }
  ngOnInit(): void {
    // this.sviluppoTaglia = ["12", "23", "45", "987", "kjh", "87"];
    console.log(this.currentItem);
  }

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      this.onClose();
      return;
    }
    this.taglietoSend(form);
    console.log(this.currentItem);
    this.confirm.emit(this.currentItem);
  }

  taglietoSend(form) {
    let index = 0;
    let arrayToSend = [];
    this.currentItem.qtPerTaglia.forEach((taglia) => {
      const key = `label_${taglia}_${index}`;
      // console.log("questa è key",key)
      let valoreInput = +form.value[key] as number;
      // console.log(valoreInput);
      if (valoreInput != null) {
        arrayToSend.push(valoreInput);
      }
      index++;
    });
    this.currentItem.qtPerTaglia = arrayToSend;

    let sum: number = 0;
    this.currentItem.qtPerTaglia.forEach((qtTg) => {
      let qt: number = Utility.toInteger(qtTg);

      sum += qt;
    });
    this.currentItem.quantitaDaOrdinare = sum;
  }

  //va cambiato con il popup error
  error(err: any): void {
    console.log("errore", err.error);
    //alert('Si è verificato un errore nella scrittura dei dati!');
    const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
    // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati;

    this.onClose();
  }

  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  ngOnDestroy() {}

  /* 
  getLabels(array: any[], indice: number) {
    // console.log("get Labels", indice);
    let quantita: number = 0;
    
    //  let qnt: number = this.currentItem.righeOrdine[indiceriga].taglieSku[indice].quantita

    if (indice < array.length) {
      quantita = array[indice];
    }
    return quantita;
  } */
}
