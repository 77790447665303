import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/_services/crud.service';
import { HttpClient } from "@angular/common/http";
import { AlertService } from 'src/app/_services/alert.service';
import { environment } from 'src/environments/environment';
import { FileMagazzinoDTO } from '../models/fileMagazzinoDTO';
import { FileMagazzinoMapper } from '../mappers/fileMagazzinoMapper';

@Injectable({
  providedIn: 'root'
})
export class UploadMagazzinoService extends CrudService<FileMagazzinoDTO> {

  constructor(
    httpClient: HttpClient,
    public alertSrv: AlertService,
  ) { 
    super(httpClient, alertSrv);
    this.serverPort = environment.ordiniPort;
    this.endpoint = "lancio";
    this.endpointAll = "lanci";
    this.mapper = new FileMagazzinoMapper();
  }
}
