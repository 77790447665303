import { Injectable } from "@angular/core";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { AService } from "src/app/_services/AService";
import { State } from "src/app/_models/State";
import { BolleDto, CapobolleDto, ComponentiFaseBolle, FasiBolle } from "../models/bolleDto"

class CapobolleMapper implements IDTOMapper<CapobolleDto>{
  bolleMapper:BolleMapper= new BolleMapper();
  fasiMapper:FasiperBolleMapper = new FasiperBolleMapper();
  map(data: any): CapobolleDto {
    
    let item:CapobolleDto = new CapobolleDto();
    item.idCapobolla= data.idCapobolla
    item.numeroCapobolla= data.numeroCapobolla
    item.idOrdine= data.idOrdine
    item.ente= data.ente
    item.anno= data.anno
    item.numero= data.numero
    item.consegna= data.consegna
    item.tagLancio= data.tagLancio
    item.paia= data.paia
    item.idRiga= data.idRiga
    item.numeroRiga= data.numeroRiga
    item.modello= data.modello
    item.parte= data.parte
    item.colore= data.colore
    item.idArticolo=data.idArticolo
    item.forma=data.forma
    item.marchio=data.marchio
    item.barcode=data.barcode
    item.stagione=data.stagione
    item.fasi= [...data.fasi.map((r)=>this.fasiMapper.map(r))] as FasiBolle[]
    item.note=data.note
    item.idSviluppo= data.idSviluppo
    item.codiceSviluppo=data.codiceSviluppo;
    item.progressioneSviluppo=data.progressioneSviluppo;
    item.quantitaPerTaglia=data.quantitaPerTaglia;
    item.validUntil= data.validUntil;
    item.bolle=[...data.bolle.map((r)=>this.bolleMapper.map(r))] as BolleDto[]
   
    return item;
  }

}

class BolleMapper implements IDTOMapper<BolleDto>{
  fasiMapper:FasiperBolleMapper = new FasiperBolleMapper();  
  map(data: any): BolleDto {
    let item:BolleDto = new BolleDto();
    item.idBolla= data.idBolla
    item.numeroBolla= data.numeroBolla
    item.idOrdine= data.idOrdine
    item.ente= data.ente
    item.anno= data.anno
    item.numero= data.numero
    item.consegna= data.consegna
    item.tagLancio= data.tagLancio
    item.paia= data.paia
    item.idRiga= data.idRiga
    item.numeroRiga= data.numeroRiga
    item.modello= data.modello
    item.parte= data.parte
    item.colore= data.colore
    item.idArticolo=data.idArticolo
    item.forma=data.forma
    item.marchio=data.marchio
    item.barcode=data.barcode
    item.stagione=data.stagione
    item.fasi= [...data.fasi.map((r)=>this.fasiMapper.map(r))] as FasiBolle[]
    item.note=data.note
    item.idSviluppo= data.idSviluppo
    item.codiceSviluppo=data.codiceSviluppo;
    item.progressioneSviluppo=data.progressioneSviluppo;
    item.distribuzione=data.distribuzione;
    item.validUntil= data.validUntil;   
    return item;
  }

}

class FasiperBolleMapper implements IDTOMapper<FasiBolle>{
 
 componentiMapper: ComponentiBolleMapper = new ComponentiBolleMapper()
  map(data: any): FasiBolle {
    
    let item:FasiBolle = new FasiBolle();
    item.descrizioneFase= data.descrizioneFase;
    item.codiceFase= data.codiceFase;
    item.componenti= [...data.componenti.map((r)=>this.componentiMapper.map(r))] as ComponentiFaseBolle[];
    return item;
  }

}

class ComponentiBolleMapper implements IDTOMapper<ComponentiFaseBolle>{
  map(data: any): ComponentiFaseBolle {
    
    let item:ComponentiFaseBolle = new ComponentiFaseBolle();

    item.tipo= data.tipo;
    item.codice= data.codice;
    item.descMateriaPrima= data.descMateriaPrima;
    item.unMis= data.unMis;
    item.consumo= data.consumo;
    return item;
  }

}

@Injectable({
  providedIn: 'root'
})
export class GeneraBolleService extends AService<CapobolleDto>{

  state(): State<CapobolleDto> {
     
    let currentState:State<CapobolleDto> = new State<CapobolleDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<CapobolleDto> = new CapobolleMapper();
  }
