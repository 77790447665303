import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { BreadcrumbModule } from 'src/app/_layout/breadcrumb/breadcrumb.module';
import { BlockUIModule } from 'ng-block-ui';
import { CardModule } from '../content/partials/general/card/card.module';

import { UploadDropdownComponent } from './uploadDropdown/upload-dropdown/upload-dropdown.component';
import { ImportazioniFileOrdiniComponent } from './importazioni-file-ordini/importazioni-file-ordini.component';
import { DettaglioErroriComponent } from './dettaglio-errori/dettaglio-errori.component';

@NgModule({
  declarations: [
    UploadDropdownComponent,
    ImportazioniFileOrdiniComponent,
    DettaglioErroriComponent
  ],

  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule,
    NgxDropzoneModule,
    BreadcrumbModule,
    CardModule,
  ]
})
export class FeatureFileOrdiniModule { }
