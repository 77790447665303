import { CommonModule } from "@angular/common";
import { EmailValidator, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { SearchFieldComponent } from "./component/search-field/search-field.component";
import { InputFieldStrComponent } from "./component/input-field-str/input-field-str.component";
import { InputFieldIntComponent } from "./component/input-field-int/input-field-int.component";
import { InputFieldDecComponent } from "./component/input-field-dec/input-field-dec.component";
import { AutocomolateBaseComponent } from "./component/autocomplate-base/autocomplate-base.component";
import { IntValidatorDirective } from "./validators/int.validator";
import { DecValidatorDirective } from "./validators/dec.validator";
import { StrValidatorDirective } from "./validators/str.validator";
import { DecPipe } from "./pipe/dec.pipe";
import { DateValidatorDirective } from "./validators/date.validator";
import { InputFieldDesComponent } from './component/input-field-des/input-field-des.component';
import { InputFieldDateComponent } from './component/input-field-date/input-field-date.component';
import { InputFieldEmailComponent } from './component/input-field-email/input-field-email.component';
import { EmailValidatorDirective } from "./validators/email.validator";
import { InputFieldTextareaComponent } from './component/input-field-textarea/input-field-textarea.component';
import { SearchComponent } from "../_components/search/search.component";
import { NgbdSortableHeader } from "../_directives/sortable.directive";
import { SafePipe } from "../featureAnasnelle/services/pipeTransform/safePipe";
import { TypeaheadOracleComponent } from './component/typeahead-oracle/typeahead-oracle.component';
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    SearchFieldComponent,
    InputFieldStrComponent,
    InputFieldIntComponent,
    InputFieldDecComponent,
    AutocomolateBaseComponent,
    IntValidatorDirective,
    DecValidatorDirective,
    StrValidatorDirective,
    DateValidatorDirective,
    EmailValidatorDirective,
    DecPipe,
    InputFieldDesComponent,
    InputFieldDateComponent,
    InputFieldEmailComponent,
    InputFieldTextareaComponent,
    SearchComponent,
    NgbdSortableHeader,
    SafePipe,
    TypeaheadOracleComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    SearchFieldComponent,
    InputFieldStrComponent,
    InputFieldDesComponent,
    InputFieldIntComponent,
    InputFieldDecComponent,
    InputFieldDateComponent,
    InputFieldEmailComponent,
    AutocomolateBaseComponent,
    SearchComponent,
    NgbdSortableHeader,
    IntValidatorDirective,
    DecValidatorDirective,
    StrValidatorDirective,
    DateValidatorDirective,
    DecPipe,
    SafePipe,
    TypeaheadOracleComponent,
    NgbTypeahead
  
  ],
})
export class SharedModule {}
