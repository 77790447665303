import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { TipoSearch } from "../_models/TipoSearch";
import { Province } from "../_models/localizzazioni/province";
import { environment } from "../../environments/environment";
//ho aggiunto questo
import { ApiAllComboBoxdataService } from "./apiAllComboBoxdata.service";
import { AllComboboxDataDto } from "../_models/allcomboboxdata/allcomboboxdata-dto";
import { Nazioni } from "../_models/localizzazioni/nazioni";
import { OrdiniDto } from "../featureOrdini/models/ordiniDto";
import { ScalaturaDto } from "../featureScalature/models/scalatura-dto";
import { ScalatureDto } from "../featureScalature/models/scalature-dto";

@Injectable({
  providedIn: "root",
})
export class ApiService<TipoDto> {
  domain: string;
  search: TipoSearch = null;
  orderByColumn: string = "";
  orderBydirection: string = "";
  allDataApiEndPoint = "";
  getByIdEndPoint = "";
  insertDataEndPoint: string = "";
  updateDataEndPoint: string = "";
  dataDeleteEndPoint = "";

  getDataByIdField = "";

  constructor(
    public http: HttpClient,
    public service1: ApiAllComboBoxdataService
  ) {
    /*  if (environment.useLocalData == false) {
      this.domain = environment.baseDomainAnagraficheApi;
    } else {
      this.domain = environment.baseDomainApiLocal;
    } */
  }

  setVariablesApi(
    allDataEndPoint: string,
    dataByIdEndPoint: string,
    domain: string
  ) {
    this.allDataApiEndPoint = allDataEndPoint;
    this.getByIdEndPoint = dataByIdEndPoint;
    this.dataDeleteEndPoint = dataByIdEndPoint;
    this.insertDataEndPoint = dataByIdEndPoint;
    this.updateDataEndPoint = dataByIdEndPoint;
    this.domain = domain;
  }

  setProvince(allDataEndPoint: string) {
    this.allDataApiEndPoint = allDataEndPoint;
  }

  fetchData(
    search: TipoSearch,
    page: number,
    pagesize: number,
    orderbycolumn: string,
    orderbydirection: string
  ): Observable<object> {
    this.search = search;
    this.orderByColumn = orderbycolumn;
    this.orderBydirection = orderbydirection;

    if (search.searchString.trim().length != 0) {
      const response = this.wrapObject(page, pagesize, this.search.items);

      return of(response);
    } else {
      return this.getAllData().pipe(
        map((items) => this.wrapObject(page, pagesize, items))
      );
    }
  }

  filterItems(searchString: string, list: any[]) {
    let filteredList: any = list;
    if (!this.search.isEmpty()) {
      searchString = this.search.searchString;
      filteredList = list.filter((item: any) => {
        let result = false;
        for (
          let index = 0;
          index < this.search.searchFieldList.length;
          index++
        ) {
          const sf = this.search.searchFieldList[index];

          let field: string = "";

          if (this.search.searchChildObject !== "") {
            let childObj = this.search.searchChildObject;

            field = item[childObj][sf];
          } else {
            field = item[sf];
          }

          let matchfield: boolean = field
            .toString()
            .toLowerCase()
            .trim()
            .includes(searchString.toLowerCase().trim());

          if (matchfield) {
            result = true;

            break;
          }
        }
        return result;
      });

      console.log(filteredList);
    }

    return filteredList;
  }

  compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  sort(list: any[], sortColumn: string, sortDirection: string): any[] {
    if (sortDirection === "") {
      return list;
    } else {
      return [...list].sort((a, b) => {
        const res = this.compare(a[sortColumn], b[sortColumn]);
        return sortDirection === "asc" ? res : -res;
      });
    }
  }

  paginate(page: number, pageSize: number, list: any[]) {
    const start = (page - 1) * pageSize;
    const end = Math.min(start + pageSize, list.length);
    const data = {
      page: page,
      items: list.slice(start, end),
      count: list.length,
    };
    return data;
  }

  wrapObject(page, pageSize, list): any {
    // 1. sort
    let sortedList = this.sort(list, this.orderByColumn, this.orderBydirection);

    // 2 - Filter
    let filteredList = this.filterItems(this.search.searchString, sortedList);

    // 3. paginate
    let paginatedList = this.paginate(page, pageSize, filteredList);

    return paginatedList;
  }

  getAllData(): Observable<object> {
    const url = `${this.domain}/${this.allDataApiEndPoint}`;

    return this.http.get(url);
  }

  getDataById(id: number): Observable<object> {
    //  var payloadString = `{"${this.getDataByIdField}" : "${id}"}`;
    //  let payload = JSON.parse(payloadString);
    const url = `${this.domain}/${this.getByIdEndPoint}?${this.getDataByIdField}=${id}`;
    
    return this.http.get(url);
  }

  getDataByMultipleId(stringOfId: string): Observable<Object> {
    const url = `${this.domain}/${this.getByIdEndPoint}?${this.getDataByIdField}=${stringOfId}`;
    return this.http.get(url);
  }

  insertData(entity: TipoDto): Observable<object> {
    const url = `${this.domain}/${this.insertDataEndPoint}`;
    console.log(url)

    return this.http.post(url, entity);
  }

  updateData(entity: TipoDto): Observable<object> {
    const url = `${this.domain}/${this.updateDataEndPoint}`;
    console.log(url)

    return this.http.put(url, entity);
  }

  deleteData(id: number, data: string): Observable<object> {
/*     console.log(data);
    let payload = JSON.parse('{"' + data + '" : "' + id + '"}');
    console.log(payload); */
    //let payload =  { data :  "" + id}
    const url = `${this.domain}/${this.dataDeleteEndPoint}?${data}=${id}`;
    console.log(url);
    return this.http.delete(url/* , { body: payload } */);
  }

  getprovince() {
    const url = "../assets/data/province.json";
    return this.http.get<Province[]>(url);
  }

  getNazioni() {
    const url = "../assets/data/nazioni.json";
    return this.http.get<Nazioni[]>(url);
  }
}
