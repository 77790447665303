import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-articoli-anasnelle",
  templateUrl: "./articoli-anasnelle.component.html",
  styleUrls: ["./articoli-anasnelle.component.css"],
})
export class ArticoliAnasnelleComponent implements OnInit {
  @ViewChild("iframe") iframe: ElementRef;

  ngAfterViewInit() {
/*     let doc = this.iframe.nativeElement.contentWindow;
    console.log("doc", doc);
    let token = localStorage.getItem("token");

    doc.postMessage(localStorage.setItem("token", token), "*"); */
  }
  constructor(public http: HttpClient) {}
  ngOnInit(): void {
    // this.onOpenIframe();
  }

  src = environment.anasnelleArticoli;
/* 
  onOpenIframe() {
    const anasnelleTokenUrl = "https://caph:8081/AnaSnelle-BE/cindyauth";
    let token = localStorage.getItem("token");

    this.http
      .get(anasnelleTokenUrl, {
        headers: { ["Authorization"]: `Bearer ${token}` },
      })
      .subscribe(console.log);
  } */
}
