import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  OnDestroy,
} from "@angular/core";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { AlertService } from "src/app/_services/alert.service";

import { LancioProduzioneService } from "../../service/lancio-produzione.service";
import { ActivatedRoute } from "@angular/router";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { LancioProdDto, QuantitaPerTaglia } from "../../models/lancioProdDto";
import { LanciSearchService } from "../../service/lanci.search.service";
import { BehaviorSubject, Observable } from "rxjs";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/_directives/sortable.directive";

import { CrudService } from "src/app/_services/crud.service";
import { environment } from "src/environments/environment";
import { CapobolleDto } from "../../models/bolleDto";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { PopUpUploadOrdiniComponent } from "src/app/_popups/pop-up-upload-ordini/pop-up-upload-ordini.component";
import { JsReportService } from "src/app/shared/services/jsReport/js-report.service";
import { GeneraBolleService } from "../../service/genera-bolle.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { HttpClient } from "@angular/common/http";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { GeneraBolleCRUDService } from "../../service/genera-bolle-crud.service";

@Component({
  selector: "app-lancio-produzione-page-list",
  templateUrl: "./lancio-produzione-page-list.component.html",
  styleUrls: ["./lancio-produzione-page-list.component.css"],
})
export class LancioProduzionePageListComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  lanci$: Observable<LancioProdDto[]> | undefined =
    this.lanciSearchService.items$;
  total$: Observable<number> | undefined = this.lanciSearchService.total$;
  loadingStampa$:Observable<boolean> 
  _loadingStampaBSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  generaBolleDataSource: RestDataSource;

  constructor(
    private modalservice: NgbModal,
    private alertSrv: AlertService,
    private route: ActivatedRoute,
    private lanciSearchService: LanciSearchService,
    private crudService: CrudService<LancioProdDto>,
    private reportSrv: JsReportService,
    private generaBolleSrv: GeneraBolleService,
    private bolleCRUDSrv:GeneraBolleCRUDService,
    private http: HttpClient
  ) {
    this.generaBolleDataSource = new RestDataSource(this.http);
    this.generaBolleDataSource.setVariablesApi(
      null,
      "creaBolleAlgoritmoQuant",
      "creaBolleAlgoritmoQuant",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );

    this.generaBolleDataSource.setByIdField("idLancio");
    this.generaBolleSrv.connectDataSource(this.generaBolleDataSource);
  this.loadingStampa$ = this._loadingStampaBSubject.asObservable()
  }
  ngOnDestroy(): void {
    this.lanciSearchService.searchTerms = [""];
  }

  ngAfterViewInit(): void {
    // console.log("headers", this.headers);
    //this.lanci$.subscribe((lanci)=>console.log("lanci",lanci))
  }

  onSearchTermChanged(searchTerm: string) {
    //qui assegno il filtro specifico alla funzione generica che c'è sul service di search
    this.lanciSearchService.filterItemFunction = this.filterLancio;

    this.lanciSearchService.searchTerms = [searchTerm];
  }

  filterLancio(lancio: LancioProdDto, searchTerms: string[]): boolean {
    let hasIdOrdine: boolean =
      lancio.lancio.ordini.findIndex((o) => {
        let ente = "";
        if (o.ente === "") {
          ente = "CIN";
        } else {
          ente = o.ente;
        }
        let codiceOrdine = " " + ente + "-" + o.anno + "-" + o.numero;
        
        return codiceOrdine.toString().includes(searchTerms[0]);
      }) === -1
        ? false
        : true;

    let hasTagLancio: boolean =
      lancio.tagLancio !== undefined && lancio.tagLancio !== null
        ? true
        : false;

    let match: boolean;
    if (
      (hasTagLancio && lancio.tagLancio.includes(searchTerms[0])) ||
      hasIdOrdine ||
      lancio.idLancio.toString().includes(searchTerms[0]) ||
      searchTerms[0] == ""
    ) {
      match = true;
    } else {
      match = false;
    }

    // console.log("match", match);

    return match;
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.lanciSearchService.sortColumn = column;
    this.lanciSearchService.sortDirection = direction;
  }

  // NUOVE FUNZIONI FINE

  calcQtyOrdine(lan: LancioProdDto) {
    let lanci = lan.lancio;

    let total = 0;
    lanci.ordini.forEach((o) => {
      let qtOrdine: number = 0;

      o.qtPerTaglia.forEach((t: QuantitaPerTaglia) => {
        qtOrdine += t.quantita;
      });

      total += qtOrdine;
    });

    return total;
  }

  showOrdini(lan: LancioProdDto) {
    let lanci = lan.lancio;
    let arrayOrdini = [];
    lanci.ordini.forEach((or) => {
      let ente = "";
      if (or.ente === "") {
        ente = "CIN";
      } else {
        ente = or.ente;
      }
      let codiceOrdine = " " + ente + "-" + or.anno + "-" + or.numero;
      let match = arrayOrdini.find((cod)=>cod == codiceOrdine)
      if(!match)      arrayOrdini.push(codiceOrdine);

    });
    return arrayOrdini;
  }

  ngOnInit(): void {
    this.lanciSearchService.sortColumn = "idLancio";
    this.lanciSearchService.sortDirection = "desc";
    this.route.queryParams.subscribe((params) => {
      if (params["paramsString"] != undefined) {
        this.lanciSearchService.searchTerms = [
          params["paramsString"].toString(),
        ];
        this.onSearchTermChanged(params["paramsString"].toString());
      }
      if (params["paramsString"] === "") {
        this.lanciSearchService.searchTerms = [""];
      }
      console.log("params", params["paramsString"]);
    });

    // this.lanciSearchService.items$.subscribe((items: LancioProdDto[]) => console.log("lanci", items))
  }

  getBolle(idLancio): Observable<CapobolleDto> {

    this.bolleCRUDSrv.endpoint = "bolleLancio";
    return this.bolleCRUDSrv.getBy([{ variable: "idLancio", value: idLancio }]).pipe(
      map((res: any) => {
        let item: CapobolleDto;
        if (res.dati !== undefined) {
          item = JSON.parse(res.dati);
        }
        console.log("capobolle",item);
        return item;
      })
    );
  }




  onStampaBolle(id) {
    this._loadingStampaBSubject.next(true)
    this.getBolle(id)
      .pipe(
        tap((res) => console.log("tap capobolle", res)),
        mergeMap((capoBolle) => {
          let reportToSend = {
            template: { name: "Report_Bolle" },
            data: capoBolle,
          };
          console.log("formdata", reportToSend);
          console.log("formdata str", JSON.stringify(reportToSend));

          return this.reportSrv.getReport(reportToSend);
        })
      )
      .subscribe( (report) => {
        var newBlob = new Blob([report], { type: "application/pdf" });
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = "Bolle Lancio Produzione";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 10000);
        this._loadingStampaBSubject.next(false)
        if(report){
          this.alertSrv.success(`Il Lancio ${id} è stato stampato con successo`)
        } else {
          this.alertSrv.error(Globals.printCreateMessageFailure(`il Lancio`))

        }
        
      },
      (err) => {
        this._loadingStampaBSubject.next(false)

        if(err && err.error && err.error.codEsito !== codiciErrore.erroreLancio){
          this.alertSrv.error( Globals.MappaMessaggiErrore.get("ERRORBOLLE"))

        } else {
          this.alertSrv.error( Globals.MappaMessaggiErrore.get(codiciErrore.erroreLancio), Globals.MappaMessaggiErrore.get("KO.A16_dettaglio") )

        }

        /* const modalRefError = this.modalservice.open(PopUpUploadOrdiniComponent, {
          size: "md",
          windowClass: "animated FadeIn",
        });
        modalRefError.componentInstance.showButton = false;
        if(err.error && err.error.codEsito !== 
          "KO.A16"){
            modalRefError.componentInstance.progressMessage =
            Globals.MappaMessaggiErrore.get("ERRORBOLLE");
          }else{
            modalRefError.componentInstance.progressMessage =
            Globals.MappaMessaggiErrore.get("KO.A16");
          }
     

        const sub = modalRefError.componentInstance.confirm.pipe(
          map((data) => {
            console.log(data);
          })
        ); */
        console.log("erroreJsreport", err);
      });
  }

  onLancioDelete(idLancio: number): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });

    modalRef.componentInstance.currentItem = {};
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          let getParam = {
            variable: "idLancio",
            value: idLancio,
          };
          this.crudService.serverPort = environment.ordiniPort;
          this.crudService.endpoint = "lancio";
          return this.crudService.delete(getParam);
        })
      )
      .subscribe(
        (_) => {
          modalRef.componentInstance.onClose();

          this.alertSrv.success(Globals.printDeleteMessageSuccess("Lancio"));

          // ricarica la lista
          this.lanciSearchService.searchTerms = [""];
        },
        (err) => {
          modalRef.componentInstance.error(err);
          this.alertSrv.error(Globals.printDeleteMessageFailure("Lancio"));
          console.log(err);
        }
      );
  }
}
