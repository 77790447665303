import { Component, OnInit } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";

import { TipiOrdineEditComponent } from "../tipi-ordine-edit/tipi-ordine-edit.component";

import { AlertService } from "src/app/_services/alert.service";
import { TipiOrdineDto } from "src/app/featureTipoOrdine/models/tipiordine-dto";

import { TipiOrdineService } from "../../service/tipi-ordine.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
import { AndFilter } from "src/app/_filters/AndFilter";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { environment } from "src/environments/environment";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";

@Component({
  selector: "app-tipi-ordine-page-list",
  templateUrl: "./tipi-ordine-page-list.component.html",
  styleUrls: ["./tipi-ordine-page-list.component.css"],
})
export class TipiOrdinePageListComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  tipiOrdineDataSource: RestDataSource;
  state: State<TipiOrdineDto> = new State();

  constructor(
    private modalservice: NgbModal,
    private allComboData: ApiAllComboBoxdataService,
    private tipiOrdineService: TipiOrdineService,
    private alertSrv: AlertService,
    private http: HttpClient

  ) {
    this.tipiOrdineDataSource = new RestDataSource(this.http);

    this.tipiOrdineDataSource.setVariablesApi(
      "allTipoOrdine",
      "tipoOrdine",
      "tipoOrdine",
      `${environment.baseDomain}:${environment.anaPort}`    );

    this.tipiOrdineDataSource.setByIdField("idTipoOrdine");
    this.tipiOrdineService.connectDataSource(this.tipiOrdineDataSource);
  }
  _searchTerm: string;
  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this.filterTipiOrdine(this.tipiOrdineService);
  }

  get searchTerm() {
    return this._searchTerm;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.filterTipiOrdine(this.tipiOrdineService);
  }
  get pageSize() {
    return this._pageSize;
  }
  set page(newPage: number) {
    this._page = newPage;

    this.filterTipiOrdine(this.tipiOrdineService);
  }

  get page() {
    return this._page;
  }
 
  loadData() {
    this.tipiOrdineService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterTipiOrdine(service);

        console.log(this._page + " " + this._pageSize);
      },
    });
  }


  mapTipiOrdine = new Map<string, string>([
    ["L", "c/Lavorazione"],
    ["V", "c/Vendita"]
]);



filterTipiOrdine(service: TipiOrdineService) {
  console.log(this._searchTerm);

  this.state = service
    .filter(
      new AndFilter([
        new FieldsFilter({
          fieldList: ["codTipoOrdine", "descTipoOrdine"],
          searchString: this._searchTerm == undefined ? "" : this._searchTerm,
        }),
    //   new TipiPagamentoiFilter(null),
      ])
    )
    .paginate(this._page, this.pageSize)
    .sort(this._sortColumn, this._sortDirection)
    .state();
}

  ngOnInit(): void {
    this._sortColumn="idTipoOrdine";
    this._sortDirection="desc"
    this._rotateDirection="desc"
    this.loadData();
   
  }

  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");

    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.filterTipiOrdine(this.tipiOrdineService);
  }

  onTipoOrdineCreateOrModify(index: number): void {
    let modalRef: any = null;

    modalRef = this.modalservice.open(TipiOrdineEditComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });

    let tipoOrdine: TipiOrdineDto = new TipiOrdineDto();
    let typeop = "ins";

    if (index >= 0) {
      tipoOrdine = this.state.paginatedItems[index];

      typeop = "upd";
    }

    modalRef.componentInstance.currentItem = tipoOrdine;

    modalRef.componentInstance.typeop = typeop;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log(data);

          if (typeop == "ins") {
            return this.tipiOrdineService.create(data as TipiOrdineDto);
          } else if (typeop == "upd") {
            if (modalRef.componentInstance.delete == false)
              return this.tipiOrdineService.update(data as TipiOrdineDto);
            else {
              typeop = "del";

              return this.tipiOrdineService.delete(tipoOrdine.idTipoOrdine);
            }
          }
        })
      )
      .subscribe(
        (response:CindyCustomResponse) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";
          this.loadData();
          this._sortColumn = "idTipoOrdine";
          this._sortDirection = "desc";
          this._rotateDirection = "desc";

          if(response.codEsito=="OK"){
            this.onSuccessPrint(typeop);
          }else{
            this.onErrorPrint(typeop);
          }
          
        },
        (err) => {
          modalRef.componentInstance.error(err);
          modalRef.componentInstance.onClose();
          this.searchTerm = "";
        }
      );
  }


  onErrorPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.error(Globals.printCreateMessageFailure("il Tipo Ordine"));
    else if (typeop == "upd") {
      this.alertSrv.error(Globals.printModifyMessageFailure("il Tipo Ordine"));
    } else {
      this.alertSrv.error(Globals.printDeleteMessageFailure("il Tipo Ordine"));
    }
  }

  onSuccessPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.success(
        Globals.printCreateMessageSuccess("Il Tipo Ordine")
      );
    else if (typeop == "upd") {
      this.alertSrv.success(
        Globals.printModifyMessageSuccess("Il Tipo Ordine")
      );
    } else {
      this.alertSrv.success(
        Globals.printDeleteMessageSuccess("Il Tipo Ordine")
      );
    }
  }

}
