import { RecapitiDto } from "../../_models/recapiti/recapiti-dto";

export class AziendeDto {
  idAzienda: number = -1; // *

  extCode: string;

  //codiceAzienda:string;
  tipoAzienda: string; // *
  ragioneSociale: string; // *
  indirizzo: string; // *
  cap: string; // *
  citta: string; // *
  provincia: string;
  nazione: string;
  codiceFiscale: string;  // *
  partitaIva: string; // *
  partitaIvaEstero: string;
  banca: string; // *
  iban: string; // *
  swift: string; // *
  codTipoFattura: string; // *
  descTipoFattura: string; // **
  numProtocolloLettereIntento: string; // *
  numDichiarazioneEsportazione: string; // *
  dataDichiarazioneEsportazione: Date; // *
  nsNumeroProtocollo: string; // *
  dataProtocollo: Date; // *
  sconto: number; // *
  raggruppamentoDDT: boolean; // *
  assicurazioneMerceCliente: boolean; // *
  personaFisica: boolean; // *
  bolliSuFattura: boolean; // *
  notaFineFattura: string; // *
  agente: number; // ?
  agenteDesc: string; // ??
  capoZona: number; // ?
  capoZonaDesc: string; // ??
  spedizioniere: number; // ?
  spedizioniereDesc: string; // ??
  idPortoCollegato: number; // ?
  idEsenzioneIvaCollegata: number; // *
  idPagamentoCollegato: number;
  zonaProvvigione: string; // *
  percentualeProvvigioneAgente: number; // *
  percentualeProvvigioneCapozona: number; // *
  validUntil: Date;
  codiceSDI: string;
  pec: string;
  notaFatturazioneElettronica: string;
  codiceRea: string;

  recapiti:RecapitiDto[]=[]
  recapitiDaCreare:RecapitiDto[]=[]
  recapitiDaCancellare:RecapitiDto[]=[]
  recapitiDaModificare:RecapitiDto[]=[]
//  dettaglioEsistente: boolean;
}
