import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('token');
    token = (token) ? token : '';

    const s = req.url.toLowerCase();
    if (s.indexOf('/authuser') !== -1 || s.indexOf('/validatetoken') !== -1) {
      return next.handle(req.clone());
    }
    const jsonReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
      }
    });
    return next.handle(jsonReq as any);
  }

}
