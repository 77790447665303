import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { switchMap } from "rxjs/operators";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";


import { AlertService } from "src/app/_services/alert.service";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";

import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
//import { AndFilter } from "src/app/_filters/AndFilter";
//import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { Observable } from "rxjs";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/_directives/sortable.directive";
import { SearchComponent } from "src/app/_components/search/search.component";
import * as _ from "lodash";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { ErroriService } from "src/app/_services/errori-service.service";
import { MagazzinoGeneraleCRUDService } from "../../service/magazzino-generale-crud.service";
import { MagazzinoGeneraleService } from "../../service/magazzino-generale-search.service";
import { MagazzinoGeneraleDTO } from "../../models/magazzinoGeneraleDTO";

@Component({
  selector: 'app-magazzino-generale-pagelist',
  templateUrl: './magazzino-generale-pagelist.component.html',
  styleUrls: ['./magazzino-generale-pagelist.component.css']
})
export class MagazziniGeneralePagelistComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  modalRef: NgbModalRef;

  magazzini$: Observable<MagazzinoGeneraleDTO[]> | undefined =
    this.magazziniGenSearchSrv.items$;
  total$: Observable<number> | undefined = this.magazziniGenSearchSrv.total$;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  @ViewChild(SearchComponent) searchInput: SearchComponent;


  constructor(
    private modalservice: NgbModal,
    private alertSrv: AlertService,
    private http: HttpClient,
    private magazziniGenCRUDSrv: MagazzinoGeneraleCRUDService,
    private magazziniGenSearchSrv: MagazzinoGeneraleService,
    private errorSrv: ErroriService
  ) {
    this.magazziniGenSearchSrv.filterItemFunction = this.filter;
    this.magazziniGenSearchSrv.searchTerms = [""];
  }

  
  ngOnDestroy(): void {}

  onSearchTermChanged(searchTerm: string) {
    //qui assegno il filtro specifico alla funzione generica che c'è sul service di search
    this.magazziniGenSearchSrv.filterItemFunction = this.filter;

    this.magazziniGenSearchSrv.searchTerms = [searchTerm];
  }

  filter(magazzino: MagazzinoGeneraleDTO, searchTerms: string[]): boolean {


    let match: boolean;
    if (
     
      magazzino.nome
        .toString()
        .toLowerCase()
        .includes(searchTerms[0]) ||  magazzino.dataInserimento
        .toString()
        .toLowerCase()
        .includes(searchTerms[0]) ||
      searchTerms[0] == ""
    ) {
      match = true;
    } else {
      match = false;
    }
    return match;
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.magazziniGenSearchSrv.sortColumn = column;
    this.magazziniGenSearchSrv.sortDirection = direction;
  }

  ngOnInit(): void {

    console.log("indice")

  }

  onMagazzinoGeneraleModify(index){
    console.log("indice", index)
  }
}
