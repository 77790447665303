import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IstanzaFaseLavDto } from "src/app/featureOrdini/models/istanzaFaseLavDto";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { Utility } from "src/app/_zCore/utility";
import { FaseLavorazione } from "src/app/featureOrdini/models/rigaOrdineDto";
import { ValutaDto } from "src/app/featureOrdini/models/valutaDto";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";

@Component({
  selector: "app-istanza-lavorazione-edit",
  templateUrl: "./istanza-lavorazione-edit.component.html",
  styleUrls: ["./istanza-lavorazione-edit.component.css"],
})
export class IstanzaLavorazioneEditComponent implements OnInit {
  @Output() confirm = new EventEmitter<IstanzaFaseLavDto>();

  typeop = "";
  alltype: AllComboboxDataDto;
  currentItem: IstanzaFaseLavDto[];
  fasiLavorazione: FaseLavorazione[];
  valuta: ValutaDto;
  quantita: number = 12;
  constructor(public activeModal: NgbActiveModal,
    private modalservice: NgbModal) {}

  ngOnInit(): void {
    console.log(this.fasiLavorazione);
    this.getTotale()
  }
  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      this.onClose();

      return;
    }

    const vm = new IstanzaFaseLavDto();
    /*  vm.idIstanzaFase=Utility.toInteger(this.currentItem.idIstanzaFase)
    vm.dtInizioLavorazione=Utility.fromStringToDate(form.value.dtInizioLavorazione)
    vm.dtFineLavorazione = Utility.fromStringToDate(form.value.dtFineLavorazione)
    vm.costoLavorazione = Utility.toInteger(form.value.costoLavorazione); 
    vm.idValuta=Utility.toInteger(form.value.idValuta); */
    this.confirm.emit(vm);
    console.log(JSON.stringify(vm));
  }

  //va cambiato con il popup error
  error(err: any): void {
    console.log("errore",err.error)
    //alert('Si è verificato un errore nella scrittura dei dati!');
    const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
   // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati; 
   
    this.onClose();

  } 

  getDescFaseLavorazione(idFase) {
    let fase = this.fasiLavorazione.find((fase) => {
      return fase.idFaseLavorazione == idFase;
    });
    return fase.descFaseLavorazione;
  }

  getCOdFaseLavorazione(idFase) {
    let fase = this.fasiLavorazione.find((fase) => {
      return fase.idFaseLavorazione == idFase;
    });
    return fase.codFaseLavorazione;
  }

  totale=0
  getTotale() {

    this.currentItem.forEach((istanza) => {
      console.log
      this.totale += (istanza.costoLavorazione * this.quantita);
    });
    console.log(this.totale)
  }
  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  ngOnDestroy() {}
}
