import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IDataSource } from "./IDataSource";

export class JsonDataSource implements IDataSource{
    
    filename:string;
    httpClient:HttpClient;

    constructor(filename:string,httpClient:HttpClient){

        this.filename = filename;
        this.httpClient = httpClient;
    }
    getDataById(id: number): Observable<object> {
        throw new Error("Method not implemented.");
    }
    insertData(data: any): Observable<object> {
        throw new Error("Method not implemented.");
    }
    updateData(data: any): Observable<object> {
        throw new Error("Method not implemented.");
    }
    deleteData(id: number): Observable<object> {
        throw new Error("Method not implemented.");
    }
    
    getAllData(): Observable<object> {

        return this.httpClient.get( `assets/data/${this.filename}`);
    }

    getDataByMultipleId(stringOfId:string): Observable<object>{
        throw new Error("Method not implemented.");
    }

        
   
    
}