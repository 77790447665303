import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { switchMap } from "rxjs/operators";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { SviluppiTaglieEditComponent } from "../sviluppi-taglie-edit/sviluppi-taglie-edit.component";

import { AlertService } from "src/app/_services/alert.service";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";

import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
//import { AndFilter } from "src/app/_filters/AndFilter";
//import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { Observable } from "rxjs";
import { SviluppiTaglieSearchService } from "../../service/sviluppi-taglie-search.service";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/_directives/sortable.directive";
import { SviluppiTaglieCRUDService } from "../../service/sviluppi-taglie-crud.service";
import { SearchComponent } from "src/app/_components/search/search.component";
import * as _ from "lodash";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { ErroriService } from "src/app/_services/errori-service.service";

@Component({
  selector: "app-sviluppi-taglie-page-list",
  templateUrl: "./sviluppi-taglie-page-list.component.html",
  styleUrls: ["./sviluppi-taglie-page-list.component.css"],
})
export class SviluppiTagliePageLIstComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  modalRef: NgbModalRef;

  sviluppiTaglie$: Observable<SviluppiTaglieDto[]> | undefined =
    this.sviluppiTagliaSearchSrv.items$;
  total$: Observable<number> | undefined = this.sviluppiTagliaSearchSrv.total$;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  @ViewChild(SearchComponent) searchInput: SearchComponent;

  constructor(
    private modalservice: NgbModal,
    private alertSrv: AlertService,
    private http: HttpClient,
    private sviluppiCRUDsrv: SviluppiTaglieCRUDService,
    private sviluppiTagliaSearchSrv: SviluppiTaglieSearchService,
    private errorSrv: ErroriService
  ) {
    this.sviluppiTagliaSearchSrv.filterItemFunction = this.filter;
    this.sviluppiTagliaSearchSrv.searchTerms = [""];
  }

  ngOnDestroy(): void {}

  onSearchTermChanged(searchTerm: string) {
    //qui assegno il filtro specifico alla funzione generica che c'è sul service di search
    this.sviluppiTagliaSearchSrv.filterItemFunction = this.filter;

    this.sviluppiTagliaSearchSrv.searchTerms = [searchTerm];
  }

  filter(sviluppo: SviluppiTaglieDto, searchTerms: string[]): boolean {
    let hasTaglia: boolean =
      sviluppo.sviluppoTaglia.findIndex((tg) => {
        return tg.toString().toLowerCase().includes(searchTerms[0]);
      }) === -1
        ? false
        : true;

    let match: boolean;
    if (
      hasTaglia ||
      sviluppo.codiceSviluppoTaglia
        .toString()
        .toLowerCase()
        .includes(searchTerms[0]) ||
      searchTerms[0] == ""
    ) {
      match = true;
    } else {
      match = false;
    }
    return match;
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.sviluppiTagliaSearchSrv.sortColumn = column;
    this.sviluppiTagliaSearchSrv.sortDirection = direction;
  }

  ngOnInit(): void {
    let utente = JSON.parse(localStorage.getItem("userData"));

    this.sviluppiTagliaSearchSrv.params = [
      { variable: "userId", value: utente.userID },
    ];
  }

  onSviluppoTaglieCreateOrModify(
    index: number,
    sviluppo?: SviluppiTaglieDto
  ): void {
    //   let modalRef: any = null;

    this.modalRef = this.modalservice.open(SviluppiTaglieEditComponent, {
      size: "xl",
      windowClass: "animated FadeIn",
    });

    let svTaglia: SviluppiTaglieDto = new SviluppiTaglieDto();
    let typeop = "ins";

    if (index >= 0) {
      svTaglia = sviluppo;

      typeop = "upd";
    }

    this.modalRef.componentInstance.currentItem = _.cloneDeep(svTaglia);

    this.modalRef.componentInstance.typeop = typeop;
    const sub = this.modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log(data);

          if (typeop == "ins") {
            return this.sviluppiCRUDsrv.create(data as SviluppiTaglieDto);
          } else if (typeop == "upd") {
            if (this.modalRef.componentInstance.delete == false)
              return this.sviluppiCRUDsrv.update(data as SviluppiTaglieDto);
            else {
              typeop = "del";

              return this.sviluppiCRUDsrv.delete({
                variable: "IdSviluppo",
                value: svTaglia.idSviluppoTaglia,
              });
            }
          }
        })
      )
      .subscribe(
        (response: CindyCustomResponse) => {
          console.log("res", response);

          this.modalRef.componentInstance.onClose();
          this.sviluppiTagliaSearchSrv.searchTerms = [""];
          this.searchInput.clear();
          if (typeop == "ins")
            this.alertSrv.success(
              Globals.printCreateMessageSuccess("Lo Sviluppo Taglie")
            );
          else if (typeop == "upd") {
            this.alertSrv.success(
              Globals.printModifyMessageSuccess("Lo Sviluppo Taglie")
            );
          } else {
            if (response.codEsito.toLowerCase() === "ok") {
              //  modalRef.componentInstance.onClose();
              this.alertSrv.success(
                Globals.printDeleteMessageSuccess("Lo Sviluppo Taglie")
              );
            } else {
              this.modalRef.componentInstance.onClose();

              const modalRefErrore = this.modalservice.open(
                PopUpIstanzaEsistenteComponent,
                {
                  size: "sm",
                  windowClass: "animated FadeIn",
                }
              );
              modalRefErrore.componentInstance.codiceErrore = response.codEsito;
              modalRefErrore.componentInstance.messaggioEventuale =
                response.dati;

              /*   this.alertSrv.error(
                Globals.printDeleteMessageFailure("Sviluppo Taglie")
              ); */
            }
          }
        },
        (err) => {
          let errore: CindyCustomResponse = err.error;
          console.log("qui", errore);
          this.modalRef.close();
          if (typeop == "ins")
            if (errore.codEsito == codiciErrore.codiceDuplicato) {
              this.errorSrv.onToastTestata(
                Globals.MappaMessaggiErrore.get(codiciErrore.codiceDuplicato)
              );
            } else {
              this.errorSrv.onToast(
                Globals.printCreateMessageFailure("lo Sviluppo Taglie")
              );
            }
          else if (typeop == "upd") {
            if (errore.codEsito == codiciErrore.codiceDuplicato) {
              this.errorSrv.onToastTestata(
                Globals.MappaMessaggiErrore.get(codiciErrore.codiceDuplicato)
              );
            } else {
              this.errorSrv.onToastTestata(
                Globals.printModifyMessageFailure("lo Sviluppo Taglie")
              );
            }
          } else {
            this.modalRef.componentInstance.error(err);

            /*  this.alertSrv.error(
              Globals.printDeleteMessageFailure("lo Sviluppo Taglie")
            ); */
          }
        }
      );
  }
}
