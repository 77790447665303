import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ArticoloEstesoDto,
  DistintaBase,
} from "../_models/articoli/articoliDto";
import { AziendeDto } from "../featureMain/models/aziende-dto";
import { FasiLavorazioneDto } from "../_models/fasilavorazione/fasilavorazione-dto";
import { FileOrdiniImportazioni } from "../_models/fileOrdini/fileOrdinImportazioni";
import { ImballiDto } from "../featureImballi/models/imballi-dto";
import { LavorantiDto } from "../_models/lavoranti/lavoranti-dto";
import { FabbisogniDto } from "../featureOrdini/models/fabbisogniDto";
import { OrdiniDto } from "../featureOrdini/models/ordiniDto";
import { PortiDto } from "../_models/porti/porti-dto";
import { RecapitiDto } from "../_models/recapiti/recapiti-dto";
import { ScalatureDto } from "../featureScalature/models/scalature-dto";
import { SviluppiTaglieDto } from "../featureSviluppiTaglia/models/sviluppitaglie-dto";
import { TipiEsenzioneDto } from "../_models/tipiesenzione/tipiesenzione-dto";
import { TipiOrdineDto } from "../featureTipoOrdine/models/tipiordine-dto";
import { TipiPagamentoDto } from "../_models/tipipagamento/tipipagamento-dto";
import { UserDto } from "../_models/utenti/usersDto";
import { VariabileAziendaDTO } from "../_models/variabiliPerAzienda/VariabileAziendaDTO";
import { ApiService } from "./api.service";
import { ApiAllComboBoxdataService } from "./apiAllComboBoxdata.service";
import { TipoRigaOrdine } from "../featureOrdini/models/rigaOrdineDto";
import { environment } from "src/environments/environment";


interface ToSendId{

  id:number;

}

@Injectable({
  providedIn: "root",
})
export class FileOrdiniService extends ApiService<FileOrdiniImportazioni> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allFileOrdini",
      "fileOrdini",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );

    this.getDataByIdField = "idFileOrdine";
  }

  insertFile(entity: FileOrdiniImportazioni): Observable<object> {
    let headers = new HttpHeaders();
    headers.append("Accept", "text/*");
    let options = { headers: headers };
    const url = `${environment.baseDomain}:${environment.ordiniPort}/${this.getByIdEndPoint}`;
    return this.http.post(url, entity, options);
  }

  getFileOrdiniDaLavorare() {
    let endpoint = "fileOrdiniDaLavorare";
    return this.http.get(`${environment.baseDomain}:${environment.ordiniPort}/${endpoint}`);
  }

  uploadFile(formData: FormData) {
    let headers = new HttpHeaders();
    headers.append("Accept", "text/csv");
    let options = { headers: headers };
    const url = `${environment.baseDomain}:${environment.ordiniPort}/upload`;
    return this.http.post(url, formData, options);
  }


  rielaboraFile(idFile:number) {
    let numero = idFile;
    let objToSend:ToSendId = { id: numero };
    console.log("objToSend", objToSend);
    const url = `${environment.baseDomain}:${environment.ordiniPort}/rielaboraFileOrdini`;
    return this.http.post(url, objToSend);
  }

  checkFileData(fileDataAsString: string) {
    let result: any = {};

    let lines = fileDataAsString.split("\n");

    let faseIndex = 46;

    // lines.forEach(l =>{

    //   let lineFields = l.split("\;");

    //   console.log(lineFields);
    // });

    result.lines = lines.filter((l) => l.trim().length != 0);

    return result;
  }
}

@Injectable({
  providedIn: "root",
})
export class UsersService extends ApiService<UserDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi("users", "user", "https://cindy.iamboo.net:9097");

    this.getDataByIdField = "userId";
  }
}

@Injectable({
  providedIn: "root",
})
export class FasiDiLavorazioneService extends ApiService<FasiLavorazioneDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allFasiLavorazione",
      "faseLavorazione",
      "https://cindy.iamboo.net:9095"
    );

    this.getDataByIdField = "IDfase";
  }
}

@Injectable({
  providedIn: "root",
})
export class ImballiService extends ApiService<ImballiDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allImballi",
      "imballo",
      "https://cindy.iamboo.net:9095"
    );

    this.getDataByIdField = "IDimballo";
  }
}

@Injectable({
  providedIn: "root",
})
export class LavorantiService extends ApiService<LavorantiDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allLavoranti",
      "lavorante",
      "https://cindy.iamboo.net:9095"
    );

    this.getDataByIdField = "IDlavorante";
  }
}

@Injectable({
  providedIn: "root",
})
export class AziendeService extends ApiService<AziendeDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allAziende",
      "azienda",
      "https://cindy.iamboo.net:9095"
    );

    this.getDataByIdField = "IDazienda";
  }

  getRecapitiByIdAzienda(id: number) {
    let endpoint = "recapitiByAzienda";

    return this.http.get( `${environment.baseDomain}:${environment.anaPort}/${endpoint}?IdAzienda=${id}`);
  }
}

@Injectable({
  providedIn: "root",
})
export class RecapitiService extends ApiService<RecapitiDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allRecapiti",
      "recapito",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    this.getDataByIdField = "idRecapito";
  }
}

@Injectable({
  providedIn: "root",
})
export class OrdiniService extends ApiService<OrdiniDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allOrdiniEstesi",
      "ordineEsteso",
      "https://cindy.iamboo.net:9094"
    );
    this.getDataByIdField = "idOrdine";
  }
}

@Injectable({
  providedIn: "root",
})
export class ArticoliService extends ApiService<ArticoloEstesoDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allArticoli",
      "distintaBase",
      "https://cindy.iamboo.net:9095"
    );
    this.getDataByIdField = "idArticolo";
  }
}
@Injectable({
  providedIn: "root",
})
export class DistintaBaseService extends ApiService<DistintaBase> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allArticoli",
      "distintaBase",
      "https://cindy.iamboo.net:9095"
    );
    this.getDataByIdField = "idArticolo";
  }
}

@Injectable({
  providedIn: "root",
})
export class InputCustomPerAziendaService extends ApiService<VariabileAziendaDTO> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allVariabiliAziende",
      "variabiliAzienda",
      "https://cindy.iamboo.net:9095"
    );
    this.getDataByIdField = "idAzienda";
  }
}
@Injectable({
  providedIn: "root",
})
export class SviluppoTagliService extends ApiService<SviluppiTaglieDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allSviluppiTaglia",
      "sviluppoTaglia",
      "https://cindy.iamboo.net:9095"
    );

    this.getDataByIdField = "IdSviluppo";
  }
}

@Injectable({
  providedIn: "root",
})
export class PortiService extends ApiService<PortiDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi("allPorti", "porto", "https://cindy.iamboo.net:9095");

    this.getDataByIdField = "portoID";
  }
}

@Injectable({
  providedIn: "root",
})
export class ScalatureService extends ApiService<ScalatureDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allScalatureEstese",
      "scalaturaEstesa",
      "https://cindy.iamboo.net:9095"
    );

    this.getDataByIdField = "idScalatura";
  }
}

@Injectable({
  providedIn: "root",
})
export class TipiEsenzioniService extends ApiService<TipiEsenzioneDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allTipoEsenzione",
      "tipoEsenzione",
      "https://cindy.iamboo.net:9095"
    );

    this.getDataByIdField = "IDtipo";
  }
}

@Injectable({
  providedIn: "root",
})
export class TipiAliquoteService extends ApiService<TipiEsenzioneDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allTipoEsenzione",
      "tipoEsenzione",
      "https://cindy.iamboo.net:9095"
    );

    this.getDataByIdField = "IDtipo";
  }
}

@Injectable({
  providedIn: "root",
})
export class TipiPagamentoService extends ApiService<TipiPagamentoDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allTipoPagamento",
      "tipoPagamento",
      "https://cindy.iamboo.net:9095"
    );

    this.getDataByIdField = "IDtipo";
  }
}

@Injectable({
  providedIn: "root",
})
export class TipiOrdineService extends ApiService<TipiOrdineDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allTipoOrdine",
      "tipoOrdine",
      "https://cindy.iamboo.net:9095"
    );

    this.getDataByIdField = "IDtipo";
  }
}

@Injectable({
  providedIn: "root",
})
export class TipiRigaOrdineService extends ApiService<TipoRigaOrdine> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      "allTipiRigaOrdine",
      "tipoRigaOrdine",
      "https://cindy.iamboo.net:9094"
    );

    this.getDataByIdField = "idTipo";
  }
}

@Injectable({
  providedIn: "root",
})
export class FabbisogniService extends ApiService<FabbisogniDto> {
  constructor(http: HttpClient, allComboData: ApiAllComboBoxdataService) {
    super(http, allComboData);

    this.setVariablesApi(
      ".",
      "fabbisogniOrdini",
      "https://cindy.iamboo.net:9094"
    );

    this.getDataByIdField = "listaOrdini";
  }
}
