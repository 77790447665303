import { Injectable } from '@angular/core';
import { CindyCustomResponse } from '../_models/esitoServer/CindyCustomResponse';
import { codiciErrore } from 'src/assets/data/codiciErrore';
import { PopUpIstanzaEsistenteComponent } from '../_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from './alert.service';
import { Globals } from '../_models/Globals';

@Injectable({
  providedIn: 'root'
})

export class ErroriService {

  constructor(
    private modalservice: NgbModal,
    public alertSrv: AlertService

  ) { }

  //Anagrafiche




  //Ordini Produzione
//ricevo il codice errore e i dati aggiuntivi e nella funzione distinguo se creare toast o popUp

OnErrorOrdini(errore:CindyCustomResponse, typeop?:string){

  if(errore.codEsito===codiciErrore.erroreStato){

   this.onToastTestata('Stato dell\'ordine non riconosciuto')
  }

  if(errore.codEsito === codiciErrore.ordineCollegatoLancio){
    const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = errore.codEsito;
    modalRefErrore.componentInstance.messaggioEventuale = JSON.parse(errore.dati).descErrore;
  } else {
    console.log("errorSrv",errore)
    if (typeop == "ins")
    this.onToast(
      Globals.printCreateMessageFailure("Ordine")
    );
  else if (typeop == "upd") {
    this.onToast(
      Globals.printModifyMessageFailure("Ordine")
    );
  } else {
    this.onToast(
      Globals.printDeleteMessageFailure("Ordine")
    );
  }
  }



}

onToastConMessaggioAggiuntivo(messaggio, datiAggiuntivi){
  this.alertSrv.error( Globals.MappaMessaggiErrore.get(codiciErrore.koGenerico), datiAggiuntivi)

}

onToast(messaggio){
  this.alertSrv.error(messaggio)
}

onToastTestata(messaggio){
  this.alertSrv.error( Globals.MappaMessaggiErrore.get(codiciErrore.koGenerico), messaggio)
 
}








  //Ordini Acquisto

  //Lanci
}
