import { globalMessages } from "src/assets/data/toastmessages";
import { codiciErrore } from "src/assets/data/codiciErrore";
export class Globals {
  static printDeleteMessageSuccess(objType: string): string {
    return ` ${objType} ${globalMessages.deleteMessages.deleteSuccess}  `;
  }

  static printDeleteMessageFailure(objType: string): string {
    return `  ${globalMessages.deleteMessages.deleteFailure} ${objType}. \n ${globalMessages.errorMessages.error}`;
  }

  static printModifyMessageSuccess(objType: string): string {
    return `${objType} ${globalMessages.editMessages.editSuccess}  `;
  }

  static printModifyMessageFailure(objType: string): string {
    return ` ${globalMessages.editMessages.editFailure} ${objType} .\n ${globalMessages.errorMessages.error}`;
  }
  static printCreateMessageSuccess(objType: string): string {
    return `${objType} ${globalMessages.createMessages.createSuccess}`;
  }
  static printCreateMessageFailure(objType: string): string {
    return `${globalMessages.createMessages.createFailure} ${objType}. \n ${globalMessages.errorMessages.error} `;
  }

  static printDownloadMessageSuccess(objType: string): string {
    return ` ${objType} ${globalMessages.downloadMessages.downloadSuccess} `;
  }

  static printDownloadMessageFailure(objType: string): string {
    return `${globalMessages.downloadMessages.downloadFailure} ${objType} `;
  }

  static printSuccess(objType: string): string {
    return `${objType} ${globalMessages.printMessages.printSuccess}`;
  }

  static printFailure(objType: string): string {
    return `${globalMessages.printMessages.printFailure} ${objType} `;
  }

  static Versione = "0.2.60";

  static MappaMessaggiErrore: Map<string, string> = new Map([
    [
      codiciErrore.scalaturaCollegataASviluppo,
      "Impossibile eliminare lo sviluppo perché collegato alla scalatura:",
    ],
    [
      codiciErrore.selezioneErrata,
      "La selezione contiene fornitori diversi o appartiene a ordini differenti",
    ],
    ["DUP_RIGA_ODA_dettaglio", "Assicurarsi di aver selezionato correttamente"],
    [codiciErrore.sviluppoDuplicato, "Sviluppo già presente nella scalatura"],

    [
      codiciErrore.erroreElaborazione,
      "Questi ordini hanno un problema di elaborazione dei dati:",
    ],
    [codiciErrore.formatoFileInvadio, "Formato file non valido"],
    [
      codiciErrore.ordineCollegatoLancio,
      "L'ordine è già collegato al lancio",
    ],

    [codiciErrore.codiceDuplicato, "Il codice inserito è già esistente"],

    [
      codiciErrore.erroreCaricamentoDati,
      "C'è stato un errore nel caricamento dei dati \n Provare a ripetere l'azione",
    ],
    [
      codiciErrore.erroreResiduale,
      "C'è stato un errore nel caricamento dei dati",
    ],
    ["KO.GENERICO_dettaglio", "Contattare l'assistenza"],
    [
      codiciErrore.dibaIncompleta,
      "La distinta base di uno degli articoli presenti nell'ordine è vuota o incompleta",
    ],
    ["DIBA_dettaglio", "Inserire i dati mancanti e riprovare"],
    [
      codiciErrore.componenteDuplicato,
      "Il componente selezionato è già presente su questo Ordine",
    ],
    ["DATAMANCANTE", "Inserire una Data di Consegna"],
    [codiciErrore.elaborazioneInCorso, "Elaborazione operazione in corso"],
    ["ERRORBOLLE", "C'è stato un errore nell'elaborazione dei dati. Riprovare"],
    [
      codiciErrore.erroreSubmit,
      "C'è stato un errore nel salvataggio. \n I dati non sono stati salvati",
    ],
    [
      codiciErrore.importazioneDuplicata,
      "Il file che si sta tentando di importare è stato importato precedentemente",
    ],
    [
      codiciErrore.impossibileImportare,
      "C'è stato un errore nel caricamento del file. Ripetere l'operazione",
    ],
    [codiciErrore.erroreLancio, "Impossibile stampare il lancio"],
    [
      "KO.A16_dettaglio",
      "Un articolo collegato ha la Distinta Base mancante o incompleta",
    ],

    [
      codiciErrore.erroreOperazione,
      "Impossibile completare l'operazione. Non sono presenti a sistema i seguenti dati:",
    ],
    [
      codiciErrore.aziendaCollegataOrdine,
      "L'azienda non può essere eliminata perché collegata ad un ordine",
    ],
    [
      codiciErrore.aziendaCollegataArticolo,
      "L'azienda non può essere eliminata perché collegata ad un articolo su Anagrafiche Snelle",
    ],
    [
      codiciErrore.scalaturaCollegataMateriale,
      "La scalatura non può essere eliminata perché collegata ad un materiale su Anagrafiche Snelle",
    ],
    [
      codiciErrore.articoliMancanti,
      "Impossibile caricare gli articoli",
    ],
    [
      codiciErrore.sviluppoMancante,
      "Impossibile trovare i dati dello sviluppo. Inserire i dati mancanti e riprovare",
    ],
    [
      codiciErrore.aziendaMancante,
      "Impossibile trovare i dati dell'azienda. Inserire i dati mancanti e riprovare",
    ],
    [
      codiciErrore.tipiRigaOrdineMancanti,
      "Impossibile trovare i dati dei Tipi Riga. Inserire i dati mancanti e riprovare",
    ],
    [
      codiciErrore.componentiMancanti,
      "Impossibile trovare i dati dei Componenti. Inserire i dati mancanti e riprovare",
    ],
    [codiciErrore.assistenza, "Contattare l'assistenza"],
    [codiciErrore.dibaVUOTA,"Impossibile calcolare il fabbisogno"],
    ["dibaVUOTA_dettaglio","Controllare la distinta base e riprovare"],
    [codiciErrore.erroreCalcoloFabbisogni,"Esiste già un Ordine d'Acquisto definitivo per l'ordine selezionato"],
    [codiciErrore.koGenerico, "Impossibile completare l'operazione"],
    [codiciErrore.DIBAMancante, "Distinta base mancante o incompleta"],
    ["errorFornitore", "Selezionare un fornitore"]


  ]);

  static MappaMessaggiUtente: Map<string, string> = new Map([
    [
      "conferma",
      "Sei sicuro di voler proseguire con questa azione?I prezzi verranno azzerati",
    ],
    [
      "cofermaLotto",
      "Uno degli ordini selezionati appartiene già ad un lotto.\n Sei sicuro di voler proseguire? \n Se premi conferma, il lotto verrà sostituito",
    ],
  ]);
}
