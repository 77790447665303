import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';

import { DashboardComponent } from './component/dashboard/dashboard.component';
@NgModule({
  declarations: [
    DashboardComponent

  ],
  imports: [
    NgbModule,
    SharedModule

  ]
})
export class DashboardModule { }
