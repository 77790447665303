import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-lavorazione-articoli-anasnelle',
  templateUrl: './lavorazione-articoli-anasnelle.component.html',
  styleUrls: ['./lavorazione-articoli-anasnelle.component.css']
})
export class LavorazioneArticoliAnasnelleComponent {

  src= environment.anasnelleLavorazioneArticoli
}
