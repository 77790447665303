import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';


@Component({
  selector: 'app-search',
 
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements AfterViewInit {
 

  @Input() inputLabel:String=''

  @Input() loading:Observable<boolean> | undefined;

  @ViewChild("searchFieldInput") inputRef:ElementRef | undefined;

  @Output() searchTermEvent:EventEmitter<String> =  new EventEmitter();

  ngAfterViewInit(): void {
    
    fromEvent(this.inputRef.nativeElement,"keyup").pipe(
      map((event:any)=>event.target.value),
      debounceTime(300),
      distinctUntilChanged(), 
    ).subscribe((value:String)=>{

      this.searchTermEvent.emit(value);
    });
  }


  clear(){
    this.inputRef.nativeElement.value=''
  }
}
