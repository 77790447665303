import { Injectable } from '@angular/core';
import { BolleDto, CapobolleDto } from '../models/bolleDto';
import { CrudService } from 'src/app/_services/crud.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/_services/alert.service';
import { environment } from 'src/environments/environment';
import { BolleMapper, CapobolleMapper } from '../mappers/bolle.mappers';

@Injectable({
  providedIn: 'root'
})
export class GeneraBolleCRUDService extends CrudService<CapobolleDto> {
endpointPerAlgoritmo=''
  constructor(
    httpClient: HttpClient,
    public alertSrv: AlertService,

  ) {
    super(httpClient, alertSrv);
    this.serverPort = environment.ordiniPort;
    //this.endpoint = "creaBolleAlgoritmoQuant";
    this.endpoint = this.endpointPerAlgoritmo;

    this.endpointAll = "creaBolleAlgoritmoQuant";
    this.mapper = new CapobolleMapper();
  }}
