import { Injectable } from '@angular/core';
import { SearchService } from 'src/app/_services/search.service';
import { SviluppiTaglieDto } from '../models/sviluppitaglie-dto';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SviluppoTaglieMapper } from '../mappers/sviluppiTaglieMapper';
import { AlertService } from 'src/app/_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class SviluppiTaglieSearchService extends SearchService<SviluppiTaglieDto>  {

  constructor(httpClient:HttpClient,
    public alertSrv: AlertService,
    ) {
    super(httpClient, alertSrv);
    this.url = `${environment.baseDomain}`;
    this.port = environment.anaPort;
   // this.endpoint = 'sviluppiTagliaByUser'
    this.endpoint = 'allSviluppiTaglia'

    this.mapper = new SviluppoTaglieMapper();
  }



}
