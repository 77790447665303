import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Pipe,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";
import { ordiniAcquistoDto } from "src/app/_models/ordiniAcquisto/ordiniAcquistoDto";
import {
  BehaviorSubject,
  from,
  fromEvent,
  observable,
  Observable,
  of,
} from "rxjs";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { OrdiniProduzioneService } from "src/app/featureOrdini/service/ordini-produzione.service";
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  find,
  map,
  mergeMap,
  switchMap,
  tap,
  toArray,
} from "rxjs/operators";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import {
  CompAnasnelleService,
  ComponentiAnasnelleMapper,
} from "../../service/componenti-anasnelle.service";
import { HttpClient } from "@angular/common/http";
import { CompAnasnelleDTO } from "src/app/_models/componentiAnasnelle/componentiAnaSnelleDTO";
// import {
//   SviluppoTaglieMapper,
//   SviluppoTaglieService,
// } from "src/app/featureSviluppiTaglia/service/sviluppo-taglie.service";
import { ScalatureService } from "src/app/featureScalature/service/scalature.service";
import { ScalatureSviluppiDTO } from "src/app/_models/scalatureSviluppi/scalatureSviluppi";
import {
  ScalatureSviluppiMapper,
  ScalatureSviluppiService,
} from "../../service/scalatura-sviluppi.service";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { Condition } from "selenium-webdriver";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
import { PopUpUploadOrdiniComponent } from "src/app/_popups/pop-up-upload-ordini/pop-up-upload-ordini.component";
import { ScalatureDto } from "src/app/featureScalature/models/scalature-dto";
import { Utility } from "src/app/_zCore/utility";
import { bootstrapApplication } from "@angular/platform-browser";
import { NgbAccordion } from "@ng-bootstrap/ng-bootstrap";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { BolleDto } from "src/app/featureLancioProd/models/bolleDto";
import { SviluppiTaglieCRUDService } from "src/app/featureSviluppiTaglia/service/sviluppi-taglie-crud.service";
import { Globals } from "src/app/_models/Globals";
import { AlertService } from "src/app/_services/alert.service";
import { OrdiniAcquistoService } from "../../service/ordini-acquisto.service";

@Component({
  selector: "app-odaedit-create",
  templateUrl: "./odaedit-create.component.html",
  styleUrls: ["./odaedit-create.component.css"],
  // changeDetection: ChangeDetectionStrategy.Default,
})
export class ODAEditCreateComponent implements OnInit, AfterViewInit {
  @ViewChild("addButton") addButtonRef: ElementRef | undefined;

  @Output() confirm = new EventEmitter<ordiniAcquistoDto[]>();
  frecciaGiu: boolean = false;
  currentItem: ordiniAcquistoDto[] = [];
  listaFornitori$: Observable<AziendeDto[]>;
  tagLancio: string;
  stagione: number;
  lotto: number;
  dataOrdine: string;
  codiceFornitore: string;
  descrizioneFornitore: string;
  codiceComponente: string;
  componenti: string;
  colore: string;
  compAnasnelleDataSource: RestDataSource;
  listaComponentiFornitoreSelezionato$: Observable<CompAnasnelleDTO[]>;
  scalaturaSviluppo$: Observable<ScalatureSviluppiDTO>;
  listasviluppi$: Observable<SviluppiTaglieDto[]>;
  listaSviluppiBSubject: BehaviorSubject<SviluppiTaglieDto[]> =
    new BehaviorSubject([]);
  scalatureSviluppiDatasource: RestDataSource;
  sviluppoTaglieDataSource: RestDataSource;
  scalatureDatasource: RestDataSource;
  numeroRiga: number = 0;
  cmpSelezionato: CompAnasnelleDTO;
  idSviluppo: number;
  scalaturaSelezionata: any;
  progressioneScalatura: any[];
  enableSalva$: Observable<boolean>;
  _enableSalvaBSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  nextLotto$:Observable<string> | undefined 


  constructor(
    public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    private ordiniProdSrv: OrdiniProduzioneService,
    private compAnasnelleSrv: CompAnasnelleService,
    public http: HttpClient,
    private scalaturaSviluppiSrv: ScalatureSviluppiService,
    private sviluppiSrv: SviluppiTaglieCRUDService,
    private scalatureSrv: ScalatureService,
    private alertSrv:AlertService,
    private ordiniAcquistoSrv:OrdiniAcquistoService
  ) {
    this.compAnasnelleDataSource = new RestDataSource(this.http);
    this.compAnasnelleDataSource.setVariablesApi(
      "",
      "componentiAnasnelleByFornitore",
      "componentiAnasnelleByFornitore",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    this.compAnasnelleDataSource.setByIdField("idAzienda");
    this.compAnasnelleSrv.connectDataSource(this.compAnasnelleDataSource);

    this.scalatureSviluppiDatasource = new RestDataSource(this.http);
    this.scalatureSviluppiDatasource.setVariablesApi(
      "",
      "scalaturaSviluppoByScalatura",
      "scalaturaSviluppoByScalatura",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    this.scalatureSviluppiDatasource.setByIdField("idScalatura");
    this.scalaturaSviluppiSrv.connectDataSource(
      this.scalatureSviluppiDatasource
    );

    this.sviluppoTaglieDataSource = new RestDataSource(this.http);

    this.sviluppoTaglieDataSource.setVariablesApi(
      "allSviluppiTaglia",
      "sviluppoTaglia",
      "sviluppoTaglia",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    this.sviluppoTaglieDataSource.setByIdField("IdSviluppo");
    // this.sviluppiSrv.connectDataSource(this.sviluppoTaglieDataSource);

    this.scalatureDatasource = new RestDataSource(this.http);

    this.scalatureDatasource.setVariablesApi(
      "allScalatureEstese",
      "scalaturaEstesa",
      "scalaturaEstesa",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    this.scalatureDatasource.setByIdField("idScalatura");
    this.scalatureSrv.connectDataSource(this.scalatureDatasource);

    this.listasviluppi$ = this.listaSviluppiBSubject.asObservable();
    this.enableSalva$ = this._enableSalvaBSubject.asObservable();

    this.ordiniAcquistoSrv.getNextLotto().subscribe((res)=>{
      if(res){
        this.lotto = res
      }
    })

  }

  onPanel() {
    console.log("ciao");
    if (this.frecciaGiu == true) {
      this.frecciaGiu = false;
    } else {
      this.frecciaGiu = true;
    }
  }

  ngAfterViewInit(): void {
    fromEvent(this.addButtonRef.nativeElement, "click")
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.onAddComponente();
      });

    this.listaFornitori$ = this.ordiniProdSrv.loadFornitoriConComponenti();
    this.listaFornitori$.subscribe((res)=>console.log("fornitori",res))
    /*  this.listaFornitori$ = this.listaFornitori$.pipe(
       tap((res) => console.log(res)),
      map((az: AziendeDto[]) =>{

        let aziende = az.filter((a: AziendeDto) => a.tipoAzienda === "fornitore")

      return aziende.sort((a1:AziendeDto,a2:AziendeDto)=> a1.ragioneSociale.trim().localeCompare(a2.ragioneSociale.trim()) )

      }
      )
    ); */
  }

  ngOnInit(): void {}

  onQntDaOrdinare(qnt) {
    console.log("ena", qnt);

    if (qnt !== "0") {
      this._enableSalvaBSubject.next(true);
    } else {
      this._enableSalvaBSubject.next(false);
    }
  }

  onDataCreazioneChanged(dataConsegna) {
    console.log(dataConsegna);
    this.dataConsegna = dataConsegna;
  }

  OnChangeFornitore(datiFornitore) {
    let arrayDatiFornitore = datiFornitore.split("&");
    console.log("arrayfornitore", arrayDatiFornitore);
    this.codiceFornitore = arrayDatiFornitore[1];
    arrayDatiFornitore[1] !== "undefined"
      ? (this.descrizioneFornitore = arrayDatiFornitore[2])
      : "";
    this.listaComponentiFornitoreSelezionato$ = this.loadComponenti(
      parseInt(arrayDatiFornitore[0])
    );
  }

  //trasformo l'observable di componenti in observable di componenti[]
  loadComponenti(id: number): Observable<CompAnasnelleDTO[]> {
    let mapper: ComponentiAnasnelleMapper = new ComponentiAnasnelleMapper();
    return this.compAnasnelleDataSource.getDataById(id).pipe(
      map((data: any) => {
        let componenti: CompAnasnelleDTO[] = [];
        let datiItems: any[] = [];
        if (data.dati !== undefined) {
          datiItems = JSON.parse(data.dati);
        } else datiItems = data;
        datiItems.forEach((i) => componenti.push(mapper.map(i)));
        return componenti;
      }),
      tap((res) => console.log("tapcomponeti", res))
    );
  }

  isASviluppo(codeCmp) {
    return this.listaComponentiFornitoreSelezionato$.pipe(
      map((res) => {
        let match = res.find((cmp) => {
          return cmp.codiceComponente == codeCmp;
        });
        if (match !== undefined) {
          return match.flagTaglie;
        } else {
          return false;
        }
      })
      /*  tap((res) => {
          console.log("tap", res);
        }) */
    );
    /*  .subscribe((res) => {
        console.log("res sub", res);
        return res;
      }); */
  }

  onBUttonDisabled$: Observable<boolean>;
  onDisabledButton = true;
  onAddComponente() {
    this._enableSalvaBSubject.next(false);
    this.onDisabledButton = true;
    let rigaODA: ordiniAcquistoDto;
    this.cmpSelezionato$.subscribe((cmp) => {
      this.cmpSelezionato = cmp;
      console.log("questo", this.cmpSelezionato);
      rigaODA = this.currentItem.find((oda) => {
        return oda.codiceComponente === this.cmpSelezionato.codiceComponente;
      });
      console.log("rigaODA", rigaODA);

      if (rigaODA == undefined) {
        rigaODA = new ordiniAcquistoDto();
        rigaODA.qtPerTaglia = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0,
        ];
        rigaODA.codiceComponente = this.cmpSelezionato.codiceComponente;
        rigaODA.descrizioneComponente =
          this.cmpSelezionato.descrizioneComponente;
        rigaODA.coloreComponente = this.cmpSelezionato.coloreComponente;
        rigaODA.unMis = this.cmpSelezionato.unMisura;
        rigaODA.prezzo = this.cmpSelezionato.prezzo;
        rigaODA.codScalatura = this.cmpSelezionato.codScalatura;
        rigaODA.progressioneSviluppo = this.sviluppoSelezionato;
        rigaODA.idScalatura = this.cmpSelezionato.idScalatura;
        console.log("flagtaglie",this.cmpSelezionato.flagTaglie)
        console.log("codiceSviluppo", this.codiceSviluppo)

        if (this.cmpSelezionato.flagTaglie == true) {
          //il componente è SICURAMENTE a sviluppo, FORSE a SCALATURA:
          rigaODA.idSviluppo = this.idSviluppo;

          rigaODA.codSviluppo = this.codiceSviluppo;
          console.log("codiceSviluppo", rigaODA.codSviluppo)


          if (
            this.cmpSelezionato.codScalatura == null ||
            this.cmpSelezionato.codScalatura == undefined
          ) {
            //il componente non è a scalatura
            /*    this.sviluppiSrv.getAll().subscribe((res:SviluppiTaglieDto[])=>{
              this.listaSviluppiBSubject.next(res)
            })
             */

            rigaODA.codScalatura = null;
            rigaODA.codSviluppo = null;
          } else {
            if (
              this.cmpSelezionato.idScalatura !== null ||
              this.cmpSelezionato.idScalatura !== undefined
            ) {
              console.log(
                "componente è a scalatura",
                this.cmpSelezionato.idScalatura,
                this.cmpSelezionato.codScalatura
              );

              //il componente è a scalatura e presente su Cindy:
              this.loadSviluppi(this.cmpSelezionato.idScalatura).subscribe(
                (listaSviluppi: SviluppiTaglieDto[]) => {
                  // console.log("listaSviluppi1",listaSviluppi)
                  this.listaSviluppiBSubject.next(listaSviluppi);
                }
              );

              this.loadScalatura(this.cmpSelezionato.idScalatura);
              this.scalaturaSelezionata$ = this.loadScalatura(
                this.cmpSelezionato.idScalatura
              );
              this.scalaturaSelezionata$.subscribe((res) => {
                //this.scalaturaSelezionata = res;
                rigaODA.codScalatura = res.scalatura.codiceScalatura;
                rigaODA.progressioneScalatura = this.getProgressioneScalatura(
                  res,
                  rigaODA.idSviluppo
                );
                console.log(
                  "rigaODA.progressioneScalatura",
                  rigaODA.progressioneScalatura
                );
              });

              // rigaODA.codScalatura = this.cmpSelezionato.codScalatura;
            } else {
              console.log(
                "componente è a scalatura ma non è su cindy",
                this.cmpSelezionato.idScalatura
              );
              //il componente è a scalatura ma non è presente su Cindy:
              this.onErroreScalatura(this.cmpSelezionato.codScalatura);
            }
          }
        } else {
          //il componente è a pezzi
          rigaODA.showGridTaglie = false;
        }

        this.currentItem.push(rigaODA);
      } else {
        console.log(
          "componente è già selezionato codCmp",
          this.cmpSelezionato.codiceComponente
        );
        console.log(
          "componente è già selezionato codRigaCmp",
          rigaODA.codiceComponente
        );
        console.log("componente è già selezionato codRigaCmp", rigaODA);

        this.onCmpGiaSelezionato();
      }
    });
  }

  array = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0,
  ];

  onChangeQt(event: any, indice: number, riga: ordiniAcquistoDto) {
    console.log("change", event);
    riga.qtPerTaglia[indice] = Utility.toInteger(event);
   // console.log(indice, riga.qtPerTaglia[indice]);

    let sum: number = 0;
    riga.qtPerTaglia.forEach((qtTg) => {
      let qt: number = Utility.toInteger(qtTg);

      sum += qt;
    });
    riga.quantitaDaOrdinare = sum;
    console.log(sum);

    if(sum !== 0){
      this._enableSalvaBSubject.next(true);

    } else {
      this._enableSalvaBSubject.next(false);

    }
  }
  cmpSelezionato$: Observable<CompAnasnelleDTO>;

  scalaturaSelezionata$: Observable<ScalatureDto>;
  OnChangeComponente(codComponente: string) {
    console.log("daticomponente", codComponente);
    if (codComponente) {
      this.onDisabledButton = false;
    } else {
      this.onDisabledButton = false;
    }
    this.codiceComponente = codComponente;

    this.cmpSelezionato$ = this.listaComponentiFornitoreSelezionato$.pipe(
      map((listaCmp: CompAnasnelleDTO[]) => {
        return listaCmp.find((cmp) => {
          return cmp.codiceComponente == codComponente;
        });
      })
    );

    this.cmpSelezionato$.subscribe((res: CompAnasnelleDTO) => {
      if (res.idScalatura !== null) {
        this.loadSviluppi(res.idScalatura).subscribe(
          (listaSviluppi: SviluppiTaglieDto[]) => {
            console.log(listaSviluppi);
            this.listaSviluppiBSubject.next(listaSviluppi);
          },
          (err) => {}
        );
      } else {
        this.sviluppiSrv.getAll().subscribe((res: CindyCustomResponse) => {
          if (res && res.codEsito == "OK") {
            let listaSviluppi = JSON.parse(res.dati);
            this.listaSviluppiBSubject.next(listaSviluppi);
          } else {
          }
        });
        //this.listaSviluppiBSubject.next([]);
      }
    });
  }

  loadScalatura(idScalatura: number) {
    if (idScalatura !== null) {
      return this.scalatureDatasource.getDataById(idScalatura).pipe(
        map((res: CindyCustomResponse) => {
          let datiItem: ScalatureDto;
          if (res.dati !== undefined) {
            datiItem = JSON.parse(res.dati);
          }
          console.log("scalatura datiItem", datiItem);
          return datiItem;
        })
      );
    }
  }

  getProgressioneScalatura(scalatura: ScalatureDto, idSviluppo) {
    let label: string = "";
    let progressioneScalatura = [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    console.log("scalatura in getProgressioni", scalatura);
    progressioneScalatura.forEach((el) => {
      scalatura.relazioni.forEach((rel) => {
        if (rel.idSviluppoTaglia == parseInt(idSviluppo)) {
          let indiceArray = progressioneScalatura.indexOf(el);
          rel.dettagli.forEach((det) => {
            label = det.label;
            console.log("label", label);

            // console.log("indiceArray", indiceArray);
            //  console.log("det.indice", det.indice);
            progressioneScalatura[det.indice] = label;
          });
        }
      });
    });

    console.log("progScal", progressioneScalatura);

    return progressioneScalatura;
  }

  taglietoSend(form, riga: ordiniAcquistoDto) {
    let index = 0;
    let arrayToSend = [];
    riga.qtPerTaglia.forEach((taglia) => {
      // const key = `${riga.codiceComponente}label_${taglia}_${index}_${riga.codSviluppo}`;
      const key = `${riga.codiceComponente}_${index}_${riga.codSviluppo}`;

      console.log(key);
      let valoreInput = +form.value[key] as number;

      console.log("valoreInput", valoreInput);

      if (valoreInput) {
        arrayToSend.push(valoreInput);
      } else {
        arrayToSend.push(0);
      }
      index++;
    });
    riga.qtPerTaglia = arrayToSend;

    let sum: number = 0;
    riga.qtPerTaglia.forEach((qtTg) => {
      let qt: number = Utility.toInteger(qtTg);

      sum += qt;
    });
    riga.quantitaDaOrdinare = sum;
  }

  onErroreScalatura(codScalatura) {
    console.log("funzionaError");
    let messaggioScalatura: string;
    if (codScalatura == null) {
      messaggioScalatura =
        "Non è presente alcuna scalatura corrispondente su Cindy";
    } else {
      messaggioScalatura =
        "La scalatura corrispondente al componente è: " + codScalatura;
    }
    const modalRef = this.modalservice.open(PopUpUploadOrdiniComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.showButton = true;
    modalRef.componentInstance.progressMessage =
      "Attenzione, il componente selezionato non ha uno sviluppo collegato su Cindy.";
    modalRef.componentInstance.optionalMessage = messaggioScalatura;
    modalRef.componentInstance.optionalMessage1 =
      "Inserisci i dati mancanti e riprova";

    const sub = modalRef.componentInstance.confirm.pipe(
      map((data) => {
        console.log(data);
      })
    );
  }

  onCmpGiaSelezionato() {
    this.alertSrv.warning(
      Globals.MappaMessaggiErrore.get("DUPCOMPONENT"),
    );
   /*  const modalRef = this.modalservice.open(PopUpUploadOrdiniComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.showButton = true;
    modalRef.componentInstance.progressMessage =
      "Il componente selezionato è già presente su questo Ordine";

    const sub = modalRef.componentInstance.confirm.pipe(
      map((data) => {
        console.log(data);
      })
    ); */
  }

  loadSviluppi(idScalatura): Observable<SviluppiTaglieDto[]> {
    console.log("loadSviluppi", idScalatura);
    let arrayIDSviluppo: number[] = [];
    let arraySviluppi = [];

    return this.scalatureSviluppiDatasource.getDataById(idScalatura).pipe(
      // tap((res) => console.log("scalatureSviluppi", res)),
      switchMap((data: any) => {
        let datiItems: ScalatureSviluppiDTO[];
        if (data.dati !== undefined) {
          datiItems = JSON.parse(data.dati);
        }
        datiItems.forEach((s) => arrayIDSviluppo.push(s.idSviluppoTaglia));
        // console.log("arrayIDSviluppo", arrayIDSviluppo);
        return from(arrayIDSviluppo);
      }),
      // tap((res) => console.log("tap", res)),
      switchMap((idSvTg: number) => {
        let sviluppo;
        sviluppo = this.sviluppoTaglieDataSource.getDataById(idSvTg);
        arraySviluppi.push(sviluppo);
        return arraySviluppi;
      }),
      //tap((res) => console.log("tap2", res)),
      map((dataList: CindyCustomResponse[]) => {
        // console.log("datalist", dataList);
        let sviluppo: SviluppiTaglieDto;
        let arraySviluppi: SviluppiTaglieDto[] = [];
        dataList.forEach((res: CindyCustomResponse) => {
          if (res.dati !== undefined) {
            sviluppo = JSON.parse(res.dati);
            arraySviluppi.push(sviluppo);
          }
        });

        return arraySviluppi;
      })
    );
  }
  codiceSviluppo;
  sviluppoSelezionato: string[];
  sviluppoSelezionato$: Observable<string[]>;
  OnChangeSviluppo(idSviluppoTaglia) {
    console.log("idSviluppoTaglia", idSviluppoTaglia);
    this.sviluppoSelezionato$ = this.sviluppiSrv
      .getBy([{ variable: "IdSviluppo", value: idSviluppoTaglia }])
      .pipe(
        map((data: any) => {
          console.log("data", data);
          let dataItem = JSON.parse(data.dati);
          this.idSviluppo = dataItem.idSviluppoTaglia;
          this.codiceSviluppo = dataItem.codiceSviluppoTaglia;
          console.log("data", this.codiceSviluppo);

          return dataItem.sviluppoTaglia;
        })
      );
     this.sviluppoSelezionato$.subscribe((res) => {
      this.sviluppoSelezionato = res;
    });  
  }
  dataConsegna: string;
  setFormattedDate(dateString: string) {
    console.log(dateString);

    let dateToks = dateString.split("-");
    return `${dateToks[2]}/${dateToks[1]}/${dateToks[0]}`;
  }

  onSubmit(form: NgForm): void {
    this.currentItem.forEach((rigaOda: ordiniAcquistoDto) => {
      rigaOda.codiceFornitore = this.codiceFornitore;
      rigaOda.tagLancio.push(this.tagLancio);
      rigaOda.descrizioneFornitore = this.descrizioneFornitore;
      rigaOda.codiceComponenteCliente = rigaOda.codiceComponente;
      console.log("data", this.dataConsegna);
      rigaOda.dataConsegna = this.setFormattedDate(this.dataConsegna);
      rigaOda.lotto = this.lotto;
      rigaOda.stagione = this.stagione;
    });
    console.log("emit", this.currentItem);

    this.confirm.emit(this.currentItem);
  }

  onDeleteComponente(indice) {
    let rigaDaEliminare = this.currentItem[indice];
    let codCmp = rigaDaEliminare.codiceComponente;
    console.log("onDeleteComponente", rigaDaEliminare);
    this.currentItem.splice(indice, 1);
    this.OnChangeComponente(codCmp);
  }

  onDelete() {}

  onClose() {
    this.activeModal.close("close");
  }
}
