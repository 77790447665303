import { Injectable } from "@angular/core";
import { ScalatureDto } from "../models/scalature-dto";
import { SearchService } from "src/app/_services/search.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ScalatureMapper } from "../mappers/scalatureMappers";
import { Observable, map, tap } from "rxjs";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
import { SviluppiTaglieCRUDService } from "src/app/featureSviluppiTaglia/service/sviluppi-taglie-crud.service";
import { AlertService } from "src/app/_services/alert.service";

@Injectable({
  providedIn: "root",
})
export class ScalatureSearchService extends SearchService<ScalatureDto> {
  constructor(
    httpClient: HttpClient,
    private sviluppiCRUDSrv: SviluppiTaglieCRUDService,
    public alertSrv: AlertService,

  ) {
    super(httpClient, alertSrv);
    this.url = `${environment.baseDomain}`;
    this.port = environment.anaPort;
    this.endpoint = "allScalatureEstese";

    this.mapper = new ScalatureMapper();

    this.listaSviluppibyIdUtente$.subscribe((res)=>this.listaSviluppi = res);

  }

 // utente = JSON.parse(localStorage.getItem("userData"));

  listaSviluppibyIdUtente$: Observable<SviluppiTaglieDto[]> = this.sviluppiCRUDSrv
    .getAll(/* [{variable:"userId",value:this.utente.userID}] */)
    .pipe(
      //tap(res=>console.log("getAll", JSON.parse(res.dati))),
      map((res) => {
        return JSON.parse(res.dati);
      })
    );


     listaSviluppi:SviluppiTaglieDto[] | undefined= undefined;
}
