import {
  Component,
  Output,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Globals } from "src/app/_models/Globals";

import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { AlertService } from "src/app/_services/alert.service";


@Component({
  selector: 'app-avvia-lancio-produzione',
  templateUrl: './avvia-lancio-produzione.component.html',
  styleUrls: ['./avvia-lancio-produzione.component.css']
})
export class AvviaLancioProduzioneComponent implements OnInit {
  @Output() confirm = new EventEmitter<string>();

tagLancio:string=''
  constructor(  public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    public alertSrv: AlertService) { }

  ngOnInit(): void {
    console.log("tagAvvioLancio",this.tagLancio)
  }

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      this.onClose();
      return;
    }
    this.tagLancio= form.value.tagLancio;
    this.confirm.emit(this.tagLancio.toString().toUpperCase().trim());
     

  }

  error(err: any): void {
    console.log("errore", err.error);
    //alert('Si è verificato un errore nella scrittura dei dati!');
    this.alertSrv.error(Globals.MappaMessaggiErrore.get(err.error.codEsito));

/*     const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito; */
    // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati;

    this.onClose();
  }

  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  ngOnDestroy() {}


}
