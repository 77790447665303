import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/_models/Globals';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor() {

   }
  user = JSON.parse(localStorage.getItem('currentUser')).displayName
  displayName = this.user.charAt(0).toUpperCase()+this.user.slice(1)

  versione=Globals.Versione + " " + environment.environment_name
  ngOnInit(): void {
  }

}
