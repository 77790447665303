import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AlertService } from "../_services/alert.service";
import { NgbAlert } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-alert",
  templateUrl: "alert.component.html",
})
export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private detailSubscription: Subscription;

  message: string;
  detailMessage: string;
  timeout = 0;

  @ViewChild("selfClosingAlert", { static: false }) selfClosingAlert: NgbAlert;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe((message) => {
      this.message = message;
      setTimeout(() => this.close(), 8000);
    });
    this.detailSubscription = this.alertService
      .getDetailMessage()
      .subscribe((detailMessage) => {
        this.detailMessage = detailMessage;
        setTimeout(() => this.close(), 8000);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.detailSubscription.unsubscribe();
  }

  close() {
    this.message = "";
    this.detailMessage = "";
  }

  createTimer(cmp) {
    setTimeout(() => cmp.close(), 10000);
  }
}
