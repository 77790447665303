import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[validDate][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: DateValidatorDirective, multi: true }
    // { provide: NG_VALIDATORS, useExisting: forwardRef(() => IntValidatorDirective), multi: true }
  ]
})
export class DateValidatorDirective implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    const regexp = '^[0-9]+$/-';
    // const regexp = '^[0-9]+(\.[0-9]{1,2})?$';
    if (c.value) {
      const v = new String(c.value);
      if (!v.match(regexp)) {
        return { 'invalidDate': true };
      }
    }
    return null;
  }
}
