import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDTOMapper } from 'src/app/_helpers/IDTOMapper';
import { ItemFilter } from 'src/app/_filters/ItemFilter';
import { RecapitiDto } from 'src/app/_models/recapiti/recapiti-dto';
import { AService } from 'src/app/_services/AService';
import { State } from 'src/app/_models/State';
import { Province } from 'src/app/_models/localizzazioni/province';
import { Nazioni } from 'src/app/_models/localizzazioni/nazioni';
import { RestDataSource } from 'src/app/_datasources/RestDataSource';

class NazioniMapper implements IDTOMapper<Nazioni>{
    map(data: any): Nazioni {
      
      let nazione:Nazioni = new Nazioni();
  
      nazione.siglaEstesa = data.siglaEstesa;
      nazione.descrizione = data.descrizione;
      nazione.sigla = data.sigla;
  
     return nazione;
    }
  
  }

  class ProvinceMapper implements IDTOMapper<Province>{
    map(data: any): Province {
      
      let provincia:Province = new Province();
  
      provincia.nome = data.nome;
      provincia.sigla = data.sigla;
      provincia.regione = data.regione;
    
  
     return provincia;
    }
  
  }

  @Injectable({
    providedIn: 'root'
  })
  export class NazioniService extends AService<Nazioni> {
   
    state(): State<Nazioni> {
       
      let currentState:State<Nazioni> = new State<Nazioni>();
  
      currentState.currentSize = this._resultItems.length;
      currentState.totalSize = this._items.length;
      currentState.items = this._resultItems;
  
      return currentState;
     }
    
     mapper:IDTOMapper<Nazioni> = new NazioniMapper();
  }


  @Injectable({
    providedIn: 'root'
  })
  export class ProvinceService extends AService<Province> {
   
    state(): State<Province> {
       
      let currentState:State<Province> = new State<Province>();
  
      currentState.currentSize = this._resultItems.length;
      currentState.totalSize = this._items.length;
      currentState.items = this._resultItems;
  
      return currentState;
     }
    
     mapper:IDTOMapper<Province> = new ProvinceMapper();
  }