import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-input-field-date',
  templateUrl: './input-field-date.component.html',
  styleUrls: ['./input-field-date.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]

})
export class InputFieldDateComponent{

  @Input() type = 'date';
  @Input() display = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() readonly = false;
  @Input() maxlength = 99999;
  @Input() errorRequired = '  *';
  @Input() errorMaxlength = 'campo lunghezza massima';
  @Input() value = '';
  @Input() class = '';
  @Input() disabled = false;

  constructor() { }

  @Output() valueChange = new EventEmitter<string>();

  change(newValue) {
    this.valueChange.emit(newValue);
  }


}
