export class ordiniAcquistoDto {
  showInput: boolean = false;

  idRiga: number;
  idOrdine: number;
  codiceFornitore: string;
  descrizioneFornitore: string;
  dataConsegna: string;
  codiceComponente: string;
  descrizioneComponente: string;
  coloreComponente: string;
  unMis: string;
  quantitaCalcolata: number;
  lotto: number;
  stagione: number;
  tags: string[];
  stato: string;
  tagLancio: string[] = [];
  extCode: string;
  validUntil: string;
  prezzo: number;
  note: string;
  codSviluppo: string;
  codScalatura: string;
  qtPerTaglia: number[];
  quantitaDaOrdinare: number;
  quantitaMagazzino: number;
  codiceComponenteCliente: string;
  progressioneScalatura: string[];
  idScalatura: number;
  idSviluppo: number;
  progressioneSviluppo: string[];
  indirizzo: string;
  cap: string;
  citta: string;
  provincia: string;
  nazione: string;
  odpOriginario: number;
  dettaglioOdpOriginario: DettagliOdpOriginario;

  showGridTaglie: boolean;
  showGridSviluppo: boolean;

  getFormattedDate(): string {
    if (this.dataConsegna) {
      let dateToks = this.dataConsegna.split("/");
      return `${dateToks[2]}-${dateToks[1]}-${dateToks[0]}`;
    }
  }

  setFormattedDate(dateString: string) {
    console.log(dateString);

    let dateToks = dateString.split("-");
    this.dataConsegna = `${dateToks[2]}/${dateToks[1]}/${dateToks[0]}`;
  }

  getCodiceOrdine() {
    //console.log("ente", this.dettaglioOdpOriginario.ente);
    if (this.odpOriginario) {
      let ente;
      if (
        this.dettaglioOdpOriginario.ente == undefined ||
        this.dettaglioOdpOriginario.ente == "undefined" ||
        this.dettaglioOdpOriginario.ente == null ||
        this.dettaglioOdpOriginario.ente == "null" ||
        this.dettaglioOdpOriginario.ente == ""
      ) {
        ente = "CIN";
      } else {
        ente = this.dettaglioOdpOriginario.ente;

      }

      let anno = this.dettaglioOdpOriginario.anno;
      let numero = this.dettaglioOdpOriginario.numero;
      if (ente !== null && anno !== null && numero !== null) {
        return `${ente}-${anno}-${numero}`;
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  }
}

export class Tags {
  tagLancio: string;
}

export class DettagliOdpOriginario {
  idOrdine: number;
  ente: string;
  anno: number;
  numero: string;
}
