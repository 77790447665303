import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { SharedModule } from "../shared/shared.module";
import { BlockUIModule } from "ng-block-ui";
import { OrdiniAcquistoPageListComponent } from "./component/ordini-acquisto-page-list/ordini-acquisto-page-list/ordini-acquisto-page-list.component";
import { OrdiniAcquistoEditComponent } from './component/ordini-acquisto-edit/ordini-acquisto-edit.component';
import { ODAEditCreateComponent } from './component/odaedit-create/odaedit-create.component';
import { AvviaODAComponent } from './component/avvia-oda/avvia-oda.component';
import { NotaODAComponent } from './component/nota-testata-oda/nota-testata-oda.component';


@NgModule({
    declarations: [
        OrdiniAcquistoPageListComponent,
        OrdiniAcquistoEditComponent,
        ODAEditCreateComponent,
        AvviaODAComponent,
        NotaODAComponent,
  
    ],
    imports: [
      NgbModule,
      SharedModule,
      BlockUIModule
  
    ]
  })
  export class featureOrdiniAcquisto { }
  