import { Injectable, ViewChild } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class AlertService {
    private subject = new Subject<any>();
    private detailMessagesubject = new Subject<any>();
    private keepAfterNavigationChange = false;

    staticAlertClosed = false; 
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next(null);
                    this.detailMessagesubject.next(null);
                }
            }
        });
    }
    success(message: string, detailMessage?:string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
        this.detailMessagesubject.next({ type: 'success', text: detailMessage })
    }

    error(message: string, detailMessage?:string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
        this.detailMessagesubject.next({ type: 'error', text: detailMessage })

    }

    info(message: string,detailMessage?:string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'info', text: message });
        this.detailMessagesubject.next({ type: 'info', text: detailMessage })

    }

    warning(message: string,detailMessage?:string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'warning', text: message });
        this.detailMessagesubject.next({ type: 'warning', text: detailMessage })

    }
   
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    
    getDetailMessage(): Observable<any> {
        return this.detailMessagesubject.asObservable();
    }
    


    

}

