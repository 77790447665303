import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[validEmail][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }
   
  ]
})

export class EmailValidatorDirective implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    const regexp = '[-\\w\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
    
    if (c.value) {
      const v = new String(c.value);
      const resultMatch = v.match(regexp);
      if (!resultMatch) {
        return { 'invalidEmail': true };
      }
    }
    return null;
  }
}
