import { ArticoloEstesoDto } from "src/app/_models/articoli/articoliDto";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { OrdiniDto } from "src/app/featureOrdini/models/ordiniDto";
import { TipoRigaOrdine } from "src/app/featureOrdini/models/rigaOrdineDto";
import { State } from "src/app/_models/State";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
import {
  TipiOrdineDto
} from "src/app/featureTipoOrdine/models/tipiordine-dto";
import { ScalatureDto } from "src/app/featureScalature/models/scalature-dto";


export class OrdiniState extends State<OrdiniDto> {
  listaAziende: AziendeDto[] = [];
  listaTipiOrdine: TipiOrdineDto[] = [];
  listaTipiRigaOrdine: TipoRigaOrdine[] = [];
  listaSviluppi: SviluppiTaglieDto[]=[];
  listaArticoli:ArticoloEstesoDto[]=[]
  listaScalature: ScalatureDto[] = []

  
  
  public get aziende() : AziendeDto[] {
    return this.listaAziende;
  }

  
  public get tipiOrdine() : TipiOrdineDto[] {
    return this.listaTipiOrdine;
  }

  
  public get tipiRigaOrdine() : TipoRigaOrdine[] {
    return this.listaTipiRigaOrdine;
  }

  
  public get sviluppi() : SviluppiTaglieDto[] {
    return this.listaSviluppi;
  }

  
  public get articoli() : ArticoloEstesoDto[] {
    return this.listaArticoli;
  }

  
  public get scalature() : ScalatureDto[] {
    return this.listaScalature;
  }
 
  

}
