// import { OrdineGenDto } from "./ordiniGenDto";
import { ConsegnaDto } from "./consegnaDto";
import { LancioDto, UltimoLancio } from "./lancioDto";
import { StagioneDto } from "./stagioneDto";
import { RigaOrdineDto as RigaOrdineDto } from "./rigaOrdineDto";
import { Utility } from "src/app/_zCore/utility";
import { StatoDto } from "./statoDto";

export class Ordine {
  idOrdine = 0;
  flagTipoOrdine = "";
  ente = "";
  anno = 0;
  numero = 0;
  utente = "";
  codiceCliente = "";
  tipologiaVendita = "";
  tipoCommessa = "";
  codiceEnteEmittente = "";
  descrizioneDestinazione = "";
  numeroOrdineClienteFornitore = 0;
  codiceClienteSpedizione = "";
  terzista = 0;
  extCode = "";
  validUntil = "";
  annoCaricamento = 0;
  marca: "";
  scatola: "";
  idStagione: number;
  idConsegna: number;
  idLancio: number;
  idFile: number;
  idTipoOrdine: number;
  dataCreazioneOrdine: string;
  dataOrdine:string;
  tagLancio: string;

  getFormattedDate(): string {
    if(this.dataCreazioneOrdine){
      let dateToks = this.dataCreazioneOrdine.split("/");
      return `${dateToks[2]}-${dateToks[1]}-${dateToks[0]}`;
    }
 
  }

  annoDaAssegnare = this.anno;

  setFormattedDate(dateString: string) {
    console.log(dateString);

    let dateToks = dateString.split("-");
    this.dataCreazioneOrdine = `${dateToks[2]}/${dateToks[1]}/${dateToks[0]}`;
    if (this.anno == 0) {
      this.annoDaAssegnare = parseInt(dateToks[0]);
    }

    console.log(this.dataCreazioneOrdine);
  }

  static getCodiceOrdine(ordine:Ordine) {
    let co = "";
    let numero_ordine = ordine.numero == 0 ? "" : ordine.numero;
    let anno_ordine = ordine.anno;
    let ente_ordine = ordine.ente;

    if (ordine.anno == 0) {
      ordine.anno = ordine.annoDaAssegnare;
    }
//console.log("nome ordine", ente_ordine, "tipo", ordine.idTipoOrdine)

    if (ente_ordine.length == 0) {
      co = `CIN-${anno_ordine}-${numero_ordine.toString()}`;
    } else {
      co = `${ente_ordine}-${anno_ordine}-${numero_ordine}`;
    }

    return co;
  }
}
export class OrdiniDto {
  constructor() {
    this.ordine = new Ordine();
    this.consegna = new ConsegnaDto();
    this.stagione = new StagioneDto();
    this.righeOrdine = new Array<RigaOrdineDto>();
    this.ultimoLancio = new UltimoLancio();
    this.ultimaVariazioneStato = new StatoDto()

  }

  ordine: Ordine;
  consegna: ConsegnaDto;
  stagione: StagioneDto;
  righeOrdine: RigaOrdineDto[];
  lotto: string = "";
  ultimoLancio: UltimoLancio;
  ultimaVariazioneStato:StatoDto;
}
