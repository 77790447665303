import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';

import { AziendePageListComponent } from './component/aziende-page-list/aziende-page-list.component';
import { AziendeEditComponent } from './component/aziende-edit/aziende-edit.component';


@NgModule({
  declarations: [
    AziendePageListComponent,
    AziendeEditComponent,
 

  ],
  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule

  ]
})
export class FeatureMainModule { }
