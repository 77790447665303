import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDTOMapper } from '../_helpers/IDTOMapper';
import { ItemFilter } from '../_filters/ItemFilter';
import { TipiEsenzioneDto } from '../_models/tipiesenzione/tipiesenzione-dto';
import { AService } from './AService';
import { State } from '../_models/State';

class AliquoteMapper implements IDTOMapper<TipiEsenzioneDto>{
  map(data: any): TipiEsenzioneDto {
    
    let item:TipiEsenzioneDto = new TipiEsenzioneDto();

    item.aliquotaIVA = data.aliquotaIVA;
    item.codiceTipoEsenzione = data.codiceTipoEsenzione;
    item.descrizioneTipoEsenzione = data.descrizioneTipoEsenzione;
    item.extCode = data.extCode;
    item.flagEsenzione = data.flagEsenzione;
    item.idEsenzione = data.idEsenzione;
    item.nomeTipoEsenzione = data.nomeTipoEsenzione;
    item.regimeTipoEsenzione = data.regimeTipoEsenzione;
    item.validUntil = data.validUntil;
    
    return item;
  }

}

@Injectable({
  providedIn: 'root'
})
export class AliquoteService extends AService<TipiEsenzioneDto> {
   
   state(): State<TipiEsenzioneDto> {
     
    let currentState:State<TipiEsenzioneDto> = new State<TipiEsenzioneDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.filter((a) => a.flagEsenzione == false).length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<TipiEsenzioneDto> = new AliquoteMapper();
  


}
