import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  Input,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DettagliRecapito,
  RecapitiDto,
} from "src/app/_models/recapiti/recapiti-dto";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { Utility } from "src/app/_zCore/utility";
import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { RecapitiService } from "src/app/_services/allServices";
import { Province } from "src/app/_models/localizzazioni/province";
import { Nazioni } from "src/app/_models/localizzazioni/nazioni";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { map } from "rxjs/operators";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";

@Component({
  selector: "app-recapiti-edit",
  templateUrl: "./recapiti-edit-component.component.html",
  styleUrls: ["./recapiti-edit-component.component.css"],
})
export class RecapitiEditComponentComponent implements OnInit, OnDestroy {
  @Output() confirm = new EventEmitter<RecapitiDto>();
  typeop = "";
  alltype: AllComboboxDataDto;
  currentItem: RecapitiDto;
  tipoRecapitoSelezionato: string;
  ragioneSociale: string;
  recFromAziende = false;
  listaProvince;
  listaNazioni;
  delete: boolean = false;

  public get nomeRecapito(): string {
    return this.currentItem.nomeRecapito;
  }
  public set nomeRecapito(v: string) {
    this.currentItem.nomeRecapito = v;
  }

  public get idAzienda(): number {
    return this.currentItem.idAzienda;
  }
  public set idAzienda(v: number) {
    this.currentItem.idAzienda = v;
  }

  public get tipoRecapito(): string {
    return this.currentItem.tipoRecapito;
  }
  public set tipoRecapito(v: string) {
    this.currentItem.dettagliRecapito = new DettagliRecapito();

    this.currentItem.tipoRecapito = v;
  }

  public get via(): string {
    return this.currentItem.dettagliRecapito.via;
  }
  public set via(v: string) {
    this.currentItem.dettagliRecapito.via = v;
  }

  public get localita(): string {
    return this.currentItem.dettagliRecapito.localita;
  }
  public set localita(v: string) {
    this.currentItem.dettagliRecapito.localita = v;
  }

  public get numero(): string {
    return this.currentItem.dettagliRecapito.numero;
  }
  public set numero(v: string) {
    this.currentItem.dettagliRecapito.numero = v;
  }

  public get provincia(): string {
    return this.currentItem.dettagliRecapito.provincia;
  }
  public set provincia(v: string) {
    this.currentItem.dettagliRecapito.provincia = v;
  }
  public get nazione(): string {
    return this.currentItem.dettagliRecapito.nazione;
  }
  public set nazione(v: string) {
    this.currentItem.dettagliRecapito.nazione = v;
  }

  public get cap(): string {
    return this.currentItem.dettagliRecapito.cap;
  }
  public set cap(v: string) {
    this.currentItem.dettagliRecapito.cap = v;
  }

  public get valoreRecapito(): string {
    return this.currentItem.dettagliRecapito.valore;
  }
  public set valoreRecapito(v: string) {
    this.currentItem.dettagliRecapito.valore = v;
  }

  public get extCode(): string {
    return this.currentItem.extCode;
  }
  public set extCode(v: string) {
    this.currentItem.extCode = v;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public AllComboBoxSrv: ApiAllComboBoxdataService,
    //  public recapitiSrv:RecapitiService,
    private modalservice: NgbModal
  ) {}

  ngOnInit(): void {
    this.tipoRecapitoSelezionato = this.currentItem.tipoRecapito;
/*     this.AllComboBoxSrv.loadAziendeJson().subscribe((response) => {
      this.alltype.listaAziende = response;
      console.log(response);
    }); */

  //  this.findNomeAzienda();

    if (this.currentItem.dettagliRecapito == undefined) {
      this.currentItem.dettagliRecapito = new DettagliRecapito();
    }
  }

  

/*   findNomeAzienda() {
    if (this.currentItem.idAzienda) {
      let azienda: AziendeDto = this.alltype.listaAziende.find(
        (az) => az.idAzienda == this.currentItem.idAzienda
      );
      this.ragioneSociale = azienda.ragioneSociale;
      console.log("find ragione", this.ragioneSociale);
    }
  } */
  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      this.onClose();
      return;
    }

    //console.log(this.ragioneSociale);

    console.log(this.currentItem);
    this.confirm.emit(this.currentItem);
  }

  //qui devi mettere il popup dei dati mancanti
  error(err: any): void {
    console.log("errore",err.error)
    //alert('Si è verificato un errore nella scrittura dei dati!');
    const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
   // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati; 
  
  } 
  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new RecapitiDto();

          this.delete = true;
          console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
        },
        (err) => {
          modalRef.componentInstance.onClose();
        }
      );
  }

  ngOnDestroy() {}
}
