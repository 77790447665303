import { Component, OnInit } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";

import { ImballiEditComponent } from "../imballi-edit/imballi-edit.component";

import { AlertService } from "src/app/_services/alert.service";
import { ImballiDto } from "src/app/featureImballi/models/imballi-dto";
import { Utility } from "src/app/_zCore/utility";

import { ImballiService } from "../../service/imballi.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
import { AndFilter } from "src/app/_filters/AndFilter";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-imballi-page-list",
  templateUrl: "./imballi-page-list.component.html",
  styleUrls: ["./imballi-page-list.component.css"],
})
export class ImballiPageListComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  ImballiDataSource: RestDataSource;
  state: State<ImballiDto> = new State();

  constructor(
    private modalservice: NgbModal,
    private service1: ApiAllComboBoxdataService,
    private imballiService: ImballiService,
    private alertSrv: AlertService,
    private http: HttpClient
  ) {
    this.ImballiDataSource = new RestDataSource(this.http);

    this.ImballiDataSource.setVariablesApi(
      "allImballi",
      "imballo",
      "imballo",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    this.ImballiDataSource.setByIdField("idImballo");
    this.imballiService.connectDataSource(this.ImballiDataSource);
  }

  _searchTerm: string;
  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this.loadData();
  }

  get searchTerm() {
    return this._searchTerm;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.loadData();
  }
  get pageSize() {
    return this._pageSize;
  }
  set page(newPage: number) {
    this._page = newPage;

    this.loadData();
  }

  get page() {
    return this._page;
  }

  loadData() {
    this.imballiService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterImballi(service);

        console.log(this._page + " " + this._pageSize);
      },
    });
  }

  filterImballi(service: ImballiService) {
    console.log(this._searchTerm);

    this.state = service
      .filter(
        new AndFilter([
          new FieldsFilter({
            fieldList: ["codice", "descrizione", "misura"],
            searchString: this._searchTerm == undefined ? "" : this._searchTerm,
          }),
          //   new TipiPagamentoiFilter(null),
        ])
      )
      .sort(this._sortColumn, this._sortDirection)
      .paginate(this._page, this.pageSize)
      .state();
  }

  ngOnInit(): void {
    this._sortColumn = "codice";
    this._sortDirection = "desc";
    this._rotateDirection = "desc";
    this.loadData();
  }

  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");

    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.filterImballi(this.imballiService);
  }

  /*  onImballiCreate(): void {
 const modalRef = this.modalservice.open(ImballiEditComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.currentItem = new ImballiDto();
    modalRef.componentInstance.alltype = this.alltype;
    modalRef.componentInstance.typeop = "ins";
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) =>
          this.imballiService.insertData(data as ImballiDto)
        )
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";

          this.alertSrv.success(
            Globals.printCreateMessageSuccess("Imballo")
          );
          this.reloadData();
        },
        (err) => {
          modalRef.componentInstance.error(err);
          this.alertSrv.error(
            Globals.printCreateMessageFailure("Imballo")
          );
        }
      );
  }

  onImballiModify(index): void {
    const item = this.datasource.getItems()[index];
    const modalRef = this.modalservice.open(ImballiEditComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    modalRef.componentInstance.typeop = "upd";
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log(data);

          return this.imballiService.updateData(data as ImballiDto);
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";

          this.alertSrv.success(
            Globals.printModifyMessageSuccess("Imballo")
          );
          this.reloadData();
        },
        (err) => {
          modalRef.componentInstance.error(err);
          this.alertSrv.error(
            Globals.printModifyMessageFailure("Imballo")
          );
        }
      );
  };


   onImballiDelete(index): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.datasource.getItems()[index];
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          return this.imballiService.deleteData(item.idImballo, "IDimballo");
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
          this.reloadData();
          this.alertSrv.success(
            Globals.printDeleteMessageSuccess("Imballo")
          );
        },
        (err) => {
          modalRef.componentInstance.error(err);
          this.alertSrv.success(
            Globals.printDeleteMessageFailure("Imballo")
          );
          console.log(err);
        }
      );
  };   */

  onImballoCreateOrModify(index: number): void {
    let modalRef: any = null;

    modalRef = this.modalservice.open(ImballiEditComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });

    let imballo: ImballiDto = new ImballiDto();
    let typeop = "ins";

    if (index >= 0) {
      imballo = this.state.paginatedItems[index];
      typeop = "upd";
    }

    modalRef.componentInstance.currentItem = imballo;
    modalRef.componentInstance.typeop = typeop;

    let backup;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log(data);
          backup = data;
          if (typeop == "ins") {
            return this.imballiService.create(data as ImballiDto);
          } else if (typeop == "upd") {
            if (modalRef.componentInstance.delete == false)
              return this.imballiService.update(data as ImballiDto);
            else {
              typeop = "del";
              return this.imballiService.delete(imballo.idImballo);
            }
          }
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";
          this.loadData();
          this._sortColumn = "idImballo";
                this._sortDirection = "desc";
                this._rotateDirection = "desc";
          if (response.codEsito == "OK") {
            this.onSuccessPrint(typeop);
          } else {
            this.onErrorPrint(typeop);
          }
        },
        (err) => {
          modalRef.componentInstance.error(err);
          modalRef.componentInstance.onClose();
          this.searchTerm = "";
          this.loadData();
        }
      );
  }

  onErrorPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.error(Globals.printCreateMessageFailure("l'Imballo"));
    else if (typeop == "upd") {
      this.alertSrv.error(Globals.printModifyMessageFailure("l'Imballo"));
    } else {
      this.alertSrv.error(Globals.printDeleteMessageFailure("l'Imballo"));
    }
  }

  onSuccessPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.success(Globals.printCreateMessageSuccess("L'Imballo"));
    else if (typeop == "upd") {
      this.alertSrv.success(Globals.printModifyMessageSuccess("L'Imballo"));
    } else {
      this.alertSrv.success(Globals.printDeleteMessageSuccess("L'Imballo"));
    }
  }

  OnToFixing(num, dig) {
    Utility.toFixing(num, dig);
  }
}
