import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { ordiniAcquistoDto } from "src/app/_models/ordiniAcquisto/ordiniAcquistoDto";
import { DDT, DDTEstesoDto, RigheDDT } from "../../models/DDTdto";
import { Variables } from "src/assets/data/variables";
import { Observable } from "rxjs";
import { DDTCRUDService } from "../../services/ddt-crud.service";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { Utility } from "src/app/_zCore/utility";

@Component({
  selector: "app-registra-ddt",
  templateUrl: "./registra-ddt.component.html",
  styleUrls: ["./registra-ddt.component.css"],
})
export class RegistraDDTComponent implements OnInit, AfterViewInit {
  @Output() confirm = new EventEmitter<DDTEstesoDto>();

  constructor(
    public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    private ddtSrv: DDTCRUDService
  ) {}

 // totaleRigheOda: ordiniAcquistoDto[];
  currentItem: DDTEstesoDto
  //  righeToView: ordiniAcquistoDto[];
 // lottoCorrente: string;
  //fornitoreCorrente: string;
  DDTCorrente$: Observable<DDTEstesoDto>;
  stringOfId: string;
  ngAfterViewInit(): void {}
  ngOnInit(): void {
    // console.log("righeToView", this.righeToView);
    console.log("currentItem", this.currentItem);
    console.log("stringOfId", this.stringOfId);

    let arrayDDT;
    this.DDTCorrente$ = this.ddtSrv.onGetRigheDDT(this.stringOfId).pipe(
      map((res: CindyCustomResponse) => {
        let ddt: DDTEstesoDto = JSON.parse(res.dati);
        console.log("ddt", ddt);

        return ddt;
      })
    );
    this.DDTCorrente$.subscribe((res) => {
      this.currentItem = res;
      console.log("server", res);
    });


    console.log("arrayDDT", arrayDDT);

    /* 
    this.righeToView = this.totaleRigheOda.filter(
      (oda) =>
        oda.stato.toString().toLowerCase() ==
        Variables.ordinato.toLocaleLowerCase()
    ); */
    // this.creaOggettoDDT();
  }

/*   creaOggettoDDT() {
    let ddt: DDT = new DDT();
    let today = new Date().toISOString().split("T");
    ddt.codiceDDT = "";
    ddt.lotto = parseInt(this.lottoCorrente);
    ddt.codiceFornitore = this.righeToView[0].codiceFornitore;
    ddt.descFornitore = this.righeToView[0].descrizioneFornitore;
    ddt.dataOda = this.righeToView[0].dataConsegna;
    ddt.flagDDTFinale = false;
    ddt.compilazioneDDT = today[0];
    this.currentItem.ddt = ddt;

    let righe: RigheDDT[] = [];
    this.righeToView.forEach((riga) => {
      let rigaDDT = new RigheDDT();
      rigaDDT.idRigaOda = riga.idRiga;
      rigaDDT.idOda = riga.idOrdine;
      rigaDDT.codiceComponente = riga.codiceComponenteCliente;
      rigaDDT.coloreComponente =
        riga.coloreComponente && riga.coloreComponente !== null
          ? riga.coloreComponente
          : "";
      rigaDDT.qntOrdinata = riga.quantitaDaOrdinare;
      rigaDDT.qntConsegnata = riga.quantitaMagazzino;
      rigaDDT.qntInArrivo = +(
        riga.quantitaDaOrdinare - riga.quantitaMagazzino
      ).toFixed(2);
      // console.log("calcolo: ",riga.quantitaDaOrdinare,"-", riga.quantitaMagazzino, "totale",rigaDDT.qntInArrivo)

      righe.push(rigaDDT);
    });

    this.currentItem.righeDDT = righe;
  } */
  onDataCompilazioneChanged(data) {
    this.currentItem.ddt.compilazioneDDT = data;
    console.log("data compilazione", this.currentItem.ddt.compilazioneDDT);
  }

  onCodiceDDTChange(codice) {
    this.currentItem.ddt.codiceDDT = codice;
    console.log("codice ddt",  this.currentItem.ddt.codiceDDT);
  }

  onQuantitaChange(qnt, indice){
    console.log("quantità",qnt)
    this.currentItem.righeDDT[indice].qntInArrivo=  Utility.toDecimal(+qnt) as number
    console.log("quantità",typeof(this.currentItem.righeDDT[indice].qntInArrivo))

  }

  onSubmit(fp: NgForm, evaso: boolean) {

    this.currentItem.ddt.flagDDTFinale=evaso
    console.log("submit", this.currentItem);
    this.currentItem.righeDDT.forEach((riga)=>{
      Utility.toDecimal(+riga.qntInArrivo) as number
    })


     this.confirm.emit(this.currentItem);

    this.onClose(); 

  }

  onClose(): void {
    this.activeModal.close("close");
  }
}
