import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDTOMapper } from 'src/app/_helpers/IDTOMapper';
import { ItemFilter } from 'src/app/_filters/ItemFilter';
import { TipiPagamentoDto } from 'src/app/_models/tipipagamento/tipipagamento-dto';
import { AService } from 'src/app/_services/AService';
import { State } from 'src/app/_models/State';


class TipiPagamentoMapper implements IDTOMapper<TipiPagamentoDto>{
  map(data: any): TipiPagamentoDto {
    
    let item:TipiPagamentoDto = new TipiPagamentoDto();

    item.idTipoPagamento = data.idTipoPagamento;
    item.descrizioneTipoPagamento = data.descrizioneTipoPagamento;
    item.codiceTipoPagamento = data.codiceTipoPagamento;
    item.extCode = data.extCode;
    item.rateizzazione = data.rateizzazione;
    item.extJson = data.extJson;
    item.extCode = data.extCode;
    item.validUntil = data.validUntil;

    
    return item;
  }

}
@Injectable({
  providedIn: 'root'
})
export class TipiPagamentoService extends AService<TipiPagamentoDto> {
 
  state(): State<TipiPagamentoDto> {
     
    let currentState:State<TipiPagamentoDto> = new State<TipiPagamentoDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<TipiPagamentoDto> = new TipiPagamentoMapper();
}
