import { NgModule } from "@angular/core";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from "@angular/platform-browser";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  NgbModule,
  NgbCarouselConfig,
  NgbModalConfig,
  NgbTypeaheadModule,
} from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthGuard } from "./_guards/auth.guard";
import { AlertComponent } from "./_directives/alert.component";
import { AlertService } from "./_services/alert.service";
import { AuthService } from "./_services/auth.service";

// Routing
import { routing } from "./app.routing";

// Components
import { AppComponent } from "./app.component";

import { SettingsModule } from "./_layout/settings/settings.module";
import { ThemeSettingsConfig } from "./_layout/settings/theme-settings.config";
import { MenuSettingsConfig } from "./_layout/settings/menu-settings.config";

import { HeaderComponent } from "./_layout/header/header.component";
import { VerticalComponent as HeaderVerticalComponent } from "./_layout/header/vertical/vertical.component";
import { HorizontalComponent as HeaderHorizontalComponent } from "./_layout/header/horizontal/horizontal.component";
import { FullLayoutNavbarComponent } from "./_layout/header/full-layout-navbar/full-layout-navbar.component";

import { FooterComponent } from "./_layout/footer/footer.component";
import { NavigationComponent as AppNavigationComponent } from "./_layout/navigation/navigation.component";

import { PublicLayoutComponent } from "./_layout/public-layout/public-layout.component";
import { PrivateLayoutComponent } from "./_layout/private-layout/private-layout.component";

import { LoginComponent } from "./login";

import { ChangelogComponent } from "./changelog/changelog.component";

import { NavbarService } from "./_services/navbar.service";
import { VerticalnavComponent } from "./_layout/navigation/verticalnav/verticalnav.component";
import { HorizontalnavComponent } from "./_layout/navigation/horizontalnav/horizontalnav.component";

// perfect scroll bar
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
// spinner
import { NgxSpinnerModule } from "ngx-spinner";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";

//import { DeviceDetectorModule } from "ngx-device-detector";
import { RouterModule } from "@angular/router";
import { CustomizerComponent } from "./_layout/customizer/customizer.component";
import { PartialsModule } from "./content/partials/partials.module";
import { BreadcrumbModule } from "./_layout/breadcrumb/breadcrumb.module";
import { DataApiService } from "./_services/data.api";
import { HorizontalCustomizerComponent } from "./_layout/customizer/horizontal-customizer/horizontal-customizer.component";
import { BlockTemplateComponent } from "./_layout/blockui/block-template.component";
import { BlockUIModule } from "ng-block-ui";
import { MatchHeightModule } from "./content/partials/general/match-height/match-height.module";
import { FullLayoutComponent } from "./_layout/full-layout/full-layout.component";

import { TokenInterceptor } from "./_zCore/service/tokeninterceptor";

import { PopUpDeleteComponent } from "./_popups/pop-up-delete/pop-up-delete.component";
import { FeatureMainModule } from "./featureMain/feature-main.module";
import { FeaturePortiModule } from "./featurePorti/feature-porti.module";
import { FeatureImballiModule } from "./featureImballi/feature-imballi.module";
import { FeatureFasidiLavorazione } from "./featureFasidiLavorazione/featureFasidiLavorazione.module";
// import { FeatureLavorantiModule } from "./featureLavoranti/feature-lavoranti.module";
import { FeatureRecapitiModule } from "./featureRecapiti/feature-recapiti.module";
import { FeatureScalatureModule } from "./featureScalature/feature-scalature.module";
import { FeatureSviluppiTaglieModule } from "./featureSviluppiTaglia/feature-sviluppi-taglia.module";
import { FeatureTipiEsenzioneModule } from "./featureTipiEsenzione/feature-tipi-esenzione.module";
import { FeatureTipiEsenzioneFalseModule } from "./featureTipiAliquote/feature-tipi-aliquote.module";
import { FeatureTipiOrdineModule } from "./featureTipoOrdine/feature-tipi-ordine.module";
import { FeatureTipiPagamentoModule } from "./featureTipiPagamento/feature-tipi-pagamento.module";
import { FeatureFileOrdiniModule } from "./featureFileOrdini/file-ordini.module";
import { FeatureOrdiniModule } from "./featureOrdini/feature-ordini.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { PopUpUploadOrdiniComponent } from "./_popups/pop-up-upload-ordini/pop-up-upload-ordini.component";
import { featureOrdiniAcquisto } from "./featureOrdiniAcquisto/feature-Ordini-Acquisto.module";
import { PopUpIstanzaEsistenteComponent } from "./_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { SessionExpiredModalComponent } from "./featureAuth/component/session-expired-modal/session-expired-modal.component";
import { PopUpConfermaComponent } from "./_popups/pop-up-conferma/pop-up-conferma.component";
import { FeatureUserRolesModule } from "./featureUserRoles/userRoles.module";
import { FeatureRoleFunctionsModule } from "./featureMenuUser/roleFunction.module";
import { FeatureLancioProdModule } from "./featureLancioProd/feature-lancioProd.module";
import { TypeaheadComponent } from './_components/typeahead/typeahead.component';
import { PlaygroundComponent } from './_components/playground/playground.component';
import { DashboardAnasnelleModule } from "./featureAnasnelle/dashboard/dashboardAnasnelle.module";
import { LavorazioneArticoliAnasnelleModule } from "./featureAnasnelle/lavorazioneArticoli/lavorazioneArticoli.module";
import { ArticoliAnasnelleModule } from "./featureAnasnelle/articoli/articoliAnasnelle.module";

import { AbilityModule, AbilityService } from '@casl/angular';
import { PureAbility } from '@casl/ability';
import { AppAbility } from "./shared/services/AppAbility";
import { SearchComponent } from "./_components/search/search.component";
import { NgbdSortableHeader } from "./_directives/sortable.directive";
import { MaterialiAnasnelleModule } from "./featureAnasnelle/materiali/materialiAnasnelle.moduel";
import { SafePipe } from "./featureAnasnelle/services/pipeTransform/safePipe";
import { MagazziniGeneraliModule } from "./featureMagazzini/magazzini.module";
import { FeatureDDTModule } from "./featureDDT/ddt.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
    declarations: [
        AppComponent,
        PublicLayoutComponent,
        PrivateLayoutComponent,
        HeaderComponent,
        FullLayoutNavbarComponent,
        HeaderHorizontalComponent,
        HeaderVerticalComponent,
        FooterComponent,
        AppNavigationComponent,
        AlertComponent,
        LoginComponent,
        ChangelogComponent,
        VerticalnavComponent,
        HorizontalnavComponent,
        CustomizerComponent,
        HorizontalCustomizerComponent,
        BlockTemplateComponent,
        FullLayoutComponent,
        PopUpDeleteComponent,
        PopUpUploadOrdiniComponent,
        PopUpIstanzaEsistenteComponent,
        SessionExpiredModalComponent,
        PopUpConfermaComponent,
        PlaygroundComponent,
       
    ],
    providers: [
        { provide: AppAbility , useValue: new AppAbility () },
        { provide: PureAbility, useExisting: AppAbility  },
        AuthGuard,
        AlertService,
        NavbarService,
        DataApiService,
        AuthService,
        AbilityService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        NgbCarouselConfig,
        NgbModalConfig,
        NgbTypeaheadModule
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule],
    imports: [
        AbilityModule,
        BrowserModule,
        PartialsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatchHeightModule,
        BreadcrumbModule,
        NgbModule,
        NgbTypeaheadModule,
        FormsModule,
        routing,
        // Settings modules
        SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
        PerfectScrollbarModule,
        NgxSpinnerModule,
     //   DeviceDetectorModule.forRoot(),
        LoadingBarRouterModule,
        BlockUIModule.forRoot({
            template: BlockTemplateComponent,
        }),
        FeatureMainModule,
        FeaturePortiModule,
        FeatureImballiModule,
        FeatureFasidiLavorazione,
        FeatureRecapitiModule,
        FeatureScalatureModule,
        FeatureSviluppiTaglieModule,
        FeatureTipiEsenzioneModule,
        featureOrdiniAcquisto,
        FeatureTipiEsenzioneFalseModule,
        FeatureTipiOrdineModule,
        FeatureTipiPagamentoModule,
        FeatureUserRolesModule,
        FeatureRoleFunctionsModule,
        FeatureFileOrdiniModule,
        FeatureOrdiniModule,
        DashboardModule,
        FeatureLancioProdModule,
        TypeaheadComponent,
        LavorazioneArticoliAnasnelleModule,
        ArticoliAnasnelleModule,
        MaterialiAnasnelleModule,
        DashboardAnasnelleModule,
        MagazziniGeneraliModule,
        FeatureDDTModule
        

    ]
})
export class AppModule {}
