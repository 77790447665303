import { Utility } from "src/app/_zCore/utility";
import { IstanzaFaseLavDto } from "./istanzaFaseLavDto";
import { TaglieSku } from "./taglieSku";
import { ValutaDto } from "./valutaDto";

export class RigaOrdine {
  idRiga = 0;
  numeroRiga: string = "";
  modello: string = "";
  prefissoParte: string = "";
  codiceParte: string = "";
  codiceColore: string = "";
  idArticolo = 0;
  descrizioneArticolo: string = "";
  misuraPartePadre = 0;
  codiceDropStatura: string = "";
  tipoVariante: string = "";
  codiceVariante: string = "";
  codiceCartellino: string = "";
  codiceEtichetta: string = "";
  combModForm: string = "";
  idOrdine = 0;
  validUntil: string = "";
  idSviluppo: number;
  extCode: string = "";
  sconto: number = 0;
  scontoUlteriore: number;
  idTipoRigaOrdine: number;
  dataConsegnaConcordata: string;
  dataConsegnaPrevista: string;
  note: string;

  public getFormattedDateConcordata(): string {
    if (this.dataConsegnaConcordata) {
      let dateToks = this.dataConsegnaConcordata.split("/");
      return `${dateToks[2]}-${dateToks[1]}-${dateToks[0]}`;
    } else {
      return "";
    }
  }

  public setFormattedDateConcordata(dateString: string) {
    console.log(dateString);

    let dateToks = dateString.split("-");
    this.dataConsegnaConcordata = `${dateToks[2]}/${dateToks[1]}/${dateToks[0]}`;

    console.log(this.dataConsegnaConcordata);
  }

  public getFormattedDatePrevista(): string {
    if (this.dataConsegnaPrevista) {
      let dateToks = this.dataConsegnaPrevista.split("/");
      return `${dateToks[2]}-${dateToks[1]}-${dateToks[0]}`;
    } else {
      return "";
    }
  }

  public setFormattedDatePrevista(dateString: string) {
    console.log(dateString);

    let dateToks = dateString.split("-");
    this.dataConsegnaPrevista = `${dateToks[2]}/${dateToks[1]}/${dateToks[0]}`;

    console.log(this.dataConsegnaPrevista);
  }
}
export class RigaOrdineDto {
  rigaOrdine: RigaOrdine = new RigaOrdine();
  ultimaVariazioneStatoRiga: UltimaVariazioneStatoRiga =
    new UltimaVariazioneStatoRiga();
  valoriVariabiliAzienda: ValoriVariabiliAzienda[] =
    new Array<ValoriVariabiliAzienda>();
  taglieSku: TaglieSku[] = new Array<TaglieSku>();

  get modelloParte() {
    return `${this.rigaOrdine.modello}-${this.rigaOrdine.codiceParte}`;
  }

  static getQuantita(row: RigaOrdineDto) {
    let sum: number = 0;
    row.taglieSku.forEach((qtTg: TaglieSku) => {
      let qt: number = Utility.toInteger(qtTg.quantita);

      sum += qt;
    });

    return sum;
  }

  static getImportoTotale(row: RigaOrdineDto): number {
    let it: number = 0;

    row.istanzeFaseLavorazione.forEach((fl) => {
      it +=
        Utility.toDecimal(fl.costoLavorazione) * RigaOrdineDto.getQuantita(row);
    });

    return it;
  }

  istanzeFaseLavorazione: IstanzaFaseLavDto[] = new Array<IstanzaFaseLavDto>();
  fasiLavorazione: FaseLavorazione[] = new Array<FaseLavorazione>();
  valuta: ValutaDto = new ValutaDto();
  tipoRigaOrdine: TipoRigaOrdine;
}

export class ValoriVariabiliAzienda {
  idValore: number;
  idRiga: number;
  idVariabile: number;
  valoreVariabile: string;
}

export class FaseLavorazione {
  idFaseLavorazione: 0;
  extCode: "";
  codFaseLavorazione: 0;
  descFaseLavorazione: "";
}

export class TipoRigaOrdine {
  idTipoRigaOrdine: number;
  codiceTipoRigaOrdine: string;
  descrizioneTipoRigaOrdine: string;
  validUntil: string;
}

export class UltimaVariazioneStatoRiga {
  idVariazione: number;
  idStatoRigaOrdine: number;
  idRiga: number;
  tsVariazione: string;
  extCode: string;
  validUntil: string;
  descrizione: string;
}
