import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-input-field-dec',
  templateUrl: './input-field-dec.component.html',
  styleUrls: ['./input-field-dec.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class InputFieldDecComponent {
  @Input() type = "";
  @Input() display = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() readonly = false;
  @Input() maxlength = 99999;
  @Input() errorRequired = ' *';
  @Input() errorNumeric = 'carattere non ammesso ';
  @Input() value = '';
  @Input() class = '';
  
  constructor() { }

  @Output() valueChange = new EventEmitter<string>();

  change(newValue) {
    this.valueChange.emit(newValue);
  }
 
}
