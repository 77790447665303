import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { ItemFilter } from "src/app/_filters/ItemFilter";
import { UserDto } from "src/app/_models/utenti/usersDto";
import { AService } from "src/app/_services/AService";
import { FileOrdiniDto } from "src/app/_models/fileOrdini/fileOrdinidto";
import { State } from "src/app/_models/State";
import { AziendeMapper } from "src/app/featureMain/service/aziende.service";
import { FileOrdiniState } from "../state/fileOrdiniState";
import { AziendeServiceNew } from "src/app/featureMain/service/aziende.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { HttpClient } from "@angular/common/http"

class FileOrdiniMapper implements IDTOMapper<FileOrdiniDto> {
  map(data: any): FileOrdiniDto {
    let item: FileOrdiniDto = new FileOrdiniDto();

    item.fileOrdini = data.fileOrdini;
    item.autore = data.autore;
    item.tipoFile = data.tipoFile;
    item.flagStato = data.flagStato;
    return item;
  }
}

@Injectable({
  providedIn: "root",
})
export class UploadService extends AService<AziendeDto> {

  state(): State<AziendeDto> {
    let currentState:  State<AziendeDto> = new  State<AziendeDto>();
    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;

    return currentState;
  }

  mapper: IDTOMapper<AziendeDto> = new AziendeMapper();
}
