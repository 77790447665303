import { Component, OnInit } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";

import { TipiEsenzioneEditComponent } from "../tipi-esenzione-edit/tipi-esenzione-edit.component";

import { AlertService } from "src/app/_services/alert.service";
import { TipiEsenzioneDto } from "src/app/_models/tipiesenzione/tipiesenzione-dto";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { TipiEsenzioneService } from "../../service/tipi-esenzione.service";
import { HttpClient } from "@angular/common/http";
import { AndFilter } from "src/app/_filters/AndFilter";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { EsenzioniFilter } from "../../filtri/esenzioniFilter";
import { environment } from "src/environments/environment";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
@Component({
  selector: "app-tipi-esenzione-page-list",
  templateUrl: "./tipi-esenzione-page-list.component.html",
  styleUrls: ["./tipi-esenzione-page-list.component.css"],
})
export class TipiEsenzionePageListComponent implements OnInit {
  /*  search: TipoSearch;
  alltype: AllComboboxDataDto;
  datasource: GlobalDataSource<TipiEsenzioneDto>;
  datasource$;
  rows;
  total$: Observable<number>;
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";
  destroy: Subject<boolean> = new Subject<boolean>();
  displayedRows: any;
 */

  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  esenzioneDataSource: RestDataSource;
  state: State<TipiEsenzioneDto> = new State();

  constructor(
    private modalservice: NgbModal,
    private allComboData: ApiAllComboBoxdataService,
    private esenzioneService: TipiEsenzioneService,
    private alertSrv: AlertService,
    private http: HttpClient
  ) {
    this.esenzioneDataSource = new RestDataSource(this.http);

    this.esenzioneDataSource.setVariablesApi(
      "allTipoEsenzione",
      "tipoEsenzione",
      "tipoEsenzione",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    this.esenzioneDataSource.setByIdField("IDtipo");

    this.esenzioneService.connectDataSource(this.esenzioneDataSource);
  }

  _searchTerm: string;
  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this.loadData();
  }

  get searchTerm() {
    return this._searchTerm;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.loadData();
  }
  get pageSize() {
    return this._pageSize;
  }
  set page(newPage: number) {
    this._page = newPage;

    this.loadData();
  }

  get page() {
    return this._page;
  }

  loadData() {
    this.esenzioneService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterEsenzioni(service);

        console.log(this._page + " " + this._pageSize);
      },
    });
  }

  filterEsenzioni(service: TipiEsenzioneService) {
    console.log(this._searchTerm);

    this.state = service
      .filter(
        new AndFilter([
          new FieldsFilter({
            fieldList: [
              "codiceTipoEsenzione",
              "nomeTipoEsenzione",
              "regimeTipoEsenzione",
              
            ],
            searchString:
              this._searchTerm == undefined || this._searchTerm == null
                ? ""
                : this._searchTerm,
          }),
          new EsenzioniFilter(null),
        ])
      )
      .sort(this._sortColumn, this._sortDirection)
      .paginate(this._page, this.pageSize)
      .state();
  }

  ngOnInit(): void {
    this._sortColumn = "codiceTipoEsenzione";
    this._sortDirection = "asc";
    this._rotateDirection = "asc";
    this.loadData();
  }

  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");
    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.filterEsenzioni(this.esenzioneService);
  }

  onTipoEsenzioneCreateOrModify(index: number): void {
    let modalRef: any = null;

    modalRef = this.modalservice.open(TipiEsenzioneEditComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });

    let tipoEsenzione: TipiEsenzioneDto = new TipiEsenzioneDto();
    let typeop = "ins";

    if (index >= 0) {
      tipoEsenzione = this.state.paginatedItems[index];

      typeop = "upd";
    }

    modalRef.componentInstance.currentItem = tipoEsenzione;

    modalRef.componentInstance.typeop = typeop;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log(data);

          if (typeop == "ins") {
            return this.esenzioneService.create(data as TipiEsenzioneDto);
          } else if (typeop == "upd") {
            if (modalRef.componentInstance.delete == false)
              return this.esenzioneService.update(data as TipiEsenzioneDto);
            else {
              typeop = "del";

              return this.esenzioneService.delete(tipoEsenzione.idEsenzione);
            }
          }
        })
      )
      .subscribe(
        (response: CindyCustomResponse) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";

          this.loadData();
          this._sortColumn = "idEsenzione";
          this._sortDirection = "desc";
          this._rotateDirection = "desc";

          if (response.codEsito == "OK") {
            this.onSuccessPrint(typeop);
          } else {
            this.onErrorPrint(typeop);
          }
        },
        (err) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";
          modalRef.componentInstance.error(err);
        }
      );
  }

  onErrorPrint(typeop: string) {
    if (typeop == "ins"){
      this.alertSrv.error(Globals.printCreateMessageFailure("il Tipo Esenzione"));
    }else if (typeop == "upd") {
      this.alertSrv.error(Globals.printModifyMessageFailure("il Tipo Esenzione"));
    } else {
      this.alertSrv.error(Globals.printDeleteMessageFailure("il Tipo Esenzione"));
    }
  }

  onSuccessPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.success(
        Globals.printCreateMessageSuccess("Il Tipo Esenzione")
      );
    else if (typeop == "upd") {
      this.alertSrv.success(
        Globals.printModifyMessageSuccess("Il Tipo Esenzione")
      );
    } else {
      this.alertSrv.success(
        Globals.printDeleteMessageSuccess("Il Tipo Esenzione")
      );
    }
  }
}
