import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDTOMapper } from 'src/app/_helpers/IDTOMapper';
import { ItemFilter } from 'src/app/_filters/ItemFilter';
import { FasiLavorazioneDto } from 'src/app/_models/fasilavorazione/fasilavorazione-dto';
import { AService } from 'src/app/_services/AService';
import { State } from 'src/app/_models/State';

class FasiLavorazioneMapper implements IDTOMapper<FasiLavorazioneDto>{
  map(data: any): FasiLavorazioneDto {
    
    let item:FasiLavorazioneDto = new FasiLavorazioneDto();

    item.idFaseLavorazione = data.idFaseLavorazione;
    item.codFaseLavorazione = data.codFaseLavorazione;
    item.descFaseLavorazione = data.descFaseLavorazione;
    item.codOperazioneLavorazione = data.codOperazioneLavorazione;
    item.codAvanzamentoLavorazione = data.codAvanzamentoLavorazione;
    item.extCode = data.extCode;
    item.validUntil = data.validUntil;

  
    return item;
  }

}

@Injectable({
  providedIn: 'root'
})
export class FasiLavorazioneService extends AService<FasiLavorazioneDto>{

  state(): State<FasiLavorazioneDto> {
     
    let currentState:State<FasiLavorazioneDto> = new State<FasiLavorazioneDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<FasiLavorazioneDto> = new FasiLavorazioneMapper();}
