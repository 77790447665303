import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDTOMapper } from 'src/app/_helpers/IDTOMapper';
import { ItemFilter } from 'src/app/_filters/ItemFilter';
import { TipoRigaOrdine } from 'src/app/featureOrdini/models/rigaOrdineDto';
import { AService } from 'src/app/_services/AService';
import { State } from 'src/app/_models/State';


class TipoRigaOrdineMapper implements IDTOMapper<TipoRigaOrdine>{
  map(data: any): TipoRigaOrdine {
    
    let item:TipoRigaOrdine = new TipoRigaOrdine();

    item.idTipoRigaOrdine = data.idTipoRigaOrdine;
    item.codiceTipoRigaOrdine = data.codiceTipoRigaOrdine;
    item.descrizioneTipoRigaOrdine = data.descrizioneTipoRigaOrdine;
    item.validUntil = data.validUntil;
    return item;
  }

}

@Injectable({
  providedIn: 'root'
})
export class TipiRigaOrdineService extends AService<TipoRigaOrdine> {


  state(): State<TipoRigaOrdine> {
     
    let currentState:State<TipoRigaOrdine> = new State<TipoRigaOrdine>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<TipoRigaOrdine> = new TipoRigaOrdineMapper();
  }
