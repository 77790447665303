import { Injectable } from '@angular/core';
import { ScalatureDto } from '../models/scalature-dto';
import { CrudService } from 'src/app/_services/crud.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ScalatureMapper } from '../mappers/scalatureMappers';
import { AlertService } from 'src/app/_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ScalatureCRUDService extends CrudService<ScalatureDto>{

  constructor(
    httpClient: HttpClient,
    public alertSrv: AlertService,

  
 ) { 
    super(httpClient, alertSrv);
 this.serverPort=environment.anaPort
 this.endpoint= 'scalaturaEstesa'
 this.endpointAll='allScalatureEstese';

 
 this.mapper= new ScalatureMapper()
 }}
