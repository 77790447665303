
export class ImballiDto {
  idImballo: number;
  descrizione: string;
  codice: string;
  misura: string;
  contenutoScatole: number;
  pesoLordo: number;
  pesoNetto: number;
  lunghezza: number;
  larghezza: number;
  altezza: number
  volumecm: number;
  volumem: number;
  extCode: string;
  validUntil: Date;
}
