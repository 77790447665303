import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDTOMapper } from 'src/app/_helpers/IDTOMapper';
import { ItemFilter } from 'src/app/_filters/ItemFilter';
import { UserDto } from 'src/app/_models/utenti/usersDto';
import { AService } from 'src/app/_services/AService';
import { State } from 'src/app/_models/State';


class UserMapper implements IDTOMapper<UserDto>{
  map(data: any): UserDto {
    
    let item:UserDto = new UserDto();

    item.userID = data.userID;
    item.username = data.username;
    item.password = data.password;
    item.validUntil = data.validUntil;
    return item;
  }

}


@Injectable({
  providedIn: 'root'
})
export class UserService extends AService<UserDto> {


  state(): State<UserDto> {
     
    let currentState:State<UserDto> = new State<UserDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;

    return currentState;
   }
  
   mapper:IDTOMapper<UserDto> = new UserMapper();}
