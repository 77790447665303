import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FileOrdiniImportazioni } from "src/app/_models/fileOrdini/fileOrdinImportazioni";
import { GestioneFileService } from "src/app/_services/gestione-file.service";
import { DettaglioErrori } from "src/app/_models/fileOrdini/dettaglioErrori";
import { UserDto } from "src/app/_models/utenti/usersDto";
import { Observable, map, tap } from "rxjs";
import { UsersCRUDService } from "../service/users-crud.service";
import { codiciErrore } from "src/assets/data/codiciErrore";
@Component({
  selector: "app-dettaglio-errori",
  templateUrl: "./dettaglio-errori.component.html",
  styleUrls: ["./dettaglio-errori.component.css"],
})
export class DettaglioErroriComponent implements OnInit {
  currentItem: FileOrdiniImportazioni;
  listaErrori: DettaglioErrori[];
  listaAutori$: Observable<UserDto[]> | undefined = undefined;
  autore$: Observable<UserDto> | undefined = undefined;

  autore: UserDto;
  nomeFileOrdine;
  erroreSistema = codiciErrore.erroreSistema;
  constructor(
    public activeModal: NgbActiveModal,
    private tableexcelService: GestioneFileService,
    private userSrv: UsersCRUDService
  ) {
    this.listaAutori$ = this.userSrv.getAll().pipe(
      map((res) => {
        return JSON.parse(res.dati);
      })
    );
  }
  showErroreSistemaAlert = false;
  ngOnInit(): void {
    console.log("errori", JSON.parse(this.currentItem.descEsito));
    this.listaErrori = JSON.parse(this.currentItem.descEsito);
    let erroreSistemaEsistente = this.listaErrori.find(
      (err) => err.codiceErrore.toString().trim() == codiciErrore.erroreSistema.trim()
    );
    if (erroreSistemaEsistente) {
      this.showErroreSistemaAlert = true;
    } else {
      this.showErroreSistemaAlert = false;
    }

    // console.log("listaAutori",this.listaAutori);
    console.log("currentItem", this.currentItem);
    this.autore$ = this.listaAutori$.pipe(
      tap((res) => console.log("res tap", res)),
      map((res) => {
        let utente: UserDto;
        if (
          this.currentItem.autore !== null &&
          this.currentItem.autore !== undefined
        ) {
          utente = res.find((utente) => {
            return utente.userID == this.currentItem.autore;
          });
          return utente;
        } else {
          utente = {
            userID: "",
            username: "Sconosciuto",
            password: "",
            validUntil: "",
          };

          return utente;
        }
      })
    );
  }

  onClose() {
    this.activeModal.close("close");
  }

  getErrori(descEsito: string) {
    let errori = [];

    if (descEsito !== null && descEsito !== undefined)
      errori = JSON.parse(descEsito);

    // console.log(errori);

    return errori;
  }

  getExcelData(): void {
    let testataErrori = ["Testata"];

    let errori = this.listaErrori;

    this.tableexcelService.exportAsExcelFile(errori, "Tabella Errori");
  }
}
