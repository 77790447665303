import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Globals } from "src/app/_models/Globals";

@Component({
  selector: "app-pop-up-istanza-esistente",
  templateUrl: "./pop-up-istanza-esistente.component.html",
  styleUrls: ["./pop-up-istanza-esistente.component.css"],
})
export class PopUpIstanzaEsistenteComponent implements OnInit {
  @Output() confirm = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  codiceErrore;
  messaggio;
  messaggioEventuale;
  testata
  ;

  ngOnInit(): void {
    this.messaggio = Globals.MappaMessaggiErrore.get(this.codiceErrore);
    console.log("MessaggioEventuale", this.messaggioEventuale);
    if(this.testata == undefined){
      this.testata = "Attenzione"
    } else {
      this.testata = this.testata
    }
  }

  onSubmit(): void {
    let vm = true;
    this.confirm.emit(vm);
    console.log(vm);
  }
  onClose(): void {
    this.activeModal.close("close");
  }
}
