import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';

import { UserRolesPageLIstComponent } from './component/user-roles-page-list/user-roles-page-list.component';
import { UserRolesEditComponent } from './component/user-roles-edit/user-roles-edit.component';

@NgModule({
  declarations: [
    UserRolesPageLIstComponent,
    UserRolesEditComponent,
  ],

  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule
  ]
})
export class FeatureUserRolesModule { }
