export class DDTEstesoDto{
    ddt:DDT;
    righeDDT:RigheDDT[];
}

export class DDT{

idDDT: number;
lotto: number;
codiceFornitore: string;
descFornitore: string;
codiceDDT: string;
dataOda: string;
compilazioneDDT: string;
causale: string;
note: string;
flagDDTFinale: boolean;
extCode: string;
validUntil: string;

}

export class RigheDDT{
    idOda: number;
    idRiga:number;
    idDDT: number;
    idRigaOda: number;
    codiceComponente: string;
    coloreComponente: string;
    qntOrdinata: number;
    qntConsegnata: number;
    qntInArrivo: number;
    extCode: string;
    validUntil: string;
}