import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { ItemFilter } from "src/app/_filters/ItemFilter";
import { ScalaturaDto } from "src/app/featureScalature/models/scalatura-dto";
import { AService } from "src/app/_services/AService";
import { State } from "src/app/_models/State";
import {
  DettagliRelazioniScalaturaDto,
  RelazioniDto,
  ScalatureDto,
} from "src/app/featureScalature/models/scalature-dto";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
// import {
//   SviluppoTaglieMapper,
//   SviluppoTaglieService,
// } from "src/app/featureSviluppiTaglia/service/sviluppo-taglie.service";
import { ScalatureState } from "../state/stateScalaturePerSviluppi";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { HttpClient } from "@angular/common/http";
import { LoggerService } from "src/app/shared/services/logger.service";
import { environment } from "src/environments/environment";
export class ScalatureMapper implements IDTOMapper<ScalatureDto> {
  relazioniMapper: RelazioniScalatureMapper = new RelazioniScalatureMapper();

  map(data: any): ScalatureDto {
    let scal = new ScalatureDto();
    scal.scalatura.idScalatura = data.scalatura.idScalatura;
    scal.scalatura.codiceScalatura = data.scalatura.codiceScalatura;
    scal.scalatura.extCode = data.scalatura.extCode;
    scal.scalatura.validUntil = data.scalatura.validUntil;
    scal.relazioni = [
      ...data.relazioni.map((r) => this.relazioniMapper.map(r)),
    ];

    return scal;
  }
}

export class RelazioniScalatureMapper implements IDTOMapper<RelazioniDto> {
  dettagliMapper: DettagliRelazioneScalaturaMapper =
    new DettagliRelazioneScalaturaMapper();
  map(data: any): RelazioniDto {
    let relazione: RelazioniDto = new RelazioniDto();

    relazione.idRelazione = data.idRelazione;
    relazione.idScalatura = data.idScalatura;
    relazione.idSviluppoTaglia = data.idSviluppoTaglia;
    relazione.codiceSviluppoTaglia = data.codiceSviluppoTaglia;
    relazione.dettagli = [
      ...data.dettagli.map((d) => this.dettagliMapper.map(d)),
    ];

    return relazione;
  }
}

class DettagliRelazioneScalaturaMapper
  implements IDTOMapper<DettagliRelazioniScalaturaDto>
{
  map(data: any): DettagliRelazioniScalaturaDto {
    let dr = new DettagliRelazioniScalaturaDto();

    dr.idDettaglio = data.idDettaglio;
    dr.idRelazione = data.idRelazione;
    dr.indice = data.indice;
    dr.label = data.label;

    return dr;
  }
}

@Injectable({
  providedIn: "root",
})
export class ScalatureService extends AService<ScalatureDto> {
  private _listaSviluppi: SviluppiTaglieDto[];
  sviluppiDataSource: RestDataSource;
  constructor(
   // public sviluppiSrv: SviluppoTaglieService,
    private http: HttpClient,
    public loggerService:LoggerService
  ) {
    super();
    this.sviluppiDataSource = new RestDataSource(this.http);
    this.sviluppiDataSource.setVariablesApi(
      "allSviluppiTaglia",
      "sviluppoTaglia",
      "sviluppoTaglia",
      `${environment.baseDomain}:${environment.anaPort}`    );
    this.sviluppiDataSource.setByIdField("IdSviluppo");
 //   this.sviluppiSrv.connectDataSource(this.sviluppiDataSource);
  }

  override loadState(): Observable<this> {

    // const obs = new Observable<this>(sub => {

    //   forkJoin({
    //     listaScalature: super.loadState(),
    //     listaSviuppi: this.sviluppiSrv.loadState()
    //   }).subscribe({
    //     next: res =>{

    //  //     this._listaSviluppi = res.listaSviuppi.state().items;

    //       sub.next(res.listaScalature)

    //     },
    //     error(err) {
            
    //         this.loggerService.error(err);
    //     },
    //   })
    // });

    // return obs;

    return null;

  }

  state(): ScalatureState {
    let currentState: ScalatureState = new ScalatureState();
    currentState.listaSviluppi = this._listaSviluppi;
    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
  }

  mapper: ScalatureMapper = new ScalatureMapper();
}
