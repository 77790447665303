import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Pipe,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Ordine, OrdiniDto } from "src/app/featureOrdini/models/ordiniDto";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { Utility } from "src/app/_zCore/utility";
import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import {
  RigaOrdine,
  RigaOrdineDto,
} from "src/app/featureOrdini/models/rigaOrdineDto";
import {
  ArticoliDto,
  ArticoloEstesoDto,
  DistintaBase,
} from "src/app/_models/articoli/articoliDto";
import { ApiService } from "src/app/_services/api.service";
import { environment } from "src/environments/environment";
import { SviluppoDto } from "src/app/featureScalature/models/sviluppo-dto";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
import { TipoSearch } from "src/app/_models/TipoSearch";
import {
  ArticoliService,
  OrdiniService,
  SviluppoTagliService as SviluppoTaglieService,
  TipiOrdineService,
} from "src/app/_services/allServices";
import { DTOMapper } from "src/app/_helpers/dtoMappper";
import { EditRigaOrdineComponent } from "../edit-riga-ordine/edit-riga-ordine.component";
import { AuthService } from "src/app/_services/auth.service";
import { TipiOrdineDto } from "src/app/featureTipoOrdine/models/tipiordine-dto";
import { PopUpConfermaComponent } from "src/app/_popups/pop-up-conferma/pop-up-conferma.component";
import { DatePipe } from "@angular/common";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { OrdiniState } from "../state/ordiniState";
import { DistintaBaseService } from "src/app/shared/services/distintaBaseService/distinta-base.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { HttpClient } from "@angular/common/http";
import { State } from "src/app/_models/State";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { Globals } from "src/app/_models/Globals";
import { AlertService } from "src/app/_services/alert.service";
import { PopUpUploadOrdiniComponent } from "src/app/_popups/pop-up-upload-ordini/pop-up-upload-ordini.component";
import { Observable, Subscription, throwError } from "rxjs";
import { OrdiniProduzioneService } from "../../service/ordini-produzione.service";
import { StagioneDto } from "src/app/featureOrdini/models/stagioneDto";
import { TipiOrdineSearchService } from "src/app/featureTipoOrdine/service/tipi-ordine-search.service";
import { ConsegnaDto } from "../../models/consegnaDto";
import {
  OrdiniProdCRUDService,
  RigheOrdineCRUDService,
} from "../../service/ordini-prod-crud.service";
import { ErroriService } from "src/app/_services/errori-service.service";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { Variables } from "src/assets/data/variables";
export class subscriber {
  cmp: any = null;

  newVal: boolean;
  next(confirm: any) {
    // console.log("nuovoValore", this.newVal);

    if (confirm == true) {
      this.cmp.isStatoChanged = true;
      this.cmp.selectedStato = this.newVal;
    }
    // annulla
    else {
    }

    // if (confirm == true && this.cmp.selectedStato != this.newVal) this.cmp.selectedStato = this.newVal;
    // if(confirm == false && this.cmp.selectedStato == this.newVal){
    //   this.cmp.selectedStato = this.newVal;
    // }
    // else {
    //   this.cmp.selectedStato = !this.newVal;
    // }
  }
  error(err: any) {
    console.error("Observer got an error: " + err);
  }
  complete() {
    console.log("Observer got a complete notification");
  }
}
@Component({
  selector: "app-ordini-edit",
  templateUrl: "./ordini-edit.component.html",
  styleUrls: ["./ordini-edit.component.css"],
})
export class OrdiniEditComponent implements OnInit {
  @Output() confirm = new EventEmitter<OrdiniDto>();

  searchTerm: string;
  typeop = "";
  //  listaAziende$: Observable<AziendeDto[]>;

  listaStagioni$: Observable<StagioneDto[]> =
    this.ordiniSrv.listaStagioniByCliente$;
  enableListaStagioni$: Observable<boolean> =
    this.ordiniSrv.enablelistaStagioniSub$;

  listaAziendeConArticoli$: Observable<AziendeDto[]> =
    this.ordiniSrv.listaAziendeConArticoli$;
  listaTipiOrdine$: Observable<TipiOrdineDto[]> = this.tipiOrdineSrv.loadAll();

  //listaAziende;
  // listaTipiRigaOrdine;
  listaSviluppi$: Observable<SviluppiTaglieDto[]>;
  listaCodiciSviluppo$: Observable<string[]> | undefined = undefined;
  //listaArticoli;
  //listaOrdini;
  articoliDIBADataSource: RestDataSource;
  state: State<DistintaBase> = new State();
  //articoliEsistenti = false;
  idAziendaSelezionata: number;
  aziendaSelezionata$: Observable<AziendeDto>;
  alltype;
  delete: boolean = false;

  currentItem: OrdiniDto;
  selectedCodiceAzienda;
  indirizzo$: Observable<string>;
  codiceAzienda: string = "";

  selectedStato: boolean = false;
  rigaOrdineAttuale;
  tipiOrdine: TipiOrdineDto[] = [];
  showRow = false;
  codSv: number;

  //sviluppoSelezionato: SviluppiTaglieDto;
  arrayRigheOrdine: ArticoliDto[] = [];
  //sviluppiTot: SviluppiTaglieDto[] = [];
  // tipoOrdineSelezionato: TipiOrdineDto;
  isCindyOrder = false;
  showSviluppoSelezionato = false;
  enableRigaEdit = true;

  _dataCreazioneOrdine: Date;
  preCancellazione$: Observable<any>;
  enableDelete$: Observable<boolean>;
  constructor(
    public activeModal: NgbActiveModal,
    private ordiniSrv: OrdiniProduzioneService,
    private modalservice: NgbModal,
    public apiSrvOrdini: OrdiniService,
    public dibaSrv: DistintaBaseService,
    public authSrv: AuthService,
    public alertSrv: AlertService,
    private http: HttpClient,
    private rigaOrdineCRUDSrv: RigheOrdineCRUDService,
    private tipiOrdineSrv: TipiOrdineSearchService,
    public erroriSrv: ErroriService,
    public ordiniProdCRUDSrv: OrdiniProdCRUDService
  ) {
    this.articoliDIBADataSource = new RestDataSource(this.http);
    this.articoliDIBADataSource.setVariablesApi(
      null,
      "distintaBase",
      "distintaBase",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    this.articoliDIBADataSource.setByIdField("idArticolo");
    this.dibaSrv.connectDataSource(this.articoliDIBADataSource);
  }

  sviluppoSelezionato$: Observable<SviluppiTaglieDto>;
  tagLancio;
  ngOnInit(): void {
    //console.log("currentItem", this.currentItem.righeOrdine[0].rigaOrdine.idSviluppo);
    let utente = JSON.parse(localStorage.getItem("userData"));
    this.listaCodiciSviluppo$ = this.ordiniSrv.loadCodiciSviluppo(
      this.currentItem.righeOrdine,
      utente.userID
    );

    this.selectedStatoFromStringToBoolean();
    this.ordiniSrv.loadAziendeConArticoli();
    if (this.currentItem.ordine.codiceCliente)
      this.OnChangeSelect(parseInt(this.currentItem.ordine.codiceCliente));

    if (!this.currentItem.ordine.tagLancio) {
      let currentdate: any = new Date();
      let oneJan: any = new Date(currentdate.getFullYear(), 0, 1);
      let numberOfDays = Math.floor(
        (currentdate - oneJan) / (24 * 60 * 60 * 1000)
      );
      this.tagLancio = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
    } else {
      this.tagLancio = this.currentItem.ordine.tagLancio;
    }

    // this.prepareAziende();
    // console.log("listaArticoli", this.listaArticoli);

    this.sviluppoSelezionato$ = this.listaSviluppi$.pipe(
      map((sviluppi: SviluppiTaglieDto[]) => {
        return sviluppi.find((res) => {
          return res.idSviluppoTaglia == this.codSv;
        });
      })
    );
    this.preCancellazione$ = this.onControlloDeleteOrdine();

    // this.tipiOrdine = this.listaTipiOrdine;
    this.isCindyOrderfunction();

    console.log("righe", this.currentItem);

    this.aggiornaDistinteBase();

    if (this.typeop == "ins") {
      this._dataCreazioneOrdine = new Date();

      let newDate = new Date();
      this.currentItem.ordine.dataCreazioneOrdine =
        newDate.toLocaleDateString();
    }
  }

  onChangeStagione(datiStagione) {
    this.ordiniSrv.stagioneDataSource
      .getDataById(datiStagione)
      .subscribe((data: CindyCustomResponse) => {
        if (data) {
          let stagione: StagioneDto = JSON.parse(data.dati);
          this.currentItem.ordine.idStagione = stagione.idStagione;
          this.currentItem.stagione.idAzienda = this.idAziendaSelezionata;
          this.currentItem.stagione.idStagione = stagione.idStagione;
          this.currentItem.stagione.secolo = stagione.secolo;
          this.currentItem.stagione.numero = stagione.numero;
          this.currentItem.stagione.anno = stagione.anno;
        } else {
          this.alertSrv.error(
            Globals.MappaMessaggiErrore.get(codiciErrore.erroreResiduale)
          );
        }
      });

    console.log("stagione", this.currentItem.stagione);
  }
  listaArticoliAzienda$: Observable<object>;
  subArticoli: Subscription;

  loadArticoli(id: number) {
    let url = `${environment.baseDomain}:${environment.anaPort}/articoliByAzienda?idAzienda=${id}`;
    this.listaArticoliAzienda$ = this.http.get(url).pipe(
      // tap((res)=> {console.log("loadarticoli",res)}),

      map((res: CindyCustomResponse) => {
        let articoli: ArticoloEstesoDto[] = [];
        // console.log("res", res);
        if (res && res.codEsito.toLowerCase() == "ok") {
          articoli = JSON.parse(res.dati) as ArticoloEstesoDto[];

          if (articoli.length === 0) {
            this.alertSrv.error(
              Globals.MappaMessaggiErrore.get("KO.ARTMANCANTI")
            );
          }
          return articoli;
        }

        return articoli;
      })
    );
    /*  this.subArticoli = this.listaArticoliAzienda$.subscribe(
      (res: ArticoloEstesoDto[]) => {
        this.listaArticoli = res;
        console.log("res articoli", this.listaArticoli);

        if (this.listaArticoli.length !== 0) {
          this.articoliEsistenti = true;
          console.log("articoliEsistenti", this.articoliEsistenti);
        } else {
          this.articoliEsistenti = false;
          console.log("articoliEsistenti", this.articoliEsistenti);
        }
      }
    ); */
  }

  onDataCreazioneChanged(event) {
    this.currentItem.ordine.setFormattedDate(event);
  }

  /*  getCodiceSviluppo(idSviluppo): Observable<string> {
    return this.listaSviluppi$.pipe(
      map((sviluppi: SviluppiTaglieDto[]) => {
        let sviluppoById = sviluppi.find((sviluppo) => {
          return sviluppo.idSviluppoTaglia == idSviluppo;
        });

        return sviluppoById.codiceSviluppoTaglia;
      }),
      tap((res)=>console.log("tap",res))
    );
  } */

  selectedStatoFromStringToBoolean() {
    this.selectedStato = this.currentItem.ordine.flagTipoOrdine === "L";
  }

  selectedStatoFromBooleanToString() {
    if (this.selectedStato == false) {
      this.currentItem.ordine.flagTipoOrdine = "V";
    }
    if (this.selectedStato == true) {
      this.currentItem.ordine.flagTipoOrdine = "L";
    }
  }

  aggiornaDistinteBase() {
    if (this.currentItem) {
      this.currentItem.righeOrdine.forEach((riga) => {
        if (
          this.mappaArticoliRiga.get(riga.rigaOrdine.idArticolo) == undefined
        ) {
          // console.log("aggiorna distinta base",riga.rigaOrdine.idArticolo)
          this.dibaSrv.getbyId(riga.rigaOrdine.idArticolo).subscribe({
            next: (dB: CindyCustomResponse) => {
              if (dB && dB.codEsito === "OK") {
                let distintaBase: DistintaBase = JSON.parse(dB.dati);
                this.mappaArticoliRiga.set(
                  riga.rigaOrdine.idArticolo,
                  distintaBase
                );
                /*  console.log(
                  "mappa",
                  this.mappaArticoliRiga.get(riga.rigaOrdine.idArticolo)
                ); */
              } else {
                this.enableRigaEdit = false;

                this.erroriSrv.onToastConMessaggioAggiuntivo(
                  Globals.MappaMessaggiErrore.get(codiciErrore.dibaIncompleta),
                  `Articolo: ${riga.rigaOrdine.modello}-${riga.rigaOrdine.codiceParte}-${riga.rigaOrdine.codiceColore}`
                );
              }
            },
            error: (err) => {
              this.enableRigaEdit = false;
              // this.onClose()
              console.log("errore500", err);
              // this.onErroreDIBA();
              this.erroriSrv.onToastTestata(
                Globals.MappaMessaggiErrore.get(codiciErrore.dibaIncompleta)
              );
            },
          });
        }
      });
    }
  }

  distintaBase(numeroRigaOrdine): DistintaBase {
    return this.mappaArticoliRiga.get(
      this.currentItem.righeOrdine[numeroRigaOrdine].rigaOrdine.idArticolo
    );
  }

  mappaArticoliRiga: Map<number, DistintaBase> = new Map();

  onShowRow(index) {
    if (this.showRow == true && index <= 0) {
      this.showRow = false;
    } else {
      this.showRow = true;
      this.currentItem.righeOrdine.forEach((riga) => {
        if (
          this.showRow == true &&
          this.currentItem.righeOrdine.indexOf(riga) == index
        ) {
          this.codSv = riga.rigaOrdine.idSviluppo;
        }
      });
      // this.onSviluppo();
    }
  }

  /*   onSviluppo() {
    this.sviluppoSelezionato = this.sviluppiTot.find(
      (sviluppo: SviluppiTaglieDto) => {
        return this.codSv == sviluppo.idSviluppoTaglia;
      }
    );
  } */

  getLabels(idSviluppo, indice) {
    if (!idSviluppo && !indice) {
      this.erroriSrv.onToastTestata(
        Globals.MappaMessaggiErrore.get(codiciErrore.sviluppoMancante)
      );
    } else {
      // console.log("get Labels", indice);
      let quantita: number = 0;
      //  let qnt: number = this.currentItem.righeOrdine[indiceriga].taglieSku[indice].quantita
      this.rigaOrdineAttuale = this.currentItem.righeOrdine.find((riga) => {
        return riga.rigaOrdine.idSviluppo == idSviluppo;
      });

      // console.log("rigaOrdineAttuale",this.rigaOrdineAttuale)
      let tSkuObj = this.rigaOrdineAttuale.taglieSku.find((tsk) => {
        return tsk.codTaglia == parseInt(indice) + 1;
      });

      if (tSkuObj) {
        quantita = parseInt(tSkuObj.quantita);
        //   console.log("qnt",quantita)
      }
      return quantita;
      /*     if (indice < this.rigaOrdineAttuale.taglieSku.length) {
    quantita = this.rigaOrdineAttuale.taglieSku[indice].quantita;
  }
  return quantita;  */
    }
  }

  OnChangeSelect(idAzienda) {
    if (idAzienda !== undefined) {
      this.ordiniSrv.loadStagioneByCliente(idAzienda);
      let azienda: AziendeDto;
      this.aziendaSelezionata$ = this.listaAziendeConArticoli$.pipe(
        map((res) => {
          let az: AziendeDto = res.find(
            (az: AziendeDto) => idAzienda == az.idAzienda
          );

          return az;
        }),
        catchError((err) => {
          console.log("ErroreAziendaMancante", err);
          this.erroriSrv.onToastTestata(
            Globals.MappaMessaggiErrore.get(codiciErrore.aziendaMancante)
          );
          return throwError(err);
        })
      );

      this.codiceAzienda = idAzienda.toString();
      this.idAziendaSelezionata = idAzienda;
      this.loadArticoli(idAzienda);
    } else {
      this.erroriSrv.onToastTestata(
        Globals.MappaMessaggiErrore.get(codiciErrore.aziendaMancante)
      );
    }
  }

  OnChangeTagLancio(tagLancio) {
    this.currentItem.ordine.tagLancio = tagLancio;
  }

  onTipoOrdine(idTipoOrdine) {
    /*     let tiOr = idTipoOrdine;
    let tipoOrdine: TipiOrdineDto = this.tipiOrdine.find((to) => {
      return to.idTipoOrdine == tiOr;
    });
    this.tipoOrdineSelezionato = tipoOrdine; */
  }

  isStatoChanged: boolean = false;
  onChangeLavorazione(event) {
    if (this.typeop == "upd") {
      event.preventDefault();
      let newValue = event.target.checked;
      const modalRefConferma = this.modalservice.open(PopUpConfermaComponent, {
        size: "sm",
        windowClass: "animated FadeIn",
      });
      modalRefConferma.componentInstance.codiceConferma = "conferma";

      let sub = new subscriber();
      sub.cmp = this;
      sub.newVal = newValue;

      modalRefConferma.componentInstance.confirm.subscribe(sub);
      console.log("sub", sub.newVal);
    } else {
      if (this.selectedStato) {
        this.selectedStato = false;
      } else {
        this.selectedStato = true;
      }
    }

    /*       console.log("selected stato",this.selectedStato);


        this.currentItem.righeOrdine.forEach((riga)=>{}) */
  }

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);

    this.currentItem.ordine.codiceCliente = this.codiceAzienda;
    this.selectedStatoFromBooleanToString();
    if (
      this.currentItem.stagione.idStagione == undefined ||
      this.currentItem.stagione.idStagione == null
    ) {
      this.currentItem.stagione = null;
    }
    //console.log("anno", this.currentItem.ordine.anno);
    if (this.currentItem.ordine.dataCreazioneOrdine) {
    }
    //let dateToks = this.currentItem.ordine.dataCreazioneOrdine.split("/");

    // this.currentItem.consegna.dataConsegna = `${dateToks[2]}-${dateToks[1]}-${dateToks[0]}`;

    if (this.currentItem.ordine.anno == 0) {
      this.currentItem.ordine.anno = new Date().getFullYear();
    }

    if (this.currentItem.ordine.idOrdine == 0) {
      this.currentItem.ultimaVariazioneStato = {
        idVariazione: null,
        idOrdine: this.currentItem.ordine.idOrdine,
        idStatoOrdine: 0,
        descrizione: Variables.creato.toUpperCase(),
        tsVariazione: null,
        extCode: null,
        validUntil: null,
      };
    }

    console.log("emit ordini Edit", this.currentItem);
    this.confirm.emit(this.currentItem);
  }

  OnError(err: any): void {
    // console.log("errore edit", JSON.parse(err.error.dati).descErrore);
    /*    if(err && err.error) {
    let datiErrore= err.error
    this.erroriSrv.OnErrorOrdini(datiErrore)

   }
 */
    /*     if(err && err.error && err.error.codEsito == "KO.A18"){
      const modalRefErrore = this.modalservice.open(
        PopUpIstanzaEsistenteComponent,
        {
          size: "sm",
          windowClass: "animated FadeIn",
        }
      );
      modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
      modalRefErrore.componentInstance.messaggioEventuale = JSON.parse(err.error.dati).descErrore;
    } */
    //this.onClose();
  }

  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onRigaCreate() {
    //  if (this.articoliEsistenti == true) {
    const modalRef = this.modalservice.open(EditRigaOrdineComponent, {
      size: "xl",
      windowClass: "animated FadeIn",
    });
    let riga = new RigaOrdineDto();
    riga.rigaOrdine.idOrdine = this.currentItem.ordine.idOrdine;
    modalRef.componentInstance.currentItem = riga;
    modalRef.componentInstance.typeop = "ins";
    console.log("this.currentItem.ultimoLancio", this.currentItem.ultimoLancio);
    modalRef.componentInstance.ultimoLancioOrdine =
      this.currentItem.ultimoLancio;
    modalRef.componentInstance.ultimaVariazioneStatoOrdine =
      this.currentItem.ultimaVariazioneStato;

    modalRef.componentInstance.selectedStato = this.selectedStato;
    // modalRef.componentInstance.listaSviluppiTaglie$ = this.listaSviluppi$;
    modalRef.componentInstance.listaTipiRigaOrdine$ =
      this.ordiniSrv.loadTipiRigaOrdine();
    modalRef.componentInstance.listaArticoli$ = this.listaArticoliAzienda$;
    modalRef.componentInstance.codiceAzienda = this.codiceAzienda;
    // modalRef.componentInstance.listaOrdini = this.listaOrdini;

    modalRef.componentInstance.confirm.subscribe((res: RigaOrdineDto) => {
      let numeroRiga = (this.currentItem.righeOrdine.length + 1).toString();
      res.rigaOrdine.numeroRiga = numeroRiga;
      // console.log("riga ordine", res);

      this.currentItem.righeOrdine.push(res);

      this.aggiornaDistinteBase();
    });
    /*  } else {
      const modalRef = this.modalservice.open(PopUpUploadOrdiniComponent, {
        size: "md",
        windowClass: "animated FadeIn",
      });
      modalRef.componentInstance.showButton = false;
      modalRef.componentInstance.progressMessage =
        "Articoli Inesistenti per il Cliente selezionato";
      const sub = modalRef.componentInstance.confirm.pipe(
        map((data) => {
          console.log("close pop-up", data);
        })
      );
    } */
  }

  ngOnDestroy() {}
  /* 
  isUserSuper() {
    return this.authSrv.isUserSuper();
  } */
  getOrdineLabel() {
    return Ordine.getCodiceOrdine(this.currentItem.ordine);
  }

  isCindyOrderfunction() {
    if (
      this.currentItem.ordine.idFile == null ||
      this.currentItem.ordine.idFile == undefined ||
      this.currentItem.ordine.idFile == 0
    ) {
      this.isCindyOrder = true;
    } else {
      this.isCindyOrder = false;
    }
  }

  calcolaImportoTotale(indiceRiga: number) {
    return RigaOrdineDto.getImportoTotale(
      this.currentItem.righeOrdine[indiceRiga]
    );
  }

  onAnnullaOrdine() {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    modalRef.componentInstance.testoBottone = "CONFERMA";
    modalRef.componentInstance.testoBody =
      "Sei sicuro di voler annullare questo elemento?";
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((res) => {
          console.log("res confirm annulla", res);
          return res;
        }),
           switchMap((res) => {
          console.log("res confirm annulla", res);

          if (res) {
            console.log("annullaOrdine", this.currentItem);
            return this.ordiniProdCRUDSrv.onAnnullaOrdine(
              this.currentItem.ordine.idOrdine,
              this.currentItem
            );
          }
        }),
     
      )
      .subscribe(
        (res: CindyCustomResponse) => {
          console.log("res annulla", res);
          if(res){
            modalRef.close();
            console.log("res dati", JSON.parse(res.dati));
           // this.onAnnullaRighe(this.currentItem.righeOrdine)
           this.onClose()
           window.location.reload()
          
          }
      
        },
        (err) => {
          console.log("err annulla", err);
          console.log("err dati", JSON.parse(err.error).dati);
        }
      );
  }


  onAnnullaRighe(righe:RigaOrdineDto[]){
    righe.forEach((riga)=>{
      this.ordiniProdCRUDSrv.onAnnullaRigaOrdine(riga.rigaOrdine.idRiga).subscribe((res)=> {
        console.log("annulla righe",res)
        this.onClose()
        window.location.reload()

      })
    })
  }
  calcolaquantita(indiceRiga: number) {
    return RigaOrdineDto.getQuantita(this.currentItem.righeOrdine[indiceRiga]);
  }

  onRigaModify(index) {
    const item = this.currentItem.righeOrdine[index];
    const modalRef = this.modalservice.open(EditRigaOrdineComponent, {
      size: "xl",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.currentItem = item;
    modalRef.componentInstance.typeop = "upd";
    //modalRef.componentInstance.consegna = this.currentItem.consegna;
    modalRef.componentInstance.isStatoChanged = this.isStatoChanged;
    console.log("this.currentItem.ultimoLancio", this.currentItem.ultimoLancio);

    modalRef.componentInstance.ultimoLancioOrdine =
      this.currentItem.ultimoLancio;
    modalRef.componentInstance.ultimaVariazioneStatoOrdine =
      this.currentItem.ultimaVariazioneStato;

    modalRef.componentInstance.selectedStato = this.selectedStato;
    // modalRef.componentInstance.listaSviluppiTaglie$ = this.listaSviluppi$;
    modalRef.componentInstance.listaTipiRigaOrdine$ =
      this.ordiniSrv.loadTipiRigaOrdine();
    modalRef.componentInstance.listaArticoli$ = this.listaArticoliAzienda$;
    modalRef.componentInstance.codiceAzienda = this.codiceAzienda;
    // modalRef.componentInstance.listaOrdini = this.listaOrdini;

    modalRef.componentInstance.confirm.subscribe((res: RigaOrdineDto) => {
      console.log("riga ordine modify", res);
      this.currentItem.righeOrdine[index] = res;

      this.aggiornaDistinteBase();
    });

    console.log("modifica riga", this.currentItem);
  }

  onRigaDelete(index): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem.righeOrdine[index];
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        // tap((res)=> console.log(res)),
        switchMap((res) => {
          if (res) {
            return this.rigaOrdineCRUDSrv.delete({
              variable: "idRigaOrdine",
              value: item.rigaOrdine.idRiga,
            });
          }
        })
      )
      .subscribe(
        (res: CindyCustomResponse) => {
          if (res.codEsito == "OK") {
            this.showRow = false;
            this.currentItem.righeOrdine.splice(index, 1);
            modalRef.componentInstance.onClose();
            this.alertSrv.success(
              Globals.printDeleteMessageSuccess("Riga Ordine")
            );
          } else {
            this.erroriSrv.onToast(
              Globals.printDeleteMessageFailure("Riga Ordine")
            );
            /*    this.alertSrv.error(
              Globals.printDeleteMessageFailure("Riga Ordine")
            ); */
          }

          console.log("delete Riga", res);
        },
        (err) => {
          console.log("errore delete riga", err);
          //this.alertSrv.error(Globals.printDeleteMessageFailure("Riga Ordine"));
          this.erroriSrv.onToast(
            Globals.printDeleteMessageFailure("Riga Ordine")
          );
        }
      );
    /*  const sub = modalRef.componentInstance.confirm.subscribe((res) => {
       this.showRow = false;
       this.currentItem.righeOrdine.splice(index, 1);
       modalRef.componentInstance.onClose();
     }); */
  }

  onControlloDeleteOrdine(): Observable<any> {
    return this.ordiniProdCRUDSrv
      .preCancellazione(this.currentItem.ordine.idOrdine)
      .pipe(
        map((res: CindyCustomResponse) => {
          if (res) {
            let dati = JSON.parse(res.dati);
            console.log("enable", res);
            if (parseInt(dati.numOdaNonPo) > 0) {
              let avviso = `L'ordine non può essere cancellato perché collegato a ${dati.numOdaNonPo} ODA`;
              let enableBottone = false;
              let oggettoAvviso = {
                avviso: avviso,
                enableBottone: enableBottone,
              };
              return oggettoAvviso;
            } else {
              if (parseInt(dati.numPo) > 0) {
                let avviso = `L'ordine è collegato a ${dati.numPo} PDA`;
                let enableBottone = true;
                let oggettoAvviso = {
                  avviso: avviso,
                  enableBottone: enableBottone,
                };
                return oggettoAvviso;
              } else {
                let oggettoAvviso = {
                  avviso: "",
                  enableBottone: true,
                };
                return oggettoAvviso;
              }
            }
          } else {
            let oggettoAvviso = {
              avviso: "",
              enableBottone: true,
            };
            return oggettoAvviso;
          }
        })
      );
  }

  onDelete() {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new OrdiniDto();

          this.delete = true;
          // console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          // console.log("response delete",response);

          modalRef.componentInstance.onClose();
        },
        (err) => {
          console.log(err);
          modalRef.componentInstance.onClose();
        }
      );
  }
}
