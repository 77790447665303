import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-anasnelle',
  templateUrl: './dashboard-anasnelle.component.html',
  styleUrls: ['./dashboard-anasnelle.component.css']
})
export class DashboardAnasnelleComponent implements OnInit {
  ngOnInit(): void {
   console.log("src",this.src);
  }

  src= environment.anasnelleDashboard


}
