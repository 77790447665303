import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';
import { MagazziniGeneralePagelistComponent } from './components/magazzino-generale-pagelist/magazzino-generale-pagelist.component';
import { FeatureFileMagazziniComponent } from './components/feature-file-magazzini/feature-file-magazzini.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { BreadcrumbModule } from '../_layout/breadcrumb/breadcrumb.module';
import { CardModule } from '../content/partials/general/card/card.module';


@NgModule({
  declarations: [  
    MagazziniGeneralePagelistComponent, FeatureFileMagazziniComponent
  ],

  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule,
    NgxDropzoneModule,
    BreadcrumbModule,
    CardModule,
  ]
})
export class MagazziniGeneraliModule { }
