import { Observable, of } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { IDataSource } from "../_datasources/IDataSource";
import { ItemFilter } from "../_filters/ItemFilter";
import { IDTOMapper } from "../_helpers/IDTOMapper";
import { State } from "../_models/State";

// interfaccia generica di un servizio
export abstract class AService<T> {
  _dataSource: IDataSource;

  _items: T[] = [];

  _resultItems: T[] = [];

  mapper: IDTOMapper<T>;

  _paginatedItems: T[];

  connectDataSource(ds: IDataSource) {
    this._dataSource = ds;
  }

  loadState(): Observable<this> {
    let service = this;

    return this._dataSource.getAllData().pipe(
      map((data: any) => {
        service._items = [];

        let datiItems: any[] = [];

        if (data.dati !== undefined) {
          datiItems = JSON.parse(data.dati);
        } else datiItems = data;

        datiItems.forEach((i) => service._items.push(this.mapper.map(i)));

        this._resultItems = service._items;
//console.log("qts",this._resultItems)
        return this;
      })
    );
  }

  abstract state(): State<T>;

  filter(filter: ItemFilter): this {
    //console.log("filter",this._resultItems)
    this._resultItems = filter.execute(this._items);

    return this;
  }

  paginate(page: number, pageSize: number): this {
    const start = (page - 1) * pageSize;
    const end = Math.min(start + pageSize, this._resultItems.length);
    //console.log("start",start,"end",end)
    this._paginatedItems = this._resultItems.slice(start, end);
//console.log("paginatedItems",this._paginatedItems)
    return this;
  }

  public readonly SORT_ASC: string = "asc";
  public readonly SORT_DESC: string = "desc";
  public readonly SORT_NONE: string = "";

  compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  sort(sortColumn: string, sortDirection: string): this {
    if (sortDirection === this.SORT_NONE) {
      return this;
    } else {
      this._resultItems = [...this._resultItems].sort((a, b) => {
        const res = this.compare(a[sortColumn], b[sortColumn]);
        return sortDirection === this.SORT_ASC ? res : -res;
      });

      return this;
    }
  }

  clear(): this {
    this._resultItems = [];

    return this;
  }

  getAllData(): Observable<object> {
    return this._dataSource.getAllData();
  }

  create(data: T): Observable<object> {
    //console.log("create", this._dataSource)
    //console.log("create", data)

    return this._dataSource.insertData(data);
  }

  multipleCreate(data: T[]): Observable<object> {
    return this._dataSource.insertData(data);
  }

  update(data: T): Observable<object> {
  //  console.log("update", this._dataSource)
    return this._dataSource.updateData(data);
  }

  delete(id: number): Observable<object> {
 //   console.log("delete", this._dataSource)

    return this._dataSource.deleteData(id);
  }

  getbyId(id: number): Observable<object> {
    return this._dataSource.getDataById(id);
  }

  getDataByMultipleId(stringOfId: string): Observable<Object> {
   // console.log("idmultiply",stringOfId)
    return this._dataSource.getDataByMultipleId(stringOfId);
  }
}
