// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  codeMenuItem:string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  badge: { type: string; value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[];
  };
  vertical_menu: {
    items: Partial<MenuItem>[];
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: [
      {
        title: "Anagrafica",
        icon: "la-file",
        page: "/aziendelist",
      } /*,
      {
        title: 'Anagrafica (Prova)',
        icon: 'la-file',
        page: '/customer'
      },
      {
        title: 'StyleTable (Prova)',
        icon: 'la-file',
        page: '/styletable'
      },
      {
        title: 'Changelog',
        icon: 'la-file',
        page: '/changelog',
        badge: { type: 'badge-danger', value: '2.7' }
      },
      {
        title: 'Templates',
        icon: 'la-television',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Horizontal',
              page: 'null'
            },
            {
              title: 'Vertical',
              page: 'null'
            },
          ]
        }
      },
      {
        title: 'Raise Support',
        icon: 'la-support',
        page: 'https://pixinvent.ticksy.com/',
        isExternalLink: true
      },
      {
        title: 'Documentaion',
        icon: 'la-text-height',
        page: 'https://modern-admin-docs.web.app/html/ltr/documentation/index.html',
        isExternalLink: true,
      }*/,
    ],
  },
  vertical_menu: {
    items: [
      {
        title: "Anagrafiche",
        icon: "la-book",
        page: "/dashboard",
        codeMenuItem: "ANAGRAFICHE",
        submenu: {
          items: [
            {
              title: "Aziende",
              icon: "la-building-o",
              page: "/aziendelist",
              codeMenuItem:"AZIENDE"
            },
            {
              title: "Porti",
              icon: "la-building",
              page: "/portilist",
              codeMenuItem:"PORTI"
            },
            {
              title: "Imballi",
              icon: "la-building",
              page: "/imballilist",
              codeMenuItem:"IMBALLI"
            },
     /*        {
              title: "Fasi di Lavorazione",
              icon: "la-building",
              page: "/fasilavorazione",
              codeMenuItem:"FASILAVORAZIONE"
            }, */
      /*       {
              title: "Recapiti",
              icon: "la-building",
              page: "/recapiti",
              codeMenuItem:"RECAPITI",
            }, */
            {
              title: "Scalature",
              icon: "la-building",
              page: "/scalature",
              codeMenuItem:"SCALATURE",
            },
            {
              title: "Sviluppi Taglie",
              icon: "la-building",
              page: "/sviluppitaglie",
              codeMenuItem:"SVILUPPITAGLIE"
            },
            {
              title: "Esenzioni",
              icon: "la-building",
              page: "/tipiesenzione",
              codeMenuItem:"ESENZIONI",
            },

            {
              title: "Aliquote IVA",
              icon: "la-building",
              page: "/tipiesenzioneIva",
              codeMenuItem:"ALIQUOTEIVA",
            },
            {
              title: "Tipi Ordine",
              icon: "la-building",
              page: "/tipiordine",
              codeMenuItem:"TIPIORDINE",
            },
            {
              title: "Tipi Pagamento",
              icon: "la-building",
              page: "/tipipagamento",
              codeMenuItem:"TIPIPAGAMENTO"
            },
          ],
        },
      },
      {
        title: "Ordini",
        icon: "feather ft-file-text",
        page: "/ordini",
        codeMenuItem:"ORDINI",
        submenu: {
          items: [
            {
              title: "Visualizza",
              icon: "la-building",
              page: "/ordini",
              codeMenuItem:"VISUALIZZA"
            },
            {
              title: "Carica File Ordine",
              icon: "la-building",
              page: "/uploadOrdini",
              codeMenuItem:"CARICAFILEORDINE",
            },
            {
              title: "Gestione Importazioni",
              icon: "la-building",
              page: "/importazioni",
              codeMenuItem:"GESTIONEIMPORTAZIONI"
            },
          ],
        },
      },
      {
        title: "Ordini Acquisto",
        icon: "feather ft-package",
        page: "/OrdiniAcquisto",
        codeMenuItem:"ORDINIACQUISTO",
        submenu: {
          items: [
            {
              title: "Visualizza",
              icon: "la-building",
              page: "/OrdiniAcquisto",
              codeMenuItem:"VISUALIZZA"
            },
          ],
        },
      },
      {
        title: "Lancio",
        icon: "feather ft-navigation",
        page: "/lancioPageList",
        codeMenuItem:"LANCIO",
        submenu: {
          items: [
            {
              title: "Visualizza",
              icon: "la-building",
              page: "/lancioPageList",
              codeMenuItem:"VISUALIZZA",
            },
          ],
        },
      },
      {
        title: "Anagrafiche Snelle",
        icon: "feather ft-list",
        page: "/dashboardAnasnelle",
        codeMenuItem:"ANAGRAFICHESNELLE",
        submenu: {
          items: [
            {
              title: "Report AVV",
              icon: "la-building",
              page: "/dashboardAnasnelle",
              codeMenuItem:"REPORTAVV"
            },
            {
              title: "Lavorazione Articoli",
              icon: "la-building",
              page: "/lavorazioneArticoliAnasnelle",
              codeMenuItem:"LAVORAZIONEARTICOLI"
            },
            {
              title: "Articoli",
              icon: "la-building",
              page: "/articoliAnasnelle",
              codeMenuItem:"ARTICOLI",
            },
            {
              title: "Materiali",
              icon: "la-building",
              page: "/materialiAnasnelle",
              codeMenuItem:"MATERIALI"
            },
          ],
        },
      },
      {
        title: "Magazzini",
        icon: "feather ft-command",
        page: "/aziendelist",
        codeMenuItem:"MAGAZZINI",
        submenu: {
          items: [
            {
              title: "Generale",
              icon: "la-building",
              page: "/magazziniGeneralePageList",
              codeMenuItem:"GENERALE",
            },
            {
              title: "Carica Magazzino",
              icon: "la-building",
              page: "/caricaMagazzino",
              codeMenuItem:"UPLOADMAGAZZINO",
            },
          ],
        },
      },
    /*   {
        title: "Configurazione",
        icon: "feather ft-package",
        page: "/userRoles",
        codeMenuItem:"CONFIGURAZIONE",
        submenu: {
          items: [
            {
              title: "Ruoli",
              icon: "la-building",
              page: "/userRoles",
              codeMenuItem:"RUOLI",
            },
            {
              title: "Menù Visualizzati",
              icon: "la-building",
              page: "/roleFunctions",
              codeMenuItem:"LAYOUT"
            },
          ],
        },
      }, */
    ],
  },
};
