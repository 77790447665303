import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { roleFunction } from 'src/app/_models/roleFunction/roleFunction';
import { AllComboboxDataDto } from 'src/app/_models/allcomboboxdata/allcomboboxdata-dto';
import { Utility } from 'src/app/_zCore/utility';
import { PopUpDeleteComponent } from 'src/app/_popups/pop-up-delete/pop-up-delete.component';
import { map } from 'rxjs/operators';
import { PopUpIstanzaEsistenteComponent } from 'src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component';


@Component({
  selector: 'app-role-function-edit',
  templateUrl: './role-function-edit.component.html',
  styleUrls: ['./role-function-edit.component.css']
})
export class RoleFunctionEditComponent implements OnInit {
  @Output() confirm = new EventEmitter<roleFunction>();
  
  typeop = '';
  alltype: AllComboboxDataDto;
  currentItem: roleFunction;
  delete: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private modalservice: NgbModal
  ) {}
  ngOnInit(): void {
console.log("role",this.currentItem)
  };

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert('Dati mancanti!');
      this.onClose();

      return;
    }

    const vm = new roleFunction(); 
    vm.idRelazione= Utility.toInteger(this.currentItem.idRelazione); 
    vm.roleId = Utility.toString(form.value.roleId); 
    vm. menu= Utility.toString(form.value.menu).toUpperCase(); 
    vm. item= Utility.toString(form.value.item).toUpperCase(); 
    vm.validUntil=this.currentItem.validUntil;
    this.confirm.emit(vm); 
   // console.log(JSON.stringify(vm))
    
  }

   error(err: any): void {
    console.log("errore",err.error)
    //alert('Si è verificato un errore nella scrittura dei dati!');
    const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
   // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati; 
   
    this.onClose();

  } 

  onClose(): void {
    this.activeModal.close('close');
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new roleFunction();

          this.delete = true;
          console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          console.log(response)

          modalRef.componentInstance.onClose();         
        },
        (err) => {
          console.log(err)
          modalRef.componentInstance.onClose();
        }
      );


  }



  ngOnDestroy() {

  }
}
