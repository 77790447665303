/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";

import { catchError, debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { SortColumn, SortDirection } from "../_directives/sortable.directive";
import { HttpClient } from "@angular/common/http";
import { CindyCustomResponse } from "../_models/esitoServer/CindyCustomResponse";
import { Utility } from "../_zCore/utility";
import { IDTOMapper } from "../_helpers/IDTOMapper";
import { GetParam } from "./crud.service";
import { Globals } from "../_models/Globals";
import { AlertService } from "./alert.service";

type MatchFunctionType = (T, terms: string[]) => Boolean;
type SortFunctionType = (T, column: SortColumn, direction: string) => any[];

interface SearchResult<T> {
  items: T[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerms: string[];
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

export const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort<T>(items: T[], column: SortColumn, direction: string): T[] {
  if (direction === "" || column === "") {
    return items;
  } else {
    return [...items].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === "asc" ? res : -res;
    });
  }
}

@Injectable({ providedIn: "root" })
export class SearchService<T> {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _page$ = new BehaviorSubject<number>(1);

  private _search$ = new Subject<void>();
  private _items$ = new BehaviorSubject<T[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerms:  [""],
    sortColumn: "",
    sortDirection: "",
  };
  _url: string = "";
  _port: number;
  private _endpoint: string;

  public get url(): string {
    return this._url;
  }
  public set url(v: string) {
    this._url = v;
  }

  public get port(): number {
    return this._port;
  }
  public set port(v: number) {
    this._port = v;
  }

  public get endpoint(): string {
    return this._endpoint;
  }
  public set endpoint(v: string) {
    this._endpoint = v;
  }

  
  private _params : GetParam[];
  public get params() : GetParam[] {
    return this._params;
  }
  public set params(v : GetParam[]) {
    this._params  = v;
  }
  

  matches(item: T, terms: string[]): Boolean {
    return true;
  }
  _matchFunction: MatchFunctionType = this.matches;
  _sortFunction: SortFunctionType = sort;


  public set filterItemFunction(mf: MatchFunctionType) {
    this._matchFunction = mf;
  }

  public set sortItemFunction(sf: SortFunctionType) {
    this._sortFunction = sf;
  }

  mapper: IDTOMapper<T>;

  constructor(
    public httpClient: HttpClient,
    public alertSrv: AlertService,
    ) {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap((_) => {

          let paramString="";

          if(this._params)
          {
            paramString +="?"
            this._params.forEach(p=> paramString+=`${p.variable}=${p.value}`);
          }
          console.log("url",`${this._url}:${this._port}/${this._endpoint}${paramString}`)
            return this.httpClient.get(`${this._url}:${this._port}/${this._endpoint}${paramString}`)
        }
        ),
        //tap(res=>console.log("sv",Utility.fromCindyCustomResponseToDTO(res as any))),
        switchMap((res: CindyCustomResponse) => {
          let results: any[] = Utility.fromCindyCustomResponseToDTO(res);

         console.log("result",results);

          let items: T[] = [];
          results.forEach((r) => items.push(this.mapper.map(r)));

          return this._search(items);
        }),
        delay(200),
        tap(() => this._loading$.next(false)),
        catchError(err => {
          console.log('Errore:', err);
          this.alertSrv.error( Globals.MappaMessaggiErrore.get("KO.GENERICO"),Globals.MappaMessaggiErrore.get("KO.GENERICO_dettaglio") )

          return throwError(err);
      })
      )
      .subscribe((result) => {
        this._items$.next(result.items);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get items$() {
    return this._items$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }

  get page$() {
    return this._page$.asObservable();
  }

  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerms():string[] {
    return this._state.searchTerms;
  }

  set page(page: number) {
    this._page$.next(page)
    this._set({ page });
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerms(searchTerms: string[]) {
    this._set({ searchTerms });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(items: T[]): Observable<SearchResult<T>> {
    const { sortColumn, sortDirection, pageSize, page, searchTerms } =
      this._state;

    // 1. filter
    let filtereditems = items.filter((item) =>
      this._matchFunction(item, searchTerms)
    );

    // 2. sort
    let sortedItems = this._sortFunction(filtereditems, sortColumn, sortDirection);
    const total = filtereditems.length;

    // 3. paginate
    items = sortedItems.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ items, total });
  }
}
