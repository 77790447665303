import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';
import { DashboardAnasnelleComponent } from './components/dashboard-anasnelle/dashboard-anasnelle.component';

@NgModule({
  declarations: [
    DashboardAnasnelleComponent,
  ],

  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule,
  ]
})
export class DashboardAnasnelleModule { }