import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { Utility } from "src/app/_zCore/utility";
import { ArticoliSviluppi } from "../models/articoliSviluppiDto";
import { Ordine, OrdiniDto } from "../models/ordiniDto";
import {
  RigaOrdine,
  RigaOrdineDto,
  UltimaVariazioneStatoRiga,
  ValoriVariabiliAzienda,
} from "../models/rigaOrdineDto";
import { TaglieSku } from "../models/taglieSku";
import { UltimoLancio } from "../models/lancioDto";
import { StatoDto } from "../models/statoDto";

export class OrdiniEstesiProduzioneMapper implements IDTOMapper<OrdiniDto> {
  ordiniMapper: OrdineMapper = new OrdineMapper();
  righeOrdineMapper: RigaOrdineDTOMapper = new RigaOrdineDTOMapper();
  ultimoLancioMapper: UltimoLancioMapper = new UltimoLancioMapper();
  statoMapper: StatoMapper = new StatoMapper();

  map(data: any): OrdiniDto {
    let odto = new OrdiniDto();
    odto.ordine = this.ordiniMapper.map(data.ordine);
    odto.righeOrdine = [
      ...data.righeOrdine.map((r) => this.righeOrdineMapper.map(r)),
    ] as RigaOrdineDto[];
    odto.consegna = data.consegna;
    odto.stagione = data.stagione;
    odto.lotto = data.lotto;
    odto.ultimaVariazioneStato = this.statoMapper.map(
      data.ultimaVariazioneStato
    );
    if (data.ultimoLancio) {
      odto.ultimoLancio = this.ultimoLancioMapper.map(data.ultimoLancio);
    } else {
      odto.ultimoLancio = { idLancio: 0, dataLancio: "" };
    }

    return odto;
  }
}

export class UltimoLancioMapper implements IDTOMapper<UltimoLancio> {
  map(data: any): UltimoLancio {
    let ulan = new UltimoLancio();
    ulan.idLancio = data.idLancio;
    ulan.dataLancio = data.dataLancio;

    return ulan;
  }
}

export class OrdineMapper implements IDTOMapper<Ordine> {
  map(data: any): Ordine {
    let ordine = new Ordine();
    ordine.anno = data.anno;
    ordine.annoCaricamento = data.annoCaricamento;
    ordine.codiceCliente = data.codiceCliente;
    ordine.codiceClienteSpedizione = data.codiceClienteSpedizione;
    ordine.codiceEnteEmittente = data.codiceEnteEmittente;
    ordine.descrizioneDestinazione = data.descrizioneDestinazione;
    ordine.ente = data.ente;
    ordine.extCode = data.extCode;
    ordine.flagTipoOrdine = data.flagTipoOrdine;
    ordine.idConsegna = data.idConsegna;
    ordine.idFile = data.idFile;
    ordine.idOrdine = data.idOrdine;
    ordine.idStagione = data.idStagione;
    ordine.idTipoOrdine = data.idTipoOrdine;
    ordine.numero = data.numero;
    ordine.numeroOrdineClienteFornitore = data.numeroOrdineClienteFornitore;
    ordine.terzista = data.terzista;
    ordine.tipoCommessa = data.tipoCommessa;
    ordine.tipologiaVendita = data.tipologiaVendita;
    ordine.utente = data.utente;
    ordine.validUntil = data.validUntil;
    ordine.marca = data.marca;
    ordine.scatola = data.scatola;
    ordine.dataCreazioneOrdine = data.dataCreazioneOrdine;
    ordine.tagLancio = data.tagLancio;

    return ordine;
  }
}

export class RigaOrdineDTOMapper implements IDTOMapper<RigaOrdineDto> {
  rigaOrdineMapper: RigaOrdineMapper = new RigaOrdineMapper();
  taglieSKUMapper: TaglieSKUMapper = new TaglieSKUMapper();
  valoriVariabileMapper: ValoriVariabileAziendaMapper =
    new ValoriVariabileAziendaMapper();
  ultimaVariazioneStatoRigaMapper: UltimaVariazioneStatoRigaMapper =
    new UltimaVariazioneStatoRigaMapper();

  map(data: any): RigaOrdineDto {
    let r = new RigaOrdineDto();

    r.rigaOrdine = this.rigaOrdineMapper.map(data.rigaOrdine);
    r.taglieSku = [...data.taglieSku.map((t) => this.taglieSKUMapper.map(t))];
    r.istanzeFaseLavorazione = data.istanzeFaseLavorazione;
    r.valuta = data.valuta;
    r.fasiLavorazione = data.fasiLavorazione;
    r.tipoRigaOrdine = data.tipoRigaOrdine;
    r.valoriVariabiliAzienda = [
      ...data.valoriVariabiliAzienda.map((t) =>
        this.valoriVariabileMapper.map(t)
      ),
    ];
    r.ultimaVariazioneStatoRiga = this.ultimaVariazioneStatoRigaMapper.map(
      data.ultimaVariazioneStatoRiga
    );

    return r;
  }
}

export class ValoriVariabileAziendaMapper
  implements IDTOMapper<ValoriVariabiliAzienda>
{
  map(data: any): ValoriVariabiliAzienda {
    let v: ValoriVariabiliAzienda = new ValoriVariabiliAzienda();

    v.idValore = data.idValore;
    v.idRiga = data.idRiga;
    v.idVariabile = data.idVariabile;
    v.valoreVariabile = data.valoreVariabile;

    return v;
  }
}

export class TaglieSKUMapper implements IDTOMapper<TaglieSku> {
  map(data: any): TaglieSku {
    let taglia: TaglieSku = new TaglieSku();

    taglia.idRiga = data.idRiga;
    taglia.codTaglia = data.codTaglia;
    taglia.extCode = data.extCode;
    taglia.idTagliaSku = data.idTagliaSku;
    taglia.quantita = Utility.toInteger(data.quantita);
    taglia.sku = data.sku;
    taglia.validUntil = data.validUntil;

    return taglia;
  }
}

export class RigaOrdineMapper implements IDTOMapper<RigaOrdine> {
  map(data: any): RigaOrdine {
    let riga = new RigaOrdine();
    riga.idRiga = data.idRiga;
    riga.numeroRiga = data.numeroRiga;
    riga.modello = data.modello;
    riga.prefissoParte = data.prefissoParte;
    riga.codiceParte = data.codiceParte;
    riga.codiceColore = data.codiceColore;
    riga.idArticolo = data.idArticolo;
    riga.descrizioneArticolo = data.descrizioneArticolo;
    riga.misuraPartePadre = data.misuraPartePadre;
    riga.codiceDropStatura = data.codiceDropStatura;
    riga.tipoVariante = data.tipoVariante;
    riga.codiceVariante = data.codiceVariante;
    riga.codiceCartellino = data.codiceCartellino;
    riga.codiceEtichetta = data.codiceEtichetta;
    riga.combModForm = data.combModForm;
    riga.idOrdine = data.idOrdine;
    riga.validUntil = data.validUntil;
    riga.idSviluppo = data.idSviluppo;
    riga.extCode = data.extCode;
    riga.sconto = data.sconto;
    riga.scontoUlteriore = data.scontoUlteriore;
    riga.idTipoRigaOrdine = data.idTipoRigaOrdine;
    riga.dataConsegnaConcordata = data.dataConsegnaConcordata;
    riga.dataConsegnaPrevista = data.dataConsegnaPrevista;
    riga.note = data.note;

    return riga;
  }
}

export class ArticoloSviluppo implements IDTOMapper<ArticoliSviluppi> {
  map(data: any): ArticoliSviluppi {
    let articoloSviluppo: ArticoliSviluppi = new ArticoliSviluppi();

    articoloSviluppo.idRelazione = data.idRelazione;
    articoloSviluppo.idArticolo = data.idArticolo;
    articoloSviluppo.idSviluppo = data.idSviluppo;
    articoloSviluppo.validUntil = data.validUntil;

    return articoloSviluppo;
  }
}

export class StatoMapper implements IDTOMapper<StatoDto> {
  map(data: any): StatoDto {
    let stato: StatoDto = new StatoDto();
    stato.idVariazione = data ? data.idVariazione : "";
    stato.idOrdine = data ? data.idOrdine : "";
    stato.idStatoOrdine = data ? data.idStatoOrdine : "";
    stato.descrizione = data ? data.descrizione : "";
    stato.tsVariazione = data ? data.tsVariazione : "";
    stato.extCode = data ? data.extCode : "";
    stato.validUntil = data ? data.validUntil : "";

    return stato;
  }
}

export class UltimaVariazioneStatoRigaMapper
  implements IDTOMapper<UltimaVariazioneStatoRiga>
{
  map(data: any): UltimaVariazioneStatoRiga {
    let ultimaVariazione: UltimaVariazioneStatoRiga =
      new UltimaVariazioneStatoRiga();
    ultimaVariazione.idVariazione = data ? data.idVariazione : "";
    ultimaVariazione.idStatoRigaOrdine = data ? data.idStatoRigaOrdine : "";
    ultimaVariazione.idRiga = data ? data.idRiga : "";
    ultimaVariazione.tsVariazione = data ? data.tsVariazione : "";
    ultimaVariazione.extCode = data ? data.extCode : "";
    ultimaVariazione.validUntil = data ? data.validUntil : "";
    ultimaVariazione.descrizione = data ? data.descrizione : "";

    return ultimaVariazione;
  }
}
