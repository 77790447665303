export class CapobolleDto{
    idCapobolla:string;
    numeroCapobolla:string;
    idOrdine:number;
    ente:string;
    anno:string;
    numero:number;
    consegna:string;
    tagLancio:string;
    paia:number;
    idRiga:number;
    numeroRiga:string;
    modello:string;
    parte:string;
    colore:string;
    idArticolo:number;
    forma:string;
    marchio:string;
    barcode:string;
    stagione:string;
    fasi:FasiBolle[];
    note:string;
    idSviluppo:number;
    codiceSviluppo:string;
    progressioneSviluppo:string[];
    quantitaPerTaglia:number[];
    validUntil:string;
    bolle:BolleDto[]
}

export class FasiBolle{
    descrizioneFase:string;
    codiceFase:number;
    componenti:ComponentiFaseBolle[];
}

export class ComponentiFaseBolle{
    tipo:string;
	codice:string;
	descMateriaPrima:string;
	unMis:string;
	consumo:number;
}

export class BolleDto{
idBolla:number
numeroBolla:number
idOrdine:number
ente:string;
anno:string;
numero:number
consegna:string;
tagLancio:string;
paia:number;
idRiga:number;
numeroRiga:string;
modello:string;
parte:string;
colore:string;
idArticolo:number;
forma:string;
marchio:string;
stagione:string;
barcode:string;
fasi:FasiBolle[];
note:string;
idSviluppo:number;
codiceSviluppo:string;
progressioneSviluppo:string[];
distribuzione:number[];
validUntil:string;
}

