import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  Input,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Rateizzazione, TipiPagamentoDto } from "src/app/_models/tipipagamento/tipipagamento-dto";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { Utility } from "src/app/_zCore/utility";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { map } from "rxjs/operators";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { Globals } from "src/app/_models/Globals";
import { ErroriService } from "src/app/_services/errori-service.service";

@Component({
  selector: "app-tipi-pagamento-edit",
  templateUrl: "./tipi-pagamento-edit.component.html",
  styleUrls: ["./tipi-pagamento-edit.component.css"],
})
export class TipiPagamentoEditComponent implements OnInit, OnDestroy {
  @Output() confirm = new EventEmitter<TipiPagamentoDto>();
  typeop = "";
  alltype: AllComboboxDataDto;
  currentItem: TipiPagamentoDto;
  frequenza: Rateizzazione;
  delete: boolean = false;



  constructor(public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    private errorSrv: ErroriService
    ) {}

  ngOnInit(): void {}

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      return;
    }

    const vm = new TipiPagamentoDto();
    const fr = new Rateizzazione();
    vm.idTipoPagamento = Utility.toInteger(this.currentItem.idTipoPagamento);
    vm.descrizioneTipoPagamento = Utility.toString(
      form.value.descrizioneTipoPagamento
    );
    vm.codiceTipoPagamento = Utility.toString(form.value.codiceTipoPagamento).toUpperCase();
    fr.frequenza =Utility.toString(form.value.frequenza);
  
    vm.rateizzazione = fr;
    vm.extJson = this.currentItem.extJson;
    vm.extCode = Utility.toString(form.value.extCode);
    vm.validUntil = this.currentItem.validUntil;
    this.confirm.emit(vm);
    console.log(vm);
  }

  error(err: any): void {
    console.log("errore", err.error);
    let errore: CindyCustomResponse = err.error;
    //alert('Si è verificato un errore nella scrittura dei dati!');
    /*  const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito; */
    // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati;
    if (errore.codEsito == codiciErrore.codiceDuplicato) {
      this.errorSrv.onToastTestata(
        Globals.MappaMessaggiErrore.get(err.error.codEsito)
      );
    } else {
      this.errorSrv.onToastTestata(
        `${Globals.MappaMessaggiErrore.get(
          codiciErrore.erroreResiduale
        )}. ${Globals.MappaMessaggiErrore.get("KO.GENERICO_dettaglio")}`
      );
    }
    this.onClose();

  } 

  prepareRateizzazione(obj): any {
if (obj){
  let values= JSON.stringify(obj)
  let object = JSON.parse(values)
   return object['frequenza']
//    return JSON.stringify(obj)

}
   
  }

  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  
  onDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new TipiPagamentoDto();

          this.delete = true;
          console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
   
        },
        (err) => {
          modalRef.componentInstance.onClose();
 
        }
      );


  }



  ngOnDestroy() {}
}
