import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';


import { RecapitiPageListComponent } from './component/recapiti-page-list/recapiti-page-list.component';
import { RecapitiEditComponentComponent } from './component/recapiti-edit-component/recapiti-edit-component.component';

@NgModule({
  declarations: [
    RecapitiPageListComponent,
    RecapitiEditComponentComponent,
  ],
  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule
  ]
})
export class FeatureRecapitiModule { }
