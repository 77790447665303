export class MagazzinoGeneraleDTO {
    id:number;
    nome:string;
    idAziendaProd:number;
    idCliente:number;
    idStagione:number;
    idFase:number;
    idTerzista:number;
    dataInserimento:string;

}