import { Routes, RouterModule } from "@angular/router";
import { PublicLayoutComponent } from "./_layout/public-layout/public-layout.component";
import { PrivateLayoutComponent } from "./_layout/private-layout/private-layout.component";
import { AuthGuard } from "./_guards/auth.guard";
import { LoginComponent } from "./login";
import { ChangelogComponent } from "./changelog/changelog.component";

import { AziendePageListComponent } from "./featureMain/component/aziende-page-list/aziende-page-list.component";
import { PortiPageListComponent } from "./featurePorti/component/porti-page-list/porti-page-list.component";
import { ImballiPageListComponent } from "./featureImballi/component/imballi-page-list/imballi-page-list.component";
import { FasiLavorazionePageListComponent } from "./featureFasidiLavorazione/component/fasi-lavorazione-page-list/fasi-lavorazione-page-list.component";
// import { LavorantiPageListComponent } from "./featureLavoranti/component/lavoranti-page-list/lavoranti-page-list.component";
import { RecapitiPageListComponent } from "./featureRecapiti/component/recapiti-page-list/recapiti-page-list.component";
import { ScalaturePageListComponent } from "./featureScalature/component/scalature-page-list/scalature-page-list.component";
import { SviluppiTagliePageLIstComponent } from "./featureSviluppiTaglia/component/sviluppi-taglie-page-list/sviluppi-taglie-page-list.component";
import { TipiEsenzionePageListComponent } from "./featureTipiEsenzione/component/tipi-esenzione-page-list/tipi-esenzione-page-list.component";
import { TipiEsenzioneFalsePageListComponent } from "./featureTipiAliquote/component/tipi-aliquote-page-list/tipi-aliquote-page-list.component";

import { TipiOrdinePageListComponent } from "./featureTipoOrdine/component/tipi-ordine-page-list/tipi-ordine-page-list.component";
import { TipiPagamentoPageListComponent } from "./featureTipiPagamento/component/tipi-pagamento-page-list/tipi-pagamento-page-list.component";

import { UploadDropdownComponent } from "./featureFileOrdini/uploadDropdown/upload-dropdown/upload-dropdown.component";
import { OrdiniPageListComponent } from "./featureOrdini/component/ordini-page-list/ordini-page-list.component";
import { OrdiniAcquistoPageListComponent } from "./featureOrdiniAcquisto/component/ordini-acquisto-page-list/ordini-acquisto-page-list/ordini-acquisto-page-list.component";
import { DashboardComponent } from "./dashboard/component/dashboard/dashboard.component";
import { ImportazioniFileOrdiniComponent } from "./featureFileOrdini/importazioni-file-ordini/importazioni-file-ordini.component";
import { CalcoloFabbisogniComponent } from "./featureOrdini/component/calcolo-fabbisogni/calcolo-fabbisogni.component";
import { UserRolesPageLIstComponent } from "./featureUserRoles/component/user-roles-page-list/user-roles-page-list.component";
import { RoleFunctionPageLIstComponent } from "./featureMenuUser/component/role-function-page-list/role-function-page-list.component";
import { LancioProduzionePageListComponent } from "./featureLancioProd/component/lancio-produzione-page-list/lancio-produzione-page-list.component";
import { PlatformLocation } from "@angular/common";
import { PlaygroundComponent } from "./_components/playground/playground.component";
import { DashboardAnasnelleComponent } from "./featureAnasnelle/dashboard/components/dashboard-anasnelle/dashboard-anasnelle.component";
import { LavorazioneArticoliAnasnelleComponent } from "./featureAnasnelle/lavorazioneArticoli/components/lavorazione-articoli-anasnelle/lavorazione-articoli-anasnelle.component";
import { ArticoliAnasnelleComponent } from "./featureAnasnelle/articoli/components/articoli-anasnelle/articoli-anasnelle.component";
import { MaterialiAnasnelleComponent } from "./featureAnasnelle/materiali/components/materiali-anasnelle/materiali-anasnelle.component";
import { MagazziniGeneralePagelistComponent } from "./featureMagazzini/components/magazzino-generale-pagelist/magazzino-generale-pagelist.component";
import { FeatureFileMagazziniComponent } from "./featureMagazzini/components/feature-file-magazzini/feature-file-magazzini.component";
const appRoutes: Routes = [
  // Public layout
  {
    path: "",
    component: PublicLayoutComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "", component: LoginComponent },
    ],
  },
  // Private layout
  {
    path: "",
    component: PrivateLayoutComponent,
    children: [
      {
        path: "playground",
        component: PlaygroundComponent,
        canActivate: [AuthGuard],
      },
      { path: "logout", component: LoginComponent, canActivate: [AuthGuard] },
      {
        path: "changelog",
        component: ChangelogComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "aziendelist",
        component: AziendePageListComponent,
        canActivate: [AuthGuard],
      },
      { path: "portilist", component: PortiPageListComponent },
      { path: "imballilist", component: ImballiPageListComponent },
      { path: "fasilavorazione", component: FasiLavorazionePageListComponent },
      // { path: "lavoranti", component: LavorantiPageListComponent },
      { path: "recapiti", component: RecapitiPageListComponent },
      { path: "scalature", component: ScalaturePageListComponent },
      { path: "sviluppitaglie", component: SviluppiTagliePageLIstComponent },
      { path: "tipiesenzione", component: TipiEsenzionePageListComponent },
      {
        path: "tipiesenzioneIva",
        component: TipiEsenzioneFalsePageListComponent,
      },

      { path: "tipiordine", component: TipiOrdinePageListComponent },
      { path: "tipipagamento", component: TipiPagamentoPageListComponent },

      { path: "uploadOrdini", component: UploadDropdownComponent },
      { path: "ordini", component: OrdiniPageListComponent },
      { path: "recapiti/:id", component: RecapitiPageListComponent },
      { path: "dashboard", component: DashboardComponent },
      { path: "importazioni", component: ImportazioniFileOrdiniComponent },
      { path: "tabellaFabbisogni", component: CalcoloFabbisogniComponent },
      { path: "OrdiniAcquisto", component: OrdiniAcquistoPageListComponent },
      { path: "userRoles", component: UserRolesPageLIstComponent },
      { path: "roleFunctions", component: RoleFunctionPageLIstComponent },
      { path: "lancioPageList", component: LancioProduzionePageListComponent },
      { path: "dashboardAnasnelle", component: DashboardAnasnelleComponent },
      {
        path: "lavorazioneArticoliAnasnelle",
        component: LavorazioneArticoliAnasnelleComponent,
      },
      { path: "articoliAnasnelle", component: ArticoliAnasnelleComponent },
      { path: "materialiAnasnelle", component: MaterialiAnasnelleComponent },
      {
        path: "magazziniGeneralePageList",
        component: MagazziniGeneralePagelistComponent,
      },
      { path: "caricaMagazzino", component: FeatureFileMagazziniComponent },
    ],
  },
  // otherwise redirect to home
  { path: "**", redirectTo: "changelog" },
];

export const routing = RouterModule.forRoot(appRoutes, {
  scrollOffset: [0, 0],
  scrollPositionRestoration: "top",
});
