import { fileURLToPath } from "url";
import { ItemFilter } from "./ItemFilter";

export class FieldsFilter extends ItemFilter {
  constructor(filterParams: any) {
    super(filterParams);
  }

  filter(item: any, filterParams: any) {
    let searchFieldsList: any[] = filterParams.fieldList;
    let searchString = filterParams.searchString;
    let child = filterParams.child === undefined ? null : filterParams.child;

    if (searchString.trim() == "") {
      return true;
    }

    for (let index = 0; index < searchFieldsList.length; index++) {
      const sf = searchFieldsList[index];

      let fieldValue: string = "";


      let itemChild = null;
      if(child !== null){

        itemChild = item[child];
      }
      

      fieldValue = itemChild !== null ? itemChild[sf] : item[sf];

      let matchfield: boolean;
      //metto questa verifica in caso uno dei campi di ricerca sia null
     if (fieldValue != null) {
        matchfield = fieldValue
          .toString()
          .toLowerCase()
          .trim()
          .includes(searchString.toLowerCase().trim());
      }

      if (matchfield) {
        return true;
      }
    }

    return false;
  }
}
