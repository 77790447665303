import { Component, OnInit } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";

import { TipiPagamentoEditComponent } from "../tipi-pagamento-edit/tipi-pagamento-edit.component";

import { AlertService } from "src/app/_services/alert.service";
import { TipiPagamentoDto } from "src/app/_models/tipipagamento/tipipagamento-dto";

import { TipiPagamentoService } from "../../service/tipi-pagamento.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
import { AndFilter } from "src/app/_filters/AndFilter";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { environment } from "src/environments/environment";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";

@Component({
  selector: "app-tipi-pagamento-page-list",
  templateUrl: "./tipi-pagamento-page-list.component.html",
  styleUrls: ["./tipi-pagamento-page-list.component.css"],
})
export class TipiPagamentoPageListComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  tipiPagamentoDataSource: RestDataSource;
  state: State<TipiPagamentoDto> = new State();

  constructor(
    private modalservice: NgbModal,
    private allComboData: ApiAllComboBoxdataService,
    private tipiPagamentoService: TipiPagamentoService,
    private alertSrv: AlertService,
    private http: HttpClient
  ) {
    this.tipiPagamentoDataSource = new RestDataSource(this.http);

    this.tipiPagamentoDataSource.setVariablesApi(
      "allTipoPagamento",
      "tipoPagamento",
      "tipoPagamento",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    this.tipiPagamentoDataSource.setByIdField("IDtipo");
    this.tipiPagamentoService.connectDataSource(this.tipiPagamentoDataSource);
  }

  _searchTerm: string;
  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this.loadData();
  }

  get searchTerm() {
    return this._searchTerm;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.loadData();
  }
  get pageSize() {
    return this._pageSize;
  }
  set page(newPage: number) {
    this._page = newPage;

    this.loadData();
  }

  get page() {
    return this._page;
  }

  loadData() {
    this.tipiPagamentoService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterTipiPagamento(service);

        console.log(this._page + " " + this._pageSize);
      },
    });
  }

  filterTipiPagamento(service: TipiPagamentoService) {
    console.log(this._searchTerm);

    this.state = service
      .filter(
        new AndFilter([
          new FieldsFilter({
            fieldList: ["codiceTipoPagamento", "descrizioneTipoPagamento"],
            searchString: this._searchTerm == undefined ? "" : this._searchTerm,
          }),
          //   new TipiPagamentoiFilter(null),
        ])
      )
      .sort(this._sortColumn, this._sortDirection)
      .paginate(this._page, this.pageSize)
      .state();
  }

  ngOnInit(): void {
    this._sortColumn = "idTipoPagamento";
    this._sortDirection = "desc";
    this._rotateDirection = "desc";
    this.loadData();
    // console.log(this.state.items)
  }

  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");

    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.filterTipiPagamento(this.tipiPagamentoService);
  }

  onTipoPagamentoCreateOrModify(index: number): void {
    let modalRef: any = null;

    modalRef = this.modalservice.open(TipiPagamentoEditComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });

    let tipoPagamento: TipiPagamentoDto = new TipiPagamentoDto();
    let typeop = "ins";

    if (index >= 0) {
      tipoPagamento = this.state.paginatedItems[index];

      typeop = "upd";
    }

    modalRef.componentInstance.currentItem = tipoPagamento;

    modalRef.componentInstance.typeop = typeop;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log(data);

          if (typeop == "ins") {
            return this.tipiPagamentoService.create(data as TipiPagamentoDto);
          } else if (typeop == "upd") {
            if (modalRef.componentInstance.delete == false)
              return this.tipiPagamentoService.update(data as TipiPagamentoDto);
            else {
              typeop = "del";

              return this.tipiPagamentoService.delete(
                tipoPagamento.idTipoPagamento
              );
            }
          }
        })
      )
      .subscribe(
        (response: CindyCustomResponse) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";

          this.loadData();
          this._sortColumn = "idTipoPagamento";
          this._sortDirection = "desc";
          this._rotateDirection = "desc";

          if (response.codEsito == "OK") {
            this.onSuccessPrint(typeop);
          } else {
            this.onErrorPrint(typeop);
          }
        },
        (err) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";
          modalRef.componentInstance.error(err);
        }
      );
  }
  onErrorPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.error(Globals.printCreateMessageFailure("il Tipo Pagamento"));
    else if (typeop == "upd") {
      this.alertSrv.error(Globals.printModifyMessageFailure("il Tipo Pagamento"));
    } else {
      this.alertSrv.error(Globals.printDeleteMessageFailure("il Tipo Pagamento"));
    }
  }

  onSuccessPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.success(
        Globals.printCreateMessageSuccess("Il Tipo Pagamento")
      );
    else if (typeop == "upd") {
      this.alertSrv.success(
        Globals.printModifyMessageSuccess("Il Tipo Pagamento")
      );
    } else {
      this.alertSrv.success(
        Globals.printDeleteMessageSuccess("Il Tipo Pagamento")
      );
    }
  }
  prepareRateizzazione(obj): any {
    return obj["frequenza"];
  }
}
