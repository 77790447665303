import { Component, EventEmitter, Input, Output  } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-input-field-des',
  templateUrl: './input-field-des.component.html',
  styleUrls: ['./input-field-des.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]


})
export class InputFieldDesComponent {
  @Input() type = 'text';
  @Input() display = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() readonly = false;
  @Input() maxlength = 99999;
  @Input() errorRequired = '  *';
  @Input() errorMaxlength = 'campo lunghezza massima';
  @Input() errorSymbol = "carattere non ammesso";
  @Input() value = '';
  @Input() erroreOpzionale = false;
  @Input() class = '';



  @Output() valueChange = new EventEmitter<string>();

 
  
  constructor() { }

  change(event){

    this.valueChange.emit(this.value);


  }
}
