import { Injectable } from "@angular/core";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { AService } from "src/app/_services/AService";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
import { ScalatureSviluppiDTO } from "src/app/_models/scalatureSviluppi/scalatureSviluppi";

export class ScalatureSviluppiMapper implements IDTOMapper<ScalatureSviluppiDTO> {

  map(data: any): ScalatureSviluppiDTO {


    let item: ScalatureSviluppiDTO = new ScalatureSviluppiDTO();

    item.idRelazione= data.idRelazione;
    item.idScalatura= data.idScalatura;
    item.idSviluppoTaglia= data.idScalatura;
    item.validUntil= data.idScalatura;

    return item;
  }
}

@Injectable({
  providedIn: "root",
})
export class ScalatureSviluppiService extends AService<ScalatureSviluppiDTO> {
  constructor(public http: HttpClient) {
    super();
  }
  state(): State<ScalatureSviluppiDTO> {
    let currentState: State<ScalatureSviluppiDTO> = new State<ScalatureSviluppiDTO>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
  }

  mapper: IDTOMapper<ScalatureSviluppiDTO> = new ScalatureSviluppiMapper();
}
