import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IDTOMapper } from 'src/app/_helpers/IDTOMapper';
import { ArticoloEstesoDto } from 'src/app/_models/articoli/articoliDto';
import { ItemFilter } from 'src/app/_filters/ItemFilter';
import { AService } from 'src/app/_services/AService';
import { State } from 'src/app/_models/State';


class ArticoloEstesoMapper implements IDTOMapper<ArticoloEstesoDto>{
  map(data: any): ArticoloEstesoDto {
    
    let articolo:ArticoloEstesoDto = new ArticoloEstesoDto();
    
    articolo.idArticolo=data.idArticolo;
    articolo.modello= data.modello;
    articolo.parte=data.parte;
    articolo.colore=data.colore;
    articolo.descrizione=data.descrizione;
    articolo.descrizioneMateriale=data.descrizioneMateriale;
    articolo.coloreMateriale=data.coloreMateriale;
    articolo.extCode= data.extCode;
    articolo.linea=data.linea;
    articolo.timestampModifica= data.timestampModifica;
    articolo.stagione= data.stagione;
    articolo.codiceFornitore=data.codiceFornitore;
    articolo.image=data.image;

   return articolo;
  }

}

@Injectable({
  providedIn: 'root'
})
export class ArticoloEstesoService extends AService<ArticoloEstesoDto>{


  state(): State<ArticoloEstesoDto> {
     
    let currentState:State<ArticoloEstesoDto> = new State<ArticoloEstesoDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<ArticoloEstesoDto> = new ArticoloEstesoMapper();}
