import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-input-field-int',
  templateUrl: './input-field-int.component.html',
  styleUrls: ['./input-field-int.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class InputFieldIntComponent {
  @Input() type = 'number';
  @Input() display = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() readonly = false;
  @Input() maxlength = 99999;
  @Input() errorMaxlength = "codice troppo lungo";
  @Input() errorRequired = ' *';
  @Input() errorNumeric = 'campo non numerico ';
  @Input() value = 0;

  @Output() valueChange = new EventEmitter<number>();

 
  
  constructor() { }

  change(newValue){

    this.valueChange.emit(newValue);

  }

}
