import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FasiLavorazioneDto } from "src/app/_models/fasilavorazione/fasilavorazione-dto";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { Utility } from "src/app/_zCore/utility";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { map, switchMap } from "rxjs/operators";
import { Globals } from "src/app/_models/Globals";
import { FasiDiLavorazioneService } from "src/app/_services/allServices";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { AlertService } from "src/app/_services/alert.service";

@Component({
  selector: "app-fasi-lavorazione-edit",
  templateUrl: "./fasi-lavorazione-edit.component.html",
  styleUrls: ["./fasi-lavorazione-edit.component.css"],
})
export class FasiLavorazioneEditComponent implements OnInit, OnDestroy {
  @Output() confirm = new EventEmitter<FasiLavorazioneDto>();
  typeop = "";
  alltype: AllComboboxDataDto;
  currentItem: FasiLavorazioneDto;
  delete: boolean = false;


  constructor(
    public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    private fasiLavorazioneService: FasiDiLavorazioneService,
    private alertSrv: AlertService
  ) {}

  ngOnInit(): void {}

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      this.onClose();

      return;
    }

    const vm = new FasiLavorazioneDto();
    vm.idFaseLavorazione = Utility.toInteger(
      this.currentItem.idFaseLavorazione
    );
    //vm.codAvanzamentoLavorazione = Utility.toInteger(form.value.codAvanzamentoLavorazione);
    // vm.codOperazioneLavorazione = Utility.toInteger(form.value.codOperazioneLavorazione);
    vm.codFaseLavorazione = Utility.toInteger(form.value.codFaseLavorazione);
    vm.descFaseLavorazione = Utility.toString(form.value.descFaseLavorazione);
    vm.validUntil = Utility.toString(this.currentItem.validUntil);

    this.confirm.emit(vm);
    //    console.log(JSON.stringify(vm))
  }
  //va cambiato con il popup error
  error(err: any): void {
    console.log("errore",err.error)
    const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
    modalRefErrore.componentInstance.messaggioEventuale = JSON.parse(err.error.dati).descErrore; 
   

  } 
  
  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

 /*  onFaseLavorazioneDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log(item.idFaseLavorazione);
          return this.fasiLavorazioneService.deleteData(
            item.idFaseLavorazione,
            "IDfase"
          );
        })
      )
      .subscribe(
        (response) => {
          if (!response.ERRORE) {
            modalRef.componentInstance.onClose();
            this.alertSrv.success(
              Globals.printDeleteMessageSuccess("Fase di Lavorazione")
            );
          } else {
            modalRef.componentInstance.onClose();

            const modalRefErrore = this.modalservice.open(
              PopUpIstanzaEsistenteComponent,
              {
                size: "sm",
                windowClass: "animated FadeIn",
              }
            );
            modalRefErrore.componentInstance.codiceErrore = response.ERRORE;
          }
        },
        (err) => {
          modalRef.componentInstance.error(err);
          this.alertSrv.error(
            Globals.printDeleteMessageFailure("Fase di Lavorazione")
          );
          console.log(err);
        }
      );
  } */

  onDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new FasiLavorazioneDto();

          this.delete = true;
          console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
   
        },
        (err) => {
          modalRef.componentInstance.onClose();
 
        }
      );


  }

  ngOnDestroy() {}
}
