export class CompAnasnelleDTO {
    codiceFornitore: string;
    codiceComponente: string;
    descrizioneComponente: string;
    unMisura: string;
    prezzo: number;
    coloreComponente: string;
    codiceComponenteCliente: string;
    flagTaglie:boolean;
    codScalatura:string;
    idScalatura:number;
}

