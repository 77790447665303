import { Injectable } from "@angular/core";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { AService } from "src/app/_services/AService";
import { OrdiniDto, Ordine } from "src/app/featureOrdini/models/ordiniDto";
import {
  RigaOrdineDto,
  TipoRigaOrdine,
  ValoriVariabiliAzienda,
} from "src/app/featureOrdini/models/rigaOrdineDto";
import { RigaOrdine } from "src/app/featureOrdini/models/rigaOrdineDto";
import { TaglieSku } from "src/app/featureOrdini/models/taglieSku";
import { Utility } from "src/app/_zCore/utility";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import {
  AziendeMapper,
  AziendeServiceNew,
} from "src/app/featureMain/service/aziende.service";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, forkJoin, Observable, of, pipe, Subject, throwError } from "rxjs";
import { OrdiniState } from "../component/state/ordiniState";
import { TipiOrdineDto } from "src/app/featureTipoOrdine/models/tipiordine-dto";
import { TipiOrdineService } from "src/app/featureTipoOrdine/service/tipi-ordine.service";
import { TipiRigaOrdineService } from "./tipi-riga-ordine.service";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";

import { ArticoloEstesoDto } from "src/app/_models/articoli/articoliDto";
import { ArticoloEstesoService } from "./articolo-esteso.service";

import { LoggerService } from "src/app/shared/services/logger.service";
import { ScalatureDto } from "src/app/featureScalature/models/scalature-dto";
import { ScalatureService } from "src/app/featureScalature/service/scalature.service";
import { catchError, map, tap } from "rxjs/operators";
import { ScalatureMapper } from "src/app/featureScalature/service/scalature.service";
//import { SviluppoTaglieMapper } from "src/app/featureSviluppiTaglia/service/sviluppo-taglie.service";
import { ArticoliSviluppi } from "src/app/featureOrdini/models/articoliSviluppiDto";
import { StagioneDto } from "src/app/featureOrdini/models/stagioneDto";
import { environment } from "src/environments/environment";
import { AbilityBuilder } from "@casl/ability";
import { AppAbility } from "src/app/shared/services/AppAbility";
import { OrdiniEstesiProduzioneMapper } from "../mappers/ordiniProdMapper";
import { SviluppiTaglieCRUDService } from "src/app/featureSviluppiTaglia/service/sviluppi-taglie-crud.service";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { ErroriService } from "src/app/_services/errori-service.service";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { Globals } from "src/app/_models/Globals";

/* export class OrdiniEstesiProduzioneMapper implements IDTOMapper<OrdiniDto> {
  ordiniMapper: OrdineMapper = new OrdineMapper();
  righeOrdineMapper: RigaOrdineDTOMapper = new RigaOrdineDTOMapper();

  map(data: any): OrdiniDto {
    let odto = new OrdiniDto();
    odto.ordine = this.ordiniMapper.map(data.ordine);
    odto.righeOrdine = [
      ...data.righeOrdine.map((r) => this.righeOrdineMapper.map(r)),
    ] as RigaOrdineDto[];
    odto.consegna = data.consegna;
    odto.stagione = data.stagione;
    odto.lotto = data.lotto;

    return odto;
  }
}

class OrdineMapper implements IDTOMapper<Ordine> {
  map(data: any): Ordine {
    let ordine = new Ordine();
    ordine.anno = data.anno;
    ordine.annoCaricamento = data.annoCaricamento;
    ordine.codiceCliente = data.codiceCliente;
    ordine.codiceClienteSpedizione = data.codiceClienteSpedizione;
    ordine.codiceEnteEmittente = data.codiceEnteEmittente;
    ordine.descrizioneDestinazione = data.descrizioneDestinazione;
    ordine.ente = data.ente;
    ordine.extCode = data.extCode;
    ordine.flagTipoOrdine = data.flagTipoOrdine;
    ordine.idConsegna = data.idConsegna;
    ordine.idFile = data.idFile;
    ordine.idOrdine = data.idOrdine;
    ordine.idStagione = data.idStagione;
    ordine.idTipoOrdine = data.idTipoOrdine;
    ordine.numero = data.numero;
    ordine.numeroOrdineClienteFornitore = data.numeroOrdineClienteFornitore;
    ordine.terzista = data.terzista;
    ordine.tipoCommessa = data.tipoCommessa;
    ordine.tipologiaVendita = data.tipologiaVendita;
    ordine.utente = data.utente;
    ordine.validUntil = data.validUntil;
    ordine.marca = data.marca;
    ordine.scatola = data.scatola;
    ordine.dataCreazioneOrdine = data.dataCreazioneOrdine;
    ordine.tagLancio = data.tagLancio;

    return ordine;
  }
}

class RigaOrdineDTOMapper implements IDTOMapper<RigaOrdineDto> {
  rigaOrdineMapper: RigaOrdineMapper = new RigaOrdineMapper();
  taglieSKUMapper: TaglieSKUMapper = new TaglieSKUMapper();
  valoriVariabileMapper: ValoriVariabileAziendaMapper =
    new ValoriVariabileAziendaMapper();

  map(data: any): RigaOrdineDto {
    let r = new RigaOrdineDto();

    r.rigaOrdine = this.rigaOrdineMapper.map(data.rigaOrdine);
    r.taglieSku = [...data.taglieSku.map((t) => this.taglieSKUMapper.map(t))];
    r.istanzeFaseLavorazione = data.istanzeFaseLavorazione;
    r.valuta = data.valuta;
    r.fasiLavorazione = data.fasiLavorazione;
    r.tipoRigaOrdine = data.tipoRigaOrdine;
    r.valoriVariabiliAzienda = [
      ...data.valoriVariabiliAzienda.map((t) =>
        this.valoriVariabileMapper.map(t)
      ),
    ];

    return r;
  }
}

class ValoriVariabileAziendaMapper
  implements IDTOMapper<ValoriVariabiliAzienda>
{
  map(data: any): ValoriVariabiliAzienda {
    let v: ValoriVariabiliAzienda = new ValoriVariabiliAzienda();

    v.idValore = data.idValore;
    v.idRiga = data.idRiga;
    v.idVariabile = data.idVariabile;
    v.valoreVariabile = data.valoreVariabile;

    return v;
  }
}

class TaglieSKUMapper implements IDTOMapper<TaglieSku> {
  map(data: any): TaglieSku {
    let taglia: TaglieSku = new TaglieSku();

    taglia.idRiga = data.idRiga;
    taglia.codTaglia = data.codTaglia;
    taglia.extCode = data.extCode;
    taglia.idTagliaSku = data.idTagliaSku;
    taglia.quantita = Utility.toInteger(data.quantita);
    taglia.sku = data.sku;
    taglia.validUntil = data.validUntil;

    return taglia;
  }
} */

/* class RigaOrdineMapper implements IDTOMapper<RigaOrdine> {
  map(data: any): RigaOrdine {
    let riga = new RigaOrdine();
    riga.idRiga = data.idRiga;
    riga.numeroRiga = data.numeroRiga;
    riga.modello = data.modello;
    riga.prefissoParte = data.prefissoParte;
    riga.codiceParte = data.codiceParte;
    riga.codiceColore = data.codiceColore;
    riga.idArticolo = data.idArticolo;
    riga.descrizioneArticolo = data.descrizioneArticolo;
    riga.misuraPartePadre = data.misuraPartePadre;
    riga.codiceDropStatura = data.codiceDropStatura;
    riga.tipoVariante = data.tipoVariante;
    riga.codiceVariante = data.codiceVariante;
    riga.codiceCartellino = data.codiceCartellino;
    riga.codiceEtichetta = data.codiceEtichetta;
    riga.combModForm = data.combModForm;
    riga.idOrdine = data.idOrdine;
    riga.validUntil = data.validUntil;
    riga.idSviluppo = data.idSviluppo;
    riga.extCode = data.extCode;
    riga.sconto = data.sconto;
    riga.scontoUlteriore = data.scontoUlteriore;
    riga.idTipoRigaOrdine = data.idTipoRigaOrdine;
    riga.dataConsegnaConcordata = data.dataConsegnaConcordata;
    riga.dataConsegnaPrevista = data.dataConsegnaPrevista;
    riga.note = data.note;

    return riga;
  }
} */

/* class ArticoloSviluppo implements IDTOMapper<ArticoliSviluppi> {
  map(data: any): ArticoliSviluppi {
    let articoloSviluppo: ArticoliSviluppi = new ArticoliSviluppi();

    articoloSviluppo.idRelazione = data.idRelazione;
    articoloSviluppo.idArticolo = data.idArticolo;
    articoloSviluppo.idSviluppo = data.idSviluppo;
    articoloSviluppo.validUntil = data.validUntil;

    return articoloSviluppo;
  }
} */

@Injectable({
  providedIn: "root",
})
export class OrdiniProduzioneService extends AService<OrdiniDto> {
  private _listaAziende: AziendeDto[] = [];
  aziendeDataSource: RestDataSource;

  private _listaTipiOrdine: TipiOrdineDto[] = [];
  tipiOrdineDataSource: RestDataSource;

  private _listaTipiRigaOrdine: TipoRigaOrdine[] = [];
  tipiRigaOrdineDataSource: RestDataSource;

  private _listaArticoliEstesi: ArticoloEstesoDto[] = [];
  articoliEstesiDataSource: RestDataSource;

  private _listaScalature: ScalatureDto[] = [];
  scalatatureDataSource: RestDataSource;
  private _listaSviluppi: SviluppiTaglieDto[];
  sviluppiDataSource: RestDataSource;
  articoliSviluppiDataSource: RestDataSource;
  stagioneByClienteDataSource: RestDataSource;
  stagioneDataSource: RestDataSource;
  aziendeConArticoliDataSource: RestDataSource;
  fornitoriConComponentiDataSource: RestDataSource;

  constructor(
    private http: HttpClient,
    public tipiOrdineSrv: TipiOrdineService,
    public tipiRigaOrdineSrv: TipiRigaOrdineService,
    public sviluppiSrv: SviluppiTaglieCRUDService,
    public articoloSrv: ArticoloEstesoService,
    public scalatureSrv: ScalatureService,
    public loggerService: LoggerService,
    private ability: AppAbility,
    public erroriSrv:ErroriService
  ) {
    super();
    /*  this.aziendeDataSource = new RestDataSource(this.http);
    this.aziendeDataSource.setVariablesApi(
      "allAziende",
      "azienda",
      "azienda",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    this.aziendeDataSource.setByIdField("idAzienda");  */

    this.tipiOrdineDataSource = new RestDataSource(this.http);
    this.tipiOrdineDataSource.setVariablesApi(
      "allTipoOrdine",
      "tipoOrdine",
      "tipoOrdine",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    this.tipiOrdineDataSource.setByIdField("idTipoOrdine");
    this.tipiOrdineSrv.connectDataSource(this.tipiOrdineDataSource);

    this.tipiRigaOrdineDataSource = new RestDataSource(this.http);
    this.tipiRigaOrdineDataSource.setVariablesApi(
      "allTipiRigaOrdine",
      "tipoRigaOrdine",
      "tipoRigaOrdine",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );
    this.tipiRigaOrdineDataSource.setByIdField("idTipo");
    this.tipiRigaOrdineSrv.connectDataSource(this.tipiRigaOrdineDataSource);

    //devo chiamare gli articoli per cod.Azienda
    this.articoliEstesiDataSource = new RestDataSource(this.http);
    this.articoliEstesiDataSource.setVariablesApi(
      "allArticoli",
      "",
      "",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    this.articoliEstesiDataSource.setByIdField("idArticolo");
    this.articoloSrv.connectDataSource(this.articoliEstesiDataSource);

    this.scalatatureDataSource = new RestDataSource(this.http);
    this.scalatatureDataSource.setVariablesApi(
      "allScalatureEstese",
      "scalaturaEstesa",
      "scalaturaEstesa",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    this.scalatatureDataSource.getDataByIdField = "idScalatura";
    //  this.scalatureSrv.connectDataSource(this.scalatatureDataSource);

    this.sviluppiDataSource = new RestDataSource(this.http);
    this.sviluppiDataSource.setVariablesApi(
      "allSviluppiTaglia",
      "sviluppoTaglia",
      "sviluppoTaglia",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    this.sviluppiDataSource.setByIdField("IdSviluppo");

    this.articoliSviluppiDataSource = new RestDataSource(this.http);
    this.articoliSviluppiDataSource.setVariablesApi(
      "",
      "articoloSviluppoByArticolo",
      "articoloSviluppoByArticolo",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    this.articoliSviluppiDataSource.setByIdField("idArticolo");

    this.stagioneByClienteDataSource = new RestDataSource(this.http);
    this.stagioneByClienteDataSource.setVariablesApi(
      "stagioni",
      "stagioneByCliente",
      "stagioneByCliente",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );
    this.stagioneByClienteDataSource.setByIdField("idAzienda");

    this.stagioneDataSource = new RestDataSource(this.http);
    this.stagioneDataSource.setVariablesApi(
      "stagioni",
      "stagione",
      "stagione",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );
    this.stagioneDataSource.setByIdField("idStagione");

    this.aziendeConArticoliDataSource = new RestDataSource(this.http);
    this.aziendeConArticoliDataSource.setVariablesApi(
      "aziendeConArticoli",
      "",
      "",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    this.aziendeConArticoliDataSource.setByIdField("idAzienda");

    this.fornitoriConComponentiDataSource = new RestDataSource(this.http);
    this.fornitoriConComponentiDataSource.setVariablesApi(
      "aziendeConComponenti",
      "",
      "",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    this.fornitoriConComponentiDataSource.setByIdField("idAzienda");
  }

  override loadState(): Observable<this> {
    const service = this;

    const obs = new Observable<this>((sub) => {
      forkJoin({
        listaOrdini: super.loadState(),
        listaArticoli: this.articoloSrv.loadState(),
       // listaAziende: this.loadAziende(),
        listaTipiOrdine: this.tipiOrdineSrv.loadState(),
        listaTipiRigaOrdine: this.tipiRigaOrdineSrv.loadState(),
        listaScalature: this.loadScalature(),
        //listaSviluppi: this.loadSviluppi(),
      }).subscribe({
        next: (res) => {
          this._listaArticoliEstesi = res.listaArticoli.state().items;
        //  this._listaAziende = res.listaAziende;
          this._listaTipiOrdine = res.listaTipiOrdine.state().items;
          this._listaTipiRigaOrdine = res.listaTipiRigaOrdine.state().items;
          this._listaScalature = res.listaScalature;
         // this._listaSviluppi = res.listaSviluppi;

          sub.next(service);
        },
        error(err) {
          service.loggerService.error(err);
        },
      });
    });

    return obs;
  }

 /*  loadAziende(): Observable<AziendeDto[]> {
    let mapper: AziendeMapper = new AziendeMapper();

    return this.aziendeDataSource.getAllData().pipe(
      map((data: any) => {
        let aziende: AziendeDto[] = [];

        let datiItems: any[] = [];

        if (data.dati !== undefined) {
          datiItems = JSON.parse(data.dati);
        } else datiItems = data;

        datiItems.forEach((i) => aziende.push(mapper.map(i)));

        return aziende;
      })
    );
  } */

  loadTipiRigaOrdine():Observable<TipoRigaOrdine[]>{
    return this.tipiRigaOrdineDataSource.getAllData().pipe(
      map((data: any) => {
        let tipoRigaOrd: TipoRigaOrdine[] = [];

        let datiItems: any[] = [];

        if (data.dati !== undefined) {
          datiItems = JSON.parse(data.dati);
        } else datiItems = data;

       // datiItems.forEach((i) => scalature.push(mapper.map(i)));

        return tipoRigaOrd;
      }),
      catchError(err => {
        console.log('erroreLoadTipiRigaOrdine', err);
        this.erroriSrv.onToastTestata(Globals.MappaMessaggiErrore.get(codiciErrore.tipiRigaOrdineMancanti))
        
        return throwError(err);
    })
    );
  }


  loadFornitoriConComponenti(): Observable<AziendeDto[]> {

    let mapper: AziendeMapper = new AziendeMapper();

    return this.fornitoriConComponentiDataSource
      .getAllData()
      .pipe(
        map((data: any) => {
          let aziende: AziendeDto[] = [];

          let datiItems: any[] = [];

          if (data.dati !== undefined) {
            datiItems = JSON.parse(data.dati);
          } else datiItems = data;

          datiItems.forEach((i) => aziende.push(mapper.map(i)));
          aziende.sort((a1:AziendeDto,a2:AziendeDto)=> a1.ragioneSociale.trim().localeCompare(a2.ragioneSociale.trim()));
         aziende = aziende.filter((az)=> az.tipoAzienda === "fornitore");

          return aziende
        }),
        catchError(err => {
          console.log('erroreLoadFornitori', err);
          this.erroriSrv.onToastTestata(Globals.MappaMessaggiErrore.get(codiciErrore.componentiMancanti))
          
          return throwError(err);
      })
      )


  }

  loadAziendeConArticoli() {
    let mapper: AziendeMapper = new AziendeMapper();
    this.aziendeConArticoliDataSource
      .getAllData()
      .pipe(
        map((data: any) => {
          let aziende: AziendeDto[] = [];

          let datiItems: any[] = [];

          if (data.dati !== undefined) {
            datiItems = JSON.parse(data.dati);
          } else datiItems = data;

          datiItems.forEach((i) => aziende.push(mapper.map(i)));
          aziende.sort((a1:AziendeDto,a2:AziendeDto)=> a1.ragioneSociale.trim().localeCompare(a2.ragioneSociale.trim()));
         aziende = aziende.filter((az)=> az.tipoAzienda === "cliente");

          return aziende
        })
      )
      .subscribe((res) => {
       // console.log("res", res);
        this._listaAziendeConArticoliSub.next(res);
      });
  }

  loadScalature(): Observable<ScalatureDto[]> {
    let mapper: ScalatureMapper = new ScalatureMapper();

    return this.scalatatureDataSource.getAllData().pipe(
      map((data: any) => {
        let scalature: ScalatureDto[] = [];

        let datiItems: any[] = [];

        if (data.dati !== undefined) {
          datiItems = JSON.parse(data.dati);
        } else datiItems = data;

        datiItems.forEach((i) => scalature.push(mapper.map(i)));

        return scalature;
      })
    );
  }






  loadSviluppi(idUtente): Observable<SviluppiTaglieDto[]> {
  //  let mapper: SviluppoTaglieMapper = new SviluppoTaglieMapper();

    return this.sviluppiSrv.getAll(/* [{variable:"userId",value:idUtente}] */).pipe(
     // tap((res)=> console.log("tapsviluppiSrv", res)),
      map((data: CindyCustomResponse) => {
        let sviluppi: SviluppiTaglieDto[] = [];


        if (data.dati !== undefined) {
          sviluppi = JSON.parse(data.dati);
        }

          //datiItems.forEach((i) => sviluppi.push(mapper.map(i)));

        return sviluppi;
      }),
      tap((res)=>console.log("tap sviluppi",res))
    );
  }

  loadCodiciSviluppo(righe:RigaOrdineDto[], idUtente):Observable<string[]>{

    return this.loadSviluppi(idUtente).pipe(
      map((sviluppi:SviluppiTaglieDto[])=>{

        let codici:string[]=[];

        righe.forEach(row=>{

          let sv:SviluppiTaglieDto = sviluppi.find((sv:SviluppiTaglieDto)=> row.rigaOrdine.idSviluppo === sv.idSviluppoTaglia)

          if(sv !== undefined)
            codici.push(sv.codiceSviluppoTaglia);
           
        });

        return codici;
        
      }),
      //tap((res)=> console.log("tap codici",res))

    )

  }

  _listaStagioniByClienteSub: Subject<StagioneDto[]> = new Subject();

  get listaStagioniByCliente$() {
    return this._listaStagioniByClienteSub.asObservable();
  }

  _enablelistaStagioniSub: Subject<boolean> = new Subject();

  get enablelistaStagioniSub$() {
    return this._enablelistaStagioniSub.asObservable();
  }

  _listaAziendeConArticoliSub: Subject<AziendeDto[]> = new Subject();

  get listaAziendeConArticoli$() {
    return this._listaAziendeConArticoliSub.asObservable();
  }

  _listaFornitoriConComponentiSub: Subject<AziendeDto[]> = new Subject();

  get listaFornitoriConComponenti$() {
    return this._listaFornitoriConComponentiSub.asObservable();
  }

  loadStagioneByCliente(idCliente: number): void {
    this.stagioneByClienteDataSource
      .getDataById(idCliente)
      .pipe(
        map((data: any) => {
          let stagioni: StagioneDto[] = [];
          if (data.dati !== undefined) {
            stagioni = JSON.parse(data.dati);
          } else stagioni = data;

          return stagioni;
        }),
       // tap((res) => console.log("tap", res))
      )
      .subscribe((res) => {
     //   console.log("res", res);
        this._listaStagioniByClienteSub.next(res);

        this._enablelistaStagioniSub.next(res.length > 0);
      });
  }

  loadsviluppoByArticolo(number): Observable<number> {
    return this.articoliSviluppiDataSource.getDataById(number).pipe(
      map((data: any) => {
        let sviluppo: ArticoliSviluppi[];
        if (data.dati !== undefined) {
          sviluppo = JSON.parse(data.dati);
        } else sviluppo = data;

        if (sviluppo.length == 0) {
          return undefined;
        }
        return sviluppo[0].idSviluppo;
      }),
      tap((res) => console.log("tap service", res)),

    );
  }

  state(): OrdiniState {
    let currentState: OrdiniState = new OrdiniState();

    currentState.listaTipiRigaOrdine = this._listaTipiRigaOrdine;
    currentState.listaTipiOrdine = this._listaTipiOrdine;
    currentState.listaSviluppi = this._listaSviluppi;
    currentState.listaAziende = this._listaAziende;
    currentState.listaArticoli = this._listaArticoliEstesi;
    currentState.listaScalature = this._listaScalature;
    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
  }

  mapper: IDTOMapper<OrdiniDto> = new OrdiniEstesiProduzioneMapper();


}
