import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';
import { ArticoliAnasnelleComponent } from './components/articoli-anasnelle/articoli-anasnelle.component';
import { SafePipe } from '../services/pipeTransform/safePipe';

@NgModule({
  declarations: [
    ArticoliAnasnelleComponent,
  ],

  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule
  ]
})
export class ArticoliAnasnelleModule { }