import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JsReportService {

  constructor(
    public http: HttpClient
  ) {

    
   }

   
  urlReport = "https://cindy.iamboo.net:5489/api/report";

  getReport(entity: any): Observable<Blob> {
    const url = `${this.urlReport}`;
    //console.log("entity",entity)
    return this.http.post(url, entity, { responseType: 'blob' });
  }
}
