export class FileOrdiniImportazioni {
  idFile: number=0;
  tipoFile: string='';
  percorso: string='';
  descEsito: string='';
  validUntil: string='';
  flagStato: string='';
  tsInserimento: string='';
  autore:any;
  codEsito: string = "";
}
