import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pop-up-delete',
  templateUrl: './pop-up-delete.component.html',
  styleUrls: ['./pop-up-delete.component.css']
})
export class PopUpDeleteComponent implements OnInit {
  @Output() confirm = new EventEmitter();

  constructor( public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    console.log("testoBottone", this.testoBottone)
  }
  
  testoBottone=""
  testoBody=''
  onSubmit(): void {
   let vm = true
    this.confirm.emit(vm);
   // console.log("confirm pop-up",vm)
  }
  onClose(): void {
    this.activeModal.close('close');
  }
  
  error(err: any): void {
    this.onClose();
  }

}
