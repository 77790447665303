import { SviluppoDto } from './sviluppo-dto';

export class Scalatura {

  idScalatura: number;
  codiceScalatura: string;
//  sviluppi: SviluppoDto[];
  extCode: string;
  validUntil: Date;

}

export class ScalatureDto {
  scalatura: Scalatura;
  codiceSviluppoTaglia: string;
  relazioni: RelazioniDto[];

  constructor() {


    this.scalatura = new Scalatura();
    this.codiceSviluppoTaglia = '';
    this.relazioni = new Array<RelazioniDto>();

    
  }

}



export class RelazioniDto {
  idRelazione: number;
  idScalatura: number;
  idSviluppoTaglia: number;
  codiceSviluppoTaglia: string;
  validUntil: string='';
  dettagli: DettagliRelazioniScalaturaDto[] = [];

}

export class DettagliRelazioniScalaturaDto {
  idDettaglio: number;
  idRelazione: number;
  indice: number;
  label: string;
}
