import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";

import { AlertService } from "src/app/_services/alert.service";
import { FileOrdiniImportazioni } from "src/app/_models/fileOrdini/fileOrdinImportazioni";
import { DettaglioErroriComponent } from "../dettaglio-errori/dettaglio-errori.component";
import { UserService } from "../service/user.service";
import { ImportazioniFileOrdiniService } from "../service/importazioni-file-ordini.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { HttpClient } from "@angular/common/http";
import { AndFilter } from "src/app/_filters/AndFilter";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { ImportazioniState } from "../state/importazioniState";
import { environment } from "src/environments/environment";
import { Observable, map, tap } from "rxjs";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { AziendeCRUDService } from "src/app/featureMain/service/aziende-crud.service";

@Component({
  selector: "app-importazioni-file-ordini",
  templateUrl: "./importazioni-file-ordini.component.html",
  styleUrls: ["./importazioni-file-ordini.component.css"],
})
export class ImportazioniFileOrdiniComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  nomeFileOrdine: string = "";

  fileImportazioneDataSource: RestDataSource;
  state: ImportazioniState = new ImportazioniState();
  listaAziende$: Observable<AziendeDto[]> | undefined = undefined;

  constructor(
    private modalservice: NgbModal,
    private serviceAllComboBox: ApiAllComboBoxdataService,
    private fileOrdiniService: ImportazioniFileOrdiniService,
    private userService: UserService,
    private http: HttpClient,
    private alertSrv: AlertService,
    private aziendeCRUD: AziendeCRUDService
  ) {
    this.fileImportazioneDataSource = new RestDataSource(this.http);

    this.fileImportazioneDataSource.setVariablesApi(
      "allFileOrdini",
      "fileOrdini",
      "fileOrdini",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );

    this.fileImportazioneDataSource.setByIdField("idFileOrdine");
    this.fileOrdiniService.connectDataSource(this.fileImportazioneDataSource);

    let user = JSON.parse(localStorage.getItem("userData"));
   // console.log("user", user);
    this.listaAziende$ = this.aziendeCRUD.getAziendeByUser(user).pipe(
      map((res: any) => {
        return JSON.parse(res.dati);
      }),
      //tap((res) => console.log("listaAziende", res))
    );

    this.listaAziende$.subscribe((res) => {
      this.creaMappa(res);
    });
  }

  onRefresh() {
  //  location.reload();
  this.searchTerm = "";
  }

  mappaIdAzienda_RagioneSociale: Map<string, string> = new Map();

  creaMappa(listaAziende) {
    /*  let match = listaAziende.find((azienda) => {
      return azienda.idAzienda == parseInt(idAzienda);
    });
    let ragioneSociale = "";
    if (match) {
      ragioneSociale = match.ragioneSociale;
    } else {
      ragioneSociale = "";
    } */

    listaAziende.forEach((azienda) => {
      this.mappaIdAzienda_RagioneSociale.set(
        azienda.idAzienda.toString(),
        azienda.ragioneSociale
      );
    });
  }

  getRagioneSocialeAzienda(idAzienda): Observable<string> {
    return this.listaAziende$.pipe(
      map((res: AziendeDto[]) => {
        let match = res.find((azienda) => {
          return azienda.idAzienda == parseInt(idAzienda);
        });
        if (match) {
          return match.ragioneSociale;
        } else {
          return "";
        }
      })
    );
  }

  _searchTerm: string;
  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this._page = 1;

    this.loadData();
  }

  get searchTerm() {
    return this._searchTerm;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.loadData();
  }
  get pageSize() {
    return this._pageSize;
  }
  set page(newPage: number) {
    this._page = newPage;

    this.loadData();
  }

  get page() {
    return this._page;
  }

  arrayStrPercorso = [];

  loadData() {
    this.fileOrdiniService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterImportazioni(service);

        console.log(this._page + " " + this._pageSize);
      },
    });
  }

  getNomeFile(percorso: string) {
    if (percorso) {
      this.arrayStrPercorso = percorso.split("\\");
      //console.log(this.arrayStrPercorso);
      this.nomeFileOrdine = this.arrayStrPercorso.pop();
      // console.log(this.nomeFileOrdine);
      percorso = this.nomeFileOrdine;

      return this.nomeFileOrdine;
    }
  }

  getCodiceAzienda(tipo) {
    let nome = tipo.toString().split("_");

    return nome;
  }

  getAutore(idAutore) {
    let autore;
    if (
      this.state !== undefined &&
      this.state.listaUsers !== undefined &&
      idAutore !== undefined &&
      idAutore !== null &&
      idAutore !== ""
    ) {
      let user = this.state.listaUsers.find((user) => {
        return user.userID == idAutore;
      });
      if (user) {
        return (autore = user.username);
      } else {
        return (autore = "Sconosciuto");
      }
    } else return (autore = "Sconosciuto");
  }

  filterImportazioni(service: ImportazioniFileOrdiniService) {
    console.log(this._searchTerm);
    this.state = service
      .filter(
        new AndFilter([
          new FieldsFilter({
            fieldList: ["percorso", "tipoFile"],
            searchString: this._searchTerm == undefined ? "" : this._searchTerm,
          }),
          //   new TipiPagamentoiFilter(null),
        ])
      )
      .sort(this._sortColumn, this._sortDirection)
      .paginate(this._page, this.pageSize)
      .state();
  }

  ngOnInit(): void {
    this._sortColumn = "idFile";
    this._sortDirection = "desc";
    this._rotateDirection = "desc";
    this.loadData();
  }
  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");

    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.filterImportazioni(this.fileOrdiniService);
  }

  onFileOrdineDetail(importazione: FileOrdiniImportazioni) {
    console.log(importazione);

    const item = importazione;
    const modalRef = this.modalservice.open(DettaglioErroriComponent, {
      size: "lg",
      windowClass: "animated FadeIn",
    });
    let nomeFile = this.getNomeFile(importazione.percorso);
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.typeop = "upd";
    // modalRef.componentInstance.listaAutori= this.state.listaUsers
    modalRef.componentInstance.nomeFileOrdine = nomeFile;
  }
}
