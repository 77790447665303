import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IDataSource } from "./IDataSource";

export class RestDataSource implements IDataSource {
  allDataApiEndPoint: string;
  getByIdEndPoint: string;
  dataDeleteEndPoint: string;
  insertDataEndPoint: string;
  updateDataEndPoint: string;
  domain: string;
  getDataByIdField: any;

  constructor(public http: HttpClient) {}
  setByIdField(field: string) {
    this.getDataByIdField = field;
  }

  getAllData(): Observable<object> {
    const url = `${this.domain}/${this.allDataApiEndPoint}`;

    return this.http.get(url);
  }

  getDataById(id: number): Observable<object> {
    const url = `${this.domain}/${this.getByIdEndPoint}?${this.getDataByIdField}=${id}`;

    return this.http.get(url);
  }

  getDataByCode(code: string): Observable<object> {
    const url = `${this.domain}/${this.getByIdEndPoint}?${this.getDataByIdField}=${code}`;

    return this.http.get(url);
  }

  getDataByMultipleId(stringOfId: string): Observable<Object> {
    const url = `${this.domain}/${this.getByIdEndPoint}?${this.getDataByIdField}=${stringOfId}`;
    return this.http.get(url);
  }

  insertData(data: any): Observable<object> {
    const url = `${this.domain}/${this.insertDataEndPoint}`;
    return this.http.post(url, data);
  }

  updateData(data: any): Observable<object> {
    const url = `${this.domain}/${this.updateDataEndPoint}`;
    return this.http.put(url, data);
  }

  deleteData(id: number): Observable<object> {
 /*    let payload = JSON.parse(
      '{"' + this.getDataByIdField + '" : "' + id + '"}'
    );

    console.log(payload); */

    const url = `${this.domain}/${this.dataDeleteEndPoint}?${this.getDataByIdField}=${id}`;
    console.log(url);

    return this.http.delete(url/* , { body: payload } */);
  }

  setVariablesApi(
    allDataEndPoint: string,
    dataByIdEndPoint: string,
    deleteEndpoint: string,
    domain: string
  ) {
    this.allDataApiEndPoint = allDataEndPoint;
    this.getByIdEndPoint = dataByIdEndPoint;
    this.dataDeleteEndPoint = deleteEndpoint;
    this.insertDataEndPoint = dataByIdEndPoint;
    this.updateDataEndPoint = dataByIdEndPoint;
    this.domain = domain;
  }
}
