import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";

export class SviluppoTaglieMapper implements IDTOMapper<SviluppiTaglieDto>{
    map(data: any): SviluppiTaglieDto {
      
      let item:SviluppiTaglieDto = new SviluppiTaglieDto();
  
      item.idSviluppoTaglia = data.idSviluppoTaglia;
      item.codiceSviluppoTaglia = data.codiceSviluppoTaglia;
      item.sviluppoTaglia = data.sviluppoTaglia;
      item.extCode = data.extCode;
      item.taglia = data.taglia;
      item.validUntil = data.validUntil;
    //  item.idAzienda=data.idAzienda;
    
      return item;
    }
  
  }