import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { FileMagazzinoDTO } from "../models/fileMagazzinoDTO";


export class FileMagazzinoMapper implements IDTOMapper<FileMagazzinoDTO>{
    
    map(data: any): FileMagazzinoDTO {
      
      let item:FileMagazzinoDTO = new FileMagazzinoDTO();
  
   
  
      
      return item;
    }
  
  }