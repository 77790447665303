import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ThemeSettingsConfig } from "src/app/_layout/settings/theme-settings.config";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import {
  ArticoloEstesoDto,
  DistintaBase,
  Macrofasi,
} from "src/app/_models/articoli/articoliDto";
import { ConsegnaDto } from "src/app/featureOrdini/models/consegnaDto";
import { IstanzaFaseLavDto } from "src/app/featureOrdini/models/istanzaFaseLavDto";
import {
  FaseLavorazione,
  RigaOrdine,
  RigaOrdineDto,
  UltimaVariazioneStatoRiga,
  ValoriVariabiliAzienda,
} from "src/app/featureOrdini/models/rigaOrdineDto";
import { TaglieSku } from "src/app/featureOrdini/models/taglieSku";
import { ValutaDto } from "src/app/featureOrdini/models/valutaDto";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";

import { TipiOrdineDto } from "src/app/featureTipoOrdine/models/tipiordine-dto";

import { Utility } from "src/app/_zCore/utility";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { TipoRigaOrdine } from "src/app/featureOrdini/models/rigaOrdineDto";
import { DistintaBaseService } from "src/app/shared/services/distintaBaseService/distinta-base.service";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { VariabileAziendaDTO } from "src/app/_models/variabiliPerAzienda/VariabileAziendaDTO";
import {
  Observable,
  OperatorFunction,
  Subscription,
  forkJoin,
  fromEvent,
  of,
} from "rxjs";
import { OrdiniDto } from "src/app/featureOrdini/models/ordiniDto";
import { OrdiniProduzioneService } from "../../service/ordini-produzione.service";
import { environment } from "src/environments/environment";
import { isThisSecond } from "date-fns";
import { ErroriService } from "src/app/_services/errori-service.service";
import { SearchfunctionType } from "src/app/shared/component/typeahead-oracle/typeahead-oracle.component";
import { AlertService } from "src/app/_services/alert.service";
import { OrdiniProdCRUDService } from "../../service/ordini-prod-crud.service";
import { Variables } from "src/assets/data/variables";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { UltimoLancio } from "../../models/lancioDto";
import { StatoDto } from "../../models/statoDto";

@Component({
  selector: "app-edit-riga-ordine",
  templateUrl: "./edit-riga-ordine.component.html",
  styleUrls: ["./edit-riga-ordine.component.css"],
})
export class EditRigaOrdineComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Output() confirm = new EventEmitter<RigaOrdineDto>();

  @ViewChild("selectArt") selectArRef: ElementRef | undefined = undefined;

  typeop = "";
  currentItem: RigaOrdineDto;
  // consegna: ConsegnaDto;
  sviluppoSelezionato: SviluppiTaglieDto;
  arraydiEventi;
  prezzo: number;
  selectedStato;
  codiceAzienda: string;
  //  paiaTotali=this.currentItem.quantita
  isStatoChanged;
  sviluppiTot: SviluppiTaglieDto[] = [];
  listaArticoli$: Observable<ArticoloEstesoDto[]>;
  articoloSelezionato: ArticoloEstesoDto;
  tipiRigheOrdine$: Observable<TipoRigaOrdine[]>;
  divisoreArticoloSelezionato$: Observable<string> | undefined = undefined;
  isCindyOrder: boolean;
  utente;
  listaSviluppiTaglie$: Observable<SviluppiTaglieDto[]> | undefined = undefined;
  listaTipiRigaOrdine$: Observable<TipoRigaOrdine[]>;
  paiaTotali: number;
  listaVariabiliAzienda$: Observable<VariabileAziendaDTO[]>;
  _dataCreazioneOrdine: Date;
  url = `${environment.baseDomain}:${environment.anaPort}/allVariabiliAziende`;
  sviluppoSelezionato$: Observable<SviluppiTaglieDto>;
  requiredSviluppo$: Observable<boolean>;
  articoloSelezionato$: Observable<ArticoloEstesoDto>;
  macrofasiDiLavorazione: Macrofasi[] = [];

  macrofasi$: Observable<Macrofasi[]> | undefined = undefined;
  istanzeFasiLavorazione$: Observable<IstanzaFaseLavDto[]> | undefined =
    undefined;

  valoriVariabiliToSend: ValoriVariabiliAzienda[] = [];
  stringaErrore: boolean = false;
  subArticoli: Subscription;
  listaTagliePlaceholder = [
    "31",
    "31+",
    "32",
    "32+",
    "33",
    "33+",
    "34",
    "34+",
    "35",
    "35+",
    "36",
    "36+",
    "37",
    "37+",
    "38",
    "38+",
    "39",
    "39+",
    "40",
    "40+",
    "41",
    "41+",
    "42",
    "42+",
  ];
  listaValoriVariabiliAzienda$: Observable<
    [VariabileAziendaDTO, ValoriVariabiliAzienda][]
  >;
  sviluppoByArticolo$: Observable<number>;
  searching = false;
  searchFailed = false;

  ultimoLancioOrdine: UltimoLancio;
  ultimaVariazioneStatoOrdine: StatoDto;
  enableQuantitaPerTaglia: boolean = true;
  constructor(
    public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    public dibaSrv: DistintaBaseService,
    public ordiniSrv: OrdiniProduzioneService,
    public ordiniCRUDSrv: OrdiniProdCRUDService,
    public http: HttpClient,
    public errorSrv: ErroriService,
    public alertSrv: AlertService
  ) {
    this.listaVariabiliAzienda$ = this.http.get(this.url).pipe(
      // tap((res) => console.log("tap", res)),
      map((cr: CindyCustomResponse) => {
        let variabili: VariabileAziendaDTO[] = [];
        if (cr.codEsito.toLowerCase() == "ok") {
          variabili = JSON.parse(cr.dati) as VariabileAziendaDTO[];

          return variabili;
        }
        // console.log("variabili", variabili);
        return variabili;
      })
    );

    this.requiredSviluppo$ = forkJoin({
      idsvil$: this.sviluppoSelezionato$,
      svilByArt$: this.sviluppoByArticolo$,
    }).pipe(
      // tap((res) => console.log("tap forkjoin", res)),
      map((res) => {
        return true;
      })
    );

    // return this.listaVariabiliAzienda$;
  }
  public model: any;

  showModel(id) {
    console.log("id", id);
    this.articoloSelezionato = id;
  }
  search: SearchfunctionType = (
    // search: OperatorFunction<string, readonly ArticoloEstesoDto[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.listaArticoli$.pipe(
          tap((res) => console.log("tap1", res, "term", term)),

          map((res: ArticoloEstesoDto[]) => {
            let articoliToSelect: any = [];
            let articoliTotali: ArticoloEstesoDto[] = res;

            let listaFiltrata = articoliTotali.filter(
              (articolo: ArticoloEstesoDto) => {
                let modello = (articolo.modello)?articolo.modello.toString().toUpperCase().trim():''
                let parte = (articolo.parte)?articolo.parte.toString().toUpperCase().trim():'';
                let colore = (articolo.colore)?articolo.colore.toString().toUpperCase().trim():'';
                let nome: string = `${modello}-${parte}-${colore}`;
                let idArticolo = articolo.idArticolo

                return nome.includes(
                  term.toString().toLocaleUpperCase().trim()
                );
              }
            );

            listaFiltrata.forEach((articolo)=>{
              if(articolo){

                let modello = (articolo.modello)?articolo.modello.toString().toUpperCase().trim():''
                let parte = (articolo.parte)?articolo.parte.toString().toUpperCase().trim():'';
                let colore = (articolo.colore)?articolo.colore.toString().toUpperCase().trim():'';
                let nome: string = `${modello}-${parte}-${colore}`;
                let idArticolo = articolo.idArticolo


                
                let articoloToSelect = {
                  nome: nome,
                  idArticolo: idArticolo,
                };

                articoliToSelect.push(articoloToSelect)
              }
            })

            console.log("listaArticoli", articoliToSelect);

            return articoliToSelect;
          }),
          tap((res) => console.log("tap1", res, "term", term)),
          tap((res) => console.log("model", this.model)),
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap((res) => console.log("esterno", res)),

      tap(() => (this.searching = false))
    );



  formatter = (x) => {
    
    console.log("x",x)
    
    
    //return `${x.modello}-${x.parte}-${x.colore}`
  return x.nome
  };

  ngAfterViewInit(): void {
    /*   console.log("selectArt", this.selectArRef.nativeElement);

    fromEvent(this.selectArRef.nativeElement, "change").subscribe(
      (res: any) => {
        console.log(res);
      }
    ); */
  }

  prepareValueSKU(indice) {
    let tSkuObj = this.currentItem.taglieSku.find((tsk) => {
      return tsk.codTaglia == parseInt(indice) + 1;
    });

    if (tSkuObj) {
      return tSkuObj.quantita;
    } else {
      return 0;
    }
  }

  ngOnInit(): void {
    console.log("this.currentItem", this.currentItem);
    //  this.loadArticoli(this.codiceAzienda);
    this.utente = JSON.parse(localStorage.getItem("userData"));
    this.listaSviluppiTaglie$ = this.ordiniSrv.loadSviluppi(this.utente.userID);

    this.listaValoriVariabiliAzienda$ = this.creaValoriVariabiliAziendaToSend(
      this.currentItem
    );

    // console.log("idArticolo", this.currentItem.rigaOrdine.idArticolo);

    if (this.selectedStato === "L") {
      this.selectedStato = true;
    }
    if (this.selectedStato === "V") {
      this.selectedStato = false;
    }

    if (this.typeop == "ins") {
      this._dataCreazioneOrdine = new Date();
      let newDate = new Date();
      this.currentItem.rigaOrdine.dataConsegnaConcordata =
        newDate.toLocaleDateString();
      this.currentItem.rigaOrdine.dataConsegnaPrevista =
        this.currentItem.rigaOrdine.dataConsegnaConcordata;
    }

    // this.listaTipiRigaOrdine = this.tipiRigheOrdine;
    if (this.currentItem.rigaOrdine.idSviluppo) {
      this.onSviluppo(this.currentItem.rigaOrdine.idSviluppo);
    }

    this.onArticolo(this.currentItem.rigaOrdine.idArticolo, false);

    this.prepareSviluppi();

    this.calcolaPaiaTotali();

    this.prepareValuta();

    this.getTotale();

    this.showNomeArticolo(this.currentItem.rigaOrdine.idArticolo);

    // this.prepareArticoli();
  }

  public get costoIstanzaLavorazione(): number {
    return this.currentItem.istanzeFaseLavorazione.length == 0
      ? 0
      : this.currentItem.istanzeFaseLavorazione[0].costoLavorazione;
  }

  loadArticoli(id: string) {
    /* 
    let url = `${environment.baseDomain}:${environment.anaPort}/articoliByAzienda?idAzienda=${id}`;
    this.listaArticoli$ = this.http.get(url).pipe(
      tap((res)=> {console.log("loadarticoli",res)}),
      map((res: CindyCustomResponse) => {
        let articoli: ArticoloEstesoDto[] = [];
        // console.log("res", res);
        if (res.codEsito.toLowerCase() == "ok") {
          articoli = JSON.parse(res.dati) as ArticoloEstesoDto[];
          return articoli;
        }

        return articoli;
      })
    );
    this.subArticoli = this.listaArticoliAzienda$.subscribe(
      (res: ArticoloEstesoDto[]) => {
        this.listaArticoli = res;
        console.log("res articoli", this.listaArticoli);

        if (this.listaArticoli.length !== 0) {
          this.articoliEsistenti = true;
          console.log("articoliEsistenti", this.articoliEsistenti);
        } else {
          this.articoliEsistenti = false;
          console.log("articoliEsistenti", this.articoliEsistenti);
        }
      }
    ); */
  }

  creaValoriVariabiliAziendaToSend(rigaOrdine: RigaOrdineDto) {
    // console.log("riga", rigaOrdine);
    return this.listaVariabiliAzienda$.pipe(
      map((listaVar: VariabileAziendaDTO[]) => {
        let arrayObjToSend: [VariabileAziendaDTO, ValoriVariabiliAzienda][] =
          [];
        let index = 0;
        listaVar.forEach((varAz: VariabileAziendaDTO) => {
          let valoriVariabiliToSend: ValoriVariabiliAzienda;

          if (rigaOrdine.valoriVariabiliAzienda[index] === undefined) {
            valoriVariabiliToSend = new ValoriVariabiliAzienda();
            valoriVariabiliToSend.idRiga = this.currentItem.rigaOrdine.idRiga;
            valoriVariabiliToSend.idVariabile = varAz.idVariabile;
            valoriVariabiliToSend.valoreVariabile = "";
            rigaOrdine.valoriVariabiliAzienda[index] = valoriVariabiliToSend;
          }
          arrayObjToSend.push([
            varAz,
            rigaOrdine.valoriVariabiliAzienda[index],
          ]);

          index++;
        });
        //  let indiceValoriTupla = 0;
        /* 
        arrayObjToSend.forEach((arr) => {
          console.log("valori",this.currentItem.valoriVariabiliAzienda)
          if (this.currentItem.valoriVariabiliAzienda[indiceValoriTupla].idVariabile == arr[1].idVariabile)
          this.currentItem.valoriVariabiliAzienda[indiceValoriTupla].valoreVariabile = arr[1].valoreVariabile;
          indiceValoriTupla++;
        }); */
        return arrayObjToSend;
      })
      /*   tap((res) => {
        console.log("tap2", res);
      }) */
    );
  }

  prepareValuta() {
    if (!this.currentItem.valuta || this.currentItem.valuta == null) {
      let valuta: ValutaDto = new ValutaDto();
      valuta.codiceValuta = "EUR";
      valuta.simboloValuta = "€";
      this.currentItem.valuta = valuta;
      // console.log("valuta", valuta);
    }
  }

  onDataPrevistaChanged(event) {
    this.currentItem.rigaOrdine.setFormattedDatePrevista(event);
    // console.log("prevista", this.currentItem.rigaOrdine.dataConsegnaPrevista);
  }

  onDataConcordataChanged(event) {
    this.currentItem.rigaOrdine.setFormattedDateConcordata(event);
  }

  /*   prepareArticoli() {
        if (this.currentItem.rigaOrdine.idArticolo !== 0) {
      this.articoloSelezionato = this.listaArticoli.find((a) => {
        return a.idArticolo == this.currentItem.rigaOrdine.idArticolo;
      });
    } 
    if (
      this.articoloSelezionato != undefined &&
      this.articoloSelezionato.idArticolo != 0
    )
      this.prepareArticolo(this.articoloSelezionato.idArticolo);
  } */

  public get idArticoloSelezionato(): number {
    return this.articoloSelezionato == undefined
      ? 0
      : this.articoloSelezionato.idArticolo;
  }

  public get enablePrezzo(): boolean {
    return (
      this.articoloSelezionato !== undefined &&
      this.sviluppoSelezionato != undefined
    );
  }

  nomeArticolo$: Observable<string>;
  showNomeArticolo(id) {
    /*
    if(id !== null){

      return of("articolo");
    }
    */

    this.nomeArticolo$ = this.listaArticoli$.pipe(
      map((res) => {
        let match = res.find((articolo) => {
          return articolo.idArticolo == id;
        });

        console.log("typeop", this.typeop);
        if (match) {
          let modello = match.modello
            ? match.modello.toString().toUpperCase().trim()
            : "";
          let parte = match.parte
            ? match.parte.toString().toUpperCase().trim()
            : "";
          let colore = match.colore
            ? match.colore.toString().toUpperCase().trim()
            : "";

          let nome = `${modello}-${parte}-${colore}`;

          return nome;
        } else {
          if (
            this.currentItem.rigaOrdine.idOrdine !== 0 &&
            this.typeop !== "ins"
          ) {
            this.errorSrv.onToastTestata("Impossibile trovare l'articolo");
          }
          return "Digita per selezionare";
        }
      }),
      tap((nome) => {
        console.log("nome articolo", nome);
      })
    );
  }

  onArticolo(newIdArticolo: number, changed: boolean) {
    this.currentItem.rigaOrdine.idArticolo = newIdArticolo;
    this.prepareArticoloSelezionato(newIdArticolo);
    this.sviluppoByArticolo$ =
      this.ordiniSrv.loadsviluppoByArticolo(newIdArticolo);
    /*  this.sviluppoByArticolo$.subscribe((res) => {
      if (res) {
        this.currentItem.rigaOrdine.idSviluppo = res;
        this.onSviluppo(res);
      }
      console.log("sviluppoRes", res);
    }); */
  }

  prepareArticoloSelezionato(idArticolo: number) {
    console.log("idArticolo", idArticolo);
    if (idArticolo != undefined && idArticolo != 0) {
      this.articoloSelezionato$ = this.listaArticoli$.pipe(
        map((articoli) => {
          return articoli.find((art) => art.idArticolo == idArticolo);
        })
      );

      this.macrofasi$ = this.getMacrofasiArticolo(
        idArticolo,
        this.selectedStato
      );
      this.divisoreArticoloSelezionato$ = this.getDivisore(idArticolo);
    }

    // console.log("selectedStato true", this.currentItem);
  }

  getDivisore(idArticolo: number): Observable<string> {
    return this.dibaSrv.getbyId(idArticolo).pipe(
      map((res: CindyCustomResponse) => JSON.parse(res.dati) as DistintaBase),
      map(
        (diba) => {
          if (diba) {
            return diba.componenti[0].divisore;
          } else {
            console.log("riba", diba);
          }
        },
        (err) => {
          this.errorSrv.onToastTestata(
            "Impossibile recuperare il Divisore per questo articolo"
          );
          console.log("errore divisore", err);
        }
      )
    );
  }

  getMacrofasiArticolo(
    idArticolo: number,
    contoLavoro: boolean
  ): Observable<Macrofasi[]> {
    return this.dibaSrv.getbyId(idArticolo).pipe(
      map(
        (res: CindyCustomResponse) => {
          console.log("diba", res);
          let diba = JSON.parse(res.dati) as DistintaBase;

          return diba;
        },
        (err) =>
          this.alertSrv.warning(
            "L'articolo selezionato ha Distinta Base mancante"
          )
      ),
      map(
        (diba: DistintaBase) => {
          let macrofasi = diba.macrofasi;
          if (contoLavoro == false) {
            if (this.currentItem.istanzeFaseLavorazione.length === 0) {
              macrofasi = macrofasi.filter(
                (f: Macrofasi) => parseInt(f.idMacrofase) == 0
              );
              console.log("macrofasi vendita", macrofasi);

              macrofasi.forEach((macF) => {
                let ist: IstanzaFaseLavDto = new IstanzaFaseLavDto();
                ist.costoLavorazione = 0;
                ist.idFase = parseInt(macF.idMacrofase);
                this.currentItem.istanzeFaseLavorazione.push(ist);
              });
            }
            let macrofasiIst = [];
            console.log("istanze", this.currentItem.istanzeFaseLavorazione);
            this.currentItem.istanzeFaseLavorazione.forEach((fl) => {
              let mf = macrofasi.find((f: Macrofasi) => {
                return parseInt(f.idMacrofase) == fl.idFase;
              });

              if (mf !== undefined && parseInt(mf.idMacrofase) == 0)
                macrofasiIst.push(mf);
            });

            return macrofasiIst;
          } else {
            if (this.currentItem.istanzeFaseLavorazione.length === 0) {
              macrofasi = macrofasi.filter(
                (f: Macrofasi) => parseInt(f.idMacrofase) !== 0
              );

              macrofasi.forEach((macF) => {
                let ist: IstanzaFaseLavDto = new IstanzaFaseLavDto();
                ist.costoLavorazione = 0;
                ist.idFase = parseInt(macF.idMacrofase);
                this.currentItem.istanzeFaseLavorazione.push(ist);
              });
            }
            let macrofasiIst = [];
            this.currentItem.istanzeFaseLavorazione.forEach((fl) => {
              let mf = macrofasi.find((f: Macrofasi) => {
                return parseInt(f.idMacrofase) == fl.idFase;
              });

              if (mf !== undefined && parseInt(mf.idMacrofase) !== 0)
                macrofasiIst.push(mf);
            });
            return macrofasiIst;
            /*  return macrofasi.filter(
              (f: Macrofasi) => parseInt(f.idMacrofase) !== 0
            ); */
          }
        },
        (err) =>
          this.alertSrv.warning(
            "L'articolo selezionato ha Distinta Base mancante"
          )
      )

      // tap((res) => console.log("tap macrofasi", res))
    );

    /*
    .subscribe((res: CindyCustomResponse) => {
      let distintaBase: DistintaBase = JSON.parse(res.dati);
      this.macrofasiDiLavorazione = distintaBase.macrofasi;
      let istanzeLavorazione = this.currentItem.istanzeFaseLavorazione;

      let macrofasi: Macrofasi[] = this.macrofasiDiLavorazione;
      if (contoLavoro == false) {
        macrofasi = this.macrofasiDiLavorazione.filter(
          (f: Macrofasi) => parseInt(f.idMacrofase) == 0
        );
      } else {
        macrofasi = this.macrofasiDiLavorazione.filter(
          (f: Macrofasi) => parseInt(f.idMacrofase) !== 0
        );
      }

      this.macrofasiDiLavorazione = macrofasi;

   
    });*/
  }

  onSviluppo(idSviluppo: number) {
    /*     let svSel = idSviluppo;

    let sv: SviluppiTaglieDto = this.sviluppiTot.find((svt) => {
      return svt.idSviluppoTaglia == svSel;
    });
    this.sviluppoSelezionato = sv;
    */

    this.sviluppoSelezionato$ = this.listaSviluppiTaglie$.pipe(
      map((res: SviluppiTaglieDto[]) => {
        return res.find((sv) => sv.idSviluppoTaglia == idSviluppo);
      })
    );
    if (this.currentItem.taglieSku.length == 0) {
      this.sviluppoSelezionato$.subscribe((res) => {
        this.prepareTagliePerSviluppo(res);
      });
    }
  }

  prepareTagliePerSviluppo(sviluppo: SviluppiTaglieDto) {
    if (this.currentItem.taglieSku.length == 0) {
      let indice = 1;
      sviluppo.sviluppoTaglia.forEach((svT) => {
        let tSkuObj = new TaglieSku();
        tSkuObj.idRiga = 0;
        tSkuObj.codTaglia = indice++;
        tSkuObj.quantita = 0;
        tSkuObj.sku = "";
        tSkuObj.extCode = "";
        tSkuObj.validUntil = "";
        this.currentItem.taglieSku.push(tSkuObj);
      });
    } else {
      this.currentItem.taglieSku.forEach((tg) => {
        tg.quantita = Utility.toInteger(tg.quantita);
      });
    }
  }

  prepareSviluppi() {
    // this.sviluppiTot = this.listaSviluppiTaglie;
    let currentIdSviluppo = this.currentItem.rigaOrdine.idSviluppo;
    if (currentIdSviluppo !== 0 && currentIdSviluppo !== undefined) {
      this.sviluppoSelezionato = this.sviluppiTot.find(
        (sv: SviluppiTaglieDto) => {
          return sv.idSviluppoTaglia == this.currentItem.rigaOrdine.idSviluppo;
        }
      );

      this.prepareTagliePerSviluppo(this.sviluppoSelezionato);

      // this.onSviluppo(this.currentItem.rigaOrdine.idSviluppo);
    }
  }

  tipoOrdineSelezionato: TipoRigaOrdine;

  /*  onTipoRigaOrdine(ev) {
    let tiOr = ev;
    let tipoOrdine: TipoRigaOrdine = this.tipiRigheOrdine.find((to) => {
      return to.idTipoRigaOrdine == tiOr;
    });
    this.tipoOrdineSelezionato = tipoOrdine;
  } */

  getDescFaseLavorazione(idFase) {
    let fase = this.currentItem.fasiLavorazione.find((fase) => {
      return fase.idFaseLavorazione == idFase;
    });
    if (fase != undefined) {
      return fase.descFaseLavorazione;
    } else {
      fase = new FaseLavorazione();
    }
  }

  getCodFaseLavorazione(idFase) {
    let fase = this.currentItem.fasiLavorazione.find((fase) => {
      return fase.idFaseLavorazione == idFase;
    });
    if (fase != undefined) {
      return fase.codFaseLavorazione;
    } else {
      fase = new FaseLavorazione();
    }
  }

  onCambioPrezzo(newPrezzo) {
    this.currentItem.istanzeFaseLavorazione[0].costoLavorazione =
      Utility.toDecimal(newPrezzo);

    //this.getTotale();
  }

  // totale = 0;
  getTotale() {
    let totale = 0;
    this.currentItem.istanzeFaseLavorazione.forEach((istanza) => {
      totale += istanza.costoLavorazione * this.paiaTotali;
    });
    return totale.toFixed(2);
  }

  updateCostoLavorazione(event, index, istanza) {
    if (event == null || event.toString().includes("-")) {
      this.stringaErrore = true;
    } else {
      this.stringaErrore = false;
    }

    let istanzaCurrent = this.currentItem.istanzeFaseLavorazione.find(
      (is) => is.idFase == istanza.idFase
    );

    this.currentItem.istanzeFaseLavorazione[index].costoLavorazione =
      Utility.toDecimal(event);
    /*   istanza.costoLavorazione =
      Utility.toDecimal(event); */

    this.getTotale();
  }

  getQnt(indice) {
    let quantita: number = 0;
    if (indice < this.currentItem.taglieSku.length) {
      quantita = this.currentItem.taglieSku[indice].quantita;
    }
    return quantita;
  }

  onCambioQuantita(event, index) {
    let tSkuObj = this.currentItem.taglieSku.find((tsk) => {
      return tsk.codTaglia == parseInt(index) + 1;
    });
    if (tSkuObj) {
      tSkuObj.quantita = Utility.toInteger(event);

      this.calcolaPaiaTotali();

      this.getTotale();
    }
  }

  calcolaPaiaTotali() {
    let paiaTotali = 0;

    let index = 0;
    this.currentItem.taglieSku.forEach((t) => {
      paiaTotali += Utility.toInteger(t.quantita);

      index++;
    });

    this.paiaTotali = paiaTotali;
  }

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      this.onClose();

      return;
    }
    /*  this.currentItem.rigaOrdine.idArticolo =
      this.articoloSelezionato.idArticolo;
    this.currentItem.rigaOrdine.idSviluppo =
      this.sviluppoSelezionato.idSviluppoTaglia; */
    if (
      this.currentItem.tipoRigaOrdine == null ||
      this.currentItem.tipoRigaOrdine == undefined
    ) {
      this.currentItem.tipoRigaOrdine = null;
    }

    let ultimaVariazioneStatoRiga: UltimaVariazioneStatoRiga = {
      idVariazione: 0,
      idStatoRigaOrdine: 0,
      idRiga: 0,
      tsVariazione: "",
      extCode: "",
      validUntil: "",
      descrizione: Variables.creata.toUpperCase(),
    };

    if (
      this.currentItem.rigaOrdine.idRiga == 0 ||
      !this.currentItem.rigaOrdine.idRiga ||
      this.typeop == "ins"
    ) {
      this.currentItem.ultimaVariazioneStatoRiga = ultimaVariazioneStatoRiga;
    }
    /*  this.currentItem.valuta.codiceValuta = 'EUR'
    this.currentItem.valuta.codiceValuta = 'EUR' */

    console.log("emit", this.currentItem);
    this.confirm.emit(this.currentItem);

    this.onClose();
  }

  onAnnullaRiga() {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.testoBottone = "CONFERMA";
    modalRef.componentInstance.testoBody =
      "Sei sicuro di voler annullare questo elemento?";
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((res) => {
          console.log("res confirm annulla", res);
          return res;
        }),
        switchMap((res) => {
          if (res) {
            console.log("annullaOrdine", this.currentItem);
            return this.ordiniCRUDSrv.onAnnullaRigaOrdine(
              this.currentItem.rigaOrdine.idRiga
            );
          }
        })
      )
      .subscribe(
        (res: CindyCustomResponse) => {
          console.log("res annulla", res);
          if (res && res.codEsito == "OK") {
            this.onClose();
            this.alertSrv.success("La riga è stata annullata con successo");
          }
          modalRef.close();
          console.log("res dati", JSON.parse(res.dati));
          console.log("variazione", this.currentItem.ultimaVariazioneStatoRiga);
        },
        (err) => {
          console.log("err annulla", err);
          console.log("err dati", JSON.parse(err.error).dati);
        }
      );
  }

  error(err: any): void {
    console.log("errore", err.error);
    //alert('Si è verificato un errore nella scrittura dei dati!');
    const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
    // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati;

    this.onClose();
  }

  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  ngOnDestroy(): void {
    // this.subArticoli.unsubscribe();
  }
}
