export class FabbisogniDto {
  riepilogo: RiepilogoTestataFabbisogni;
  componentiPaia: ComponentiPaiaFabbisogni[] = [];
  componentiScalatura: componentiScalaturaFabbisogni[] = [];
  componentiSviluppo: ComponentiSviluppoFabbisogni[]=[];
}

export class RiepilogoTestataFabbisogni {
  data: string = "";
  paia_totali: number = 0;
  lottoAcquisto: number = 0;
  ordini: OrdiniRiepilogoTestataFabbisogni[] = [];
  articoli: ArticoliRiepilogoTestataFabbisogni[] = [];
}

export class OrdiniRiepilogoTestataFabbisogni {
  ordine: string = "";
  stagione: string = "";
}

export class ArticoliRiepilogoTestataFabbisogni {
  modello_parte_colore: string = "";
  descrizione_articolo: string = "";
  descrizione_linea: string = "";
  paia: number = 0;
}

export class ComponentiPaiaFabbisogni {
  descrizione_gr_merc: string = "";
  codice_componente: string = "";
  descrizione_componente: any = "";
  colore: any = "";
  codice_componente_forn: any = "";
  fornitore: any = "";
  udm: string = "";
  consumo: number = 0;
  paia: number = 0;
}

export class ComponentiSviluppoFabbisogni {
  sviluppo: string = "";
  descrizione_gr_merc: string = "";
  codice_componente: string = "";
  descrizione_componente: string = "";
  colore: any = "";
  codice_componente_forn: any = "";
  fornitore: any = "";
  udm: string = "";
  progressione_sviluppo: string[] = [];
  numeri: number[] = [];
}

export class componentiScalaturaFabbisogni{
  codice_componente: string = "";
  codice_componente_forn: any = "";
  colore: any = "";
  descrizione_componente: string = "";
  descrizione_gr_merc: string = "";
  fornitore: any = "";
  numeri: number[] = [];


}
