import { TipiEsenzioneDto } from '../tipiesenzione/tipiesenzione-dto';
import { TipiOrdineDto } from '../../featureTipoOrdine/models/tipiordine-dto';
import { TipiPagamentoDto } from '../tipipagamento/tipipagamento-dto';
import { PortiDto } from '../porti/porti-dto';
import { ListItemStringDto } from '../../_zCore/models/list-item-string-dto';
import { ImballiDto } from '../../featureImballi/models/imballi-dto';
import { FasiLavorazioneDto } from '../fasilavorazione/fasilavorazione-dto';
import { LavorantiDto } from '../lavoranti/lavoranti-dto';
import { AziendeDto } from '../../featureMain/models/aziende-dto';
import { RecapitiDto } from '../recapiti/recapiti-dto';
import { Province } from '../localizzazioni/province';
import { SviluppiTaglieDto } from '../../featureSviluppiTaglia/models/sviluppitaglie-dto';
import { TipoAziendaDto } from '../tipoAzienda/tipoAziendaDto';

export class AllComboboxDataDto {
  tipoOrdine: TipiOrdineDto[];
  tipoPagamento: TipiPagamentoDto[];
  tipoEsenzione: TipiEsenzioneDto[];
  tipoPorto: PortiDto[];
  tipoImballo:ImballiDto[];
  listaTipoAziende: TipoAziendaDto[];
  tipoFattura: ListItemStringDto[];
  tipoFaseLavorazione: FasiLavorazioneDto[];
  tipoLavoranti:LavorantiDto[];
  listaAziende:AziendeDto[];
  tipoRecapito: RecapitiDto[];
  listaProvince: Province[];
  listaSviluppiTaglie:SviluppiTaglieDto[]
  listaRecapiti:RecapitiDto[];
}


export class OggettoMenu{
  title:string;
  items:any[] =[];
}