import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { SharedModule } from "src/app/shared/shared.module";
import { BlockUIModule } from "ng-block-ui";
import { LancioProduzionePageListComponent } from "./component/lancio-produzione-page-list/lancio-produzione-page-list.component";
import { AvviaLancioProduzioneComponent } from "./component/avvia-lancio-produzione/avvia-lancio-produzione.component";
import { LancioProdEditComponent } from "./component/lancio-prod-edit/lancio-prod-edit.component";

import { SearchComponent } from "../_components/search/search.component";
import { NgbdSortableHeader } from "../_directives/sortable.directive";

@NgModule({
  declarations: [
    LancioProduzionePageListComponent,
    AvviaLancioProduzioneComponent,
    LancioProdEditComponent,
   // SearchComponent,
   // NgbdSortableHeader,
  ],

  imports: [NgbModule, SharedModule, BlockUIModule],
})
export class FeatureLancioProdModule {}
