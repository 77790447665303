import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormControlName,
  NgForm,
} from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DettagliRelazioniScalaturaDto,
  ScalatureDto,
} from "src/app/featureScalature/models/scalature-dto";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { Utility } from "src/app/_zCore/utility";
import { ScalaturaDto } from "src/app/featureScalature/models/scalatura-dto";
import { SviluppoDto } from "src/app/featureScalature/models/sviluppo-dto";
import { MatchingScalature } from "src/app/featureScalature/models/matchingScalature";

import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
import { environment } from "src/environments/environment";
import { RelazioniDto } from "src/app/featureScalature/models/scalature-dto";
import { SviluppoTagliService } from "src/app/_services/allServices";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { map, tap } from "rxjs/operators";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { BehaviorSubject, Observable } from "rxjs";
import { SviluppiTaglieCRUDService } from "src/app/featureSviluppiTaglia/service/sviluppi-taglie-crud.service";
import { SviluppiTaglieSearchService } from "src/app/featureSviluppiTaglia/service/sviluppi-taglie-search.service";
import { ScalatureSearchService } from "../../service/scalature-search.service";
import * as _ from "lodash";
import { Globals } from "src/app/_models/Globals";
import { AlertService } from "src/app/_services/alert.service";

@Component({
  selector: "app-scalature-edit",
  templateUrl: "./scalature-edit.component.html",
  styleUrls: ["./scalature-edit.component.css"],
})
export class ScalatureEditComponent implements OnInit, OnDestroy {
  @Output() confirm = new EventEmitter<ScalatureDto>();
  typeop = "";
  alltype: AllComboboxDataDto;
  currentItem: ScalatureDto;
  select;
  //selectedSvId: number;
  delete: boolean = false;
  idSelezionato: number;
  arrayDegliSviluppi: SviluppoDto[] = [];
  listaSviluppiTaglie: SviluppiTaglieDto[];
  arraySviluppi$: Observable<SviluppoDto[]> | undefined = undefined;
  listaSviluppiByUtente$: Observable<SviluppiTaglieDto[]> | undefined =
    undefined;
  enableSalva$: Observable<boolean>;
  _enableSalvaBSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /*   listaSviluppiTaglie$ = this.listaSviluppi$.pipe(
    map((sv) => {
      let arraycod = [];
      this.currentItem.relazioni.forEach((rel) => {
        arraycod.push(rel.codiceSviluppoTaglia);
      });
      return sv.filter((sviluppi) => {
        return arraycod.includes(sviluppi.codiceSviluppoTaglia);
      });
    }),
    tap((res) => console.log("tap", res))
  ); */

  constructor(
    public activeModal: NgbActiveModal,
    public sviluppiTaglieService: SviluppoTagliService,
    private modalservice: NgbModal,
    public scalatureSearchSrv: ScalatureSearchService,
    public alertSrv:AlertService
  ) {
    if (environment.useLocalData == false) {
    } else {
    }

    console.log(
      "this.scalatureSearchSrv.listaSviluppi",
      this.scalatureSearchSrv.listaSviluppi
    );

    this.listaSviluppiByUtente$ =
      this.scalatureSearchSrv.listaSviluppibyIdUtente$;
    this.enableSalva$ = this._enableSalvaBSubject.asObservable();
  }

  ngOnInit(): void {
    console.log("on init", this.currentItem);
    // console.log(
    //   "this.arrayDegliSviluppi",
    //   this.scalatureSearchSrv.listaSviluppi
    // );
    //this.listaSviluppiTaglie = this.scalatureSearchSrv.listaSviluppi;

    /* 
    if (this.typeop === "upd") {
      this.arraySviluppi$ = this.prepareSviluppi();
    } */

    this.prepareDettagli();
  }

  prepareDettagli() {
    console.log("on init", this.currentItem);

    let curRelazioni: RelazioniDto[] = _.cloneDeep(this.currentItem.relazioni);

    let indiceRel: number = 0;
    curRelazioni.forEach(
      (r) =>
        (r.dettagli = [...this.currentItem.relazioni[indiceRel++].dettagli])
    );

    indiceRel = 0;

    curRelazioni.forEach((curRel) => {
      this.currentItem.relazioni[indiceRel].dettagli = [];

      for (let i = 0; i < 26; i++) {
        let dett: DettagliRelazioniScalaturaDto =
          new DettagliRelazioniScalaturaDto();
        dett.indice = i;
        dett.idRelazione = curRel.idRelazione;
        dett.label = "";

        curRel.dettagli.forEach((d) => {
          if (d.indice === i) {
            dett.label = d.label;
          }
        });

        this.currentItem.relazioni[indiceRel].dettagli.push(dett);
      }

      indiceRel++;
    });

    //console.log("prepare", this.currentItem);
  }

  onChangeLabel(evento, indiceDettaglio, indiceRelazione) {
    console.log("ev",evento, "indiceDettaglio", indiceDettaglio, "indRel", indiceRelazione)
    this.currentItem.relazioni[indiceRelazione].dettagli[indiceDettaglio].label = evento
    this.currentItem.relazioni.forEach((rel) => {
      let match = rel.dettagli.find((det) => {
        return (det.label !== "" && det.label !== null && det.label !== undefined)
      });
      console.log( "match", match)
      if (match){
        this._enableSalvaBSubject.next(true)
      } else {
        this._enableSalvaBSubject.next(false) 
      }
    });
  }

  findSviluppo(idSviluppo, listaSviluppi: SviluppiTaglieDto[]) {
    // console.log("find", idSviluppo);
    let sviluppo = listaSviluppi.find(
      (sv) => sv.idSviluppoTaglia === idSviluppo
    );
    return sviluppo;
  }

  /*   prepareSviluppi(): Observable<SviluppoDto[]> {
    return this.listaSviluppi$.pipe(
      map((sviluppi) => {
        let arraySviluppi: SviluppoDto[] = [];
        this.currentItem.relazioni.forEach((rel) => {
          let sviluppoCurrentItem = sviluppi.find((sv) => {
            return sv.idSviluppoTaglia == rel.idSviluppoTaglia;
          });
          let sviluppo = new SviluppoDto();
          sviluppo.idSviluppo = rel.idSviluppoTaglia;
          sviluppo.codiceSviluppoTaglia =
            sviluppoCurrentItem.codiceSviluppoTaglia;
          sviluppo.sviluppoTaglia = sviluppoCurrentItem.sviluppoTaglia;
          this.arrayTaglie = sviluppo.sviluppoTaglia;
          let arrayScalature = [];
          let scalatureKey = {};
          let arrayIndici = [];
          rel.dettagli.forEach((dett) => {
            let key = dett.label;
            var scal = new ScalaturaDto();
            //se esiste dentro scalaturekey un elemeto con chiave key
            if (scalatureKey[key] == null || scalatureKey[key] == undefined) {
              scal.label = key;
              scalatureKey[key] = scal;
            }
            arrayIndici.push(dett.indice);
            scal.taglie = arrayIndici;
            arrayScalature.push(scal);
          });
          sviluppo.scalatura = arrayScalature;
          arraySviluppi.push(sviluppo);
        });

        return arraySviluppi;
      })
    );
  } */

  // arrayTaglie: string[] = [];

  //fatto con enrico
  /*   getTagliaFromSviluppoTaglie() {
    this.currentItem.relazioni.forEach((rel) => {
      var sv = new SviluppoDto();
      let svTaglia = this.getSviluppoArray(rel.idSviluppoTaglia);
      //console.log(objSviluppo);
      sv.idSviluppo = rel.idSviluppoTaglia;
      sv.codiceSviluppoTaglia = svTaglia.codiceSviluppoTaglia;
      sv.sviluppoTaglia = svTaglia.sviluppoTaglia;
      this.arrayTaglie = sv.sviluppoTaglia;

      //   console.log(this.arrayTaglie);
      let arrayScalature = [];
      let scalatureKey = {};
      let arrayIndici = [];
      rel.dettagli.forEach((dett) => {
        let key = dett.label;
        var scal = new ScalaturaDto();
        //se esiste dentro scalaturekey un elemeto con chiave key
        if (scalatureKey[key] == null || scalatureKey[key] == undefined) {
          scal.label = key;
          scalatureKey[key] = scal;
        }
        arrayIndici.push(dett.indice);
        scal.taglie = arrayIndici;
        arrayScalature.push(scal);
      });
      sv.scalatura = arrayScalature;
      this.arrayDegliSviluppi.push(sv);
    });
    // console.log("getTaglie", this.currentItem);
    // console.log ("array sviluppi", this.arrayDegliSviluppi )
  } */

  OnChangeSelect(e) {
    //   console.log(e);
    let string = e.target.value.split("&");
    //  console.log(a)

    //this.selectedSvId = e;
    this.currentItem.codiceSviluppoTaglia = string[0];
    this.idSelezionato = parseInt(string[1]);
    console.log("controlla codiceSviluppo", this.currentItem);
  }

  onAddRelazione() {
    let arrayIdTagliaiNelCurrentItem = [];
    this.currentItem.relazioni.forEach((rel: RelazioniDto) =>
      arrayIdTagliaiNelCurrentItem.push(rel.idSviluppoTaglia)
    );

    if (arrayIdTagliaiNelCurrentItem.indexOf(this.idSelezionato) === -1) {
      let relazione: RelazioniDto = new RelazioniDto();
      relazione.codiceSviluppoTaglia = this.currentItem.codiceSviluppoTaglia;
      relazione.idSviluppoTaglia = this.idSelezionato;
      relazione.idScalatura = this.currentItem.scalatura.idScalatura;
      relazione.dettagli = [];

      for (let i = 0; i < 26; i++) {
        let dett: DettagliRelazioniScalaturaDto =
          new DettagliRelazioniScalaturaDto();
        dett.indice = i;
        dett.label = "";
        relazione.dettagli.push(dett);
      }

      this.currentItem.relazioni.push(relazione);
    } else {
      this.alertSrv.warning(Globals.MappaMessaggiErrore.get("DUP_SVILUPPO"));

 /*      const modalRefErrore = this.modalservice.open(
        PopUpIstanzaEsistenteComponent,
        {
          size: "sm",
          windowClass: "animated FadeIn",
        }
      );
      modalRefErrore.componentInstance.messaggioEventuale =
        "Sviluppo già presente nella scalatura"; */
    }

    console.log("addRelazione", this.currentItem);
  }

  /*   onAddSviluppo() {
    // console.log("id sviluppo taglie selezionato", this.selectedSvId);
    let svTg: SviluppiTaglieDto = this.listaSviluppiTaglie.find(
      (svT) => this.selectedSvId == svT.idSviluppoTaglia
    );
    console.log("sviluppo taglie aggiunto", this.arrayDegliSviluppi);

    let svi = this.arrayDegliSviluppi.find(
      (s) => s.idSviluppo == this.selectedSvId
    );

    if (!(this.arrayDegliSviluppi.indexOf(svi) != -1)) {
      var sviluppoIns = new SviluppoDto();
      sviluppoIns.codiceSviluppoTaglia = svTg.codiceSviluppoTaglia;
      sviluppoIns.idSviluppo = svTg.idSviluppoTaglia;
      sviluppoIns.sviluppoTaglia = svTg.sviluppoTaglia;
      this.arrayDegliSviluppi.unshift(sviluppoIns);
      //  this.currentItem.sviluppi = this.arrayDegliSviluppi;
    } else {
      let match = this.arrayDegliSviluppi.find((sv) => {
        sv.idSviluppo == this.selectedSvId;
        sviluppoIns = sv;
        //      this.currentItem.sviluppi = this.arrayDegliSviluppi;
      });
      console.log("match", match);
      if (match == undefined) {
        const modalRefErrore = this.modalservice.open(
          PopUpIstanzaEsistenteComponent,
          {
            size: "sm",
            windowClass: "animated FadeIn",
          }
        );
        modalRefErrore.componentInstance.messaggioEventuale =
          "Sviluppo già presente nella scalatura";
      }
    }

    console.log("array degli sviluppi", this.arrayDegliSviluppi);
  } */

  /*   getSviluppoArray(id: number) {
    return this.listaSviluppiTaglie.find((sv) => {
      return sv.idSviluppoTaglia == id;
    });
  } */

  getSviluppo(id: number): Observable<SviluppiTaglieDto> {
    return this.listaSviluppiByUtente$.pipe(
      tap((res) => console.log(res)),
      map((sviluppi: SviluppiTaglieDto[]) => {
        let match = sviluppi.find((sv) => sv.idSviluppoTaglia == id);

        console.log("sv", match);

        return match;
      }),
      tap((res) => console.log("res", res))
    );
  }

  getIt(id) {
    this.listaSviluppiByUtente$.pipe(map((res: SviluppiTaglieDto[]) => {}));
  }

  onDeleteSviluppo(id, form: NgForm) {
    let index = this.currentItem.relazioni.findIndex((rel) => {
      return rel.idSviluppoTaglia == id;
    });
    this.currentItem.relazioni.splice(index, 1);

    // this.currentItem.sviluppi = this.arrayDegliSviluppi;
    console.log("array degli sviluppi dopo delete", id);
  }

  onSubmit(form: NgForm) {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      return;
    }

    const vm = new ScalatureDto();

    this.currentItem.scalatura.idScalatura = Utility.toInteger(
      this.currentItem.scalatura.idScalatura
    );
    this.currentItem.scalatura.codiceScalatura = Utility.toString(
      form.value.codiceScalatura
    ).toUpperCase();
    this.currentItem.scalatura.extCode = Utility.toString(form.value.extCode);
    this.currentItem.scalatura.validUntil =
      this.currentItem.scalatura.validUntil;
    // console.log(this.currentItem.codiceSviluppoTaglia);

    //this.currentItem.relazioni = [];

    //// TODO: Disabilita submit se array dettagli è VUOTO
    this.currentItem.relazioni.forEach((rel) => {
      let currentDettagli: DettagliRelazioniScalaturaDto[] =
        rel.dettagli.filter((det) => det.label.trim() !== "");

      rel.dettagli = currentDettagli;
    });

    /*       this.currentItem.relazioni.forEach((rel) => {
      let relazioniToSend = new RelazioniDto();
      if (this.typeop == "upd") {
        relazioniToSend.idSviluppoTaglia = rel.idSviluppoTaglia;
        // console.log("questo è sviluppo", sviluppo);

        relazioniToSend.idScalatura = Utility.toInteger(
          this.currentItem.scalatura.idScalatura
        );
      } else {
        relazioniToSend.idSviluppoTaglia = Utility.toInteger(
          form.value.idSviluppoTaglia
        );
        relazioniToSend.idScalatura = Utility.toInteger(
          this.currentItem.scalatura.idScalatura
        );
      }
      var arrayDettRelazioni: DettagliRelazioniScalaturaDto[] = [];
      let index = 0;
      // TODO: Da patchare
      let sviluppo = null; //this.findSviluppo(rel.idSviluppoTaglia);
      sviluppo.sviluppoTaglia.forEach((taglia) => {
        let dettaglio = new DettagliRelazioniScalaturaDto();
        const key = `label_${index}_${sviluppo.codiceSviluppoTaglia}`;
        // console.log("questa è key",key)
        //  var label = form.controls[key];
        var valoreInput: string = form.value[key];
        // console.log("qs", valoreInput);
        if (valoreInput != null && valoreInput.toLowerCase().trim() != "") {
          dettaglio.label = valoreInput;
          dettaglio.indice = index;
          arrayDettRelazioni.push(dettaglio);
        }
        index++;
      });

      rel.idSviluppoTaglia = sviluppo.idSviluppoTaglia;
      rel.dettagli = arrayDettRelazioni;
      rel.validUntil = "";

      //     console.log(sviluppoToSend);
      // vm.relazioni.push(relazioniToSend);
    });  */

    console.log("on submit", this.currentItem);

    this.confirm.emit(this.currentItem);
  }

  error(err: any): void {
    console.log("errore", err.error);
    //alert('Si è verificato un errore nella scrittura dei dati!');
/*     const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito; */
    // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati;

    this.activeModal.close("close");
  }

  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  getLabels(id, indice) {
    //  console.log("get labels", this.currentItem);
    let label: string = "";
    if (this.typeop == "upd") {
      this.currentItem.relazioni.forEach((rel) => {
        //  console.log("questo è rel", rel.idRelazione)
        if (rel.idSviluppoTaglia == id) {
          rel.dettagli.forEach((det) => {
            if (det.indice == indice) {
              //  console.log("get labels",det.label)
              label = det.label;
              return label;
            }
          });
        }
      });
    }

    return label;
  }

  onDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new ScalatureDto();

          this.delete = true;
          console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
        },
        (err) => {
          modalRef.componentInstance.onClose();
        }
      );
  }

  ngOnDestroy() {}
}
