import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SviluppiTaglieDto } from 'src/app/featureSviluppiTaglia/models/sviluppitaglie-dto';
import { CrudService } from 'src/app/_services/crud.service';
import { environment } from 'src/environments/environment';
import { SviluppoTaglieMapper } from '../mappers/sviluppiTaglieMapper';
import { AziendeCRUDService } from 'src/app/featureMain/service/aziende-crud.service';
import { AlertService } from 'src/app/_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class SviluppiTaglieCRUDService extends CrudService<SviluppiTaglieDto>{

  constructor(
    httpClient: HttpClient,
    public aziendeCRUDSrv: AziendeCRUDService,
    public alertSrv: AlertService,

  ) {
    super(httpClient, alertSrv);
    this.serverPort = environment.anaPort
    this.endpoint = "sviluppoTaglia"
    this.endpointAll = 'allSviluppiTaglia';
    //this.endpointAll='sviluppiTagliaByUser';


    this.mapper = new SviluppoTaglieMapper()
  }


}
