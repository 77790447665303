import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/_services/crud.service';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/_services/alert.service';
import { DDTEstesoDto } from '../models/DDTdto';
import { DDTEsteso_Mapper } from '../mappers/DDTMapper';
import { stringUrl } from 'src/assets/data/variables';

@Injectable({
  providedIn: 'root'
})
export class DDTCRUDService extends CrudService<DDTEstesoDto>{

  constructor(
    httpClient: HttpClient,
    public alertSrv: AlertService,

  ) {
    super(httpClient, alertSrv);
    this.serverPort = environment.ordiniPort
    this.endpoint = "ddtEsteso"
    this.endpointAll = 'ddtEstesi';


    this.mapper = new DDTEsteso_Mapper()
  }


  onGetRigheDDT(stringOfId){
    console.log("stringaId service",stringOfId)
    let url = `${environment.baseDomain}:${this.serverPort}/${stringUrl.ddtPrecompilato}?listaRighe=${stringOfId}`
    return this.httpClient.get(url)

  }

}
