import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-input-field-email',
  templateUrl: './input-field-email.component.html',
  styleUrls: ['./input-field-email.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class InputFieldEmailComponent  {

  @Input() type = 'email';
  @Input() display = '';
  @Input() name = '';
  @Input() placeholder = 'email@dominio.it';
  @Input() required = true;
  @Input() readonly = false;
  @Input() errorRequired = '  *';
  @Input() errorEmailFormat = 'Formato email non valido.';
  @Input() value = '';
 
  @Output() valueChange = new EventEmitter<string>();

 
  
  constructor() { }
 

  change(newValue){

    this.valueChange.emit(newValue);

  }

}
