import { Component, OnInit } from "@angular/core";
import {
  Router,
  ActivatedRoute,
  ParamMap,
  NavigationExtras,
} from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { AuthService } from "../_services/auth.service";
import { AlertService } from "../_services/alert.service";

export interface LoginCredentials{
  email:string;
  password:string;
}

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });

    if (localStorage.getItem("currentUser")) {
      this.authService.doLogout();
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  tryLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    const value:LoginCredentials = {
      email: this.f.email.value,
      password: this.f.password.value,
    };

    this.authService.doLogin(value).then(
      (res) => {
        res = {};
        res.user = {};
        res.user.displayName = value.email;
//console.log("user login",res)
        this.setUserInStorage(res);
        localStorage.removeItem("currentLayoutStyle");
        let returnUrl = "/dashboard";
        if (this.returnUrl) {
          returnUrl = this.returnUrl;
        }
        this.router.navigate([returnUrl]);
      },
      (err) => {
        console.log("errore login", err);
        this.submitted = false;
        if (err.error.error !== "Internal Server Error") {
          this.alertService.error(
            "Stiamo riscontrando problemi con il sistema. Riprovare più tardi"
          );
        } else {
          this.alertService.error("Password o Username errati");
        }
      }
    );
  }

  setUserInStorage(res) {
    localStorage.setItem("currentUser", JSON.stringify(res.user));
  }
}
