import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { catchError, map, mergeMap, shareReplay, switchMap, tap } from "rxjs/operators";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";

import { OrdiniDto } from "src/app/featureOrdini/models/ordiniDto";
import { RigaOrdineDto } from "src/app/featureOrdini/models/rigaOrdineDto";
import { DistintaBaseService } from "src/app/shared/services/distintaBaseService/distinta-base.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { DistintaBase } from "src/app/_models/articoli/articoliDto";
import { HttpClient } from "@angular/common/http";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
import { LancioProduzioneService } from "../../service/lancio-produzione.service";
import { BehaviorSubject, Observable, combineLatest, forkJoin, throwError } from "rxjs";
import { Router } from "@angular/router";
import { GeneraBolleService } from "../../service/genera-bolle.service";
import { PopUpUploadOrdiniComponent } from "src/app/_popups/pop-up-upload-ordini/pop-up-upload-ordini.component";
import { Globals } from "src/app/_models/Globals";
import { LancioProdDto } from "../../models/lancioProdDto";
import { CapobolleDto } from "../../models/bolleDto";
import { environment } from "src/environments/environment";
import { Utility } from "src/app/_zCore/utility";
import { LancioProdCRUDService } from "../../service/lancio-prod-crud.service";
import { JsReportService } from "src/app/shared/services/jsReport/js-report.service";
import { AlertService } from "src/app/_services/alert.service";
import { ErroriService } from "src/app/_services/errori-service.service";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { GeneraBolleCRUDService } from "../../service/genera-bolle-crud.service";
import { OrdiniProdCRUDService } from "src/app/featureOrdini/service/ordini-prod-crud.service";

@Component({
  selector: "app-lancio-prod-edit",
  templateUrl: "./lancio-prod-edit.component.html",
  styleUrls: ["./lancio-prod-edit.component.css"],
})
export class LancioProdEditComponent implements OnInit, AfterViewInit {
  @Output() confirm = new EventEmitter<string>();

  tagLancio;
  //ordiniTotali;
  currentListOrdini;
  listaSviluppiTotali;

  ordiniObs$: Observable<OrdiniDto[]> | undefined = undefined;
  listaIdOrdini;

  // currentListaRigheOrdine: RigaOrdineDto[] = [];
  currentListaRigheOrdine$: Observable<RigaOrdineDto[]> | undefined = undefined;
  distinteBaseRigheOrdine$: Observable<DistintaBase[]> | undefined = undefined;
  listaCodiciSviluppo$: Observable<string[]> | undefined = undefined;
  onLoading$ : Observable<boolean>;
  _onLoadingBSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  //dopo il modifica della quantita assegno la riga modificata a questa seconda lista:
  ListaRigheOrdineModificata: RigaOrdineDto[] = [];
  articoliDIBADataSource: RestDataSource;
  lancioProdDataSource: RestDataSource;
  generaBolleDataSource: RestDataSource;
  state: State<DistintaBase> = new State();
  lancio$: Observable<LancioProdDto>;

  tipoCalcoloAlgoritmo = "quantita";

  constructor(
    public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    public dibaSrv: DistintaBaseService,
    private http: HttpClient,
    public lancioCRUD: LancioProdCRUDService,
    private lanciosrv: LancioProduzioneService,
    private generaBolleSrv: GeneraBolleService,
    private reportSrv: JsReportService,
    private readonly router: Router,
    private alertSrv: AlertService,
    private errorSrv: ErroriService,
    private bolleCRUDSrv: GeneraBolleCRUDService,
    private ordiniProdCRUD: OrdiniProdCRUDService
  ) {
    this.articoliDIBADataSource = new RestDataSource(this.http);
    this.articoliDIBADataSource.setVariablesApi(
      null,
      "distintaBase",
      "distintaBase",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    this.articoliDIBADataSource.setByIdField("idArticolo");
    this.dibaSrv.connectDataSource(this.articoliDIBADataSource);

    /*  this.lancioProdDataSource = new RestDataSource(this.http);
    this.lancioProdDataSource.setVariablesApi(
      null,
      "creaLancio",
      "creaLancio",
      "https://cindy.iamboo.net:9094"
    );

    this.lancioProdDataSource.setByIdField("tagLancio");
    this.lanciosrv.connectDataSource(this.lancioProdDataSource); */

    this.lancioProdDataSource = new RestDataSource(this.http);
    this.lancioProdDataSource.setVariablesApi(
      null,
      "creaLancioDaIdOrdini",
      "creaLancioDaIdOrdini",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );

    this.lancioProdDataSource.setByIdField("ordiniSelezionati");
    // this.lanciosrv.connectDataSource(this.lancioProdDataSource);

    this.generaBolleDataSource = new RestDataSource(this.http);
    this.generaBolleDataSource.setVariablesApi(
      null,
      "creaBolleAlgoritmoQuant",
      "creaBolleAlgoritmoQuant",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );

    this.generaBolleDataSource.setByIdField("idLancio");
    this.generaBolleSrv.connectDataSource(this.generaBolleDataSource);
    this.onLoading$ = this._onLoadingBSubject.asObservable();
    this._onLoadingBSubject.next(true)
    this.currentListaRigheOrdine$  = this.ordiniProdCRUD.getAll().pipe(
      map((res) => {
        console.log(JSON.parse(res.dati));
        return JSON.parse(res.dati);
      }),
      tap((res) => console.log("tap ordiniTotali", res)),
      map((res: OrdiniDto[]) => {
        let arrayordiniPerLancio = [];
        this.listaIdOrdini.forEach((id) => {
          let ordine = res.find((ord) => ord.ordine.idOrdine === id);
          if (ordine !== undefined) {
            arrayordiniPerLancio.push(ordine);
          }
        });

        return arrayordiniPerLancio;
      }),
      map((res: OrdiniDto[]) => {
        let righe = [];
        res.forEach((ordine) => {
          righe = righe.concat(ordine.righeOrdine);
        });
        return righe;
      }),
      shareReplay(1)
    );


    this.distinteBaseRigheOrdine$ = this.currentListaRigheOrdine$.pipe(
      switchMap((righeOrdine: RigaOrdineDto[]) => {
        let dibaArrray$: Observable<CindyCustomResponse>[] = [];
        righeOrdine.forEach((r: RigaOrdineDto) => {
          let diba$: Observable<any> = this.dibaSrv.getbyId(
            r.rigaOrdine.idArticolo
          );

          dibaArrray$.push(diba$);
        });

        return forkJoin(dibaArrray$);
      }),
      map((res: CindyCustomResponse[]) => {
        let dibaArray: DistintaBase[] = [];

        res.forEach((cr) =>
          dibaArray.push(Utility.fromCindyCustomResponseToDTO(cr))
        );

        return dibaArray;
      }),
      tap((res)=> this._onLoadingBSubject.next(false)),
      catchError((err) => {
        console.log("errore diba", err);
        this.onClose();
        this.errorSrv.onToastTestata(
          `Impossibile visualizzare gli articoli: ${Globals.MappaMessaggiErrore.get(
            codiciErrore.DIBAMancante
          )}`
        );
        return throwError(err);
      })
    );
  }


  

  OnChangeTipoAlgoritmo(tipoAlgoritmo) {
    console.log("tipocalcolo", tipoAlgoritmo);
    console.log("ngModelTipoCalcolo", this.tipoCalcoloAlgoritmo);
  }

/*   setOrdiniSelezionati(ordiniSelezionati$: Observable<OrdiniDto[]>) {
    this.currentListaRigheOrdine$ = ordiniSelezionati$.pipe(
      tap((res)=> {
        return this._onLoadingBSubject.next(true)}),

      map((res: OrdiniDto[]) => {
        let righe = [];
        res.forEach((ordine) => {
          righe = righe.concat(ordine.righeOrdine);
        });
        return righe;
      }),
      tap((res)=> this._onLoadingBSubject.next(false)),

    );

    this.distinteBaseRigheOrdine$ = this.currentListaRigheOrdine$.pipe(
      switchMap((righeOrdine: RigaOrdineDto[]) => {
        let dibaArrray$: Observable<CindyCustomResponse>[] = [];
        righeOrdine.forEach((r: RigaOrdineDto) => {
          let diba$: Observable<any> = this.dibaSrv.getbyId(
            r.rigaOrdine.idArticolo
          );

          dibaArrray$.push(diba$);
        });

        return combineLatest(dibaArrray$);
      }),
      map((res: CindyCustomResponse[]) => {
        let dibaArray: DistintaBase[] = [];

        res.forEach((cr) =>
          dibaArray.push(Utility.fromCindyCustomResponseToDTO(cr))
        );

        return dibaArray;
      }),
      catchError((err) => {
        console.log("errore diba", err);
        this.onClose();
        this.errorSrv.onToastTestata(
          `Impossibile visualizzare gli articoli: ${Globals.MappaMessaggiErrore.get(
            codiciErrore.DIBAMancante
          )}`
        );
        return throwError(err);
      })
    );
  } */

  calcolaImportoTotale(riga: RigaOrdineDto) {
    return RigaOrdineDto.getImportoTotale(riga);
  }

  calcolaquantita(riga: RigaOrdineDto) {
    return RigaOrdineDto.getQuantita(riga);
  }

  ngAfterViewInit(): void {
    this.lancio$ = this.oncallLancio();
    // this.lancio$ = this.oncallLancio(this.tagLancio);
    this.listaCodiciSviluppo$ = this.lancioCRUD.loadCodiciSviluppo(
      this.currentListaRigheOrdine$
    );
  }

  creaStringOfIdForCallLancio() {
    let stringOfId = this.listaIdOrdini.join(",");
    console.log(stringOfId);
    return stringOfId;
  }

  ngOnInit(): void {
    console.log("tipoCalcoloAlgoritmo", this.tipoCalcoloAlgoritmo);
    //console.log("ordiniTotali", this.ordiniTotali);
    // this.selezionaOrdineByTagLancio();
    // this.currentListOrdini = this.ordiniTotali;
    // this.ordiniObs$.subscribe((res)=>console.log("ordiniobs",res))
    console.log("currentListOrdini", this.currentListOrdini);
    // this.toOpenRigheOrdine();
    /// console.log("currentListaRigheOrdine", this.currentListaRigheOrdine);
    //this.aggiornaDistinteBase();
  }

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      this.onClose();
      return;
    }
  }

  /*   oncallLancio(tagLancio): Observable<LancioProdDto> {
    return this.lanciosrv.getbyId(tagLancio).pipe(
      map((res: CindyCustomResponse) => {
        let item: LancioProdDto;
        if (res.dati !== undefined) {
          item = JSON.parse(res.dati);
        }
        return item;
      })
    );
  } */

  oncallLancio(): Observable<LancioProdDto> {
    let stringOfId = this.creaStringOfIdForCallLancio();
    return this.lancioProdDataSource.getDataByMultipleId(stringOfId).pipe(
      map((res: CindyCustomResponse) => {
        let item: LancioProdDto;
        console.log("risposta Lancio", res);
        if (res.dati !== undefined && res.dati !== "") {
          item = JSON.parse(res.dati);
        }
        return item;
      })
    );
  }

  lancioCreato: LancioProdDto;
  onCreaLancio() {
    if (this.tipoCalcoloAlgoritmo == "Seleziona Tipo di Calcolo") {
      this.alertSrv.warning(
        "Seleziona il tipo di calcolo per la generazione delle Capobolle"
      );
    } else {
      this.lancio$
        .pipe(
          catchError((err) => {
            console.log("catch error", err);
            return throwError(err);
          })
        )
        .subscribe(
          (res) => {
            this.lancioCreato = res;
            console.log("lancio", this.lancioCreato);
            if (res) {
              this.onClose();
              this.alertSrv.success(
                Globals.printCreateMessageSuccess(`Il Lancio ${res.idLancio} `)
              );
              this.generaBolle(res.idLancio);

              /*  if (res.tagLancio !== null) {
          let paramsString = res.tagLancio.toString();
          //console.log("lottoParams", cmp.lottoParams);
          this.router.navigate(["lancioPageList"], {
            queryParams: { paramsString: paramsString },
          });
        } else {
          this.router.navigate(["lancioPageList"], {
            queryParams: { paramsString: "" },
          });
        } */
            } else {
              this.alertSrv.error(
                Globals.printCreateMessageFailure(`il Lancio`)
              );
            }
          },
          (err) => {
            this.onClose();
            console.log("creazione lancio", err);
            let errore = err.error;
            let idLancio = JSON.parse(errore.dati).descErrore;
            console.log("idLancio", idLancio);

            this.errorSrv.onToastTestata(
              `${Globals.MappaMessaggiErrore.get(
                codiciErrore.ordineCollegatoLancio
              )} ${idLancio}`
            );
          }
        );
    }
  }

  generaBolle(idLancio): Observable<CapobolleDto> {
    console.log("idLancio", idLancio);
    if (this.tipoCalcoloAlgoritmo == "quantita") {
      this.bolleCRUDSrv.endpoint = "creaBolleAlgoritmoQuant";
    } else {
      this.bolleCRUDSrv.endpoint = "creaBolleAlgoritmoForme";
    }
    return this.bolleCRUDSrv
      .getBy([{ variable: "idLancio", value: idLancio }])
      .pipe(
        tap((res) => console.log("bolle TAP", res)),
        map((res: any) => {
          let item: CapobolleDto;
          console.log("capobolleItem", res);
          if (res.dati !== undefined) {
            item = JSON.parse(res.dati);
          }
          return item;
        })
      );
  }

  onStampaBolle() {
    let idLancio;
this.confirm.emit("lancio inviato")
    let reportToSend;
    //unisco tutte le chiamate in una sola per fare un'unica sub
    if (this.tipoCalcoloAlgoritmo == "Seleziona Tipo di Calcolo") {
      this.alertSrv.warning(
        "Seleziona il tipo di calcolo per la generazione delle Capobolle"
      );
    } else {
      this.onClose();
      this.lancio$
        .pipe(
          mergeMap((res) => {
            console.log("lancio", res);
            if (res) {
              this.alertSrv.success(
                Globals.MappaMessaggiErrore.get(
                  codiciErrore.elaborazioneInCorso
                ),
                "Se necessario continuare con le proprie attività"
              );

              this.tagLancio = res.tagLancio;
              idLancio = res.idLancio;
              return this.generaBolle(res.idLancio);
            }
          }),
          tap((res) => console.log("tap capobolla", res)),
          mergeMap((res) => {
            reportToSend = {
              template: { name: "Report_Bolle" },
              data: res,
            };
            console.log("formdata", res);
            console.log("formdata str", JSON.stringify(reportToSend.data));

            return this.reportSrv.getReport(reportToSend);
          }),
          catchError((err) => {
            console.log("Handling error locally and rethrowing it...", err);
            this.alertSrv.error(Globals.printCreateMessageFailure(`il Lancio`));
            return throwError(err);
          })
        )
        .subscribe(
          (report) => {
            //modalRef.close();
            var newBlob = new Blob([report], { type: "application/pdf" });
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = "Bolle Lancio Produzione";
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );

            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
              link.remove();
            }, 10000);
            if (report) {
              this.onClose();
              this.alertSrv.success(
                Globals.printCreateMessageSuccess(`Il Lancio ${idLancio} `)
              );
            } else {
              this.alertSrv.error(
                Globals.printCreateMessageFailure(`il Lancio`)
              );
            }
          },
          (err) => {
            this.onClose();

            let errore = err.error;

            if (
              err &&
              err.error &&
              err.error.codEsito == codiciErrore.ordineCollegatoLancio
            ) {
              let idLancio = JSON.parse(err.error.dati).descErrore;
              console.log("idLancio", idLancio);

              this.errorSrv.onToastTestata(
                `${Globals.MappaMessaggiErrore.get(
                  codiciErrore.ordineCollegatoLancio
                )} ${idLancio}`
              );
            } else if (
              err &&
              err.error &&
              err.error.codEsito == codiciErrore.erroreLancio
            ) {
              this.errorSrv.onToastTestata(
                Globals.MappaMessaggiErrore.get("ERRORBOLLE")
              );
            } else if (
              errore.codEsito !== codiciErrore.ordineCollegatoLancio &&
              errore.codEsito !== codiciErrore.erroreLancio
            ) {
              this.errorSrv.onToastTestata(
                Globals.MappaMessaggiErrore.get("KO.A16_dettaglio")
              );

              console.log("erroreJsreport", err);
            }
          }
        );
    }
  }

  /*   onModificaLancio() {
    let lancioToSend = this.costruisciLancio(this.currentListaRigheOrdine);
    console.log("lancioToSend", lancioToSend);
    this.lanciosrv
      .update(lancioToSend as LancioProdDto)
      .subscribe((res: CindyCustomResponse) => {
        console.log("rispostaLancioCreato", res);
        if (res.codEsito == "OK") {
          let paramsString = this.tagLancio.toString();
          //console.log("lottoParams", cmp.lottoParams);
          this.onClose();
          this.router.navigate(["lancioPageList"], {
            queryParams: { paramsString: paramsString },
          });
        }
      });
    } */

  /*  costruisciLancio(listaRighe: RigaOrdineDto[]) {
    let lancio: LancioProdDto = new LancioProdDto();
    lancio.tagLancio = this.tagLancio;
    let lan: Lancio = new Lancio();
    let ordiniLan: OrdiniPerLancio[] = [];
    listaRighe.forEach((riga: RigaOrdineDto) => {
      let ordinePerLancio: OrdiniPerLancio = new OrdiniPerLancio();
      ordinePerLancio.idOrdine = riga.rigaOrdine.idOrdine;
      ordinePerLancio.idRiga = riga.rigaOrdine.idRiga;
      ordinePerLancio.idSviluppo = riga.rigaOrdine.idSviluppo;
      let arrayqntPerTaglia = [];
      riga.taglieSku.forEach((tSku: TaglieSku) => {
        let qntPerTaglia: QuantitaPerTaglia = new QuantitaPerTaglia();
        qntPerTaglia.codTaglia = tSku.codTaglia;
        qntPerTaglia.quantita = tSku.quantita;
        qntPerTaglia.sku = tSku.sku;
        arrayqntPerTaglia.push(qntPerTaglia);
      });
      ordinePerLancio.qtPerTaglia = arrayqntPerTaglia;
      ordiniLan.push(ordinePerLancio);
    });
    lan.ordini = ordiniLan;
    lancio.lancio = lan;
    return lancio;
  }
 */
  /*   selezionaOrdineByTagLancio() {
    this.currentListOrdini = this.ordiniTotali.filter((ordine: OrdiniDto) => {
      if (
        ordine.ordine.tagLancio !== null ||
        ordine.ordine.tagLancio !== undefined ||
        ordine.ordine.tagLancio !== ""
      ) {
        return (
          ordine.ordine.tagLancio ==
          this.tagLancio.toString().toUpperCase().trim()
        );
      }
    });
  } */

  // toOpenRigheOrdine() {
  //   this.currentListOrdini.forEach((ordine) => {
  //     let righeOrdine = ordine.righeOrdine;

  //     righeOrdine.forEach((riga: RigaOrdineDto) => {
  //       this.currentListaRigheOrdine.push(riga);
  //     });
  //   });
  // }

  onRigaModify(indice) {}

  onRigaDelete(indice) {}

  aggiornaDistinteBase() {
    // if (this.currentListaRigheOrdine.length !== 0) {
    //   this.currentListaRigheOrdine.forEach((riga) => {
    //     if (
    //       this.mappaArticoliRiga.get(riga.rigaOrdine.idArticolo) == undefined
    //     ) {
    //       this.dibaSrv
    //         .getbyId(riga.rigaOrdine.idArticolo)
    //         .subscribe((dB: CindyCustomResponse) => {
    //           let distintaBase: DistintaBase = JSON.parse(dB.dati);
    //           this.mappaArticoliRiga.set(
    //             riga.rigaOrdine.idArticolo,
    //             distintaBase
    //           );
    //         });
    //     }
    //   });
    // }
  }

  distintaBase(numeroRigaOrdine, arrayRighe): DistintaBase {
    return this.mappaArticoliRiga.get(
      arrayRighe[numeroRigaOrdine].rigaOrdine.idArticolo
    );
  }

  mappaArticoliRiga: Map<number, DistintaBase> = new Map();

  getCodiceSviluppo(idSviluppo) {
    // console.log("sviluppiTot", this.sviluppiTot);
    let sviluppoById: SviluppiTaglieDto = this.listaSviluppiTotali.find(
      (sviluppo) => {
        return sviluppo.idSviluppoTaglia == idSviluppo;
      }
    );
    if (sviluppoById != undefined) {
      return sviluppoById.codiceSviluppoTaglia;
    }
    // console.log("questo Sviluppo",sviluppoById)
  }

  error(err: any): void {
    console.log("errore", err.error);
    const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
    // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati;

    this.onClose();
    this.confirm.emit(this.tagLancio);
  }

  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  ngOnDestroy() {}
}
