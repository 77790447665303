import { Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { EventEmitter } from '@angular/core';



@Component({
  selector: 'typeahead',
	standalone: true,
	imports: [NgbTypeaheadModule, FormsModule],
	templateUrl: './typeahead.component.html',

  
  styleUrls: ['./typeahead.component.css']
})
export class TypeaheadComponent {
 

  @ViewChild("typeahead_cindy") inputRef:ElementRef | undefined;

  @Input() inputLabel:string=""

  @Input() valueList:string[] | undefined;

  @Output() valueSelected: EventEmitter<string> = new EventEmitter();

  selectedValue:string="";

  formatter = (selectedValue: String) =>{

	return selectedValue
  } 

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map((term) =>
      term.length < 2  || this.valueList==undefined ? [] : 
      this.valueList.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
			),
		);

		onSelection(event){
			
			if(event!=undefined){

				this.valueSelected.emit(event);
			}
		}

}
