import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDTOMapper } from 'src/app/_helpers/IDTOMapper';
import { ItemFilter } from 'src/app/_filters/ItemFilter';
import { FileOrdiniImportazioni } from 'src/app/_models/fileOrdini/fileOrdinImportazioni';
import { AService } from 'src/app/_services/AService';
import { State } from 'src/app/_models/State';
import { UserDto } from 'src/app/_models/utenti/usersDto';
import { RestDataSource } from 'src/app/_datasources/RestDataSource';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { ImportazioniState } from '../state/importazioniState';
import { environment } from 'src/environments/environment';


class FileImportazioniMapper implements IDTOMapper<FileOrdiniImportazioni>{
  map(data: any): FileOrdiniImportazioni {
    
    let item:FileOrdiniImportazioni = new FileOrdiniImportazioni();

    item.idFile = data.idFile;
    item.tipoFile = data.tipoFile;
    item.percorso = data.percorso;
    item.descEsito = data.descEsito;
    item.validUntil = data.validUntil;
    item.flagStato = data.flagStato;
    item.tsInserimento = data.tsInserimento;
    item.autore = data.autore;
    item.codEsito = data.codEsito;
    return item;
  }

}

@Injectable({
  providedIn: 'root'
})
export class ImportazioniFileOrdiniService extends AService<FileOrdiniImportazioni> {
  private _listaUsers: UserDto[];
  userDataSource:RestDataSource;
  constructor(
    private userService: UserService,
    private http: HttpClient
  ) {
    super();
    this.userDataSource = new RestDataSource(this.http);
    this.userDataSource.setVariablesApi(
      "users",
      "user",
      "user",
      `${environment.baseDomain}:${environment.userRolePort}`
    );
    this.userDataSource.setByIdField("userId");
    this.userService.connectDataSource(this.userDataSource);
  }

  override loadState(): Observable<this> {
    this.userService
      .loadState()
      .subscribe((service) => (this._listaUsers = service.state().items));
    return super.loadState();
  }

  state():ImportazioniState {
     
    let currentState:ImportazioniState = new ImportazioniState();

    currentState.listaUsers=this._listaUsers
    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<FileOrdiniImportazioni> = new FileImportazioniMapper();}
