import { Component, OnInit } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { Globals } from "src/app/_models/Globals";

import { TipiEsenzioneFalseEditComponent } from "../tipi-aliquote-edit/tipi-aliquote-edit.component";

import { AlertService } from "src/app/_services/alert.service";
import { TipiEsenzioneDto } from "src/app/_models/tipiesenzione/tipiesenzione-dto";
import { AliquoteService } from "src/app/_services/aliquote.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { HttpClient } from "@angular/common/http";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { AliquoteFilter } from "./filters/aliquoteFilter";
import { AndFilter } from "src/app/_filters/AndFilter";
import { State } from "src/app/_models/State";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-tipi-aliquote-page-list",
  templateUrl: "./tipi-aliquote-page-list.component.html",
  styleUrls: ["./tipi-aliquote-page-list.component.css"],
})
export class TipiEsenzioneFalsePageListComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  aliquoteDataSource: RestDataSource;
  state: State<TipiEsenzioneDto> = new State();

  constructor(
    private modalservice: NgbModal,
    private alertSrv: AlertService,
    private aliquoteIVAService: AliquoteService,
    private http: HttpClient
  ) {
    this.aliquoteDataSource = new RestDataSource(this.http);

    this.aliquoteDataSource.setVariablesApi(
      "allTipoEsenzione",
      "tipoEsenzione",
      "tipoEsenzione",
      `${environment.baseDomain}:${environment.anaPort}`    );

    this.aliquoteDataSource.setByIdField("IDtipo");

    this.aliquoteIVAService.connectDataSource(this.aliquoteDataSource);
  }

  _searchTerm: string;
  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this.filterAliquote(this.aliquoteIVAService);
  }

  get searchTerm() {
    return this._searchTerm;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.filterAliquote(this.aliquoteIVAService);
  }
  get pageSize() {
    return this._pageSize;
  }

  set page(newPage: number) {
    this._page = newPage;

    this.filterAliquote(this.aliquoteIVAService);
  }

  get page() {
    return this._page;
  }

  loadData() {
    this.aliquoteIVAService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterAliquote(service);

        console.log(this._page + " " + this._pageSize);
      },
    });
  }

  filterAliquote(service: AliquoteService) {
    console.log(this._searchTerm);

    this.state = service
      .filter(
        new AndFilter([
          new FieldsFilter({
            fieldList: ["codiceTipoEsenzione"],
            searchString: this._searchTerm == undefined ? "" : this._searchTerm,
          }),
          new AliquoteFilter(null),
        ])
      )
      .paginate(this._page, this.pageSize)
      .sort(this._sortColumn, this._sortDirection)
      .state();
  }

  ngOnInit(): void {
    this._sortColumn = "codiceTipoEsenzione";
    this._sortDirection = "asc";
    this._rotateDirection = "asc";
    this.loadData();
  }

  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");
    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);

    this.filterAliquote(this.aliquoteIVAService);
  }

  onAliquoteCreateOrModify(index: number): void {
    let modalRef: any = null;

    modalRef = this.modalservice.open(TipiEsenzioneFalseEditComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });

    let tipoEsenzione: TipiEsenzioneDto = new TipiEsenzioneDto();
    let typeop = "ins";

    if (index >= 0) {
      tipoEsenzione = this.state.paginatedItems[index];

      typeop = "upd";
    }

    modalRef.componentInstance.currentItem = tipoEsenzione;

    modalRef.componentInstance.typeop = typeop;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log(data);

          if (typeop == "ins") {
            return this.aliquoteIVAService.create(data as TipiEsenzioneDto);
          } else if (typeop == "upd") {
            if (modalRef.componentInstance.delete == false)
              return this.aliquoteIVAService.update(data as TipiEsenzioneDto);
            else {
              typeop = "del";

              return this.aliquoteIVAService.delete(tipoEsenzione.idEsenzione);
            }
          }
        })
      )
      .subscribe(
        (response: CindyCustomResponse) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";
          this.loadData();
          this._sortColumn = "idEsenzione";
          this._sortDirection = "desc";
          this._rotateDirection = "desc";
          if (response.codEsito=="OK"){
            this.onSuccessPrint(typeop);
          }else{
            this.onErrorPrint(typeop);
          }         
        },
        (err) => {
          modalRef.componentInstance.error(err);
          modalRef.componentInstance.onClose();
        }
      );
  }



  onErrorPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.error(Globals.printCreateMessageFailure("l'Aliquota"));
    else if (typeop == "upd") {
      this.alertSrv.error(Globals.printModifyMessageFailure("l'Aliquota"));
    } else {
      this.alertSrv.error(Globals.printDeleteMessageFailure("l'Aliquota"));
    }
  }

  onSuccessPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.success(Globals.printCreateMessageSuccess("L'Aliquota"));
    else if (typeop == "upd") {
      this.alertSrv.success(Globals.printModifyMessageSuccess("L'Aliquota"));
    } else {
      this.alertSrv.success(Globals.printDeleteMessageSuccess("L'Aliquota"));
    }
  }
}
