import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { ItemFilter } from "src/app/_filters/ItemFilter";
import {
  DettagliOdpOriginario,
  ordiniAcquistoDto,
  Tags,
} from "src/app/_models/ordiniAcquisto/ordiniAcquistoDto";
import { AService } from "src/app/_services/AService";
import { State } from "src/app/_models/State";
import { HttpClient, HttpParams } from "@angular/common/http";
import { OrdiniAcquistoDataSource } from "../component/ordini-acquisto-page-list/ordini-acquisto-page-list/ordini-acquisto-page-list.component";
import { ScalatureMapper } from "src/app/featureScalature/service/scalature.service";
import { RelazioniScalatureMapper } from "src/app/featureScalature/service/scalature.service";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { number } from "echarts";
import { environment } from "src/environments/environment";
class OrdineAcquistoMapper implements IDTOMapper<ordiniAcquistoDto> {
  dettaglioOdpMapper: DettagliOdpOriginarioMapper =
    new DettagliOdpOriginarioMapper();
  map(data: any): ordiniAcquistoDto {
    let item: ordiniAcquistoDto = new ordiniAcquistoDto();

    item.idRiga = data.idRiga;
    item.idOrdine = data.idOrdine;
    item.codiceFornitore = data.codiceFornitore;
    item.extCode = data.extCode;
    item.validUntil = data.validUntil;
    item.descrizioneFornitore = data.descrizioneFornitore;
    item.dataConsegna = data.dataConsegna;
    item.codiceComponente = data.codiceComponente;
    item.codiceComponenteCliente = data.codiceComponenteCliente;
    item.descrizioneComponente = data.descrizioneComponente;
    item.unMis = data.unMis;
    item.quantitaCalcolata = data.quantitaCalcolata;
    item.quantitaDaOrdinare = data.quantitaDaOrdinare;
    item.quantitaMagazzino = data.quantitaMagazzino;
    item.lotto = data.lotto;
    item.stagione = data.stagione;
    item.tags = data.tags;
    item.tagLancio = data.tagLancio;
    item.coloreComponente = data.coloreComponente;
    item.unMis = data.unMis;
    item.prezzo = data.prezzo;
    item.note = data.note;
    item.codSviluppo = data.codSviluppo;
    item.codScalatura = data.codScalatura;
    item.qtPerTaglia = data.qtPerTaglia;
    item.stato = data.stato;
    item.progressioneSviluppo = data.progressioneSviluppo;
    item.idScalatura = data.idScalatura;
    item.progressioneScalatura = data.progressioneScalatura;
    item.indirizzo = data.indirizzo;
    item.cap = data.cap;
    item.citta = data.citta;
    item.provincia = data.provincia;
    item.nazione = data.nazione;
    item.odpOriginario = data.odpOriginario;
    //  console.log("qst",item.dettaglioOdpOriginario)
    if (item.odpOriginario) {
      item.dettaglioOdpOriginario = this.dettaglioOdpMapper.map(
        data.dettaglioOdpOriginario
      );
    } else {
      item.dettaglioOdpOriginario = {
        idOrdine: 0,
        ente: "",
        anno: 0,
        numero: "",
      };
    }

    return item;
  }
}

class DettagliOdpOriginarioMapper implements IDTOMapper<DettagliOdpOriginario> {
  map(data: any): DettagliOdpOriginario {
    let v: DettagliOdpOriginario = new DettagliOdpOriginario();

    v.idOrdine = data.idOrdine;
    v.ente = data.ente;
    v.anno = data.anno;
    v.numero = data.numero;

    return v;
  }
}
@Injectable({
  providedIn: "root",
})
export class OrdiniAcquistoService extends AService<ordiniAcquistoDto> {
  constructor(public http: HttpClient) {
    super();
  }
  state(): State<ordiniAcquistoDto> {
    let currentState: State<ordiniAcquistoDto> = new State<ordiniAcquistoDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
  }

  reOrder(endpoint, data: any): Observable<object> {
    let ds: OrdiniAcquistoDataSource = this
      ._dataSource as OrdiniAcquistoDataSource;

    return ds.doPost(endpoint, data);
  }

  getNextLotto(): Observable<any> {
    let url = `${environment.baseDomain}:${environment.ordiniPort}/nextNumeroLotto`;
    return this.http.get(url).pipe(
      map((res: CindyCustomResponse) => {
        let lotto
        if(res){
          lotto = JSON.parse(res.dati);
          if(lotto){
            return lotto
          } else {
            lotto =''
          }

        }
      })
    );
  }

  mapper: IDTOMapper<ordiniAcquistoDto> = new OrdineAcquistoMapper();
}
