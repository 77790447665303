export abstract class ItemFilter{

    filterParams:any;

    abstract filter(i:any,filterParams:any):boolean;

    constructor(filterParams:any){

        this.filterParams = filterParams;
    }

    execute(items:any[]):any[]{

        let filteredItems:any[]=[];

        items.forEach((i)=>{

            if(this.filter(i,this.filterParams) == true){

                filteredItems.push(i);
            }
        });
        
        return filteredItems;
    }
}