import { Observable } from "rxjs";
import { ItemFilter } from "src/app/_filters/ItemFilter";
import { TipiEsenzioneDto } from "src/app/_models/tipiesenzione/tipiesenzione-dto";


export class EsenzioniFilter extends ItemFilter {

    constructor(filterParams:any){
        super(filterParams);
    }

  

  filter(item: any, filterParams: any) {
    
    let listaTipiEsenzione:TipiEsenzioneDto = item as TipiEsenzioneDto;

    return listaTipiEsenzione.flagEsenzione == true;


  }
}
