import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { DettagliRelazioniScalaturaDto, RelazioniDto, ScalatureDto } from "../models/scalature-dto";

export class ScalatureMapper implements IDTOMapper<ScalatureDto> {
    relazioniMapper: RelazioniScalatureMapper = new RelazioniScalatureMapper();
  
    map(data: any): ScalatureDto {
      let scal = new ScalatureDto();
      scal.scalatura.idScalatura = data.scalatura.idScalatura;
      scal.scalatura.codiceScalatura = data.scalatura.codiceScalatura;
      scal.scalatura.extCode = data.scalatura.extCode;
      scal.scalatura.validUntil = data.scalatura.validUntil;
      scal.relazioni = [
        ...data.relazioni.map((r) => this.relazioniMapper.map(r)),
      ];
  
      return scal;
    }
  }
  
  export class RelazioniScalatureMapper implements IDTOMapper<RelazioniDto> {
    dettagliMapper: DettagliRelazioneScalaturaMapper =
      new DettagliRelazioneScalaturaMapper();
    map(data: any): RelazioniDto {
      let relazione: RelazioniDto = new RelazioniDto();
  
      relazione.idRelazione = data.idRelazione;
      relazione.idScalatura = data.idScalatura;
      relazione.idSviluppoTaglia = data.idSviluppoTaglia;
      relazione.codiceSviluppoTaglia = data.codiceSviluppoTaglia;
      relazione.dettagli = [
        ...data.dettagli.map((d) => this.dettagliMapper.map(d)),
      ];
  
      return relazione;
    }
  }
  
  class DettagliRelazioneScalaturaMapper
    implements IDTOMapper<DettagliRelazioniScalaturaDto>
  {
    map(data: any): DettagliRelazioniScalaturaDto {
      let dr = new DettagliRelazioniScalaturaDto();
  
      dr.idDettaglio = data.idDettaglio;
      dr.idRelazione = data.idRelazione;
      dr.indice = data.indice;
      dr.label = data.label;
  
      return dr;
    }
  }
  