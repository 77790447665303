import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Globals } from "src/app/_models/Globals";
import { OrdiniProdCRUDService } from "../../service/ordini-prod-crud.service";
import { BehaviorSubject, Observable, catchError, map, mergeMap, of, throwError } from "rxjs";
import { PrevisioneProduzioneDTO } from "../../models/previsioneProduzione";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { JsReportService } from "src/app/shared/services/jsReport/js-report.service";
import { ErroriService } from "src/app/_services/errori-service.service";

export interface tHead {
  scope: string;
  sortable: string;
  sortDirection: string;
  function: any;
  class: string;
  label: string;
}

export interface tData {
  classCss: string;
  result: string | number;
  hidden: boolean;
  term: any;
  index: number;
}

@Component({
  selector: "app-previsione-vendita",
  templateUrl: "./previsione-vendita.component.html",
  styleUrls: ["./previsione-vendita.component.css"],
})
export class PrevisioneVenditaComponent implements OnInit, AfterViewInit {
  @Output() confirm = new EventEmitter();
  codiceConferma;
  messaggio;
  idStagione;
  inizioSettimanaPrecedente;
  fineSettimanaPrecedente;
  listaPrevisioni$: Observable<PrevisioneProduzioneDTO[]>;
  dataOdierna;
  listaTd$: Observable<tData[]>;
  onPrintLoading$: Observable<boolean>;
  _onPrintLoadingBSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  tableHead: tHead[] = [
    {
      scope: "nOrdine",
      sortable: "col-md-1",
      sortDirection: "",
      function: console.log("ciao sono la funzione sulla tabella"),
      class: "",
      label: "N° Ordine",
    },
    {
      scope: "articolo",
      sortable: "col-md-2",
      sortDirection: "",
      function: console.log("ciao sono la funzione sulla tabella"),
      class: "",
      label: "Articolo",
    },
    {
      scope: "sitPrecedente",
      sortable: "col-md-1",
      sortDirection: "",
      function: console.log("ciao sono la funzione sulla tabella"),
      class: "",
      label: "Situazione Precedente",
    },
    {
      scope: "sitAttuale",
      sortable: "col-md-1",
      sortDirection: "",
      function: console.log("ciao sono la funzione sulla tabella"),
      class: "",
      label: "Situazione Attuale",
    },
    {
      scope: "delta",
      sortable: "col-md-1",
      sortDirection: "",
      function: console.log("ciao sono la funzione sulla tabella"),
      class: "",
      label: "Delta",
    },
  ];
  constructor(
    public activeModal: NgbActiveModal,
    public ordiniProdCRUD: OrdiniProdCRUDService,
    public reportSrv: JsReportService,
    public errorSrv: ErroriService

  ) {

    this.onPrintLoading$ = this._onPrintLoadingBSubject.asObservable();

  }
  ngAfterViewInit(): void {}


  ngOnInit(): void {
    let date = new Date();
    this.dataOdierna = date.toLocaleDateString();

    this.listaTd$ = this.listaPrevisioni$.pipe(
      map((res: PrevisioneProduzioneDTO[]) => {
        let listaTd: tData[] = [];

        res.forEach((prev) => {
          let classCss: string = "table-text";
          let result: string | number;
          let hidden: boolean;
          let term: any;
        });

        return listaTd;
      })
    );

    this.listaPrevisioni$.subscribe((res) => console.log("ciao", res));
    console.log();
  }

  setListaPrevisioni(
    idStagione,
    inizioSettimanaPrecedente,
    fineSettimanaPrecedente
  ) {
    this.listaPrevisioni$ = this.ordiniProdCRUD
      .onPrevisioneProduzione(
        idStagione,
        inizioSettimanaPrecedente,
        fineSettimanaPrecedente
      )
      .pipe(
        map((res: CindyCustomResponse) => {
          let previsioneToSend = JSON.parse(res.dati).previsioni;

          if (res && res !== undefined && res.dati !== undefined) {
            return previsioneToSend;
          } else {
            of([]);
          }
        })
      );
  }

  onSituazionePrecedente(previsione: PrevisioneProduzioneDTO) {
    if (previsione.quantOrdiniPrecedente == null)
      previsione.quantOrdiniPrecedente = 0;

    if (previsione.quantSimulatoPrecedente == null)
      previsione.quantSimulatoPrecedente = 0;

    return (
      previsione.quantOrdiniPrecedente + previsione.quantSimulatoPrecedente
    );
  }
  onSituazioneAttuale(previsione: PrevisioneProduzioneDTO) {
    if (previsione.quantOrdiniAttuale == null)
      previsione.quantOrdiniAttuale = 0;

    if (previsione.quantSimulatoAttuale == null)
      previsione.quantSimulatoAttuale = 0;
    return previsione.quantOrdiniAttuale + previsione.quantSimulatoAttuale;
  }

  onDelta(previsione: PrevisioneProduzioneDTO) {
    return (
      this.onSituazioneAttuale(previsione) -
      this.onSituazionePrecedente(previsione)
    );
  }

  onStampaPrevisione1() {
    let vm = true;
    this.confirm.emit(vm);
    //console.log(vm)
    this.activeModal.close("close");
  }

  onStampaPrevisione() {
    this.ordiniProdCRUD
      .onPrevisioneProduzione(
        this.idStagione,
        this.inizioSettimanaPrecedente,
        this.fineSettimanaPrecedente
      )
      .pipe(
        mergeMap((res: CindyCustomResponse) => {
          this._onPrintLoadingBSubject.next(true);
          let previsioneToSend = JSON.parse(res.dati);
          console.log("previsioneToSend", previsioneToSend.previsioni);

          let reportToSend = {
            template: { name: "Report_Previsione_Produzione" },
            data: previsioneToSend,
          };
          if (previsioneToSend.previsioni.length !== 0) {
            return this.reportSrv.getReport(reportToSend);
          }
        }),
        catchError((err) => {
          console.log("catchError", err);
          // this.errorSrv.onToastTestata("Non ci sono Previsioni per il periodo selezionato")
          this.errorSrv.onToastTestata(
            Globals.MappaMessaggiErrore.get("ERRORBOLLE")
          )

          return throwError(err);
        })
      )
      .subscribe({
        next: (report) => {
          console.log("report", report);
          this._onPrintLoadingBSubject.next(false);

         let vm = true;
         this.confirm.emit(vm);
         //console.log(vm)
         this.activeModal.close("close");

          var newBlob = new Blob([report], { type: "application/pdf" });
          const data = window.URL.createObjectURL(newBlob);
          var link = document.createElement("a");
          link.href = data;
          link.download = "Report_Previsione_Produzione";
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);

    
        },
        error: (err) => {
          let response: CindyCustomResponse = err.error;
          console.log("errore previsione", response);

          if (response != undefined) {
            this.errorSrv.onToastTestata(
              Globals.MappaMessaggiErrore.get("ERRORBOLLE")
            );
          }
        },
      });
  }

  onSubmit(): void {}
  onClose(): void {
    let vm = false;

    this.confirm.emit(vm);

    this.activeModal.close("close");
  }
}
