import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';

import { ScalaturePageListComponent } from './component/scalature-page-list/scalature-page-list.component';
import { ScalatureEditComponent } from './component/scalature-edit/scalature-edit.component';

@NgModule({
    declarations: [
        ScalaturePageListComponent,
        ScalatureEditComponent,
    ],
  
    imports: [
      NgbModule,
      SharedModule,
      BlockUIModule
    ]
  })
  export class FeatureScalatureModule { }
  