import { ScalatureDto } from "src/app/featureScalature/models/scalature-dto";
import { State } from "src/app/_models/State";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";

export class ScalatureState extends State<ScalatureDto>{

   listaSviluppi:SviluppiTaglieDto[]=[];

}

