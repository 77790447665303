import { Component, EventEmitter, Input, Output, ɵisObservable } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Observable, OperatorFunction, of } from 'rxjs';


export type FormatterFunctionType = (a: any) => string;
export type SearchfunctionType = OperatorFunction<string, readonly any[]>;

@Component({
  selector: 'app-typeahead-oracle',
  templateUrl: './typeahead-oracle.component.html',
  styleUrls: ['./typeahead-oracle.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]

})
export class TypeaheadOracleComponent {
  @Input() type = 'text';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() searching = '';
  @Input() searchingFailed = '';



  @Input() model = '';

  @Input() class = '';
  @Input() label = '';







  @Output() valueChangeEvent = new EventEmitter<string>();

 
  
  constructor() { }

  change(event){
    this.valueChangeEvent.emit(this.model);
  }

  onClick(event){

  }

  _formatterFn_default:FormatterFunctionType = (x: any) => "";

  _formatterFn = this._formatterFn_default;

  setFormatterFn(formatterFn:FormatterFunctionType ){

    this._formatterFn = formatterFn;
  }

  _searchFn_default:SearchfunctionType = (text$: Observable<string>)=> of([])

  _searchFn:SearchfunctionType = this._searchFn_default;

  setSearchFn(searchFn:SearchfunctionType){

    this._searchFn = searchFn;
  }

}
