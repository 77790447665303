import {
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { NgBlockUI, BlockUI } from "ng-block-ui";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { NgForm } from "@angular/forms";
import { Utility } from "src/app/_zCore/utility";
import { FileOrdiniDto } from "src/app/_models/fileOrdini/fileOrdinidto";
import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";
import { environment } from "src/environments/environment";
import { ApiService } from "src/app/_services/api.service";
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { PopUpUploadOrdiniComponent } from "src/app/_popups/pop-up-upload-ordini/pop-up-upload-ordini.component";
import {
  FileOrdiniService,
  TipiOrdineService,
} from "src/app/_services/allServices";
import { Globals } from "src/app/_models/Globals";
import { AlertService } from "src/app/_services/alert.service";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { AziendeServiceNew } from "src/app/featureMain/service/aziende.service";
import { State } from "src/app/_models/State";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { HttpClient } from "@angular/common/http";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { AziendeCRUDService } from "src/app/featureMain/service/aziende-crud.service";
import {
  Observable,
  OperatorFunction,
  Subject,
  combineLatest,
  merge,
  of,
} from "rxjs";
import { PopUpConfermaComponent } from "src/app/_popups/pop-up-conferma/pop-up-conferma.component";
import { ErroriService } from "src/app/_services/errori-service.service";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { TipiOrdineDto } from "src/app/featureTipoOrdine/models/tipiordine-dto";
import { TipiOrdineSearchService } from "src/app/featureTipoOrdine/service/tipi-ordine-search.service";
import { TypeaheadOracleComponent } from "src/app/shared/component/typeahead-oracle/typeahead-oracle.component";
import { Component } from "@angular/core";
import { FileMagazzinoDTO } from "../../models/fileMagazzinoDTO";
import { UploadMagazzinoService } from "../../service/upload-magazzino.service";

@Component({
  selector: 'app-feature-file-magazzini',
  templateUrl: './feature-file-magazzini.component.html',
  styleUrls: ['./feature-file-magazzini.component.css']
})
export class FeatureFileMagazziniComponent  implements OnInit, AfterViewInit {

  @BlockUI("dropzoneGallery") blockUIDropzone: NgBlockUI;
  @Output() confirm = new EventEmitter<FileMagazzinoDTO>();
  @ViewChild("myT") typeAheadTipologiaOrdine: TypeaheadOracleComponent;

  typeop = "";
  alltype: AllComboboxDataDto;
  currentItem: FileMagazzinoDTO;
  fileName;
  aziendeDataSource: RestDataSource;
  listaAziende$: Observable<AziendeDto[]> | undefined = undefined;
  selectedAzienda: string;
  selectedStato: string;
  listaTipiOrdine$: Observable<TipiOrdineDto[]> | undefined = undefined;

  tipoSelezionato;
  displayTipoSelezionato = "Digita per selezionare";
  state: State<AziendeDto> = new State<AziendeDto>();
  searching = false;
  searchFailed = false;
  searchingTipoOrdine = false;
  searchFailedTipoOrdine = false;

  constructor(
    private modalservice: NgbModal,

    private fileOrdiniService: FileOrdiniService,
    private uploadMagazzinoSrv: UploadMagazzinoService,
    private alertSrv: AlertService,
    private http: HttpClient,
    private aziendeCRUD: AziendeCRUDService,
    private errorSrv: ErroriService,
    private tipiOrdineSrv: TipiOrdineSearchService
  ) {


    let user = JSON.parse(localStorage.getItem("userData"));
    console.log("user", user);
    this.listaAziende$ = this.aziendeCRUD.getAziendeByUser(user).pipe(
      map((res: any) => {
        return JSON.parse(res.dati);
      })
      // tap((res) => console.log("listaAziende", res))
    );

    this.listaTipiOrdine$ = this.tipiOrdineSrv.loadAll();
    this.listaTipiOrdine$.subscribe((res) => console.log("tipiOrdine", res));
  }
  ngAfterViewInit(): void {
    console.log(this.instance);

    this.typeAheadTipologiaOrdine.setSearchFn(this.searchTipiOrdine);
  }
  ngOnInit(): void {}

  options = {
    close: true,
    expand: true,
    minimize: true,
    reload: true,
  };

  files: File[] = [];

  file: File;

  select;

  onSelect(event) {
    if (event.rejectedFiles.length !== 0) {
      let fileName = event.rejectedFiles[0].name;
      let messaggio = `Il formato del file ${fileName} non è valido`;
      //  modalRefErrore.componentInstance.messaggioEventuale = messaggio;
      this.errorSrv.onToastTestata(messaggio);
    } else if (
      event.addedFiles[0].type == "text/plain" ||
      event.addedFiles[0].type == "text/csv"
    ) {
      this.file = event.addedFiles[0];
      this.fileName = this.file.name;
      if (this.files.length == 0) this.files.push(this.file);
    } else {
      let messaggio = `Il formato del file ${this.fileName} non è valido`;
      //  modalRefErrore.componentInstance.messaggioEventuale = messaggio;
      this.errorSrv.onToast(messaggio);
    }
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  @ViewChild("instance", { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => {
        return !this.instance.isPopupOpen()})
    );
    const inputFocus$ = this.focus$;

    return combineLatest([
      debouncedText$,
      this.listaAziende$,
      clicksWithClosedPopup$,
      inputFocus$,
    ]).pipe(
      tap(() => (this.searching = true)),
      map(([term, aziende]) => {
        let listaClienti = aziende.filter((az) => az.tipoAzienda == "cliente");
        let filtrata = listaClienti.filter((az) => {
          let rs = az.ragioneSociale.toString().trim().toLowerCase();
          let t = term.toString().trim().toLowerCase();
          return rs.includes(t);
        });
        // console.log("listaFiltrata", filtrata);
        let listaRagioniSociali = filtrata.map((azienda) =>
          azienda.ragioneSociale.toString().trim()
        );
        return listaRagioniSociali.slice(0, 10);
      }),
      tap((res) => console.log("tap service", res))
    );
  };

  searchTipiOrdine: OperatorFunction<string, readonly TipiOrdineDto[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        (this.searchingTipoOrdine = true),
          (this.searchFailedTipoOrdine = false);
      }),
      switchMap((term) =>
        this.listaTipiOrdine$.pipe(
          map((res: TipiOrdineDto[]) => {
            let filtrata = res.filter((to) => {
              let rs = to.descTipoOrdine.toString().trim().toLowerCase();
              let cod = to.codTipoOrdine.toString().trim().toLowerCase();
              let t = term.toString().trim().toLowerCase();
              return rs.includes(t) || cod.includes(t);
            });
            // console.log("listaFiltrata", filtrata);
            let listaRisultante = [];

            filtrata.forEach((to) => {
              if (to && to.descTipoOrdine && to.codTipoOrdine) {
                let obj = {
                  display: to.descTipoOrdine,
                  id: to.idTipoOrdine,
                  codice: to.codTipoOrdine,
                };
                listaRisultante.push(obj);
              }
            });

            return listaRisultante;
          }),
          tap((res) => {
            if (res.length == 0) {
              this.searchFailedTipoOrdine = true;
            }
            console.log("tap", res, "term", term);
          }),
          tap(() => console.log("tap", "term", term)),

          catchError(() => {
            this.searchFailedTipoOrdine = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searchingTipoOrdine = false))
    );

  OnChangeConto(event) {
    console.log("stato", event);

    this.selectedStato = event;
  }
  SelectSingleFile(event) {
    this.file = event.addedFiles;
    this.files.push(this.file);
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    console.log(this.file.arrayBuffer());
  }

  SinglefileonRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onReadFile(data): any[] {
    let arrayRighe: any[] = [];

    let csvToRowArray = data.split("\n");
    for (let index = 1; index < csvToRowArray.length - 1; index++) {
      let row = csvToRowArray[index].split(";");
      // console.log("file rows",row);
      row.forEach((field) => {
        field = field.trim();
      });
      arrayRighe.push(row);
      // quantitaTot+=this.calcolaQuantitàTotFile(row);
    }

    //console.log("quantitaTot", quantitaTot);

    console.log("file rows", arrayRighe);

    return arrayRighe;
  }

  calcolaQuantitàriga(riga) {
    let quantita = [];
    for (let index = 53; index <= 78; index++) {
      quantita.push(parseInt(riga[index]));
    }
    let quantitariga = 0;
    quantita.forEach((num: number) => {
      quantitariga += num;
    });

    console.log("quantitariga", quantitariga);
    return quantitariga;
  }

  calcolaQuantitaTot(righeOrdine) {
    let quantitaTot = 0;

    righeOrdine.forEach((riga) => {
      quantitaTot += this.calcolaQuantitàriga(riga);
    });

    console.log("uqnatTot", quantitaTot);
    return quantitaTot;
  }
  async onImport() {
    this.alertSrv.success(
      Globals.MappaMessaggiErrore.get(codiciErrore.elaborazioneInCorso)
    );
    if (this.file) {
      this.fileName = this.file.name;
      let data: ArrayBuffer = await this.file.arrayBuffer();
      let fileDataAsString = String.fromCharCode.apply(
        null,
        new Uint8Array(data)
      );

      let righeOrdine = this.onReadFile(fileDataAsString);
      let quantitaTot = this.calcolaQuantitaTot(righeOrdine);

      let resultCheck = this.fileOrdiniService.checkFileData(fileDataAsString);
      const formData = new FormData();
      formData.append("fileOrdini", this.file);
      var user = JSON.parse(localStorage.getItem("userData"));
      formData.append("autore", user.userID);
      let lavorazione =
        this.selectedStato == "true" ? "LAVORAZIONE" : "VENDITA";
      console.log("lavorazione", lavorazione);
      console.log("formData", formData);
      let idTipoOrdine = this.tipoSelezionato;
      formData.append("idTipoOrdine", idTipoOrdine);
      let enableAzienda = true;
      this.listaAziende$
        .pipe(
          map((res) => {
            let match = res.find(
              (az) =>
                az.ragioneSociale.toString().toLowerCase().trim() ==
                this.selectedAzienda
            );
            if (match) {
              return match.idAzienda;
            } else {
              enableAzienda = false;
              this.errorSrv.onToastTestata("Azienda inesistente");
            }
          }),
          tap((res) => {
            formData.append("tipoFile", `${res}_${lavorazione}`);
          }),
          switchMap((res) => {
            if (enableAzienda){
              return this.fileOrdiniService.uploadFile(formData);
            }
          })
        )
        .subscribe(
          (res: any) => {
            console.log("res", res);
            if ((res.codEsito = "OK")) {
              // modalRef.componentInstance.onClose();
              //file preso in carico con successo
              // this.onImportSuccess();
              this.alertSrv.success(
                "La richiesta è stata presa in carico",
                "Controllare l'esito in 'Gestione Importazioni'"
              );
            } else {
              // modalRef.componentInstance.onClose();
              //c'è stato un errore nell'importazione => ERRORE GENERICO
              //this.importFailure();
              this.errorSrv.onToastTestata(
                Globals.MappaMessaggiErrore.get("ERRORIMPORT")
              );
            }
          },
          (error: any) => {
            console.log("error.dati", error.error);
            let errore: CindyCustomResponse =
              error.error as CindyCustomResponse;

            if (errore.codEsito == "KO.GENEX") {
              // modalRef.componentInstance.onClose();
              // this.importFailure("Prova a importare di nuovo");
              this.errorSrv.onToastTestata(
                Globals.MappaMessaggiErrore.get("ERRORIMPORT")
              );
            } else {
              // modalRef.componentInstance.onClose();
              /*      this.importFailure(
              "Il file è già stato importanto. \n Prova a importare di nuovo"
            ); */
              this.errorSrv.onToastTestata(
                "Il file è già stato importato precedentemente"
              );

              //rielaborazione fallita
              //metti al posto del testo sotto il descErrore parsato

              // let id = +JSON.parse(error.error.dati).descErrore;
              // console.log("error", id);
              // modalRef.componentInstance.onClose();
              // const modalError = this.modalservice.open(
              //   PopUpUploadOrdiniComponent,
              //   {
              //     size: "md",
              //     windowClass: "animated FadeIn",
              //   }
              // );
              // modalError.componentInstance.showButton = true;
              // modalError.componentInstance.rielaboraOrdine = true;
              // modalError.componentInstance.progressMessage =
              //   Globals.MappaMessaggiErrore.get("KO.B5");
              // const sub = modalError.componentInstance.confirm
              //   .pipe(
              //     switchMap((data) => {
              //       console.log("confirm", data);
              //       if (data == true) {
              //         //rielabora il file
              //         return this.fileOrdiniService.rielaboraFile(id);
              //       }
              //     })
              //   )
              //   .subscribe(
              //     (res: any) => {
              //       //rielaborazione con successo
              //       console.log("res sub", res);
              //       this.onImportSuccess();
              //     },
              //     (err2) => {
              //       //rielaborazione fallita
              //       console.log("err2", err2);
              //       if (err2.error.codEsito == "KO.GENEX") {
              //         this.importFailure();
              //       } else {
              //         this.importFailure();
              //       }
              //     }
              //   );
            }
            //gestione errore in caso di file già presente
          }
        );

      console.log("formData", formData);
    }
    this.files = [];
  }

  onImportSuccess() {
    const modalSuccess = this.modalservice.open(PopUpUploadOrdiniComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalSuccess.componentInstance.showButton = true;

    modalSuccess.componentInstance.progressMessage =
      "La tua richiesta è stata presa in carico. \n Controlla l'esito in 'Gestione Importazioni'";
    const sub = modalSuccess.componentInstance.confirm.pipe(
      map((data) => {
        modalSuccess.componentInstance.onClose();
        console.log(data);
      })
    );
  }

  importFailure(messaggio?: string) {
    const modalRefFailure = this.modalservice.open(PopUpUploadOrdiniComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalRefFailure.componentInstance.showButton = true;
    modalRefFailure.componentInstance.progressMessage =
      Globals.MappaMessaggiErrore.get("ERRORIMPORT");
    if (messaggio) {
      modalRefFailure.componentInstance.optionalMessage = messaggio;
    }

    const sub = modalRefFailure.componentInstance.confirm.pipe(
      map((data) => {
        modalRefFailure.componentInstance.onClose();

        console.log(data);
      })
    );
  }

  valoretypeAhead(evt: { display: string; id: number; codice: string }) {
    this.tipoSelezionato = evt.id;
    this.displayTipoSelezionato = evt.display;
    console.log(evt);
  }

  valoreAzienda(ragioneSociale) {
    this.selectedAzienda = ragioneSociale;
    console.log("ciao", ragioneSociale);
  }


}
