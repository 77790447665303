import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDTOMapper } from 'src/app/_helpers/IDTOMapper';
import { ItemFilter } from 'src/app/_filters/ItemFilter';
import { TipiOrdineDto } from 'src/app/featureTipoOrdine/models/tipiordine-dto';
import { AService } from 'src/app/_services/AService';
import { State } from 'src/app/_models/State';
import { TipiOrdineMapper } from '../mappers/tipiOrdineMapper';


@Injectable({
  providedIn: 'root'
})

export class TipiOrdineService extends AService<TipiOrdineDto> {
 
  state(): State<TipiOrdineDto> {
     
    let currentState:State<TipiOrdineDto> = new State<TipiOrdineDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<TipiOrdineDto> = new TipiOrdineMapper();
}

