
export class RecapitiDto {
  idRecapito: number=0;
  idAzienda: number=0;
  tipoRecapito: string='';
  nomeRecapito: string='';
  dettagliRecapito:DettagliRecapito;
  extCode: string='';
  validUntil: string='';

  constructor(){

    
  }
}

export class DettagliRecapito{

  via:string="";
  localita:string="";
  provincia:string="";
  numero:string="";
  cap:string="";
  nazione:string="";
  valore:string="";

  constructor(){

    
  }
}

