import { Injectable } from "@angular/core";
import {
  Observable,
  OperatorFunction,
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  switchMap,
  tap,
} from "rxjs";
import { TipiOrdineDto } from "../featureTipoOrdine/models/tipiordine-dto";
import { AziendeDto } from "../featureMain/models/aziende-dto";

@Injectable({
  providedIn: "root",
})
export class TypeaheadService {
  searchingTipoOrdine: boolean;
  searchFailedTipoOrdine: boolean;
  listaTipiOrdine$: Observable<TipiOrdineDto[]>;
  listaAziende$: Observable<AziendeDto[]>;


  constructor() {}

  filterFunction() {}

  searchTipiOrdine: OperatorFunction<string, readonly TipiOrdineDto[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        (this.searchingTipoOrdine = true),
          (this.searchFailedTipoOrdine = false);
      }),
      switchMap((term) =>
        this.listaTipiOrdine$.pipe(
          map((res: TipiOrdineDto[]) => {
            let filtrata = res.filter((to) => {
              let rs = to.descTipoOrdine.toString().trim().toLowerCase();
              let cod = to.codTipoOrdine.toString().trim().toLowerCase();
              let t = term.toString().trim().toLowerCase();
              return rs.includes(t) || cod.includes(t);
            });
            // console.log("listaFiltrata", filtrata);
            let listaRisultante = [];
            listaRisultante.push({
              id: 0,
              display: "Nessun Tipo Selezionato",
              codice: '', 
            });
            filtrata.forEach((to) => {
              if (to && to.descTipoOrdine && to.codTipoOrdine) {
                let obj = {
                  display: to.descTipoOrdine,
                  id: to.idTipoOrdine,
                  codice: to.codTipoOrdine,
                };
                listaRisultante.push(obj);
              }
            });

            return listaRisultante;
          }),
          tap((res) => {
            if (res.length == 0) {
              this.searchFailedTipoOrdine = true;
            }
            console.log("tap", res, "term", term);
          }),
          tap(() => console.log("tap", "term", term)),

          catchError(() => {
            this.searchFailedTipoOrdine = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searchingTipoOrdine = false))
    );



    searchFornitore: OperatorFunction<string, readonly AziendeDto[]> = (
      text$: Observable<string>
    ) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          (this.searchingTipoOrdine = true),
            (this.searchFailedTipoOrdine = false);
        }),
        switchMap((term) =>
          this.listaAziende$.pipe(
            map((res: AziendeDto[]) => {
              let filtrata = res.filter((az) => {
                let rs = az.ragioneSociale.toString().trim().toLowerCase();
                let cod = az.extCode.toString().trim().toLowerCase();
                let t = term.toString().trim().toLowerCase();
                return rs.includes(t) || cod.includes(t);
              });
              // console.log("listaFiltrata", filtrata);
              let listaRisultante = [];
              listaRisultante.push({
                id: 0,
                display: "Nessuna azienda",
                codice: '', 
              });
              filtrata.forEach((az) => {
                if (az && az.ragioneSociale && az.extCode) {
                  let obj = {
                    display: az.extCode+'-'+az.ragioneSociale,
                    id: az.idAzienda,
                    codice: az.extCode,
                  };
                  listaRisultante.push(obj);
                }
              });
  
              return listaRisultante;
            }),
            tap((res) => {
              if (res.length == 0) {
                this.searchFailedTipoOrdine = true;
              }
              console.log("tap", res, "term", term);
            }),
            tap(() => console.log("tap", "term", term)),
  
            catchError(() => {
              this.searchFailedTipoOrdine = true;
              return of([]);
            })
          )
        ),
        tap(() => (this.searchingTipoOrdine = false))
      );
}
