export class TipiEsenzioneDto {
    idEsenzione: number;
    codiceTipoEsenzione: string;
    nomeTipoEsenzione: string;
    descrizioneTipoEsenzione:string;
    regimeTipoEsenzione:string;
    aliquotaIVA:number;
    flagEsenzione:boolean;
    extCode: string;
    validUntil: Date;
  }
  