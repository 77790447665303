import { Component, OnInit } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";

import { PortiEditComponent } from "../porti-edit/porti-edit.component";

import { AlertService } from "src/app/_services/alert.service";
import { PortiDto } from "src/app/_models/porti/porti-dto";

import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
import { AndFilter } from "src/app/_filters/AndFilter";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { PortiService } from "../../service/porti.service";
import { GestioneFileService } from "src/app/_services/gestione-file.service";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-porti-page-list",
  templateUrl: "./porti-page-list.component.html",
  styleUrls: ["./porti-page-list.component.css"],
})
export class PortiPageListComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  portiDataSource: RestDataSource;
  state: State<PortiDto> = new State();

  constructor(
    private modalservice: NgbModal,
    private allComboData: ApiAllComboBoxdataService,
    private portiService: PortiService,
    private alertSrv: AlertService,
    private http: HttpClient,
    private tableexcelService: GestioneFileService
  ) {
    this.portiDataSource = new RestDataSource(this.http);

    this.portiDataSource.setVariablesApi(
      "allPorti",
      "porto",
      "porto",
      `${environment.baseDomain}:${environment.anaPort}`    );

    this.portiDataSource.setByIdField("idPorto");
    this.portiService.connectDataSource(this.portiDataSource);
  }

  _searchTerm: string;
  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this.loadData();
  }

  get searchTerm() {
    return this._searchTerm;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.loadData();
  }
  get pageSize() {
    return this._pageSize;
  }
  set page(newPage: number) {
    this._page = newPage;

    this.loadData();
  }

  get page() {
    return this._page;
  }

  loadData() {
    this.portiService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterPorti(service);

        console.log(this._page + " " + this._pageSize);
      },
    });
  }

  filterPorti(service: PortiService) {
    console.log(this._searchTerm);

    this.state = service
      .filter(
        new AndFilter([
          new FieldsFilter({
            fieldList: ["codPorto", "descPorto"],
            searchString: this._searchTerm == undefined ? "" : this._searchTerm,
          }),
          //   new TipiPagamentoiFilter(null),
        ])
      )
      .sort(this._sortColumn, this._sortDirection)
      .paginate(this._page, this.pageSize)
      .state();
  }

  ngOnInit(): void {
    this.loadData();
    this._sortColumn = "idPorto";
    this._sortDirection = "desc";
    this._rotateDirection = "desc";
  }

  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");

    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.filterPorti(this.portiService);
  }

  typeop = "";

  onPortiCreateOrModify(index: number): void {
    let modalRef: any = null;

    modalRef = this.modalservice.open(PortiEditComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });

    let porto: PortiDto = new PortiDto();
    this.typeop = "ins";

    if (index >= 0) {
      porto = this.state.paginatedItems[index];

      this.typeop = "upd";
    }

    modalRef.componentInstance.currentItem = porto;

    modalRef.componentInstance.typeop = this.typeop;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log("data", data);
          if (this.typeop == "ins") {
            return this.portiService.create(data as PortiDto);
          } else if (this.typeop == "upd") {
            if (modalRef.componentInstance.delete == false)
              return this.portiService.update(data as PortiDto);
            else {
              this.typeop = "del";
              return this.portiService.delete(porto.idPorto);
            }
          }
        })
      )
      .subscribe(
        (response: CindyCustomResponse) => {
          console.log(response.codEsito);
          modalRef.componentInstance.onClose();
          this.searchTerm = "";
          this.loadData();
          this._sortColumn = "idPorto";
          this._sortDirection = "desc";
          this._rotateDirection = "desc";
          if (response.codEsito.toLowerCase() == "ok") {
            if (this.typeop == "ins")
              this.alertSrv.success(Globals.printCreateMessageSuccess("Il Porto"));
            else if (this.typeop == "upd") {
              this.alertSrv.success(Globals.printModifyMessageSuccess("Il Porto"));
            } else {
              this.alertSrv.success(Globals.printDeleteMessageSuccess("Il Porto"));
            }
          } else {
            this.onError();
          }
        },
        (err: CindyCustomResponse) => {
          console.log(err.codEsito);
          modalRef.componentInstance.error(err);
          modalRef.componentInstance.onClose();
          this.onError();
        }
      );
  }

  onError() {
    if (this.typeop == "ins")
      this.alertSrv.error(Globals.printCreateMessageFailure("il Porto"));
    else if (this.typeop == "upd") {
      this.alertSrv.error(Globals.printModifyMessageFailure("il Porto"));
    } else {
      this.alertSrv.error(Globals.printDeleteMessageFailure("il Porto"));
    }
  }

  getExcelData(): void {
    this.tableexcelService.exportAsExcelFile(
      this.state.items,
      "Tabella Porti EXCELL"
    );
  }
}
