export const globalMessages = {
  deleteMessages: {
    deleteSuccess: "è stato cancellato con successo",
    deleteFailure: "C'è stato un errore con",
  },
  editMessages: {
    editSuccess: "è stato modificato con successo",
    editFailure: "C'è stato un errore con",
  },
  createMessages: {
    createSuccess: "è stato creato con successo",
    createFailure: "C'è stato un errore con",
  },

  downloadMessages:{
    downloadSuccess: "è stato caricato con successo",
    downloadFailure: "C'è stato un errore con",
  },

  errorMessages:{
    error: "I dati non sono stati salvati",
    downloadFailure: "C'è stato un errore con",
  },
  printMessages: {
    printSuccess: "è stato stampato con successo",
    printFailure: "C'è stato un errore con",
  },
};


