import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DistintaBase } from 'src/app/_models/articoli/articoliDto';
import { AlertService } from 'src/app/_services/alert.service';
import { CrudService } from 'src/app/_services/crud.service';
import { environment } from 'src/environments/environment';
import { DistintaBaseMapper } from '../mappers/distintaBaseMapper';

@Injectable({
  providedIn: 'root'
})
export class dibaCRUDService extends CrudService<DistintaBase>{

  constructor(httpClient: HttpClient,
    public alertSrv: AlertService,
    ) { 
    super(httpClient, alertSrv);
    this.serverPort= environment.anaPort
    this.endpoint= 'distintaBase'
    this.endpointAll='null';
    this.mapper= new DistintaBaseMapper()
  
  }}
