import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchService } from 'src/app/_services/search.service';
import { environment } from 'src/environments/environment';
import { OrdiniDto } from '../models/ordiniDto';
import { OrdiniEstesiProduzioneMapper } from '../mappers/ordiniProdMapper';
import { AlertService } from 'src/app/_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class OrdiniProdSearchService extends SearchService<OrdiniDto> {

  constructor(httpClient:HttpClient,
    public alertSrv: AlertService,
    ) {
    super(httpClient, alertSrv);
    this.url = `${environment.baseDomain}`;
    this.port = environment.ordiniPort;
    this.endpoint = 'allOrdiniEstesi'
    //this.endpoint = 'ordiniEstesiByUser'

    this.mapper = new OrdiniEstesiProduzioneMapper();
  }
}
