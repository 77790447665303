import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'src/app/_models/Globals';

@Component({
  selector: 'app-pop-up-conferma',
  templateUrl: './pop-up-conferma.component.html',
  styleUrls: ['./pop-up-conferma.component.css']
})
export class PopUpConfermaComponent implements OnInit {
  @Output() confirm = new EventEmitter();

  constructor( public activeModal: NgbActiveModal) { }
  codiceConferma;
  messaggio;

  ngOnInit(): void {
    this.messaggio = Globals.MappaMessaggiUtente.get(this.codiceConferma);
  }
  
  onSubmit(): void {
   let vm = true
    this.confirm.emit(vm);
    //console.log(vm)
    this.activeModal.close('close');

  }
  onClose(): void {
    let vm = false

    this.confirm.emit(vm);

    this.activeModal.close('close');
  }

}
