import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-pop-up-upload-ordini",
  templateUrl: "./pop-up-upload-ordini.component.html",
  styleUrls: ["./pop-up-upload-ordini.component.css"],
})
export class PopUpUploadOrdiniComponent implements OnInit {
  @Output() confirm = new EventEmitter();

  progressMessage:string;
  successMessage:string;
  failureMessage:string;
  optionalMessage:string=''
  optionalMessage1:string=''
  showButton;
  rielaboraOrdine=false;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onSubmit(): void {
    let vm = false;
    this.confirm.emit(vm);
    console.log(vm);
    console.log("false",vm);

  }
  onClose(): void {
    this.activeModal.close("close");
  }


  rielabora(){
    let vm = true;
    this.confirm.emit(vm);
    this.onClose();
    console.log("true",vm);
  }
}
