import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';

import { SviluppiTagliePageLIstComponent } from './component/sviluppi-taglie-page-list/sviluppi-taglie-page-list.component';
import { SviluppiTaglieEditComponent } from './component/sviluppi-taglie-edit/sviluppi-taglie-edit.component';


@NgModule({
    declarations: [
        SviluppiTagliePageLIstComponent,
        SviluppiTaglieEditComponent,
    ],
    imports: [
      NgbModule,
      SharedModule,
      BlockUIModule
    ]
  })
  export class FeatureSviluppiTaglieModule { }
  


