import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TipiOrdineDto } from 'src/app/featureTipoOrdine/models/tipiordine-dto';
import { AllComboboxDataDto } from 'src/app/_models/allcomboboxdata/allcomboboxdata-dto';
import { Utility } from 'src/app/_zCore/utility';
import { PopUpDeleteComponent } from 'src/app/_popups/pop-up-delete/pop-up-delete.component';
import { map } from 'rxjs/operators';
import { PopUpIstanzaEsistenteComponent } from 'src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component';
import { CindyCustomResponse } from 'src/app/_models/esitoServer/CindyCustomResponse';
import { codiciErrore } from 'src/assets/data/codiciErrore';
import { Globals } from 'src/app/_models/Globals';
import { ErroriService } from 'src/app/_services/errori-service.service';

@Component({
  selector: 'app-tipi-ordine-edit',
  templateUrl: './tipi-ordine-edit.component.html',
  styleUrls: ['./tipi-ordine-edit.component.css']
})
export class TipiOrdineEditComponent implements OnInit {
  @Output() confirm = new EventEmitter<TipiOrdineDto>();
  typeop = '';
  selectTipoOrdine;
  alltype: AllComboboxDataDto;
  currentItem: TipiOrdineDto;
  delete: boolean = false;


  constructor(public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    private errorSrv: ErroriService
    ) { }

  ngOnInit(): void {
    //console.log(this.currentItem.flagTipoOrdine);

  }

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert('Dati mancanti!');
      return;
    }

    const vm = new TipiOrdineDto();
    vm.idTipoOrdine = Utility.toInteger(this.currentItem.idTipoOrdine);
    vm.validUntil = Utility.toString (this.currentItem.validUntil);
    vm.codTipoOrdine = Utility.toString(form.value.codTipoOrdine).toUpperCase();
    vm.descTipoOrdine = Utility.toString(form.value.descTipoOrdine);
   // vm.flagTipoOrdine = Utility.toString(form.value.flagTipoOrdine);
    vm.extCode = Utility.toString(form.value.extCode);
    
    this.confirm.emit(vm);
    console.log(vm)

  }

  //qui devi mettere il popup dei dati mancanti
  error(err: any): void {
    console.log("errore", err.error);
    let errore: CindyCustomResponse = err.error;
    //alert('Si è verificato un errore nella scrittura dei dati!');
    /*  const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito; */
    // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati;
    if (errore.codEsito == codiciErrore.codiceDuplicato) {
      this.errorSrv.onToastTestata(
        Globals.MappaMessaggiErrore.get(err.error.codEsito)
      );
    } else {
      this.errorSrv.onToastTestata(
        `${Globals.MappaMessaggiErrore.get(
          codiciErrore.erroreResiduale
        )}. ${Globals.MappaMessaggiErrore.get("KO.GENERICO_dettaglio")}`
      );
    }
    this.onClose();

  } 

  

  onClose(): void {
    this.activeModal.close('close');
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  
  onDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new TipiOrdineDto();

          this.delete = true;
          console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
   
        },
        (err) => {
          modalRef.componentInstance.onClose();
 
        }
      );


  }


  ngOnDestroy() {

  }
}
