import { Injectable } from "@angular/core";
import { TipiOrdineDto } from "../models/tipiordine-dto";
import { SearchService } from "src/app/_services/search.service";
import { HttpClient } from "@angular/common/http";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { environment } from "src/environments/environment";
import { TipiOrdineMapper } from "../mappers/tipiOrdineMapper";
import { Observable, map } from "rxjs";
import { AlertService } from "src/app/_services/alert.service";

@Injectable({
  providedIn: "root",
})
export class TipiOrdineSearchService extends SearchService<TipiOrdineDto> {
  tipiOrdineDataSource: RestDataSource;

  constructor(httpClient: HttpClient,     public alertSrv: AlertService,
    ) {
    super(httpClient, alertSrv);
    this.url = `${environment.baseDomain}`;
    this.port = environment.anaPort;
    this.endpoint = "allTipoOrdine";

    this.mapper = new TipiOrdineMapper();

    this.tipiOrdineDataSource = new RestDataSource(this.httpClient);
    this.tipiOrdineDataSource.setVariablesApi(
      "allTipoOrdine",
      "tipoOrdine",
      "tipoOrdine",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    this.tipiOrdineDataSource.setByIdField("idTipoOrdine");
  }


  loadAll(): Observable<TipiOrdineDto[]> {
    let mapper: TipiOrdineMapper = new TipiOrdineMapper();

    return this.tipiOrdineDataSource.getAllData().pipe(
      map((data: any) => {
        let res: TipiOrdineDto[] = [];

        let datiItems: any[] = [];

        if (data.dati !== undefined) {
          datiItems = JSON.parse(data.dati);
        } else datiItems = data;

        datiItems.forEach((i) => res.push(mapper.map(i)));

        return res;
      })
    );
  }
}
