import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { ItemFilter } from "src/app/_filters/ItemFilter";
import { AService } from "src/app/_services/AService";
import { Lancio, LancioProdDto, OrdiniPerLancio, QuantitaPerTaglia } from "../models/lancioProdDto";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { HttpClient } from "@angular/common/http";
import { LoggerService } from "src/app/shared/services/logger.service";
import { State } from "src/app/_models/State";
import { Utility } from "src/app/_zCore/utility";

export class LancioProdMapper implements IDTOMapper<LancioProdDto>{
  lancioMapper:LancioMapper=new LancioMapper()
  
  map(data: any): LancioProdDto {
    
    let item:LancioProdDto = new LancioProdDto();

    item.idLancio=data.idLancio;
    item.tagLancio = data.tagLancio;
    item.lancio = this.lancioMapper.map(data.lancio);
    item.extCode = data.extCode;
    item.validUntil = data.validUntil;

    
    return item;
  }

}

class LancioMapper implements IDTOMapper<Lancio>{
  ordiniPerLancioMapper:OrdiniPerLancioMapper= new OrdiniPerLancioMapper()
  
  map(data: any): Lancio {
    let item:Lancio = new Lancio();
    item.ordini =[...data.ordini.map((r)=>this.ordiniPerLancioMapper.map(r))] as OrdiniPerLancio[]
    return item;
  }

}

class OrdiniPerLancioMapper implements IDTOMapper<OrdiniPerLancio>{
 
 qntPerTagliaMapper: QuantitaPerTagliaMapper = new QuantitaPerTagliaMapper()
  map(data: any): OrdiniPerLancio {
    
    let item:OrdiniPerLancio = new OrdiniPerLancio();
    item.idOrdine = data.idOrdine;
    item.idRiga = data.idRiga;
    item.idSviluppo = data.idSviluppo;
    item.anno= data. anno;
    item.numero = data.numero;
    item.ente = data. ente;
   // console.log(data.qntPerTaglia)
    item.qtPerTaglia =[...data.qtPerTaglia.map((r)=>this.qntPerTagliaMapper.map(r))] as QuantitaPerTaglia[];
    return item;
  }

}

class QuantitaPerTagliaMapper implements IDTOMapper<QuantitaPerTaglia>{
  map(data: any): QuantitaPerTaglia {
    
    let item:QuantitaPerTaglia = new QuantitaPerTaglia();

    item.codTaglia = data.codTaglia;
    item.quantita = Utility.toInteger(data.quantita);
    item.sku = data.sku;
    return item;
  }

}

@Injectable({
  providedIn: 'root'
})
export class LancioProduzioneService extends AService<LancioProdDto>{
  

  state(): State<LancioProdDto> {
     
    let currentState:State<LancioProdDto> = new State<LancioProdDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<LancioProdDto> = new LancioProdMapper();
  }
