import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { Utility } from "src/app/_zCore/utility";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { map } from "rxjs/operators";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { Observable } from "rxjs";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { AziendeSearchService } from "src/app/featureMain/service/aziende-search.service";
import { AziendeCRUDService } from "src/app/featureMain/service/aziende-crud.service";
import { SviluppiTaglieCRUDService } from "../../service/sviluppi-taglie-crud.service";
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { Globals } from "src/app/_models/Globals";
import { AlertService } from "src/app/_services/alert.service";

@Component({
  selector: "app-sviluppi-taglie-edit",
  templateUrl: "./sviluppi-taglie-edit.component.html",
  styleUrls: ["./sviluppi-taglie-edit.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SviluppiTaglieEditComponent implements OnInit, OnDestroy {
  @Output() confirm = new EventEmitter<SviluppiTaglieDto>();
  typeop = "";
  alltype: AllComboboxDataDto;
  currentItem: SviluppiTaglieDto;
  delete: boolean = false;
  @Input() listaAziendeByUser$: Observable<AziendeDto[]> | undefined =
    undefined;
  utente;
  backupCurrentItem: SviluppiTaglieDto;
  enableSave = true;

  constructor(
    public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    private aziendeCRUDSrv: AziendeCRUDService,
    private sviluppiCRUDSrv: SviluppiTaglieCRUDService,
    private router: Router,
    private alertSrv: AlertService
  ) {
    /*     this.utente = JSON.parse(localStorage.getItem("userData"));

    this.listaAziendeByUser$ = this.aziendeCRUDSrv
      .getAziendeByUser(this.utente)
      .pipe(map((res: any) => JSON.parse(res.dati))); */
  }
  ngOnInit(): void {
    //console.log(this.currentItem)
  }

  prepareCurrentItem(obj) {
    return obj.values;
  }
  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      return;
    }

    const vm = new SviluppiTaglieDto();
    vm.idSviluppoTaglia = Utility.toInteger(this.currentItem.idSviluppoTaglia);
    vm.validUntil = Utility.toString(this.currentItem.validUntil);
    vm.codiceSviluppoTaglia = Utility.toString(form.value.codiceSviluppoTaglia).toUpperCase();
    vm.sviluppoTaglia = [
      form.value.sviluppoTaglia0,
      form.value.sviluppoTaglia1,
      form.value.sviluppoTaglia2,
      form.value.sviluppoTaglia3,
      form.value.sviluppoTaglia4,
      form.value.sviluppoTaglia5,
      form.value.sviluppoTaglia6,
      form.value.sviluppoTaglia7,
      form.value.sviluppoTaglia8,
      form.value.sviluppoTaglia9,
      form.value.sviluppoTaglia10,
      form.value.sviluppoTaglia11,
      form.value.sviluppoTaglia12,
      form.value.sviluppoTaglia13,
      form.value.sviluppoTaglia14,
      form.value.sviluppoTaglia15,
      form.value.sviluppoTaglia16,
      form.value.sviluppoTaglia17,
      form.value.sviluppoTaglia18,
      form.value.sviluppoTaglia19,
      form.value.sviluppoTaglia20,
      form.value.sviluppoTaglia21,
      form.value.sviluppoTaglia22,
      form.value.sviluppoTaglia23,
      form.value.sviluppoTaglia24,
      form.value.sviluppoTaglia25,
    ];
    vm.sviluppoTaglia.forEach((taglia) => {
      if (taglia.indexOf(",") !== -1) {
        this.enableSave = false;
      }
    });
    //vm.idAzienda = Utility.toInteger(this.currentItem.idAzienda);

    if (this.enableSave === true) {
      this.confirm.emit(vm);
    } else {
      this.enableSave = true;
    }
    console.log("confirm: ", vm);
    console.log(vm);
  }

  //qui gestisco solo la delete
  error(err: any): void {
    console.log("errore", err.error);
    let errore: CindyCustomResponse = err.error;
    if (errore.codEsito == "KO.A1") {
      const modalRefErrore = this.modalservice.open(
        PopUpIstanzaEsistenteComponent,
        {
          size: "sm",
          windowClass: "animated FadeIn",
        }
      );
      modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
      modalRefErrore.componentInstance.messaggioEventuale = JSON.parse(
        err.error.dati
      ).descErrore;
    } else {
      this.alertSrv.error(
        Globals.printDeleteMessageFailure("lo Sviluppo Taglie")
      );
    }

    this.activeModal.close("close");
  }

  onChangeAzienda(idAzienda) {}

  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  prepareTaglie(obj): Array<string> {
    if (obj.length !== 0) {
      // let myMap = new Map(Object.entries(obj));
      //  let values = Array.from(myMap.values()) as Array<string>;
      let values = obj as Array<string>;
      //  console.log("questo è un array", values);
      return values;
    } else {
      var values: Array<string> = [
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
        "•",
      ];
      return values;
    }
  }

  onDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new SviluppiTaglieDto();

          this.delete = true;
          console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
        },
        (err) => {
          modalRef.componentInstance.onClose();
        }
      );
  }

  ngOnDestroy() {
    this.listaAziendeByUser$ = null;
  }
}
