import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { DDT, DDTEstesoDto, RigheDDT } from "../models/DDTdto";

export class DDTEsteso_Mapper implements IDTOMapper<DDTEstesoDto> {
  DDTMapper: DDTMapper = new DDTMapper();
  righeDDTMapper: RigheDDTMapper = new RigheDDTMapper();

  map(data: any): DDTEstesoDto {
    let item: DDTEstesoDto = new DDTEstesoDto();

    item.ddt = this.DDTMapper.map(data.ddt);
    item.righeDDT = [
      ...data.righeDDT.map((r) => this.righeDDTMapper.map(r)),
    ] as RigheDDT[];

    return item;
  }
}

export class DDTMapper implements IDTOMapper<DDT> {
  map(data: any): DDT {
    let item: DDT = new DDT();

    item.idDDT = data.idDDT;
    item.lotto = data.lotto;
    item.codiceFornitore = data.codiceFornitore;
    item.descFornitore = data.descFornitore;
    item.codiceDDT = data.codiceDDT;
    item.dataOda = data.dataOda;
    item.compilazioneDDT = data.compilazioneDDT;
    item.causale = data.causale;
    item.note = data.note;
    item.flagDDTFinale = data.flagDDTFinale;
    item.extCode = data.extCode;
    item.validUntil = data.validUntil;

    return item;
  }
}

export class RigheDDTMapper implements IDTOMapper<RigheDDT> {
  map(data: any): RigheDDT {
    let item: RigheDDT = new RigheDDT();
    item.idRiga = data.idRiga;
    item.idDDT = data.idDDT;
    item.idOda = data.idOda;
    item.idRigaOda = data.idRigaOda;
    item.codiceComponente = data.codiceComponente;
    item.coloreComponente = data.coloreComponente;
    item.qntOrdinata = data.qntOrdinata;
    item.qntConsegnata = data.qntConsegnata;
    item.qntInArrivo = data.qntInArrivo;
    item.extCode = data.extCode;
    item.validUntil = data.validUntil;

    return item;
  }
}
