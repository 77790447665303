import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { AziendeDto } from "../models/aziende-dto";

export class AziendeMapper implements IDTOMapper<AziendeDto> {
    map(data: any): AziendeDto {
      let item: AziendeDto = new AziendeDto();
  
      item.idAzienda = data.idAzienda;
      //item.codiceAzienda = data.codiceAzienda;
      item.tipoAzienda = data.tipoAzienda;
      item.ragioneSociale = data.ragioneSociale;
      item.indirizzo = data.indirizzo;
      item.cap = data.cap;
      item.citta = data.citta;
      item.provincia = data.provincia;
      item.nazione = data.nazione;
      item.codiceFiscale = data.codiceFiscale;
      item.partitaIva = data.partitaIva;
      item.partitaIvaEstero = data.partitaIvaEstero;
      item.banca = data.banca;
      item.iban = data.iban;
      item.swift = data.swift;
      item.codTipoFattura = data.codTipoFattura;
      item.descTipoFattura = data.descTipoFattura;
      item.numProtocolloLettereIntento = data.numProtocolloLettereIntento;
      item.numDichiarazioneEsportazione = data.numDichiarazioneEsportazione;
      item.dataDichiarazioneEsportazione = data.dataDichiarazioneEsportazione;
      item.nsNumeroProtocollo = data.nsNumeroProtocollo;
      item.dataProtocollo = data.dataProtocollo;
      item.sconto = data.sconto;
      item.raggruppamentoDDT = data.raggruppamentoDDT;
      item.assicurazioneMerceCliente = data.assicurazioneMerceCliente;
      item.personaFisica = data.personaFisica;
      item.bolliSuFattura = data.bolliSuFattura;
      item.notaFineFattura = data.notaFineFattura;
      item.agente = data.agente;
      item.agenteDesc = data.agenteDesc;
      item.capoZona = data.capoZona;
      item.capoZonaDesc = data.capoZonaDesc;
      item.spedizioniere = data.spedizioniere;
      item.spedizioniereDesc = data.spedizioniereDesc;
      item.idPortoCollegato = data.idPortoCollegato;
      item.idEsenzioneIvaCollegata = data.idEsenzioneIvaCollegata;
      item.idPagamentoCollegato = data.idPagamentoCollegato;
      item.zonaProvvigione = data.zonaProvvigione;
      item.percentualeProvvigioneAgente = data.percentualeProvvigioneAgente;
      item.percentualeProvvigioneCapozona = data.percentualeProvvigioneCapozona;
      item.extCode = data.extCode;
      item.validUntil = data.validUntil;
      item.codiceSDI = data.codiceSDI;
      item.pec = data.pec;
      item.notaFatturazioneElettronica = data.notaFatturazioneElettronica;
      item.codiceRea = data.codiceRea;
  
      return item;
    }
  }
  