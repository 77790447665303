import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDTOMapper } from 'src/app/_helpers/IDTOMapper';
import { ItemFilter } from 'src/app/_filters/ItemFilter';
import { ImballiDto } from 'src/app/featureImballi/models/imballi-dto';
import { AService } from 'src/app/_services/AService';
import { State } from 'src/app/_models/State';

class ImballiMapper implements IDTOMapper<ImballiDto>{
  map(data: any): ImballiDto {
    let imb:ImballiDto = new ImballiDto();

    imb.altezza = data.altezza;
    imb.codice = data.codice;
    imb.contenutoScatole = data.contenutoScatole;
    imb.descrizione = data.descrizione;
    imb.extCode = data.extCode;
    imb.idImballo = data.idImballo;
    imb.larghezza = data.larghezza;
    imb.lunghezza = data.lunghezza;
    imb.misura = data.misura;
    imb.pesoLordo = data.pesoLordo;
    imb.pesoNetto = data.pesoNetto;
    imb.validUntil = data.validUntil;
    imb.volumecm = data.volumecm;
    imb.volumem = data.volumem;


    
    return imb;
  }

}

@Injectable({
  providedIn: 'root'
})
export class ImballiService extends AService<ImballiDto> {

  state(): State<ImballiDto> {
     
    let currentState:State<ImballiDto> = new State<ImballiDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<ImballiDto> = new ImballiMapper();}
