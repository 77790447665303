import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { AllComboboxDataDto } from '../_models/allcomboboxdata/allcomboboxdata-dto';
import { ListItemStringDto } from 'src/app/_zCore/models/list-item-string-dto';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiAllComboBoxdataService {
  cache: AllComboboxDataDto;
  domain: string;

  constructor(private http: HttpClient) {
    this.domain = `${environment.baseDomain}:${environment.anaPort}` ;
  }

  getAllComboboxData(): Observable<AllComboboxDataDto> {
    const obs = new Observable<AllComboboxDataDto>(sub => {
      if (this.cache) {
        sub.next(this.cache);
        sub.complete();
      }

      forkJoin(
        {
          tipoOrdine: this.loadTipoOrdineJson(),
          tipoPagamento: this.loadTipoPagamentoJson(),
          tipoEsenzione: this.loadTipoEsenzioneJson(),
          tipoPorto: this.loadTipoPortoJson(),
          //ho aggiunto
          listaAziende:this.loadAziendeJson(),
          listaProvince:this.loadprovinceJson(),
          listaSviluppiTaglie: this.loadSviluppiTaglieJSon(),
          listaTipoAziende: this.loadTipoAziendeJson()
          //

        }
      ).subscribe(response => {
        const result = new AllComboboxDataDto();
        result.tipoOrdine = response.tipoOrdine,
        result.tipoPagamento = response.tipoPagamento,
        result.tipoEsenzione = response.tipoEsenzione,
        result.tipoPorto = response.tipoPorto,
        //ho aggiunto
        result.listaAziende = response.listaAziende,
        result.listaProvince = response.listaProvince,
        result.listaSviluppiTaglie = response.listaSviluppiTaglie
        //
        result.listaTipoAziende = response.listaTipoAziende,
        result.tipoFattura = this.createTipoFattura(),
        this.cache = result;
        sub.next(this.cache);
        sub.complete();
      }, err => {
        alert('Errore caricamento tabelle generali');
      });
    });
    return obs;
  }

  loadTipoOrdineJson(): Observable<any> {
    const url = this.domain + '/allTipoOrdine';
    return this.http.get(url);
  }

  loadAziendeJson(): Observable<any> {
    const url = this.domain + '/allAziende';
    return this.http.get(url);
  }
  loadSviluppiTaglieJSon(): Observable<any>{
    const url = this.domain + '/allSviluppiTaglia';
    return this.http.get(url);

  }

  loadTipoPagamentoJson(): Observable<any> {
    const url = this.domain + '/allTipoPagamento';
    return this.http.get(url);
  }

  loadTipoEsenzioneJson(): Observable<any> {
    const url = this.domain + '/allTipoEsenzione';
    return this.http.get(url);
  }

  loadTipoPortoJson(): Observable<any> {
    const url = this.domain + '/allPorti';
    return this.http.get(url);
  }

  loadTipoAziendeJson(): Observable<any> {
    const url = this.domain + '/allTipiAzienda';
    return this.http.get(url);
  }


  //l'ho aggiunto io

  loadprovinceJson(): Observable<any>{
    const url ='../assets/data/province.json';
    return this.http.get(url);
  }

  loadArticoliJson():Observable<any>{
    const url ='../assets/data/articoli.json';
    return this.http.get(url);
  }

 
  createTipoAzienda(): ListItemStringDto[] {
    const list = [];

    const item1 = {
      value: 'C',
      text: 'Cliente'
    };
    list.push(item1);
    const item2 = {
      value: 'T',
      text: 'Terzista'
    };
    list.push(item2);
    const item3 = {
      value: 'F',
      text: 'Fornitore'
    };
    list.push(item3);

    return list;
  }

  createTipoFattura(): ListItemStringDto[] {
    const list = [];

    const item1 = {
      value: '10',
      text: 'Nota di credito'
    };
    list.push(item1);
    const item2 = {
      value: '2',
      text: 'Fattuta tipo 2'
    };
    list.push(item2);
    const item3 = {
      value: '3',
      text: 'Fattuta tipo 3'
    };
    list.push(item3);

    return list;
  }
}
