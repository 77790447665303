import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { ArticoloDistintaBase, ComponentiDistintaBase, DistintaBase, Macrofasi } from "src/app/_models/articoli/articoliDto";

export class DistintaBaseMapper implements IDTOMapper<DistintaBase> {
  macrofasiMapper: MacrofasiMapper = new MacrofasiMapper();
  componentiDIBAMapper: ComponentiDistintaBaseMapper =
    new ComponentiDistintaBaseMapper();
  articoloMapper: ArticoloDIBAMapper = new ArticoloDIBAMapper();

  map(data: any): DistintaBase {
    let item: DistintaBase = new DistintaBase();

    (item.articolo = data.articolo.map((d) => this.articoloMapper.map(d))),
      (item.componenti = [
        ...data.componenti.map((d) => this.componentiDIBAMapper.map(d)),
      ]);
    item.macrofasi = [
      ...data.macrofasi.map((d) => this.macrofasiMapper.map(d)),
    ];

    return item;
  }
}

export class ComponentiDistintaBaseMapper
  implements IDTOMapper<ComponentiDistintaBase>
{
  map(data: any): ComponentiDistintaBase {
    let item = new ComponentiDistintaBase();

    item.codice = data.codice;
    item.descrizione = data.descrizione;
    item.codiceColore = data.codiceColore;
    item.descrizioneColore = data.descrizioneColore;
    item.codiceFamiglia = data.codiceFamiglia;
    item.famiglia = data.famiglia;
    item.extCode = data.extCode;
    item.groupingCode = data.groupingCode;
    item.flagTaglie = data.flagTaglie;
    item.timestampModifica = data.timestampModifica;
    item.prezzo = data.prezzo;
    item.codiceFornitore = data.codiceFornitore;
    item.unMisura = data.unMisura;
    item.iva = data.iva;
    item.codiceLavorazione = data.codiceLavorazione;
    item.descrizioneLavorazione = data.descrizioneLavorazione;
    item.extCodeFase = data.extCodeFase;
    item.extCodeDescrizioneFase = data.extCodeDescrizioneFase;
    item.qtConsumi = data.qtConsumi;
    item.orderConsumi = data.orderConsumi;
    item.noteConsumi = data.noteConsumi;
    item.idPosizioneFase = data.idPosizioneFase;
    item.labelPosizioneFase = data.labelPosizioneFase;
    item.divisore = data.divisore;
    item.codiceGruppoMerceologico = data.codiceGruppoMerceologico;
    item.descrizioneGruppoMerceologico = data.descrizioneGruppoMerceologico;
    item.idScalatura = data.idScalatura;
    item.codFornitore = data.codFornitore;
    item.descrizioneFornitore = data.descrizioneFornitore;

    return item;
  }
}

export class MacrofasiMapper implements IDTOMapper<Macrofasi> {
  map(data: any): Macrofasi {
    let macrofase: Macrofasi = new Macrofasi();

    macrofase.idMacrofase = data.idMacrofase;
    macrofase.descrizioneMacrofase = data.descrizioneMacrofase;

    return macrofase;
  }
}

export class ArticoloDIBAMapper implements IDTOMapper<ArticoloDistintaBase> {
  map(data: any): ArticoloDistintaBase {
    let articolo: ArticoloDistintaBase = new ArticoloDistintaBase();

    articolo.idArticolo = data.idArticolo;
    articolo.modello = data.modello;
    articolo.parte = data.parte;
    articolo.colore = data.colore;
    articolo.descrizione = data.descrizione;
    articolo.descrizioneMateriale = data.descrizioneMateriale;
    articolo.coloreMateriale = data.coloreMateriale;
    articolo.extCode = data.extCode;
    articolo.linea = data.linea;
    articolo.stagione = data.stagione;
    articolo.codiceFornitore = data.codiceFornitore;
    articolo.image = data.image;

    return articolo;
  }
}

