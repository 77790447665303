import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { UserDto } from "src/app/_models/utenti/usersDto";

export class UserMapper implements IDTOMapper<UserDto>{
    map(data: any): UserDto {
      
      let item:UserDto = new UserDto();
  
      item.userID = data.userID;
      item.username = data.username;
      item.password = data.password;
      item.validUntil = data.validUntil;
      return item;
    }
  
  }