export class StatoDto {
    idVariazione:number;
    idOrdine:number;
    idStatoOrdine:number;
    descrizione:string;
    tsVariazione:string;
    extCode:string;
    validUntil:string;


}

