export class OrdiniAcquistoReportDto {
  righeOrdini: RigheOrdiniReport[];
}

export class RigheOrdiniReport {
  idRiga: number = 0;
  idOrdine: number = 0;
  codiceFornitore: string = '';
  descrizioneFornitore: string = '';
  dataConsegna: string = '';
  codiceComponente: string = '';
  descrizioneComponente: string = '';
  unMis: string = '';
  quantitaCalcolata: number = 0;
  lotto: number = 0;
  stagione: string = '';
  tags: {};
  extCode: string = '';
  validUntil: string = '';
  prezzo: string = '';
  note: string = '';
  codSviluppo: string = '';
  codScalatura: string = '';
  qtPerTaglia: number[] = [];
  coloreComponente: string = '';
  stato: string = '';
  tagLancio: string[] = [];
  codiceComponenteCliente: string = '';
  quantitaMagazzino: number = 0;
  quantitaDaOrdinare: number = 0;
  progressioneSviluppo: string[] = [];
  idScalatura: number = 0;
  progressioneScalatura: string[] = [];
  indirizzo: string;
  cap: string;
  citta: string;
  provincia: string;
  nazione: string;
}
