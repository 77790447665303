import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { TipiOrdineDto } from "../models/tipiordine-dto";

export class TipiOrdineMapper implements IDTOMapper<TipiOrdineDto>{
    map(data: any): TipiOrdineDto {
      
      let item:TipiOrdineDto = new TipiOrdineDto();
  
      item.idTipoOrdine = data.idTipoOrdine;
      item.codTipoOrdine = data.codTipoOrdine;
      item.descTipoOrdine = data.descTipoOrdine;
      item.extCode = data.extCode;
      item.flagTipoOrdine = data.flagTipoOrdine;
      item.extCode = data.extCode;
      item.validUntil = data.validUntil;
  
  
      
      return item;
    }
  
  }