export class State<T>{

    items:T[]=[];

    paginatedItems:T[]=[];

    currentSize:number;

    totalSize:number;

    getItem(index:number){

        return this.items[index];
    }
}