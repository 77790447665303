export class LancioDto{
    idLancio:number;
    tagLancio:string;
    descrizioneTagLancio:string;
    dtCreazioneCp:string;
    extCode:string;
    validUntil:Date;
}

export class UltimoLancio {
    idLancio:number;
    dataLancio:string;
}