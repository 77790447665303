import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Observable, OperatorFunction, Subscription } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { AutocompleteListItem } from '../../model/autocomplete-list-item';

@Component({
  selector: 'app-autocomplate-base',
  templateUrl: './autocomplate-base.component.html',
  styleUrls: ['./autocomplate-base.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class AutocomolateBaseComponent implements OnInit, OnDestroy {
  @Input() name = '';
  @Input() placeholder = '';
  @Input() display = '';
  @Input() editable = true;
  @Input() minlenght = 3;
  @Input() service = undefined;
  @Input() searchmethod = undefined;
  @Input() decodemethod = undefined;
  @Input() selected: any = '';
  @Output() selectedtext = new EventEmitter<AutocompleteListItem>();
  @Output() startstopautocomplete = new EventEmitter<boolean>();
  sub: Subscription;

  constructor() { }

  ngOnInit(): void {

  }

  search: OperatorFunction<string, readonly any[]> = (text$: Observable<any>) =>
    text$.pipe(
      filter(text => text.trim().length >= 3),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(text => this.searchAddress(text))
    )

  searchAddress(text): Observable<any> {
    const obs = new Observable<boolean>(sub => {
      this.startstopautocomplete.emit(true);
      const result = this.service[this.searchmethod](text);
      this.sub = (result as Observable<object>).subscribe(response => {
        const list = this.service[this.decodemethod](response);
        this.resetSelectedWhenUneditable();
        sub.next(list);
        sub.complete();
      });
    });
    return obs;
  }

  formatterInput(result: any): string {
    if (result.value && result.text) {
      return result.value + ') ' + result.text;
    }
    return result;
  }

  formatterResult(result: any): string {
    if (result.value && result.text) {
      return result.value + ') ' + result.text;
    }
    return result;
  }

  selectedItem(value: any): void {
    const args = (value && value.item) ? value.item : value;
    this.selectedtext.emit(args);
  }

  resetSelectedWhenUneditable(): void {
    if (this.editable === false && !this.selected) {
      this.selectedItem(undefined);
      return;
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}


