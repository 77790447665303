import { Component, EventEmitter, Input, Output  } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-input-field-textarea',
  templateUrl: './input-field-textarea.component.html',
  styleUrls: ['./input-field-textarea.component.css']
})
export class InputFieldTextareaComponent{
  @Input() type = 'text';
  @Input() display = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() readonly = false;
  @Input() maxlength = 99999;
  @Input() errorRequired = '  *';
  @Input() errorMaxlength = 'campo lunghezza massima';
  @Input() value = '';

  @Output() valueChange = new EventEmitter<string>();

 
  
  constructor() { }

  change(event){

    this.valueChange.emit(this.value);

  }

}
