import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Form, NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImballiDto } from "src/app/featureImballi/models/imballi-dto";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { AlertService } from "src/app/_services/alert.service";
import { map } from "rxjs/operators";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { Globals } from "src/app/_models/Globals";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { ErroriService } from "src/app/_services/errori-service.service";


@Component({
  selector: "app-imballi-edit",
  templateUrl: "./imballi-edit.component.html",
  styleUrls: ["./imballi-edit.component.css"],
})
export class ImballiEditComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() confirm = new EventEmitter<ImballiDto>();
  @ViewChild("fp") form!: NgForm;
  typeop = "";
  alltype: AllComboboxDataDto;
  delete: boolean = false;


  public get codice(): string {
    return this.currentItem.codice;
  }
  public set codice(v: string) {
    this.currentItem.codice = v;
  }

  public get descrizione(): string {
    return this.currentItem.descrizione;
  }
  public set descrizione(v: string) {
    this.currentItem.descrizione = v;
  }

  
  public get misura() : string {
    return this.currentItem.misura;
  }
  public set misura(v : string) {
    this.currentItem.misura = v;
  }
  

  public get altezza(): number {
    return this.currentItem.altezza;
  }
  public set altezza(v: number) {

    this.currentItem.altezza = v;

    this.ricalcolaMisura();
    this.ricalcolaVolumi();
  }

  public get larghezza(): number {
    return this.currentItem.larghezza;
  }
  public set larghezza(v: number) {
    this.currentItem.larghezza = v;

    this.ricalcolaMisura();
    this.ricalcolaVolumi();
  }

  public get lunghezza(): number {
    return this.currentItem.lunghezza;
  }
  public set lunghezza(v: number) {
    this.currentItem.lunghezza = v;

    this.ricalcolaMisura();
    this.ricalcolaVolumi();
  }

  public get contenutoScatole() : number {
    return this.currentItem.contenutoScatole;
  }
  public set contenutoScatole(v : number) {
    this.currentItem.contenutoScatole = v;
  }

  
  
  public get pesoLordo() : number {
    return this.currentItem.pesoLordo;
  }
  public set pesoLordo(v : number) {
    this.currentItem.pesoLordo = v;
  }

  
  
  public get pesoNetto() : number {
    return this.currentItem.pesoNetto;
  }
  public set pesoNetto(v : number) {
    this.currentItem.pesoNetto = v;
  }
  

  
  
  public get volumeCm() : number {
    return this.currentItem.volumecm;
  }
  public set volumeCm(v : number) {
    this.currentItem.volumecm = v;
  }

  public get volumeM() : number {
    return this.currentItem.volumem;
  }
  public set volumeM(v : number) {
    this.currentItem.volumem = v;
  }
  
  ricalcolaMisura(){

/*     let lung= this.lunghezza.toFixed(2)
    let larg = this.larghezza.toFixed(2)
    let alt = this.altezza.toFixed(2) */

    this.currentItem.misura = `${Math.floor(this.lunghezza)}X${Math.floor(this.larghezza)}X${Math.floor(this.altezza)}`;

   // this.currentItem.misura = `${lung}X${larg}X${alt}`
  }



  ricalcolaVolumi(){

    this.currentItem.volumecm = parseFloat((this.larghezza*this.lunghezza*this.altezza).toFixed(2));

    this.currentItem.volumem = parseFloat((this.currentItem.volumecm / 1000000).toFixed(3));
  }
  
  
  currentItem: ImballiDto;
  

  constructor(
    public activeModal: NgbActiveModal,
    private alertService:AlertService,
    private modalservice: NgbModal,
    private errorSrv: ErroriService
    ) {}

  ngOnInit(): void {
    console.log("imballo", this.currentItem)
   
  }

  ngAfterViewInit() {
    //   this.calcolo(this.form);
  }

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      //alert("Dati mancanti!");
      this.onClose();

      return;
    }
 
    console.log(this.currentItem);
    this.currentItem.codice= this.currentItem.codice.toUpperCase()

    this.confirm.emit(this.currentItem);
  }



  error(err: any): void {
    console.log("errore", err);
    let errore: CindyCustomResponse = err.error;
    //alert('Si è verificato un errore nella scrittura dei dati!');
    /*  const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito; */
    // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati;
    if (errore.codEsito == codiciErrore.codiceDuplicato) {
      this.errorSrv.onToastTestata(
        Globals.MappaMessaggiErrore.get(err.error.codEsito)
      );
    } else {
      this.errorSrv.onToastTestata(
        `${Globals.MappaMessaggiErrore.get(
          codiciErrore.erroreResiduale
        )}. ${Globals.MappaMessaggiErrore.get("KO.GENERICO_dettaglio")}`
      );
    }
    this.onClose();
  } 
  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new ImballiDto();

          this.delete = true;
          this.typeop='del'
          console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
   
        },
        (err) => {
          modalRef.componentInstance.onClose();
 
        }
      );


  }

  ngOnDestroy() {}
}
