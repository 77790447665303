import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[validDec][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: DecValidatorDirective, multi: true }
    // { provide: NG_VALIDATORS, useExisting: forwardRef(() => DecValidatorDirective), multi: true }
  ]
})
export class DecValidatorDirective implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    // const regexp = '^[0-9]+$';
    const regexp = '\^[0-9]+(\.[0-9]{1,2})?$';
    if (c.value) {
      const v = new String(c.value);
      console.log("regex",!v.match(regexp))
      console.log("regex",v.split('.')[0].length)
      let valido = (v.split('.')[0].length >2&&v.split(',')[0].length >2)

      if (!v.match(regexp)||valido) {
        return { 'invalidDec': true };
      }
    }
    return null;
  }
}
