import { State } from "src/app/_models/State";
import { FileOrdiniImportazioni } from "src/app/_models/fileOrdini/fileOrdinImportazioni";
import { UserDto } from "src/app/_models/utenti/usersDto";

export class ImportazioniState extends State<FileOrdiniImportazioni>{

   listaUsers:UserDto[]=[];

}

