import { IDTOMapper } from "src/app/_helpers/IDTOMapper";


import { Utility } from "src/app/_zCore/utility";
import { Lancio, LancioProdDto, OrdiniPerLancio, QuantitaPerTaglia } from "../models/lancioProdDto";

export class LancioProdMapper implements IDTOMapper<LancioProdDto>{
    lancioMapper:LancioMapper=new LancioMapper()
    
    map(data: any): LancioProdDto {
      
      let item:LancioProdDto = new LancioProdDto();
  
      item.idLancio=data.idLancio;
      item.tagLancio = data.tagLancio;
      item.lancio = this.lancioMapper.map(data.lancio);
      item.extCode = data.extCode;
      item.validUntil = data.validUntil;
      item.dataLancio = data.dataLancio
  
      
      return item;
    }
  
  }
  
  class LancioMapper implements IDTOMapper<Lancio>{
    ordiniPerLancioMapper:OrdiniPerLancioMapper= new OrdiniPerLancioMapper()
    
    map(data: any): Lancio {
      let item:Lancio = new Lancio();
      item.ordini =[...data.ordini.map((r)=>this.ordiniPerLancioMapper.map(r))] as OrdiniPerLancio[]
      return item;
    }
  
  }
  
  export class OrdiniPerLancioMapper implements IDTOMapper<OrdiniPerLancio>{
   
   qntPerTagliaMapper: QuantitaPerTagliaMapper = new QuantitaPerTagliaMapper()
    
   map(data: any): OrdiniPerLancio {
      
      let item:OrdiniPerLancio = new OrdiniPerLancio();
      item.idOrdine = data.idOrdine;
      item.idRiga = data.idRiga;
      item.idSviluppo = data.idSviluppo;
      console.log(data.qntPerTaglia)
      item.qtPerTaglia =[...data.qtPerTaglia.map((r)=>this.qntPerTagliaMapper.map(r))] as QuantitaPerTaglia[];
      return item;
    }
  
  }
  
  class QuantitaPerTagliaMapper implements IDTOMapper<QuantitaPerTaglia>{
    map(data: any): QuantitaPerTaglia {
      
      let item:QuantitaPerTaglia = new QuantitaPerTaglia();
  
      item.codTaglia = data.codTaglia;
      item.quantita = Utility.toInteger(data.quantita);
      item.sku = data.sku;
      return item;
    }
  
  }