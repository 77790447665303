import { Component, OnInit } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";

import { RecapitiEditComponentComponent } from "../recapiti-edit-component/recapiti-edit-component.component";

import { AlertService } from "src/app/_services/alert.service";
import { RecapitiDto } from "src/app/_models/recapiti/recapiti-dto";
import { ActivatedRoute } from "@angular/router";
import { RecapitiService } from "../../service/recapiti.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { HttpClient } from "@angular/common/http";
import { AndFilter } from "src/app/_filters/AndFilter";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { RecapitiState } from "../../state/recapitiState";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-recapiti-page-list",
  templateUrl: "./recapiti-page-list.component.html",
  styleUrls: ["./recapiti-page-list.component.css"],
})
export class RecapitiPageListComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  //ho aggiunto questo
  listaRagioniSociali: {};
  listaRagioniSocialiAziende: any;

  RecapitiDataSource: RestDataSource;
  state: RecapitiState = new RecapitiState();

  constructor(
    private modalservice: NgbModal,
    private allComboData: ApiAllComboBoxdataService,
    private recapitiService: RecapitiService,
    private alertSrv: AlertService,
    private route: ActivatedRoute, // private location: Location
    private http: HttpClient
  ) {
    this.RecapitiDataSource = new RestDataSource(this.http);

    this.RecapitiDataSource.setVariablesApi(
      "allRecapiti",
      "recapito",
      "recapito",
      `${environment.baseDomain}:${environment.anaPort}`    );
    this.RecapitiDataSource.setByIdField("idRecapito");
    this.recapitiService.connectDataSource(this.RecapitiDataSource);
  }
  _searchTerm: string;
  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this.loadData();
  }

  get searchTerm() {
    return this._searchTerm;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.loadData();
  }
  get pageSize() {
    return this._pageSize;
  }
  set page(newPage: number) {
    this._page = newPage;

    this.loadData();
  }

  get page() {
    return this._page;
  }

  loadData() {
    this.recapitiService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterRecapiti(service);

        console.log(this._page + " " + this._pageSize);
      },
    });
  }

  filterRecapiti(service: RecapitiService) {
    console.log(this._searchTerm);

    this.state = service
      .filter(
        new AndFilter([
          new FieldsFilter({
            fieldList: ["nomeRecapito", "tipoRecapito"],
            searchString: this._searchTerm == undefined ? "" : this._searchTerm,
          }),
          //   new TipiPagamentoiFilter(null),
        ])
      )
      .sort(this._sortColumn, this._sortDirection)
      .paginate(this._page, this.pageSize)
      .state();
  }

  ngOnInit(): void {
    this._sortColumn = "idRecapito";
    this._sortDirection = "desc";
    this._rotateDirection = "desc";
    this.loadData();
  }

  getIndex(id) {
    const item = this.state.items.find((re) => re.idRecapito == id);
    return this.state.items.indexOf(item);
  }

  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");

    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.filterRecapiti(this.recapitiService);
  }

  /* 
  onRecapitoCreate(): void {
    const modalRef = this.modalservice.open(RecapitiEditComponentComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.currentItem = new RecapitiDto();
    modalRef.componentInstance.alltype = this.alltype;
    modalRef.componentInstance.typeop = "ins";
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) =>
          this.recapitiService.insertData(data as RecapitiDto)
        )
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";

          this.reloadData();
          this.alertSrv.success(Globals.printCreateMessageSuccess("Recapito"));
        },
        (err) => {
          modalRef.componentInstance.error(err);
          this.alertSrv.error(Globals.printCreateMessageFailure("Recapito"));
        }
      );
  }

  onRecapitoModify(index): void {
    const item = this.datasource.getItems()[index];
    const modalRef = this.modalservice.open(RecapitiEditComponentComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    modalRef.componentInstance.typeop = "upd";
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          // console.log(data);
          return this.recapitiService.updateData(data as RecapitiDto);
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";

          this.reloadData();
          this.alertSrv.success(Globals.printModifyMessageSuccess("Recapito"));
        },
        (err) => {
          modalRef.componentInstance.error(err);
          this.alertSrv.error(Globals.printModifyMessageFailure("Recapito"));
        }
      );
  }

  onRecapitoDelete(index): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.datasource.getItems()[index];
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          return this.recapitiService.deleteData(item.idRecapito, "IDrecapito");
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
          this.reloadData();
          this.alertSrv.success(Globals.printDeleteMessageSuccess("Recapito"));
        },
        (err) => {
          modalRef.componentInstance.error(err);
          this.alertSrv.success(Globals.printDeleteMessageFailure("Recapito"));
          console.log(err);
        }
      );
  }
 */

  onRecapitoCreateOrModify(index: number): void {
    let modalRef: any = null;

    modalRef = this.modalservice.open(RecapitiEditComponentComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });

    let recapito: RecapitiDto = new RecapitiDto();
    let typeop = "ins";

    if (index >= 0) {
      recapito = this.state.paginatedItems[index];

      typeop = "upd";
    }

    modalRef.componentInstance.currentItem = recapito;
    modalRef.componentInstance.nazioni = this.state.listaNazioni;
    modalRef.componentInstance.province = this.state.listaProvince;

    modalRef.componentInstance.typeop = typeop;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log(data);

          if (typeop == "ins") {
            return this.recapitiService.create(data as RecapitiDto);
          } else if (typeop == "upd") {
            if (modalRef.componentInstance.delete == false)
              return this.recapitiService.update(data as RecapitiDto);
            else {
              typeop = "del";

              return this.recapitiService.delete(recapito.idRecapito);
            }
          }
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
          this.searchTerm = "";

          this.loadData();

          if (typeop == "ins")
            this.alertSrv.success(
              Globals.printCreateMessageSuccess("Il Recapito")
            );
          else if (typeop == "upd") {
            this.alertSrv.success(
              Globals.printModifyMessageSuccess("Il Recapito")
            );
          } else {
            this.alertSrv.success(
              Globals.printDeleteMessageSuccess("Il Recapito")
            );
          }
        },
        (err) => {
          modalRef.componentInstance.error(err);

          if (typeop == "ins")
            this.alertSrv.success(
              Globals.printCreateMessageFailure("il Recapito")
            );
          else if (typeop == "upd") {
            this.alertSrv.success(
              Globals.printModifyMessageFailure("il Recapito")
            );
          } else {
            this.alertSrv.success(
              Globals.printDeleteMessageFailure("il Recapito")
            );
          }
        }
      );
  }

  prepareDettagli(recapito: RecapitiDto): any {
    let detRec = recapito.dettagliRecapito;

    let tipo = recapito.tipoRecapito.toLowerCase();
    if (tipo == "telefono" || tipo == "email") return detRec.valore;

    let indirizzo = "";

    indirizzo = `${detRec.via}, ${detRec.numero} - ${detRec.cap}, ${detRec.localita} (${detRec.provincia})`;

    return indirizzo;
  }
}
