import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { ItemFilter } from "src/app/_filters/ItemFilter";
import { AService } from "src/app/_services/AService";
import { OrdiniAcquistoReportDto, RigheOrdiniReport } from "src/app/_models/ordiniAcquisto/ordiniAcquistoReport";
import { State } from "src/app/_models/State";
import { ordiniAcquistoDto } from "src/app/_models/ordiniAcquisto/ordiniAcquistoDto";

class OdaReportMapper implements IDTOMapper<OrdiniAcquistoReportDto> {
  righeOdaMapper = new RigheOrdiniReportMapper();
  map(data: any): OrdiniAcquistoReportDto {
    let item: OrdiniAcquistoReportDto = new OrdiniAcquistoReportDto();
    item.righeOrdini = [
      ...data.righeOrdini.map((t) => this.righeOdaMapper.map(t)),
    ];

    return item;
  }
}


class RigheOrdiniReportMapper implements IDTOMapper<RigheOrdiniReport> {

  map(data: any): RigheOrdiniReport {
    let item: RigheOrdiniReport =
      new RigheOrdiniReport();

    item.idRiga = data.idRiga;
    item.idOrdine = data.idOrdine;
    item.codiceFornitore = data.codiceFornitore;
    item.descrizioneFornitore = data.descrizioneFornitore;
    item.dataConsegna = data.dataConsegna;
    item.codiceComponente = data.codiceComponente;
    item.descrizioneComponente = data.descrizioneComponente;
    item.unMis = data.unMis;
    item.quantitaCalcolata = data.quantitaCalcolata;
    item.lotto = data.lotto;
    item.tags = data.tags;
    item.stagione = data.stagione;
    item.extCode = data.extCode;
    item.validUntil = data.validUntil;
    item.prezzo = data.prezzo;
    item.note = data.note;
    item.codSviluppo = data.codSviluppo;
    item.codScalatura = data.codScalatura;
    item.qtPerTaglia = data.qtPerTaglia;
    item.coloreComponente = data.coloreComponente;
    item.stato = data.stato;
    item.tagLancio = data.tagLancio;
    item.codiceComponenteCliente = data.codiceComponenteCliente;
    item.quantitaMagazzino = data.quantitaMagazzino;
    item.quantitaDaOrdinare = data.quantitaDaOrdinare;
    item.progressioneSviluppo = data.progressioneSviluppo;
    item.idScalatura = data.idScalatura;
    item.progressioneScalatura = data.progressioneScalatura;
    item.indirizzo = data.indirizzo;
    item.cap = data.cap;
    item.citta = data.citta;
    item.provincia = data.provincia;
    item.nazione = data.nazione;

 

    return item;

  }
}

@Injectable({
  providedIn: 'root'
})
export class ODAReportService extends AService<OrdiniAcquistoReportDto>{

  state(): State<OrdiniAcquistoReportDto> {
    let currentState: State<OrdiniAcquistoReportDto> = new State<OrdiniAcquistoReportDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;

    return currentState;
  }

  mapper: IDTOMapper<OrdiniAcquistoReportDto> = new OdaReportMapper();}
