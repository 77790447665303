import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[validstr][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: StrValidatorDirective, multi: true }
    // { provide: NG_VALIDATORS, useExisting: forwardRef(() => DecValidatorDirective), multi: true }
  ]
})

export class StrValidatorDirective implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    // const regexp = '^[0-9]+$';
    const regexp = '[^a-zA-Z0-9 ]+';
    if (c.value) {
      const v = new String(c.value);
      let risultato = v.match(regexp);
      if (v.match(regexp)) {
        
        return { 'invalidstr': true };
      }
    }
    return null;
  }
}
