
export class FasiLavorazioneDto {
  idFaseLavorazione: number=0;
  codFaseLavorazione: number=0;
  descFaseLavorazione: string='';
  extCode: string='';
  validUntil: string='';
  codAvanzamentoLavorazione:number=0;
  codOperazioneLavorazione:number=0;
}
