export class TipiOrdineDto {
    idTipoOrdine: number;
    codTipoOrdine: string;
    descTipoOrdine: string;
    flagTipoOrdine:string;
    extCode: string;
    validUntil: string;
  }
  


  