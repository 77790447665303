import { Injectable } from "@angular/core";
import { CrudService } from "src/app/_services/crud.service";
import { AziendeDto } from "../models/aziende-dto";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AziendeMapper } from "../mappers/aziendeMapper";
import { Observable } from "rxjs";
import { AlertService } from "src/app/_services/alert.service";

@Injectable({
  providedIn: "root",
})
export class AziendeCRUDService extends CrudService<AziendeDto> {
  constructor(httpClient: HttpClient, public alertSrv: AlertService) {
    super(httpClient, alertSrv);
    this.serverPort = environment.anaPort;
    this.endpoint = "azienda";
    this.endpointAll = "allAziende";
    this.mapper = new AziendeMapper();
  }

  getAziendeByUser(user): Observable<AziendeDto[]> {
    this.endpointAll = "aziendeByUser";
    return this.getAll([{ variable: "userId", value: user.userID }]);
  }

  utente = JSON.parse(localStorage.getItem("userData"));
  registraIstanza(idAzienda) {
    let url = `${environment.baseDomain}:${this.serverPort}/istanzaAzienda`;
    console.log("url previsione Produzione", url);
    let body = {
      userId: this.utente.userID,
      idAzienda: idAzienda,
    };
    return this.httpClient.post(url, body);
  }
}
