import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { RecapitiService } from "src/app/featureRecapiti/service/recapiti.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { Nazioni } from "src/app/_models/localizzazioni/nazioni";
import { Province } from "src/app/_models/localizzazioni/province";
import { PortiDto } from "src/app/_models/porti/porti-dto";
import { RecapitiDto } from "src/app/_models/recapiti/recapiti-dto";
import { State } from "src/app/_models/State";
import { TipiEsenzioneDto } from "src/app/_models/tipiesenzione/tipiesenzione-dto";
import { TipoAziendaDto } from "src/app/_models/tipoAzienda/tipoAziendaDto";
import { AService } from "src/app/_services/AService";
import {
  NazioniService,
  ProvinceService,
} from "src/app/_services/global List Services/GlobalList.service";
import { OrdiniProduzioneService } from "src/app/featureOrdini/service/ordini-produzione.service";
import { environment } from "src/environments/environment";
import { ErroriService } from "src/app/_services/errori-service.service";
import { Globals } from "src/app/_models/Globals";
import { codiciErrore } from "src/assets/data/codiciErrore";


export class AziendeMapper implements IDTOMapper<AziendeDto> {
  map(data: any): AziendeDto {
    let item: AziendeDto = new AziendeDto();

    item.idAzienda = data.idAzienda;
    //item.codiceAzienda = data.codiceAzienda;
    item.tipoAzienda = data.tipoAzienda;
    item.ragioneSociale = data.ragioneSociale;
    item.indirizzo = data.indirizzo;
    item.cap = data.cap;
    item.citta = data.citta;
    item.provincia = data.provincia;
    item.nazione = data.nazione;
    item.codiceFiscale = data.codiceFiscale;
    item.partitaIva = data.partitaIva;
    item.partitaIvaEstero = data.partitaIvaEstero;
    item.banca = data.banca;
    item.iban = data.iban;
    item.swift = data.swift;
    item.codTipoFattura = data.codTipoFattura;
    item.descTipoFattura = data.descTipoFattura;
    item.numProtocolloLettereIntento = data.numProtocolloLettereIntento;
    item.numDichiarazioneEsportazione = data.numDichiarazioneEsportazione;
    item.dataDichiarazioneEsportazione = data.dataDichiarazioneEsportazione;
    item.nsNumeroProtocollo = data.nsNumeroProtocollo;
    item.dataProtocollo = data.dataProtocollo;
    item.sconto = data.sconto;
    item.raggruppamentoDDT = data.raggruppamentoDDT;
    item.assicurazioneMerceCliente = data.assicurazioneMerceCliente;
    item.personaFisica = data.personaFisica;
    item.bolliSuFattura = data.bolliSuFattura;
    item.notaFineFattura = data.notaFineFattura;
    item.agente = data.agente;
    item.agenteDesc = data.agenteDesc;
    item.capoZona = data.capoZona;
    item.capoZonaDesc = data.capoZonaDesc;
    item.spedizioniere = data.spedizioniere;
    item.spedizioniereDesc = data.spedizioniereDesc;
    item.idPortoCollegato = data.idPortoCollegato;
    item.idEsenzioneIvaCollegata = data.idEsenzioneIvaCollegata;
    item.idPagamentoCollegato = data.idPagamentoCollegato;
    item.zonaProvvigione = data.zonaProvvigione;
    item.percentualeProvvigioneAgente = data.percentualeProvvigioneAgente;
    item.percentualeProvvigioneCapozona = data.percentualeProvvigioneCapozona;
    item.extCode = data.extCode;
    item.validUntil = data.validUntil;
    item.codiceSDI = data.codiceSDI;
    item.pec = data.pec;
    item.notaFatturazioneElettronica = data.notaFatturazioneElettronica;
    item.codiceRea = data.codiceRea;

    return item;
  }
}

class RecapitiMapper implements IDTOMapper<RecapitiDto> {
  map(data: any): RecapitiDto {
    let item: RecapitiDto = new RecapitiDto();

    item.dettagliRecapito = data.dettagliRecapito;
    item.extCode = data.extCode;
    item.idAzienda = data.idAzienda;
    item.idRecapito = data.idRecapito;
    item.nomeRecapito = data.nomeRecapito;
    item.tipoRecapito = data.tipoRecapito;
    item.validUntil = data.validUntil;

    return item;
  }
}

export class AziendeState extends State<AziendeDto> {
  recapiti: RecapitiDto[] = [];
  listaTipiAzienda: TipoAziendaDto[] = [];
  listaTipoEsenzione: TipiEsenzioneDto[] = [];
  listaAziende: AziendeDto[] = [];
  listaPorti: PortiDto[];
  listaNazioni:Nazioni[];
  listaProvince:Province[];


  getRecapitiByIdAzienda(id: number):RecapitiDto[] {
    if (id <= 0 || id == null) {
      return (this.recapiti = []);
    }

    let filteredRecapiti = this.recapiti.filter((r) => (r.idAzienda == id));
    return filteredRecapiti
  }
}

@Injectable({
  providedIn: "root",
})
export class AziendeServiceNew extends AService<AziendeDto> {
  recapiti: RecapitiDto[] = [];

  listaTipiAziende: TipoAziendaDto[] = [];

  listaTipiPorto: PortiDto[] = [];

  listaTipoEsenzione: TipiEsenzioneDto[] = [];

  recapitiService: RecapitiService;

  listaProvince:Province[]=[];
  listaNazioni:Nazioni[]=[];

  domain: string = `${environment.baseDomain}:${environment.anaPort}`;

  constructor(public httpClient: HttpClient,
    private errorSrv:ErroriService) {
    super();

    this.recapitiService = new RecapitiService(
      new NazioniService(),
      new ProvinceService(),
      httpClient
    );

    let RecapitiDataSource = new RestDataSource(httpClient);

    RecapitiDataSource.setVariablesApi(
      "allRecapiti",
      "recapito",
      "recapito",
      `${environment.baseDomain}:${environment.anaPort}`
    );
    RecapitiDataSource.setByIdField("idRecapito");

    this.recapitiService.connectDataSource(RecapitiDataSource);

  
 
  }

  getAllListe():Observable<this> {

    const obs = new Observable<this>(sub => {

      forkJoin({
        aziendeService:super.loadState(),
 
         recapitiService: this.recapitiService.loadState(),
 
         listaTipiAziende: this.loadTipoAziendeJson(),
 
         listaTipiPorto: this.loadTipoPortoJson(),
 
         listaTipoEsenzione: this.loadTipoEsenzioneJson(),
         listaNazioni:this.loadNazioniJson(),
         listaProvince:this.loadProvinceJson(),
       }).subscribe({
           next: res=>{
 
             this.recapiti = res.recapitiService.state().items
             this.listaTipiAziende = JSON.parse(res.listaTipiAziende.dati)
             this.listaTipiPorto = JSON.parse(res.listaTipiPorto.dati)
             this.listaTipoEsenzione = JSON.parse(res.listaTipoEsenzione.dati)
             this.listaNazioni = res.listaNazioni;
             this.listaProvince = res.listaProvince;
 
             sub.next(res.aziendeService)
             
           },
           error: err =>{ 
            console.log("errore forkjoin", err)
            this.errorSrv.onToast(`${Globals.MappaMessaggiErrore.get(
            codiciErrore.erroreResiduale
          )}. ${Globals.MappaMessaggiErrore.get("KO.GENERICO_dettaglio")}`)
        }
     })

    });
    
    
      
    return obs;
      
  }

  override loadState(): Observable<this> {

    return this.getAllListe();

  }

  loadTipoAziendeJson(): Observable<any> {
    const url = this.domain + "/allTipiAzienda";
    return this.httpClient.get(url);
  }

  loadProvinceJson(): Observable<any>{
    const url ='../../../assets/data/province.json';
    return this.httpClient.get(url);
  }

  loadNazioniJson(): Observable<any>{
    const url ='../../../assets/data/nazioni.json';
    return this.httpClient.get(url);
  }


  loadTipoEsenzioneJson(): Observable<any> {
    const url = this.domain + "/allTipoEsenzione";
    return this.httpClient.get(url);
  }

  loadTipoPortoJson(): Observable<any> {
    const url = this.domain + "/allPorti";
    return this.httpClient.get(url);
  }

  state(): AziendeState {
    let currentState: AziendeState = new AziendeState();

    currentState.totalSize = this._items.length;
    currentState.currentSize = this._resultItems.length;
    currentState.items = this._resultItems;
    currentState.recapiti = this.recapiti;
    currentState.listaTipiAzienda = this.listaTipiAziende;
    currentState.listaTipoEsenzione = this.listaTipoEsenzione;
    currentState.listaAziende = this._items;
    currentState.listaPorti = this.listaTipiPorto;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
  }

  mapper: IDTOMapper<AziendeDto> = new AziendeMapper();
}
