import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';

import { OrdiniPageListComponent } from './component/ordini-page-list/ordini-page-list.component';
import { OrdiniEditComponent } from './component/ordini-edit/ordini-edit.component';
import { EditRigaOrdineComponent } from './component/edit-riga-ordine/edit-riga-ordine.component';
import { IstanzaLavorazioneEditComponent } from './component/istanza-lavorazione-edit/istanza-lavorazione-edit.component';
import { CalcoloFabbisogniComponent } from './component/calcolo-fabbisogni/calcolo-fabbisogni.component';
import { SearchComponent } from '../_components/search/search.component';
import { NgbdSortableHeader } from '../_directives/sortable.directive';
import { PrevisioneVenditaComponent } from './component/previsione-vendita/previsione-vendita.component';

@NgModule({
    declarations: [
        OrdiniPageListComponent,
        OrdiniEditComponent,
        EditRigaOrdineComponent,
        IstanzaLavorazioneEditComponent,
        CalcoloFabbisogniComponent,
        PrevisioneVenditaComponent,

    ],
  
    imports: [
      NgbModule,
      SharedModule,
      BlockUIModule
    ]
  })
  export class FeatureOrdiniModule { }
  