import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDTOMapper } from 'src/app/_helpers/IDTOMapper';
import { ItemFilter } from 'src/app/_filters/ItemFilter';
import { PortiDto } from 'src/app/_models/porti/porti-dto';
import { AService } from 'src/app/_services/AService';
import { State } from 'src/app/_models/State';



class PortiMapper implements IDTOMapper<PortiDto>{
  map(data: any): PortiDto {
    
    let item:PortiDto = new PortiDto();

    item.idPorto = data.idPorto;
    item.codPorto = data.codPorto;
    item.descPorto = data.descPorto;
    item.extCode = data.extCode;
    item.validUntil = data.validUntil;

    
    return item;
  }

}
@Injectable({
  providedIn: 'root'
})
export class PortiService extends AService<PortiDto>{

  state(): State<PortiDto> {
     
    let currentState:State<PortiDto> = new State<PortiDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
   }
  
   mapper:IDTOMapper<PortiDto> = new PortiMapper();
  }
