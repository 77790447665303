import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TipiEsenzioneDto } from "src/app/_models/tipiesenzione/tipiesenzione-dto";
import { AllComboboxDataDto } from "src/app/_models/allcomboboxdata/allcomboboxdata-dto";
import { Utility } from "src/app/_zCore/utility";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { map } from "rxjs/operators";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { ErroriService } from "src/app/_services/errori-service.service";
import { Globals } from "src/app/_models/Globals";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { codiciErrore } from "src/assets/data/codiciErrore";

@Component({
  selector: "app-tipi-esenzione-edit",
  templateUrl: "./tipi-esenzione-edit.component.html",
  styleUrls: ["./tipi-esenzione-edit.component.css"],
})
export class TipiEsenzioneEditComponent implements OnInit, OnDestroy {
  @Output() confirm = new EventEmitter<TipiEsenzioneDto>();
  typeop = "";
  alltype: AllComboboxDataDto;
  currentItem: TipiEsenzioneDto;
  delete: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private modalservice: NgbModal,
    private errorSrv: ErroriService
  ) {}
  ngOnInit(): void {}

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      return;
    }

    const vm = new TipiEsenzioneDto();
    vm.idEsenzione = Utility.toInteger(this.currentItem.idEsenzione);
    vm.codiceTipoEsenzione = Utility.toString(form.value.codiceTipoEsenzione).toUpperCase();
    vm.nomeTipoEsenzione = Utility.toString(form.value.nomeTipoEsenzione);
    vm.descrizioneTipoEsenzione = Utility.toString(
      form.value.descrizioneTipoEsenzione
    );
    vm.regimeTipoEsenzione = Utility.toString(form.value.regimeTipoEsenzione);
    vm.flagEsenzione = true;
    this.confirm.emit(vm);
    console.log(JSON.stringify(vm));
  }

  onDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new TipiEsenzioneDto();

          this.delete = true;
          console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
        },
        (err) => {
          modalRef.componentInstance.onClose();
        }
      );

    /*   const vm = new TipiEsenzioneDto();

    this.delete = true;
    this.confirm.emit(vm);
    console.log(JSON.stringify(vm)); */
  }

  error(err: any): void {
    console.log("errore", err.error);
    let errore: CindyCustomResponse = err.error;
    //alert('Si è verificato un errore nella scrittura dei dati!');
    /*  const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito; */
    // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati;
    if (errore.codEsito == codiciErrore.codiceDuplicato) {
      this.errorSrv.onToastTestata(
        Globals.MappaMessaggiErrore.get(err.error.codEsito)
      );
    } else {
      this.errorSrv.onToastTestata(
        `${Globals.MappaMessaggiErrore.get(
          codiciErrore.erroreResiduale
        )}. ${Globals.MappaMessaggiErrore.get("KO.GENERICO_dettaglio")}`
      );
    }
    this.onClose();
  }

  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  ngOnDestroy() {}
}
