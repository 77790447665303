import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/_services/crud.service';
import { MagazzinoGeneraleDTO } from '../models/magazzinoGeneraleDTO';
import { HttpClient } from "@angular/common/http";
import { AlertService } from 'src/app/_services/alert.service';
import { environment } from 'src/environments/environment';
import { MagazzinoGeneraleMapper } from '../mappers/magazzinoGeneraleMapper';

@Injectable({
  providedIn: 'root'
})
export class MagazzinoGeneraleCRUDService extends CrudService<MagazzinoGeneraleDTO> {

  constructor(
    httpClient: HttpClient,
    public alertSrv: AlertService,
  ) { 
    super(httpClient, alertSrv);
    this.serverPort = environment.ordiniPort;
    this.endpoint = "lancio";
    this.endpointAll = "lanci";
    this.mapper = new MagazzinoGeneraleMapper();
  }
}
