import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { map, switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";

import { ScalatureEditComponent } from "../scalature-edit/scalature-edit.component";

import { AlertService } from "src/app/_services/alert.service";
import { ScalatureDto } from "src/app/featureScalature/models/scalature-dto";

import { ScalatureService } from "../../service/scalature.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { HttpClient } from "@angular/common/http";
import { ScalatureFilterPerCodiceScalatura } from "../../filtri/scalatureFilterPerCodiceScalatura";
import { ScalatureFilterPerSviluppi } from "../../filtri/scalatureFilterPerSviluppi";
import { OrFilter } from "src/app/_filters/OrFilter";
import { ScalatureState } from "../../state/stateScalaturePerSviluppi";
import { environment } from "src/environments/environment";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { Observable, combineLatest } from "rxjs";
import { SviluppiTaglieSearchService } from "src/app/featureSviluppiTaglia/service/sviluppi-taglie-search.service";
import { ScalatureSearchService } from "../../service/scalature-search.service";
import { ScalatureCRUDService } from "../../service/scalature-crud.service";
import {
  NgbdSortableHeader,
  SortColumn,
  SortEvent,
} from "src/app/_directives/sortable.directive";
import { compare } from "src/app/_services/search.service";
import { SviluppiTaglieCRUDService } from "src/app/featureSviluppiTaglia/service/sviluppi-taglie-crud.service";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
import { SearchComponent } from "src/app/_components/search/search.component";
import * as _ from "lodash";
import { PopUpUploadOrdiniComponent } from "src/app/_popups/pop-up-upload-ordini/pop-up-upload-ordini.component";
import { ErroriService } from "src/app/_services/errori-service.service";
import { codiciErrore } from "src/assets/data/codiciErrore";

@Component({
  selector: "app-scalature-page-list",
  templateUrl: "./scalature-page-list.component.html",
  styleUrls: ["./scalature-page-list.component.css"],
})
export class ScalaturePageListComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  scalatureEstese$: Observable<ScalatureDto[]> | undefined =
    this.scalatureSearchSrv.items$;
  total$: Observable<number> | undefined = this.scalatureSearchSrv.total$;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @ViewChild(SearchComponent) searchInput: SearchComponent;

  scalatureDataSource: RestDataSource;
  state: ScalatureState = new ScalatureState();
  sviluppiByUtente$: Observable<SviluppiTaglieDto[]> | undefined = undefined;
  scalatureAbilitateUtente$: Observable<boolean[]> | undefined = undefined;

  constructor(
    private modalservice: NgbModal,
    private allComboData: ApiAllComboBoxdataService,
    private scalatureService: ScalatureService,
    private alertSrv: AlertService,
    private http: HttpClient,
    private scalatureSearchSrv: ScalatureSearchService,
    private scalatureCRUDSrv: ScalatureCRUDService,
    private sviluppiCrudSrv: SviluppiTaglieCRUDService,
    private errorSrv:ErroriService
  ) {
    this.scalatureSearchSrv.sortColumn = "codiceScalatura";
    this.scalatureSearchSrv.sortDirection = "asc";

    this.scalatureSearchSrv.filterItemFunction = this.filter;
    this.scalatureSearchSrv.sortItemFunction = this.sortScalature;
    this.sviluppiByUtente$ = this.scalatureSearchSrv.listaSviluppibyIdUtente$;

    this.scalatureAbilitateUtente$ = combineLatest([
      this.scalatureEstese$,
      this.sviluppiByUtente$,
    ]).pipe(
      map(([scalature, sviluppi]) => {
        let matchArray: boolean[] = [];
        scalature.forEach((sca) => {
          let sv = sviluppi.find((sv) => {
            return (
              sca.relazioni.find(
                (r) => r.idSviluppoTaglia == sv.idSviluppoTaglia
              ) !== undefined
            );
          });

          matchArray.push(sv !== undefined);
        });
        return matchArray;
      })
    );
  }

  ngOnDestroy(): void {}

  onSearchTermChanged(searchTerm: string) {
    this.scalatureSearchSrv.filterItemFunction = this.filter;

    this.scalatureSearchSrv.searchTerms = [searchTerm];
  }

  findSviluppo(idSviluppo) {
    this.sviluppiCrudSrv.getAll().pipe(
      map((res) => {
        let sviluppo = res.find((sv) => sv.idSviluppoTaglia === idSviluppo);
        return sviluppo;
      })
    );
  }

  filter(scalatura: ScalatureDto, searchTerms: string[]): boolean {
    let sviluppi: string[] = [];
    scalatura.relazioni.forEach((relazione) => {
      sviluppi.push(relazione.codiceSviluppoTaglia);
    });
    let hasSviluppo: boolean =
      sviluppi.findIndex((cod) => {
        return cod.toString().toLowerCase().includes(searchTerms[0]);
      }) === -1
        ? false
        : true;

    let match: boolean;
    if (
      hasSviluppo ||
      scalatura.scalatura.codiceScalatura
        .toString()
        .toLowerCase()
        .includes(searchTerms[0]) ||
      searchTerms[0] == ""
    ) {
      match = true;
    } else {
      match = false;
    }
    return match;
  }

  sortScalature(items: ScalatureDto[], column: SortColumn, direction: string) {
    if (direction === "" || column === "") {
      return items;
    } else {
      return [...items].sort((a, b) => {
        let res: number = 0;
        if (column.toLowerCase() == "codiceScalatura") {
          res = compare(
            a.scalatura.codiceScalatura[column],
            b.scalatura.codiceScalatura[column]
          );
        } else {
          res = compare(a.scalatura[column], b.scalatura[column]);
        }
        return direction === "asc" ? res : -res;
      });
    }
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.scalatureSearchSrv.sortColumn = column;
    this.scalatureSearchSrv.sortDirection = direction;
  }

  sviluppiScalatura(scal: ScalatureDto) {
    let sviluppi: string[] = [];
    scal.relazioni.forEach((relazione) => {
      sviluppi.push(relazione.codiceSviluppoTaglia);
    });
    return sviluppi;
  }

  ngOnInit(): void {}

  onScalatureCreateOrModify(
    index: number,
    scal?: ScalatureDto,
    enabled?: boolean
  ): void {
    if (enabled || index == -1) {
      let modalRef: any = null;

      modalRef = this.modalservice.open(ScalatureEditComponent, {
        size: "xl",
        windowClass: "animated FadeIn",
      });

      let scalatura: ScalatureDto = new ScalatureDto();
      let typeop = "ins";

      if (index >= 0) {
        scalatura = scal;

        typeop = "upd";
      }
      modalRef.componentInstance.currentItem = _.cloneDeep(scalatura);
      modalRef.componentInstance.typeop = typeop;
      const sub = modalRef.componentInstance.confirm
        .pipe(
          switchMap((data) => {
            console.log(data);

            if (typeop == "ins") {
              return this.scalatureCRUDSrv.create(data as ScalatureDto);
            } else if (typeop == "upd") {
              if (modalRef.componentInstance.delete == false)
                return this.scalatureCRUDSrv.update(data as ScalatureDto);
              else {
                typeop = "del";

                return this.scalatureCRUDSrv.delete({
                  variable: "idScalatura",
                  value: scalatura.scalatura.idScalatura,
                });
              }
            }
          })
        )
        .subscribe(
          (response: CindyCustomResponse) => {
            console.log("response", response);
            this.scalatureSearchSrv.searchTerms = [""];
            this.searchInput.clear();
            modalRef.componentInstance.onClose();
            if (response.codEsito == "OK") {
              this.onSuccessPrint(typeop);
            } else {
              this.onErrorPrint(typeop);
            }
          },
          (err) => {
            // modalRef.componentInstance.error(err);
            modalRef.componentInstance.onClose();
            console.log("err", err);
            if (err.error.codEsito == "KO.A15") {

              this.errorSrv.onToastTestata(Globals.MappaMessaggiErrore.get(err.error.codEsito))

              /*     const modalRef = this.modalservice.open(
                PopUpUploadOrdiniComponent,
                {
                  size: "md",
                  windowClass: "animated FadeIn",
                }
              );
              modalRef.componentInstance.showButton = true;
              modalRef.componentInstance.progressMessage =
                Globals.MappaMessaggiErrore.get(err.codEsito); */
            } else if (err.error.codEsito == codiciErrore.codiceDuplicato) {
              this.errorSrv.onToastTestata(Globals.MappaMessaggiErrore.get(codiciErrore.codiceDuplicato))
            } else {
              this.onErrorPrint(typeop);
            }
          }
        );
    }
  }

  onErrorPrint(typeop: string) {
    if (typeop == "ins") {
      this.errorSrv.onToastTestata(Globals.printCreateMessageFailure("la Scalatura"));
    } else if (typeop == "upd") {
      this.errorSrv.onToastTestata(Globals.printModifyMessageFailure("la Scalatura"));
    } else {
      this.errorSrv.onToastTestata(Globals.printDeleteMessageFailure("la Scalatura"));
    }
  }

  onSuccessPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.success("La Scalatura è stata creata con successo");
    else if (typeop == "upd") {
      this.alertSrv.success("La Scalatura è stata modificata con successo");
    } else {
      this.alertSrv.success("La Scalatura è stata cancellata con successo");
    }
  }
}
