import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { ItemFilter } from "src/app/_filters/ItemFilter";
import { roleFunction } from "src/app/_models/roleFunction/roleFunction";
import { AService } from "src/app/_services/AService";
import { State } from "src/app/_models/State";

class RoleFunctionsMapper implements IDTOMapper<roleFunction> {
  map(data: any): roleFunction {
    let item: roleFunction = new roleFunction();
    item.idRelazione = data.idRelazione;
    item.roleId = data.roleId;
    item.menu = data.menu;
    item.item = data.item;
    item.validUntil = data.validUntil;

    return item;
  }
}

@Injectable({
  providedIn: "root",
})
export class RoleFunctionsService extends AService<roleFunction> {
  state(): State<roleFunction> {
    let currentState: State<roleFunction> = new State<roleFunction>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
  }

  mapper: IDTOMapper<roleFunction> = new RoleFunctionsMapper();
}
