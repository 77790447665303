export class TipiPagamentoDto {
  idTipoPagamento: number;
  descrizioneTipoPagamento: string;
  codiceTipoPagamento: string;
  // rateizzazione: {};
  rateizzazione: Rateizzazione;
  extJson: {};
  extCode: string;
  validUntil: Date;
}

export class Rateizzazione{
  frequenza:string
}