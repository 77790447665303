import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDTOMapper } from '../_helpers/IDTOMapper';
import { CindyCustomResponse } from '../_models/esitoServer/CindyCustomResponse';
import { Utility } from '../_zCore/utility';
import { Globals } from '../_models/Globals';
import { AlertService } from './alert.service';

export interface GetParam{
  variable:string,
  value:any
}

@Injectable({
  providedIn: 'root'
})
export abstract class CrudService<T> {

  serverPort:number;

  endpoint:string;
  endpointAll:string;

  mapper: IDTOMapper<T>;

  private _params : GetParam[];
  public get params() : GetParam[] {
    return this._params;
  }
  public set params(v : GetParam[]) {
    this._params  = v;
  }

  constructor(public httpClient:HttpClient,
    public alertSrv: AlertService,
    ) { }

  create(item:T ):Observable<T>{

   // console.log("url crud insert", `${environment.baseDomain}:${this.serverPort}/${this.endpoint}`)
   // console.log("body", JSON.stringify({body:item}))
    
 
    return this.httpClient.post(`${environment.baseDomain}:${this.serverPort}/${this.endpoint}`,item)
    .pipe(
      map((res:any)=>{
        return this._mapCindyCustomResponseToItem(res);
      }),
 
    )

  }

  update(item:T ):Observable<T>{

    let url = `${environment.baseDomain}:${this.serverPort}/${this.endpoint}`;

    return this.httpClient.put(url,item)
    .pipe(
      map((res:any)=>{

        return this._mapCindyCustomResponseToItem(res);
      })
    )

  }

  private _mapCindyCustomResponseToItem(res:any){


    if(res instanceof CindyCustomResponse){
      let resItem:any = Utility.fromCindyCustomResponseToDTO(res);

      return resItem;
    }
  return res;

  }



  

  getBy(params:GetParam[] ):Observable<T>{

    let paramString="";
   console.log("url crud getby", `${environment.baseDomain}:${this.serverPort}/${this.endpoint}?${paramString}`)
   // console.log("body", JSON.stringify({body:item}))
    

    params.forEach(p=> paramString+=`${p.variable}=${p.value}`);

    return this.httpClient.get(`${environment.baseDomain}:${this.serverPort}/${this.endpoint}?${paramString}`)
    .pipe(
      tap((res)=> console.log("tap service",res)),
      map((res:CindyCustomResponse)=>{

        return this._mapCindyCustomResponseToItem(res);
      })
    )

  }


  getAll(params?:GetParam[]):Observable<any>{
    let paramString="";

    if(params){
      paramString +="?"
      params.forEach(p=> paramString+=`${p.variable}=${p.value}`);

    }
    return this.httpClient.get(`${environment.baseDomain}:${this.serverPort}/${this.endpointAll}${paramString}`)
    .pipe(
      //tap((res:any)=>console.log("getAllCRUD",JSON.parse(res.dati))),
      map((res:any)=>{
     
        return this._mapCindyCustomResponseToItem(res);
      }),
      catchError(err => {
        console.log('Errore:', err);
        this.alertSrv.error( Globals.MappaMessaggiErrore.get("KO.GENERICO"),Globals.MappaMessaggiErrore.get("KO.GENERICO_dettaglio") )

        return throwError(err);
    })
    )

  }

  delete(param:GetParam):Observable<any>{

   /*  let deleteParam = JSON.parse(
      '{"' + param.variable+ '" : "' + param.value + '"}'
    );

    console.log("deleteParam",deleteParam);

    console.log("this.serverPort",this.serverPort);
    console.log("url",`${environment.baseDomain}:${this.serverPort}/${this.endpoint}`,{body:deleteParam});

    return this.httpClient.delete(`${environment.baseDomain}:${this.serverPort}/${this.endpoint}`,{body:deleteParam}) */
    
    
    
    let paramString="";


    paramString+=`${param.variable}=${param.value}`;
 
    // console.log("deleteParam",paramString);
 
    // console.log("this.serverPort",this.serverPort);
    // console.log("url",`${environment.baseDomain}:${this.serverPort}/${this.endpoint}?${paramString}`);
 
     return this.httpClient.delete(`${environment.baseDomain}:${this.serverPort}/${this.endpoint}?${paramString}`).pipe(
      map((res:any)=>{

        return this._mapCindyCustomResponseToItem(res);
       
      })
    )

  }


}
