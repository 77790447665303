import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PortiDto } from 'src/app/_models/porti/porti-dto';
import { AllComboboxDataDto } from 'src/app/_models/allcomboboxdata/allcomboboxdata-dto';
import { Utility } from 'src/app/_zCore/utility';
import { PopUpDeleteComponent } from 'src/app/_popups/pop-up-delete/pop-up-delete.component';
import { map } from 'rxjs/operators';
import { PopUpIstanzaEsistenteComponent } from 'src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component';


@Component({
  selector: 'app-porti-edit',
  templateUrl: './porti-edit.component.html',
  styleUrls: ['./porti-edit.component.css']
})
export class PortiEditComponent implements OnInit, OnDestroy {
  @Output() confirm = new EventEmitter<PortiDto>();
  
  typeop = '';
  alltype: AllComboboxDataDto;
  currentItem: PortiDto;
  delete: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private modalservice: NgbModal
  ) {}
  ngOnInit(): void {

  };

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert('Dati mancanti!');
      this.onClose();

      return;
    }

    const vm = new PortiDto(); 
    vm.idPorto= Utility.toInteger(this.currentItem.idPorto); 
    vm.codPorto = Utility.toString(form.value.codPorto).toUpperCase(); 
    vm.descPorto = Utility.toString(form.value.descPorto); 
    vm.validUntil=this.currentItem.validUntil;
    this.confirm.emit(vm); 
    console.log(JSON.stringify(vm))
    
  }

  //va cambiato con il popup error
   error(err: any): void {
    console.log("errore",err.error)
    //alert('Si è verificato un errore nella scrittura dei dati!');
    const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
   // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati; 

  } 

  onClose(): void {
    this.activeModal.close('close');
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onDelete(): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          const vm = new PortiDto();

          this.delete = true;
          console.log(JSON.stringify(vm));

          return this.confirm.emit(vm);
        })
      )
      .subscribe(
        (response) => {
          console.log(response)

          modalRef.componentInstance.onClose();         
        },
        (err) => {
          console.log(err)
          modalRef.componentInstance.onClose();
        }
      );


  }




  ngOnDestroy() {

  }
}
