import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, switchMap, tap } from "rxjs";
import { CrudService } from "src/app/_services/crud.service";
import { RigaOrdineDto } from "src/app/featureOrdini/models/rigaOrdineDto";
import { LancioProdDto } from "../models/lancioProdDto";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { LancioProdMapper } from "../mappers/lancioProd.mapper";
import { SviluppiTaglieCRUDService } from "src/app/featureSviluppiTaglia/service/sviluppi-taglie-crud.service";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
import { AlertService } from "src/app/_services/alert.service";

@Injectable({
  providedIn: "root",
})
export class LancioProdCRUDService extends CrudService<LancioProdDto> {
  constructor(
    httpClient: HttpClient,
    public sviluppiCRUD: SviluppiTaglieCRUDService,
    public alertSrv: AlertService,

  ) {
    super(httpClient, alertSrv);
    this.serverPort = environment.ordiniPort;
    this.endpoint = "lancio";
    this.endpointAll = "lanci";
    this.mapper = new LancioProdMapper();
  }

  utente = JSON.parse(localStorage.getItem("userData"));

sviluppi$:Observable<SviluppiTaglieDto[]>=this.sviluppiCRUD.getAll([{variable:"userId",value:this.utente.userID}]).pipe(
  map((res)=>{
    return JSON.parse(res.dati)
  })
)

  loadCodiciSviluppo(righe: Observable<RigaOrdineDto[]>): Observable<string[]> {
    return combineLatest([righe, this.sviluppi$]).pipe(
      tap((res)=> console.log("tap service",res)),

      map(([righe, sviluppi]) => {
        let codici: string[] = [];
        righe.forEach((row) => {
          let sv: SviluppiTaglieDto = sviluppi.find(
            (sv: SviluppiTaglieDto) =>
              row.rigaOrdine.idSviluppo === sv.idSviluppoTaglia
          );
          if (sv !== undefined) codici.push(sv.codiceSviluppoTaglia);
        });
        return codici;
      }),
      tap((res)=> console.log("tap service",res))
    );
  }


}

