import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { MagazzinoGeneraleDTO } from "../models/magazzinoGeneraleDTO";


export class MagazzinoGeneraleMapper implements IDTOMapper<MagazzinoGeneraleDTO>{
    
    map(data: any): MagazzinoGeneraleDTO {
      
      let item:MagazzinoGeneraleDTO = new MagazzinoGeneraleDTO();
  
      item.id= data.id;
      item.nome= data.nome;
      item.idAziendaProd=data.idAziendaProd;
      item.idCliente= data.idCliente;
      item.idStagione= data.idStagione;
      item.idFase= data.idFase;
      item.idTerzista=data.idTerzista
      item.dataInserimento= data.dataInserimento
  
      
      return item;
    }
  
  }