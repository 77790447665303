import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';

import { TipiPagamentoPageListComponent } from './component/tipi-pagamento-page-list/tipi-pagamento-page-list.component';
import { TipiPagamentoEditComponent } from './component/tipi-pagamento-edit/tipi-pagamento-edit.component';

@NgModule({
  declarations: [
    TipiPagamentoPageListComponent,
    TipiPagamentoEditComponent,
  ],

  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule
  ]
})
export class FeatureTipiPagamentoModule { }
