import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { ItemFilter } from "src/app/_filters/ItemFilter";
import { AService } from "src/app/_services/AService";
import {
  ArticoliRiepilogoTestataFabbisogni,
  ComponentiPaiaFabbisogni,
  ComponentiSviluppoFabbisogni,
  FabbisogniDto,
  OrdiniRiepilogoTestataFabbisogni,
  RiepilogoTestataFabbisogni,
} from "src/app/featureOrdini/models/fabbisogniDto";
import { State } from "src/app/_models/State";
import { ordiniAcquistoDto } from "src/app/_models/ordiniAcquisto/ordiniAcquistoDto";
import { CalcoloFabbisogniMapper } from "../mappers/fabbisogniMapper";
/* 
class CalcoloFabbisogniMapper implements IDTOMapper<FabbisogniDto> {
  riepilogoMapper = new RiepilogoTestataFabbisogniMapper();
  componentiPaiaMapper = new ComponentiPaiaMapper();
  componentiSviluppoMapper = new ComponentiSviluppoMapper();
  map(data: any): FabbisogniDto {
    let item: FabbisogniDto = new FabbisogniDto();

    item.riepilogo = this.riepilogoMapper.map(data.riepilogo);
    item.componentiPaia = [
      ...data.componentiPaia.map((t) => this.componentiPaiaMapper.map(t)),
    ];
    item.componentiScalatura = data.componentiScalatura;
    item.componentiSviluppo = [
      ...data.componentiSviluppo.map((t) =>
        this.componentiSviluppoMapper.map(t)
      ),
    ];
    return item;
  }
}

class RiepilogoTestataFabbisogniMapper
  implements IDTOMapper<RiepilogoTestataFabbisogni>
{
  ordiniMapper = new OrdiniFabbisogniMapper();
  articoliMapper = new ArticoliFabbisogniMapper();
  map(data: any): RiepilogoTestataFabbisogni {
    let item: RiepilogoTestataFabbisogni = new RiepilogoTestataFabbisogni();

    item.data = data.data;
    item.paia_totali = data.paia_totali;
    item.lottoAcquisto = data.lottoAcquisto;
    item.ordini = [...data.ordini.map((o) => this.ordiniMapper.map(o))];
    item.articoli = [...data.articoli.map((t) => this.articoliMapper.map(t))];

    return item;
  }
}

class OrdiniFabbisogniMapper
  implements IDTOMapper<OrdiniRiepilogoTestataFabbisogni>
{
  map(data: any): OrdiniRiepilogoTestataFabbisogni {
    let item: OrdiniRiepilogoTestataFabbisogni =
      new OrdiniRiepilogoTestataFabbisogni();

    item.ordine = data.ordine;
    item.stagione = data.stagione;
    return item;
  }
}

class ArticoliFabbisogniMapper
  implements IDTOMapper<ArticoliRiepilogoTestataFabbisogni>
{
  map(data: any): ArticoliRiepilogoTestataFabbisogni {
    let item: ArticoliRiepilogoTestataFabbisogni =
      new ArticoliRiepilogoTestataFabbisogni();

    item.modello_parte_colore = data.modello_parte_colore;
    item.descrizione_articolo = data.descrizione_articolo;
    item.descrizione_linea = data.descrizione_linea;
    item.paia = data.paia;
    return item;
  }
}

class ComponentiPaiaMapper implements IDTOMapper<ComponentiPaiaFabbisogni> {
  map(data: any): ComponentiPaiaFabbisogni {
    let item: ComponentiPaiaFabbisogni = new ComponentiPaiaFabbisogni();

    item.descrizione_gr_merc = data.descrizione_gr_merc;
    item.codice_componente = data.codice_componente;
    item.descrizione_componente = data.descrizione_componente;
    item.colore = data.colore;
    item.codice_componente_forn = data.codice_componente_forn;
    item.fornitore = data.fornitore;
    item.udm = data.udm;
    item.consumo = data.consumo;
    item.paia = data.paia;

    return item;
  }
}

class ComponentiSviluppoMapper
  implements IDTOMapper<ComponentiSviluppoFabbisogni>
{
  map(data: any): ComponentiSviluppoFabbisogni {
    let item: ComponentiSviluppoFabbisogni = new ComponentiSviluppoFabbisogni();

    item.descrizione_gr_merc = data.descrizione_gr_merc;
    item.codice_componente = data.codice_componente;
    item.descrizione_componente = data.descrizione_componente;
    item.colore = data.colore;
    item.codice_componente_forn = data.codice_componente_forn;
    item.fornitore = data.fornitore;
    item.udm = data.udm;
    item.sviluppo = data.sviluppo;
    item.progressione_sviluppo = data.progressione_sviluppo;
    item.numeri = data.numeri;

    return item;
  }
} */

@Injectable({
  providedIn: "root",
})
export class FabbisogniService extends AService<FabbisogniDto> {
  state(): State<FabbisogniDto> {
    let currentState: State<FabbisogniDto> = new State<FabbisogniDto>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
  }

  mapper: IDTOMapper<FabbisogniDto> = new CalcoloFabbisogniMapper();
}
