import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchService } from 'src/app/_services/search.service';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/_services/alert.service';
import { MagazzinoGeneraleDTO } from '../models/magazzinoGeneraleDTO';
import { MagazzinoGeneraleMapper } from '../mappers/magazzinoGeneraleMapper';

@Injectable({
  providedIn: 'root'
})
export class MagazzinoGeneraleService extends SearchService<MagazzinoGeneraleDTO> {
  constructor(httpClient:HttpClient,
    public alertSrv: AlertService,
    ) {
    super(httpClient, alertSrv);
    this.url = `${environment.baseDomain}`;
    this.port = environment.ordiniPort;
    this.endpoint = 'lanci'

    this.mapper = new MagazzinoGeneraleMapper();
  }
}
