import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-materiali-anasnelle',
  templateUrl: './materiali-anasnelle.component.html',
  styleUrls: ['./materiali-anasnelle.component.css']
})
export class MaterialiAnasnelleComponent {

  src= environment.anasnelleMateriali

}
