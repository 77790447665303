import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { AService } from "src/app/_services/AService";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
import { CompAnasnelleDTO } from "src/app/_models/componentiAnasnelle/componentiAnaSnelleDTO";
import { RestDataSource } from "src/app/_datasources/RestDataSource";

export class ComponentiAnasnelleMapper implements IDTOMapper<CompAnasnelleDTO> {
  map(data: any): CompAnasnelleDTO {
    let item: CompAnasnelleDTO = new CompAnasnelleDTO();

    item.codiceFornitore = data.codiceFornitore;
    item.codiceComponente = data.codiceComponente;
    item.descrizioneComponente = data.descrizioneComponente;
    item.unMisura = data.unMisura;
    item.prezzo = data.prezzo;
    item.coloreComponente = data.coloreComponente;
    item.codiceComponenteCliente = data.codiceComponenteCliente;
    item.flagTaglie= data.flagTaglie;
    item.codScalatura= data.codScalatura;
    item.idScalatura= data.idScalatura;

    return item;
  }
}

@Injectable({
  providedIn: "root",
})
export class CompAnasnelleService extends AService<CompAnasnelleDTO> {
  compAnasnelleDataSource: RestDataSource;


  state(): State<CompAnasnelleDTO> {
    let currentState: State<CompAnasnelleDTO> = new State<CompAnasnelleDTO>();

    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;

    return currentState;
  }

  mapper: IDTOMapper<CompAnasnelleDTO> = new ComponentiAnasnelleMapper();
}
