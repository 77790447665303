import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';

import { BlockUIModule } from 'ng-block-ui';

import { TipiEsenzioneFalseEditComponent } from './component/tipi-aliquote-edit/tipi-aliquote-edit.component';
import { TipiEsenzioneFalsePageListComponent } from './component/tipi-aliquote-page-list/tipi-aliquote-page-list.component';

@NgModule({
  declarations: [
    TipiEsenzioneFalseEditComponent,
    TipiEsenzioneFalsePageListComponent,
  ],

  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule
  ]
})
export class FeatureTipiEsenzioneFalseModule { }
