import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AziendeDto } from "../../models/aziende-dto";
import { AllComboboxDataDto } from "../../../_models/allcomboboxdata/allcomboboxdata-dto";
// ho aggiunto  questi
import { RecapitiDto } from "src/app/_models/recapiti/recapiti-dto";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";
import { map, switchMap, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { RecapitiEditComponentComponent } from "src/app/featureRecapiti/component/recapiti-edit-component/recapiti-edit-component.component";
import { AlertService } from "src/app/_services/alert.service";
import { Globals } from "src/app/_models/Globals";
import { ActivatedRoute } from "@angular/router";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
// import { RecapitiDataSource } from "src/app/_datasources/allDataSources";
import { AziendeService, RecapitiService } from "src/app/_services/allServices";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { TipoAziendaDto } from "src/app/_models/tipoAzienda/tipoAziendaDto";
import { TipiEsenzioneDto } from "src/app/_models/tipiesenzione/tipiesenzione-dto";
import { PortiDto } from "src/app/_models/porti/porti-dto";
import { BehaviorSubject, Observable, of } from "rxjs";
import { OrdiniProduzioneService } from "src/app/featureOrdini/service/ordini-produzione.service";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { StagioneDto } from "src/app/featureOrdini/models/stagioneDto";
import { getYear, isThisQuarter } from "date-fns";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { Utility } from "src/app/_zCore/utility";
import { AziendeCRUDService } from "../../service/aziende-crud.service";
import { clippingParents } from "@popperjs/core";
import { AppAbility } from "src/app/shared/services/AppAbility";
import { AbilityService } from "@casl/angular";
import { ThisReceiver } from "@angular/compiler";

// import { Location } from '@angular/common';

@Component({
  selector: "app-aziende-edit",
  templateUrl: "./aziende-edit.component.html",
  styleUrls: ["./aziende-edit.component.css"],
})
export class AziendeEditComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly ability$: Observable<AppAbility>;
  public enableModificaExtCode: boolean = false;
  public personaFisicaSubject: BehaviorSubject<boolean>;
  personaFisica$: Observable<boolean>;
  active = 1;
  public get agenti(): AziendeDto[] {
    let agenti = this.listaAziende.filter((a) => {
      return a.tipoAzienda == "agente";
    });

    return agenti;
  }

  public get spedizionieri(): AziendeDto[] {
    let spedizionieri = this.listaAziende.filter((a) => {
      return a.tipoAzienda == "spedizioniere";
    });

    return spedizionieri;
  }

  public get capizona(): AziendeDto[] {
    let capizona = this.listaAziende.filter((a) => {
      return a.tipoAzienda == "capozona";
    });

    return capizona;
  }

  constructor(
    private modalservice: NgbModal,
    public activeModal: NgbActiveModal,
    public serviceAziende: AziendeService,
    public serviceRecapiti: RecapitiService,
    public allComboData: ApiAllComboBoxdataService,
    public aziendeCRUDSrv: AziendeCRUDService,
    private readonly router: Router,
    private alertSrv: AlertService,
    private ordiniSrv: OrdiniProduzioneService,
    abilityService: AbilityService<AppAbility>,

    private route: ActivatedRoute //   private location: Location
  ) {
    console.log("constructor",this.currentItem)
    let persFis = false
    if( this.currentItem && this.currentItem.personaFisica ){
      persFis = true
    } else {
      persFis = false
    }
    this.personaFisicaSubject = new BehaviorSubject(
      persFis
    );
    this.personaFisica$ = this.personaFisicaSubject.asObservable();
    this.ability$ = abilityService.ability$;
    this.listaStagioni$ = this.ordiniSrv.listaStagioniByCliente$;
    this.listaAziende$ = this.aziendeCRUDSrv.getAll().pipe(
      map((res) => {
        return JSON.parse(res.dati);
      })
    );
    this.listaControlloPIVA$ = this.listaAziende$.pipe(
      map((res) => {
        let lista: any[] = [];

        res.forEach((az) => {
          if (az.partitaIva !== null) {
            let obj = {
              idAzienda: az.idAzienda,
              pIVA: az.partitaIva,
            };
            lista.push(obj);
          } else {
            let obj = {
              idAzienda: az.idAzienda,
              pIVA: "iva",
            };
            lista.push(obj);
          }
        });
        return lista;
      })
    );
  }

  @Output() confirm = new EventEmitter<AziendeDto>();

  // datasource: RecapitiDataSource;
  typeop = "";
  alltype: AllComboboxDataDto;
  currentItem: AziendeDto;
  province;
  nazioni;
  capName: string;
  recapito: RecapitiDto;
  codiceFiscale: String;
  arrayValue = [];
  errorValue = false;
  delete: boolean = false;
  onShowStagione = false;
  listaTipiAzienda: TipoAziendaDto[] = [];
  listaTipoEsenzione: TipiEsenzioneDto[] = [];
  listaAziende: AziendeDto[] = [];
  listaTipiPorto: PortiDto[] = [];
  listaControlloPIVA$: Observable<any[]>;
  partitaIVADuplicata$: Observable<boolean>;

  listaStagioni$;

  fisicperson = true;
  displaynome = "";
  placeholdernome = "";
  placeholdercodiceFiscale = "";
  displaycodiceFiscale = "";

  ibansez = {
    banckcod1: "",
    banckcod2: "",
    banckcod3: "",
    banckabi: "",
    banckcab: "",
    bancknumber: "",
  };
  selectedNazione: string;
  provinciaEsistente;

  recEsistente = false;

  recapiti: RecapitiDto[] = [];
  recapitiDaCreare: RecapitiDto[] = [];
  recapitiDaCancellare: RecapitiDto[] = [];
  recapitiDaModificare: RecapitiDto[] = [];

  listaAziende$: Observable<AziendeDto[]>;

  errorNazioni;
  listaStagioni: StagioneDto[];

  ngAfterViewInit(): void {}

  secolo;
  anno;
  numero;
  arrayAnnoStagioni = [];

  stagioneToSend: StagioneDto = new StagioneDto();
  onAggiungiStagione(form: NgForm) {
    /*  this.secolo = parseInt(form.value.secoloStagione);
    this.anno = parseInt(form.value.annoStagione);
    this.numero = parseInt(form.value.numeroStagione);
 */
    this.stagioneToSend.idAzienda = this.currentItem.idAzienda;
    const result = this.anno.match(/.{1,2}/g) ?? [];
    this.stagioneToSend.secolo = result[0];
    this.stagioneToSend.anno = result[1];
    this.stagioneToSend.numero = parseInt(this.numero);
    console.log("stagione", this.stagioneToSend);

    this.ordiniSrv.stagioneDataSource
      .insertData(this.stagioneToSend)
      .subscribe((res: CindyCustomResponse) => {
        if (res.codEsito == "OK") {
          this.alertSrv.success(
            Globals.printCreateMessageSuccess("La Stagione")
          );
          this.secolo = "";
          this.anno = "";
          this.numero = "";
        } else {
          this.alertSrv.error(Globals.printCreateMessageFailure("la Stagione"));
        }
        this.ordiniSrv.loadStagioneByCliente(this.currentItem.idAzienda);

        console.log("stagione creata", res);
      });
  }

  onStagioneDelete(indice, id) {
    console.log("idStagione", id);
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          if (data == true) {
            return this.ordiniSrv.stagioneDataSource.deleteData(id);
          }
        })
      )
      .subscribe((res: CindyCustomResponse) => {
        modalRef.close();
        this.ordiniSrv.loadStagioneByCliente(this.currentItem.idAzienda);

        if (res.codEsito == "OK") {
          this.secolo = "";
          this.anno = "";
          this.numero = "";
          this.alertSrv.success(Globals.printDeleteMessageSuccess("Stagione"));
        } else {
          this.alertSrv.error(Globals.printDeleteMessageFailure("Stagione"));
        }

        console.log("stagione eliminata", res);
      });
  }

  creaArrayAnni() {
    let array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    let anni = [];
    array.forEach((anno) => {
      let data = new Date().getFullYear();
      anni.push(data + anno);
    });
    console.log(anni);

    return anni;
  }

  ngOnInit(): void {
    /*   if (this.currentItem.tipoAzienda == "cliente" && this.currentItem.idAzienda>=0) {
      this.onShowStagione = true;
    } */
    this.personaFisicaSubject.next(this.currentItem.personaFisica)
    this.arrayAnnoStagioni = this.creaArrayAnni();

    this.ordiniSrv.loadStagioneByCliente(this.currentItem.idAzienda);

    console.log("this.currentItem", this.currentItem);

    this.setupPersonLabels(this.currentItem.personaFisica);

    this.currentItem.iban = this.currentItem.iban?.trim();

    // this.getRecapitiByIDAzienda(this.currentItem.idAzienda);
    this.recEsistente = false;

    this.recEsistente = this.recapiti.length !== 0;

    if (this.currentItem.iban) {
      this.onIbanChange(this.currentItem.iban);
    }

    if (this.currentItem.nazione) {
      this.errorNazioni = true;
    } else {
      this.errorNazioni = false;
    }
    if (this.currentItem.nazione != "IT") {
      this.capName = "Zip CODE";
      this.displaycodiceFiscale = "Codice Fiscale Estero";
      this.provinciaEsistente = false;
    }

    if (
      this.currentItem.nazione == "IT" ||
      this.currentItem.nazione == undefined ||
      this.currentItem.nazione.includes(" ")
    ) {
      this.displaycodiceFiscale = "P.IVA/Codice Fiscale";
      this.provinciaEsistente = true;
      this.capName = "Cap";
    }

    this.codiceFiscale = this.currentItem.codiceFiscale;
    if (this.currentItem.nazione == undefined) {
      this.currentItem.nazione = "IT";
    }
  }

  onRecapitoCreate() {
    const modalRef = this.modalservice.open(RecapitiEditComponentComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.currentItem = new RecapitiDto();
    modalRef.componentInstance.alltype = this.alltype;
    modalRef.componentInstance.typeop = "ins";
    modalRef.componentInstance.listaProvince = this.province;
    modalRef.componentInstance.listaNazioni = this.nazioni;
    modalRef.componentInstance.recFromAziende = true;
    if (this.typeop !== "ins") {
      modalRef.componentInstance.currentItem.idAzienda =
        this.currentItem.idAzienda;
    }

    let cmp = this;
    modalRef.componentInstance.confirm
      .pipe(
        switchMap((data: RecapitiDto) => {
          if (this.typeop == "upd") {
            console.log("recapito", data);
            return this.serviceRecapiti.insertData(data as RecapitiDto);
          } else {
            cmp.recapitiDaCreare.push(data);
            cmp.recapiti = cmp.recapitiDaCreare;
            return of(null);
          }
        })
      )
      .subscribe((data: any) => {
        modalRef.componentInstance.onClose();

        if (data !== null) {
          console.log("request", data);
          let recapito = JSON.parse(data.dati);
          this.recapiti.push(recapito);
          console.log("recapiti", this.recapiti);
        }
        this.currentItem.recapiti = this.recapiti;
        this.currentItem.recapitiDaCreare = this.recapitiDaCreare;
        this.recEsistente = true;

        //   return this.serviceRecapiti.insertData(data as RecapitiDto);
      });
  }

  onRecapitoModify(index) {
    const item = this.recapiti[index];
    const modalRef = this.modalservice.open(RecapitiEditComponentComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    modalRef.componentInstance.typeop = "upd";
    modalRef.componentInstance.listaProvince = this.province;
    modalRef.componentInstance.listaNazioni = this.nazioni;

    modalRef.componentInstance.recFromAziende = true;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data: RecapitiDto) => {
          if (this.typeop == "upd") {
            return this.serviceRecapiti.updateData(data as RecapitiDto);
          } else {
            this.recapitiDaModificare.push(data);
          }
        })
      )
      .subscribe(
        (response) => {
          // this.getRecapitiByIDAzienda(this.recapiti[index].idAzienda);
          this.currentItem.recapitiDaModificare = this.recapitiDaModificare;

          modalRef.componentInstance.onClose();
          this.alertSrv.success(Globals.printModifyMessageSuccess("Recapito"));
        },
        (err) => {
          modalRef.componentInstance.error(err);
          this.alertSrv.error(Globals.printModifyMessageFailure("Recapito"));
        }
      );
  }

  onRecapitoDelete(index) {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.recapiti[index];
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data: RecapitiDto) => {
          if (this.typeop == "upd") {
            return this.serviceRecapiti.deleteData(
              item.idRecapito,
              "idRecapito"
            );
          } else {
            this.recapitiDaCancellare.push(data);
          }
        })
      )
      .subscribe(
        (response) => {
          // this.getRecapitiByIDAzienda(this.recapiti[index].idAzienda);
          this.currentItem.recapitiDaCancellare = this.recapitiDaCancellare;

          this.recapiti.splice(index, 1);
          modalRef.componentInstance.onClose();
          this.alertSrv.success(Globals.printDeleteMessageSuccess("Recapito"));
        },
        (err) => {
          this.alertSrv.error(Globals.printDeleteMessageFailure("Recapito"));
          console.log(err);
        }
      );
  }

  OnChangeSelect(e) {
    //console.log(e);
    this.selectedNazione = e;
    if (this.selectedNazione) {
      this.errorNazioni = true;
    }

    if (this.selectedNazione == "IT") {
      this.displaycodiceFiscale = "Codice Fiscale";
      this.capName = "Cap";
      if (this.currentItem.personaFisica == true) {
        this.enableCodiceFiscale = true;
      } else {
        this.enableCodiceFiscale = false;
      }
    }
    if (this.selectedNazione == "IT") {
      this.provinciaEsistente = true;
    }
    if (this.selectedNazione != "IT") {
      this.displaycodiceFiscale = "Codice Fiscale Estero";
      this.capName = "Zip CODE";
    }
    if (this.selectedNazione != "IT") {
      this.provinciaEsistente = false;
      if (this.currentItem.personaFisica == true) {
        this.enableCodiceFiscale = false;
      } else {
        this.enableCodiceFiscale = false;
      }
    }
  }

  onProvinceChange(provincia: string) {
    console.log(provincia);
    if (provincia == "" || provincia == " ") {
      this.provinciaEsistente = false;
    }
  }
  enableCodiceFiscale: boolean = false;
  onCheckboxChange(evt: boolean): void {
    this.setupPersonLabels(evt);
    if (evt === false || evt === undefined) {
      this.enableCodiceFiscale = false;
      this.personaFisicaSubject.next(false)
      /*   if (this.provinciaEsistente === false) {
        this.enableCodiceFiscale = false;
      } else {
        this.enableCodiceFiscale = false;
      } */
    } else {
      this.enableCodiceFiscale = true;
      this.personaFisicaSubject.next(true)

      /*   if (this.provinciaEsistente === true) {
        this.enableCodiceFiscale = true;
      } else {
        this.enableCodiceFiscale = false;
      } */
    }
  }

  showAgente = true;
  OnChangeTipoAzienda(evento) {
    console.log("tipoAzienda", evento);

    if (evento == "agente") {
      this.showAgente = false;
    } else {
      this.showAgente = true;
    }

    if (evento == "cliente") {
      this.onShowStagione = true;
    } else {
      this.onShowStagione = false;
    }
  }

  setupPersonLabels(evt: boolean): void {
    if (evt === false || evt === undefined) {
      this.placeholdernome = "ragione sociale";
      this.displaynome = "Ragione Sociale";
    } else {
      this.placeholdernome = "nome e cognome";
      this.displaynome = "Nome e Cognome";
    }
  }

  requiredIban = true;

  onPartitaIVAChange(value): void {
    console.log(value);
    this.partitaIVADuplicata$ = this.listaControlloPIVA$.pipe(
      map((res) => {
        let arraycontrollo = [];
        res.forEach((obj) => {
          if (
            obj.idAzienda !== this.currentItem.idAzienda &&
            obj.pIVA !== "iva"
          ) {
            arraycontrollo.push(obj.pIVA.trim());
          }
        });
        if (
          this.currentItem.partitaIva !== null &&
          arraycontrollo.indexOf(value) !== -1
        ) {
          console.log("true");
          return true;
        } else {
          console.log("false");

          return false;
        }
      })
    );
  }

  onIbanChange(value): void {
    this.ibansez.banckcod1 = value.substring(0, 2);
    this.ibansez.banckcod2 = value.substring(2, 4);
    this.ibansez.banckcod3 = value.substring(4, 5);
    this.ibansez.banckabi = value.substring(5, 10);
    this.ibansez.banckcab = value.substring(10, 16);
    this.ibansez.bancknumber = value.substring(16, 28);
  }

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      this.onClose();

      return;
    }

    /*    this.recapiti.forEach((recapito)=>{
      this.serviceRecapiti.insertData(recapito as RecapitiDto).subscribe((res)=>res)
    })  */
    if(this.currentItem.personaFisica === true){
      this.currentItem.partitaIva = ''
    }
    console.log("this currentItem", this.currentItem);
    this.currentItem.agente = Utility.toInteger(this.currentItem.agente);
    this.confirm.emit(this.currentItem);

    // console.log(this.currentItem);
  }

  onClose(): void {
    this.activeModal.close("close");
  }

  getTipoFattura(value1: string): string {
    let valret = "";
    if (value1) {
      const value2 = value1.trim();
      valret = this.alltype.tipoFattura.find((x) => x.value === value2)?.text;
    }
    return valret;
  }

  public findInvalidControls(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  prepareDettagli(obj): any {
    // console.log("obj", obj);
    let values = JSON.stringify(obj);
    let object = JSON.parse(values);
    let valore = obj["valore"];
    let via = obj["via"];
    let numero = obj["numero"];
    let localita = obj["localita"];
    let provincia = obj["provincia"];
    let nazione = obj["nazione"];
    if (valore == "") {
      return (
        via + " " + numero + " " + localita + " " + provincia + " " + nazione
      );
    } else {
      return valore;
    }
  }
  ngOnDestroy() {}

  onAziendeDelete(): void {
    let modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.currentItem;
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.alltype = this.alltype;

    let cmp = this;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        map((data) => {
          cmp.delete = true;
          return this.confirm.emit(this.currentItem);
          // return this.serviceAziende.deleteData(item.idAzienda, "IDazienda");
        })
      )
      .subscribe(
        (response) => {
          modalRef.componentInstance.onClose();
          /* 
          this.alertSrv.success(Globals.printDeleteMessageSuccess("Azienda")); */
          this.onClose();
        },
        (err) => {
          modalRef.componentInstance.error(err);
          /*    this.alertSrv.error(Globals.printDeleteMessageFailure("Azienda"));
          console.log(err); */
          modalRef.componentInstance.onClose();
        }
      );
  }
}
