import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  OperatorFunction,
  Subject,
  combineLatest,
  merge,
  of,
  throwError,
} from "rxjs";
import {
  switchMap,
  map,
  mergeMap,
  tap,
  catchError,
  debounceTime,
  filter,
  distinctUntilChanged,
} from "rxjs/operators";
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";

import { OrdiniAcquistoEditComponent } from "../../ordini-acquisto-edit/ordini-acquisto-edit.component";
import { AlertService } from "src/app/_services/alert.service";
import { ordiniAcquistoDto } from "src/app/_models/ordiniAcquisto/ordiniAcquistoDto";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
import { AndFilter } from "src/app/_filters/AndFilter";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { OrdiniAcquistoService } from "src/app/featureOrdiniAcquisto/service/ordini-acquisto.service";
import { GestioneFileService } from "src/app/_services/gestione-file.service";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { Utility } from "src/app/_zCore/utility";
import { ActivatedRoute, Router } from "@angular/router";
import { SviluppiTaglieDto } from "src/app/featureSviluppiTaglia/models/sviluppitaglie-dto";
import { ScalatureDto } from "src/app/featureScalature/models/scalature-dto";
import { ODAReportService } from "src/app/featureOrdiniAcquisto/service/odareport.service";
import { PopUpUploadOrdiniComponent } from "src/app/_popups/pop-up-upload-ordini/pop-up-upload-ordini.component";
import { ODAEditCreateComponent } from "../../odaedit-create/odaedit-create.component";
import { environment } from "src/environments/environment";
import { JsReportService } from "src/app/shared/services/jsReport/js-report.service";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { ErroriService } from "src/app/_services/errori-service.service";
import { NotaODAComponent } from "../../nota-testata-oda/nota-testata-oda.component";
import { OrdiniProduzioneService } from "src/app/featureOrdini/service/ordini-produzione.service";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { NgModel } from "@angular/forms";
import { RegistraDDTComponent } from "src/app/featureDDT/components/registra-ddt/registra-ddt.component";
import { DDTCRUDService } from "src/app/featureDDT/services/ddt-crud.service";
import { DDTEstesoDto } from "src/app/featureDDT/models/DDTdto";
import { EqualFilter } from "src/app/_filters/EqualFilter";
import { Variables } from "src/assets/data/variables";
import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";

export class OrdiniAcquistoDataSource extends RestDataSource {
  constructor(public http: HttpClient) {
    super(http);
  }

  doPost(endPoint: string, data: any): Observable<object> {
    const url = `${this.domain}/${endPoint}`;
    return this.http.post(url, data);
  }
}

@Component({
  selector: "app-ordini-acquisto-page-list",
  templateUrl: "./ordini-acquisto-page-list.component.html",
  styleUrls: ["./ordini-acquisto-page-list.component.css"],
})
export class OrdiniAcquistoPageListComponent implements OnInit, AfterViewInit {
  @ViewChildren("checkbox") checkBoxes!: QueryList<any>;
  @ViewChild("inputTypeahead") inputElement: ElementRef;
  @ViewChild("searchTermLotto") lottoInput: ElementRef;

  model: any;
  searching = false;
  searchFailed = false;

  onBlur(ordAquisto: ordiniAcquistoDto) {
    ordAquisto.showInput = false;
  }

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.listaFornitori$.pipe(
          tap((res) => console.log("tap fornitori", res)),
          map((res: any) => {
            //  let listaAziende = JSON.parse(res.dati);
            let listaFornitori = res.filter(
              (az) => az.tipoAzienda == "fornitore"
            );
            let filtrata = listaFornitori.filter((az) => {
              let rs = az.ragioneSociale.toString().trim().toLowerCase();
              let t = term.toString().trim().toLowerCase();

              return rs.includes(t);
            });
            // console.log("listaFiltrata", filtrata);
            let listaRagioniSociali = filtrata.map((azienda) =>
              azienda.ragioneSociale.toString().trim()
            );

            return listaRagioniSociali;
          }),
          tap((res) => console.log("tap", res, "term", term)),
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  _page: number = 1;
  _pageSize: number = 50;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";
  sviluppi$: Observable<SviluppiTaglieDto[]>;
  scalature$: Observable<ScalatureDto[]>;
  ordiniAcquistoDataSource: RestDataSource;
  odaReportDataSource: RestDataSource;
  odaInsertDataSource: RestDataSource;
  loadingPrint$: Observable<boolean>;
  _loadingPrintBSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showInput = false;
  listaFornitori$: Observable<AziendeDto[]>;
  listaFornitori: string[] = [];
  state: State<ordiniAcquistoDto> = new State();

  constructor(
    private modalservice: NgbModal,
    private allComboData: ApiAllComboBoxdataService,
    private ordiniAcquistoService: OrdiniAcquistoService,
    private alertSrv: AlertService,
    public http: HttpClient,
    private tableexcelService: GestioneFileService,
    private reportSrv: JsReportService,
    private route: ActivatedRoute,
    private router: Router,
    private odaReportSrv: ODAReportService,
    private errorSrv: ErroriService,
    private ordiniProdSrv: OrdiniProduzioneService,
    public ddtCRUDSrv: DDTCRUDService
  ) {
    this.ordiniAcquistoDataSource = new OrdiniAcquistoDataSource(this.http);
    let user = JSON.parse(localStorage.getItem("userData"));
    console.log("user", user.userID);

    this.ordiniAcquistoDataSource.setVariablesApi(
      `ordiniAcquistoByUser?usrid=${user.userID}`,
      "ordineAcquisto",
      "ordineAcquisto",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );

    this.ordiniAcquistoDataSource.setByIdField("");
    this.ordiniAcquistoService.connectDataSource(this.ordiniAcquistoDataSource);

    this.odaReportDataSource = new RestDataSource(this.http);
    this.odaReportDataSource.setVariablesApi(
      "",
      "odaReport",
      "odaReport",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );

    this.odaReportDataSource.setByIdField("idsRiga");
    this.odaReportSrv.connectDataSource(this.odaReportDataSource);

    this.odaInsertDataSource = new RestDataSource(this.http);
    this.odaInsertDataSource.setVariablesApi(
      "",
      "insertOdaManuale",
      "insertOdaManuale",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );
    this.odaInsertDataSource.setByIdField("");
    this.loadingPrint$ = this._loadingPrintBSubject.asObservable();

    this.listaFornitori$ = this.ordiniProdSrv.loadFornitoriConComponenti();

    /*     this.listaFornitori$.subscribe((res) => {
      this.listaFornitori = res.map((azienda) => azienda.ragioneSociale);
    }); */

    this.state.items.map((items) => (items.showInput = false));
  }

  _searchTerm: string;
  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this.filterOrdiniAcquisto(this.ordiniAcquistoService);
  }

  get searchTerm() {
    return this._searchTerm;
  }

  _searchTermLotto: string;
  set searchTermLotto(searchString: string) {
    this._searchTermLotto = searchString;

    this.filterOrdiniAcquisto(this.ordiniAcquistoService);
  }

  get searchTermLotto() {
    return this._searchTermLotto;
  }

  _searchTermTagLancio: string;
  set searchTermTagLancio(searchString: string) {
    this._searchTermTagLancio = searchString;

    this.loadData();
  }

  get searchTermTagLancio() {
    return this._searchTermTagLancio;
  }

  _searchTermFornitore: string;
  set searchTermFornitore(searchString: string) {
    this._searchTermFornitore = searchString;

    this.loadData();
  }

  get searchTermFornitore() {
    return this._searchTermFornitore;
  }

  _searchTermComponente: string;
  set searchTermComponente(searchString: string) {
    this._searchTermComponente = searchString;

    this.loadData();
  }

  get searchTermComponente() {
    return this._searchTermComponente;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.loadData();
  }
  get pageSize() {
    return this._pageSize;
  }
  set page(newPage: number) {
    this._page = newPage;

    this.loadData();
  }

  get page() {
    return this._page;
  }

  loadData() {
    this.ordiniAcquistoService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterOrdiniAcquisto(service);

        console.log(this._page + " " + this._pageSize);
      },
    });
    this._sortColumn = "idRiga";
    this._sortDirection = "desc";
    this._rotateDirection = "desc";
  }
  enableControlloDDT = false;
  enableControlloAnnullaOrdine = false;

  filterOrdiniAcquisto(service: OrdiniAcquistoService) {
    // console.log("_searchTermLotto",this._searchTermLotto, "_searchTermFornitore",this._searchTermFornitore);
    if (
      this._searchTermLotto &&
      this._searchTermLotto !== "" &&
      this._searchTermFornitore &&
      this._searchTermFornitore !== ""
    ) {
      this.enableControlloDDT = true;
      this.enableControlloAnnullaOrdine = true;
      this.enableStampa = true;
    } else {
      this.enableControlloDDT = false;
      this.enableControlloAnnullaOrdine = false;
      this.enableStampa = false;
    }

    this.state = service
      .filter(
        new AndFilter([
          new FieldsFilter({
            fieldList: [
              "rigaOrdine",
              "codiceFornitore",
              "idOrdine",
              "dataConsegna",
              "tagLancio",
              "stato",
              "ordineCliente",
              "codiceComponente",
              "descrizioneFornitore",
              "descrizioneComponente",
              "stagione",
            ],
            searchString: this._searchTerm == undefined ? "" : this._searchTerm,
          }),
          new EqualFilter({
            fieldList: ["lotto"],
            searchString:
              this._searchTermLotto == undefined ? "" : this._searchTermLotto,
          }),
          new FieldsFilter({
            fieldList: ["tagLancio"],
            searchString:
              this._searchTermTagLancio == undefined
                ? ""
                : this._searchTermTagLancio,
          }),
          new EqualFilter({
            fieldList: ["descrizioneFornitore", "codiceFornitore"],
            searchString:
              this._searchTermFornitore == undefined
                ? ""
                : this._searchTermFornitore,
          }),
          new FieldsFilter({
            fieldList: [
              "descrizioneComponente",
              "coloreComponente",
              "codiceComponente",
            ],
            searchString:
              this._searchTermComponente == undefined
                ? ""
                : this._searchTermComponente,
          }),
        ])
      )
      .sort(this._sortColumn, this._sortDirection)
      .paginate(this._page, this.pageSize)
      .state();
  }


  onChangeShowInput(oda: ordiniAcquistoDto) {
    if (!oda.showInput && oda.stato == Variables.propostaOrdine) {
      oda.showInput = true;
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 0);
    }
  }

  onRegistraDDT(righeOdaSelezionate: ordiniAcquistoDto[]) {
    console.log("onRegistraDDT", righeOdaSelezionate);
    let matchOrdiniato = righeOdaSelezionate.find(
      (oda) => oda.stato == Variables.ordinato
    );
    let matchPO = righeOdaSelezionate.find(
      (oda) => oda.stato == Variables.propostaOrdine
    );
    let matchEvaso = righeOdaSelezionate.find(
      (oda) => oda.stato == Variables.evaso
    );

    let idToSend: number[] = [];
    righeOdaSelezionate.forEach((rigaODA) => {
      idToSend.push(rigaODA.idRiga);
    });
    let stringOfId = idToSend.join(",");
    if (matchOrdiniato && matchEvaso == undefined) {
      const modalRef = this.modalservice.open(RegistraDDTComponent, {
        size: "xl",
        windowClass: "animated FadeIn",
      });
      modalRef.componentInstance.stringOfId = stringOfId;
     // modalRef.componentInstance.totaleRigheOda = righeOdaSelezionate;
      //modalRef.componentInstance.lottoCorrente = righeOdaSelezionate[0].lotto;
      modalRef.componentInstance.fornitoreCorrente =
        righeOdaSelezionate[0].codiceFornitore;
      const sub = modalRef.componentInstance.confirm
        .pipe(
          switchMap((res:DDTEstesoDto) => {
            console.log("ddt confirm", res);
            return this.ddtCRUDSrv.create(res as DDTEstesoDto);
          })
        )
        .subscribe(
          (res: CindyCustomResponse) => {
            console.log("res subscribe", JSON.parse(res.dati));
            if (res && res.codEsito.toLowerCase() == "ok") {
              let ddt =  JSON.parse(res.dati)
        
              if (ddt.ddt.flagDDTFinale) {
                this.onChangeStato(true, righeOdaSelezionate, Variables.evaso);
              }
              this.loadData();
              this.alertSrv.success("Il DDT è stato registrato con successo");
            } else {
              this.errorSrv.onToastTestata(
                "C'è stato un errore con la registrazione del DDT"
              );
            }
          },

          (err: CindyCustomResponse) => {
            console.log(err);
            this.errorSrv.onToastTestata(
              "C'è stato un errore con la registrazione del DDT"
            );
          }
        );
    } else {
      if (matchEvaso && !matchPO) {
        this.alertSrv.warning("L'ordine selezionato è già stato Evaso ");
      } else {
        this.alertSrv.warning("La selezione contiene solo Proposte d'Ordine");
      }
    }
  }

  onAnnullaODA(righeOdaSelezionate) {
    console.log("onAnnullaODA", righeOdaSelezionate);
    let righefiltrate = righeOdaSelezionate.filter((oda) => {
      return oda.stato == Variables.evaso;
    });
    this.onChangeStato(true, righefiltrate, Variables.annullato, true);
  }

  onEvadiDDT(righeOdaSelezionate: ordiniAcquistoDto[]) {
    console.log("onEvadiDDT", righeOdaSelezionate);
    let righefiltrate = righeOdaSelezionate.filter((oda) => {
      return oda.stato == Variables.ordinato;
    });
    this.onChangeStato(true, righefiltrate, Variables.evaso, true);
  }

  onFiltroPO(event) {
    console.log("evt", event);
    let value = event.target.checked;
    console.log("newValue", value);
    if (value) {
      this.searchTerm = "PO";
    } else {
      this.searchTerm = "";
    }
    // this.searchTerm = "Proposta d'Ordine";
  }

  OnChangeFornitore(oda: ordiniAcquistoDto, ragioneSociale) {
    console.log("ngmodelchange", ragioneSociale);
    if (oda && ragioneSociale) {
      oda.descrizioneFornitore = ragioneSociale;

      this.listaFornitori$
        .pipe(
          tap((res) => console.log("tap fornitori", res)),
          map((res) => {
            let match = res.find(
              (az) => az.ragioneSociale.toString().trim() == ragioneSociale
            );
            if (match) {
              return match.extCode;
            } else {
              this.errorSrv.onToastTestata("Codice Fornitore inesistente");
            }
          }),
          switchMap((res) => {
            oda.codiceFornitore = res.toString().trim();
            return this.ordiniAcquistoService.update(oda);
          })
        )
        .subscribe(
          (res: CindyCustomResponse) => {
            console.log(JSON.parse(res.dati));
            if (res.codEsito.toLowerCase() == "ok") {
              this.searchTerm = "";
              this.loadData();
              this.model = "";

              this.showInput = false;
              this.alertSrv.success(
                Globals.printModifyMessageSuccess("Il Fornitore")
              );
            } else {
              this.onError("il Fornitore");
            }
          },
          (err: CindyCustomResponse) => {
            console.log(err.codEsito);
            this.alertSrv.error(
              Globals.printModifyMessageFailure("il Fornitore")
            );
          }
        );
    } else {
      this.alertSrv.warning(Globals.MappaMessaggiErrore.get("errorFornitore"));
      setTimeout(() => {
        if (!this.model) {
          oda.showInput = false;
        }
      }, 3000);
    }
  }

  onNotaRiga(oda: ordiniAcquistoDto) {
    if (oda) {
      console.log("oda", oda);
      const modalRef = this.modalservice.open(NotaODAComponent, {
        size: "sm",
        windowClass: "animated FadeIn",
      });
      modalRef.componentInstance.nota = oda.note;
      modalRef.componentInstance.titolo = "Inserisci nota di Riga";
      const sub = modalRef.componentInstance.confirm
        .pipe(
          switchMap((res: string) => {
            console.log("risposta note", res);
            oda.note = res;
            return this.ordiniAcquistoService.update(oda);
          })
        )
        .subscribe(
          (response) => {
            console.log("response edit nota", response);
            this.alertSrv.success("La Nota è stata modificata con successo");
          },
          (err) => {
            this.errorSrv.onToastTestata(
              Globals.printModifyMessageFailure("la Nota")
            );
          }
        );
    }
  }


  onEliminaRiga(oda: ordiniAcquistoDto): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.currentItem = oda;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
       if(data){
        return this.ordiniAcquistoService.delete(oda.idRiga);

       }{
        return of('')
       }

        })
      )
      .subscribe(
        (response) => {
          console.log("risposta delete",response)

          modalRef.componentInstance.onClose();         
        },
        (err) => {
          console.log(err)
          modalRef.componentInstance.onClose();
        }
      );


  }

  onAnnullaRiga(oda: ordiniAcquistoDto) {

    let array = [];
    array.push(oda);
    this.onChangeStato(true, array, Variables.annullato, true);
  }

  onDataCreazioneChanged(event, indice) {
    console.log("data", event);
    let dateToks = event.split("-");
    this.state.items[
      indice
    ].dataConsegna = `${dateToks[2]}/${dateToks[1]}/${dateToks[0]}`;

    if (event !== "") {
      this.ordiniAcquistoService.update(this.state.items[indice]).subscribe(
        (res: CindyCustomResponse) => {
          console.log(JSON.parse(res.dati));
          if (res.codEsito.toLowerCase() == "ok") {
            this.searchTerm = "";
            // this.loadData();
            this.alertSrv.success("La Data è stata modificata con successo");
          } else {
            this.onError("la Data");
          }
        },
        (err: CindyCustomResponse) => {
          console.log(err.codEsito);
          this.alertSrv.error(Globals.printModifyMessageFailure("la Data"));
        }
      );
    } else {
      this.alertSrv.warning(Globals.MappaMessaggiErrore.get("DATAMANCANTE"));
    }
  }

  getFormattedDate(dataConsegna): string {
    // console.log(dataConsegna)
    let dateToks = dataConsegna.split("/");
    return `${dateToks[2]}-${dateToks[1]}-${dateToks[0]}`;
  }

  onReOrder() {
    let righeToReOrder: ordiniAcquistoDto[] = this.elementiSelezionati;
    console.log("righeToReOrder", righeToReOrder);
    this.ordiniAcquistoService
      .reOrder("riordinaOrdiniAcquisto", righeToReOrder)
      .subscribe((res: CindyCustomResponse) => {
        console.log("res", JSON.parse(res.dati));
        if (res.codEsito.toLowerCase() == "ok") {
          let ordineNuovo = JSON.parse(res.dati);
          this.searchTerm = ordineNuovo[0].codiceFornitore;
          this.elementiSelezionati = [];
          this.onSelectAll(false);
          this.loadData();
        } else {
          this.elementiSelezionati = [];
          this.alertSrv.error(
            Globals.printCreateMessageFailure("Ordine d'Acquisto")
          );
        }
      });
  }

  enableStampa = false;
  elementiSelezionati: ordiniAcquistoDto[] = [];

  onSelezione(event, indice) {
    console.log("event", event + " " + indice);
    let ordine = this.state.items[indice];
    if (event === true) {
      this.elementiSelezionati.push(ordine);
    } else {
      let indiceElemento = this.elementiSelezionati.indexOf(ordine);
      if (indiceElemento !== -1) {
        this.elementiSelezionati.splice(indiceElemento, 1);
      }
    }

    if (this.elementiSelezionati.length !== 0) {
      this.elementiSelezionati.forEach((ordine) => {
        if (
          ordine.codiceFornitore == null ||
          ordine.codiceFornitore == "null" ||
          ordine.codiceFornitore == undefined ||
          ordine.codiceFornitore == "undefined" ||
          ordine.codiceFornitore == ""
        ) {
          this.enableStampa = true;
        } else {
          this.enableStampa = false;
        }
      });
    } else {
      this.enableStampa = true;
    }

    console.log("elementiSelezionati", this.elementiSelezionati);
  }

  fillSelectedStato() {
    this.state.items.forEach((fill) => {
      let banana = undefined;
      this.selectedStato.push(banana);
    });
  }

  onChangeStato(
    conferma: boolean,
    elementiSelezionati: ordiniAcquistoDto[],
    tipoStato,
    alert?
  ) {
    if (conferma) {
      elementiSelezionati.forEach((ODA) => {
        ODA.stato = tipoStato;
        this.ordiniAcquistoService.update(ODA).subscribe(
          (res: CindyCustomResponse) => {
            console.log(JSON.parse(res.dati));

            if (res && res.codEsito.toLowerCase() == "ok") {
              if (alert) {
                this.alertSrv.success(
                  `L'ordine è stato ${tipoStato} con successo`
                );
              }
              // this.searchTerm = "";
              // this.loadData();
              /*  this.alertSrv.success(
                Globals.printSuccess("L'Ordine d'Acquisto")
              ); */
            } else {
              console.log("errore evaso", res);

              this.onError("l'Ordine d'Acquisto");
            }
          },
          (err) => {
            this.elementiSelezionati = [];
            let errore =
              "C'è stato un errore nella modifica dell'Ordine d'Acquisto";

            this.errorSrv.onToastTestata(errore);
            console.log("errore evaso", err);
          }
        );
      });
    }
  }

  onPrint(righeToPrint) {
    //console.log("selezionati", this.elementiSelezionati);

    this._loadingPrintBSubject.next(true);
    // let righeToPrint = this.elementiSelezionati;

    let idToSend: number[] = [];
    let fornitori = [];
    let numeriOrdine = [];
    righeToPrint.forEach((rigaODA) => {
      idToSend.push(rigaODA.idRiga);
      if (!fornitori.includes(rigaODA.codiceFornitore)) {
        fornitori.push(rigaODA.codiceFornitore);
      }
      if (!numeriOrdine.includes(rigaODA.idOrdine)) {
        numeriOrdine.push(rigaODA.idOrdine);
      }
    });

    let stringOfId = idToSend.join(",");
    let notaTestata = "";
    let errore = "";

    if (fornitori.length === 1 /* && numeriOrdine.length === 1 */) {
      const modalRef = this.modalservice.open(NotaODAComponent, {
        size: "sm",
        windowClass: "animated FadeIn",
      });
      modalRef.componentInstance.titolo = "Inserisci nota di Testata";

      const sub = modalRef.componentInstance.confirm
        .pipe(
          mergeMap((res: string) => {
            notaTestata = res;

            return this.odaReportSrv.getDataByMultipleId(stringOfId);
          }),
          catchError((err) => {
            console.log("errore creazione righe", err);
            errore =
              "C'è stato un errore nella creazione dell'Ordine d'Acquisto";
            this.errorSrv.onToastTestata(errore);
            return throwError(err);
          }),
          tap((res) => {
            console.log("odaToSend", res);
            this.onChangeStato(true, righeToPrint, Variables.ordinato);
          }),
          mergeMap((odaToSend: any) => {
            console.log("odaToSend dopo", JSON.parse(odaToSend.dati));
            let righeOda = JSON.parse(odaToSend.dati).righeOrdini;
            righeOda.forEach((riga) => {
              riga.notaTestata = notaTestata;
            });
            let ricostruzioneObj = {
              righeOrdini: righeOda,
            };
            console.log("notaTestata", notaTestata);
            let reportToSend = {
              template: { name: "Ordine_Fornitore" },
              data: JSON.stringify(ricostruzioneObj),
            };
            return this.reportSrv.getReport(reportToSend);
          }),
          catchError((err) => {
            console.log("errore report", err);
            errore =
              "C'è stato un errore nella creazione del Report Ordine d'Acquisto";
            this.errorSrv.onToastTestata(errore);

            return throwError(err);
          })
        )
        .subscribe(
          (report) => {
            this.elementiSelezionati = [];
            this.loadData();
            this._loadingPrintBSubject.next(false);
            var newBlob = new Blob([report], { type: "application/pdf" });
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = "Stampa Ordine d'Aquisto";
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );

            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
              link.remove();
            }, 100);
          },
          (err) => {
            this.elementiSelezionati = [];
            this._loadingPrintBSubject.next(false);
            errore = "C'è stato un errore nella Stampa dell'Ordine d'Acquisto";

            this.errorSrv.onToastTestata(errore);
            console.log(err);
          }
        );

      /* this.odaReportSrv.getDataByMultipleId(stringOfId).subscribe(
        (res: CindyCustomResponse) => {
          console.log("odatoPrint", JSON.parse(res.dati));

          let reportToSend = {
            template: { name: "Ordine_Fornitore" },
            data: res.dati,
          };

          this.reportSrv.getReport(reportToSend).subscribe(
            (report) => {
              this.elementiSelezionati = [];
              this.loadData();
              this.onChangeStato(true, this.elementiSelezionati);
              this._loadingPrintBSubject.next(false);
              var newBlob = new Blob([report], { type: "application/pdf" });
              const data = window.URL.createObjectURL(newBlob);
              var link = document.createElement("a");
              link.href = data;
              link.download = "Stampa Ordine d'Aquisto";
              // this is necessary as link.click() does not work on the latest firefox
              link.dispatchEvent(
                new MouseEvent("click", {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                })
              );

              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
              }, 100);
            },
            (err) => {
              this.elementiSelezionati = [];
              this._loadingPrintBSubject.next(false);

              this.alertSrv.error(
                Globals.printDeleteMessageFailure(
                  "Caricamento Ordine d'Acquisto"
                )
              );
              console.log(err);
            }
          );
          this.selectAll = false;
          this.selectedStato = [];
        },
        (err) => {
          this._loadingPrintBSubject.next(false);

          this.elementiSelezionati = [];
          this.alertSrv.error(
            Globals.printDeleteMessageFailure("Stampa Ordine d'Acquisto")
          );
          console.log(err);
        }
      ); */
    } else {
      this._loadingPrintBSubject.next(false);

      this.elementiSelezionati = [];
      console.log("non si stampa");
      this.alertSrv.warning(
        Globals.MappaMessaggiErrore.get("DUP_RIGA_ODA"),
        Globals.MappaMessaggiErrore.get("DUP_RIGA_ODA_dettaglio")
      );
      this.loadData();

      /*       const modalRefErrore = this.modalservice.open(
        PopUpIstanzaEsistenteComponent,
        {
          size: "sm",
          windowClass: "animated FadeIn",
        }
      );
      modalRefErrore.componentInstance.codiceErrore = "DUP_RIGA_ODA";
      modalRefErrore.componentInstance.messaggioEventuale =
        "Assicurati di aver selezionato correttamente"; */
    }
  }

  selectAll: boolean = false;
  selectedStato: Boolean[] = [];

  onSelectAll(event) {
    // this.loggerService.log(event);
    this.selectAll = event;

    this.state.items.forEach((fill) => {
      if (this.selectAll == true) {
        let ele = new Boolean();
        this.selectedStato.push(ele);
      } else {
        this.selectedStato = [];
      }
    });

    if (this.selectedStato.length !== 0) {
      console.log("selectedstato", this.selectedStato);
      for (let index = 0; index < this.selectedStato.length; index++) {
        let ordine = this.state.items[index];
        this.selectedStato[index] = this.selectAll;
        if (this.selectAll == true) {
          this.elementiSelezionati.push(ordine);
        } else {
          this.elementiSelezionati = [];
        }
        if (
          ordine.codiceFornitore == null ||
          ordine.codiceFornitore == "null" ||
          ordine.codiceFornitore == undefined ||
          ordine.codiceFornitore == "undefined" ||
          ordine.codiceFornitore == ""
        ) {
          this.enableStampa = true;
        } else {
          this.enableStampa = false;
        }
      }
    } else {
      console.log("selectedstato", this.selectedStato);
      this.enableStampa = true;
    }

    // this.loggerService.log(this.selectedStato);
  }

  calcQnt(calcolata: number, magazzino: number) {
    return Utility.toDecimal(calcolata - magazzino) as number;
  }

  onQntChange(value: number, indice) {
    this.state.items[indice].quantitaDaOrdinare = +value as number;
    console.log("value", value);
    this.ordiniAcquistoService
      .update(this.state.items[indice])
      .subscribe((res: CindyCustomResponse) => {
        console.log(JSON.parse(res.dati));

        if (res.codEsito.toLowerCase() == "ok") {
          // this.searchTerm = "";
          // this.loadData();
          this.alertSrv.success("La Quantità è stata modificata con successo");
        } else {
          this.onError("la quantità");
        }
      });
  }

  onQuantitaGrid(index) {
    let modalRef: any = null;
    let ordine = this.state.items[index];
    console.log("ordine", ordine);
    if (
      ordine.codSviluppo !== null &&
      ordine.codSviluppo !== "null" &&
      ordine.codSviluppo !== undefined &&
      ordine.codSviluppo !== "undefined" &&
      ordine.codSviluppo !== "" &&
      ordine.stato == "PO"
    ) {
      modalRef = this.modalservice.open(OrdiniAcquistoEditComponent, {
        size: "xl",
        windowClass: "animated FadeIn",
      });

      let ordAquisto = this.state.paginatedItems[index];
      this.typeop = "upd";

      modalRef.componentInstance.currentItem = ordine;

      modalRef.componentInstance.typeop = this.typeop;
      const sub = modalRef.componentInstance.confirm
        .pipe(
          switchMap((data) => {
            console.log("data", data);

            return this.ordiniAcquistoService.update(data as ordiniAcquistoDto);
          })
        )
        .subscribe(
          (response: CindyCustomResponse) => {
            console.log("rispostaServer", response);

            if (response.codEsito.toLowerCase() == "ok") {
              modalRef.componentInstance.onClose();
              this.searchTerm = "";
              this.loadData();
              if (this.typeop == "ins")
                this.alertSrv.success(
                  "Le Quantità sono state assegnate con successo"
                );
              else if (this.typeop == "upd") {
                this.alertSrv.success(
                  "Le Quantità sono state modificate con successo"
                );
              } else {
                this.alertSrv.success(
                  "Le Quantità sono state cancellate con successo"
                );
              }
            } else {
              //modalRef.componentInstance.onClose();
              this.onError("la quantità");
            }
          },
          (err: CindyCustomResponse) => {
            console.log(err.codEsito);
            modalRef.componentInstance.error(err);
            this.onError("la quantità");
          }
        );
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params["paramsString"] != undefined) {
        console.log("params", params);
        let arrayparametri = params["paramsString"].split("+");
        this.searchTermLotto = arrayparametri[0] || "";
        this.searchTermTagLancio = arrayparametri[1] || "";
      }
    });

    this._sortColumn = "idOrdine";
    this._sortDirection = "desc";
    this._rotateDirection = "desc";
    this.loadData();

    this.fillSelectedStato();
  }

  ngAfterViewInit(): void {
    //  console.log("input",this.lottoInput);
  }

  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");

    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.filterOrdiniAcquisto(this.ordiniAcquistoService);
  }

  typeop = "";

  onError(tipoOggetto) {
    if (this.typeop == "ins")
      this.alertSrv.error(Globals.printCreateMessageFailure(tipoOggetto));
    else if (this.typeop == "upd") {
      this.alertSrv.error(Globals.printModifyMessageFailure(tipoOggetto));
    } else {
      this.alertSrv.error(Globals.printDeleteMessageFailure(tipoOggetto));
    }
  }

  onODACreateOrModify() {
    const modalReCreate = this.modalservice.open(ODAEditCreateComponent, {
      size: "xl",
      windowClass: "animated FadeIn",
    });
    const sub = modalReCreate.componentInstance.confirm
      .pipe(
        switchMap((res: ordiniAcquistoDto) => {
          console.log("res", res);
          return this.odaInsertDataSource.insertData(res);
        })
      )
      .subscribe(
        (data: CindyCustomResponse) => {
          //insert http.post(data)
          if (data.codEsito == "OK") {
            let listaOda: ordiniAcquistoDto[] = JSON.parse(data.dati);
            let lotto = listaOda[0].lotto.toString();
            let codiceFornitore = listaOda[0].codiceFornitore.toString();
            let tagLancio = listaOda[0].tagLancio.toString();
            this.searchTermFornitore = codiceFornitore;
            this.searchTermTagLancio = tagLancio;
            this.searchTermLotto = lotto;
          }

          modalReCreate.componentInstance.onClose();
          this.loadData();

          this.alertSrv.success(
            Globals.printCreateMessageSuccess("L'Ordine d'Acquisto")
          );
          console.log(data);
        },
        (err) => {
          console.log("errore", err);
          this.alertSrv.error(
            Globals.printCreateMessageFailure("l'Ordine d'Acquisto")
          );
        }
      );
  }
}
