export const Variables = {

    evaso: "Evaso",
    ordinato: "Ordinato",
    propostaOrdine:"PO",
    simulato:"simulato",
    simulazione:"simulazione",
    lavorazione: "lavorazione",
    vendita: "vendita",
    creato:"creato",
    creata:"creata",
    annullato:"annullato",

    idSimulato:"74"

}

export const stringUrl ={
    preCancellazioneOrdine:"preCancellazioneOrdine",
    annullaOrdineCliente:"annullaOrdineCliente",
    annullaRiga:"annullaRigaOrdineCliente",
    previsioniProduzione: "datiReportPrevisioniVenditaByStagione",
    ddtPrecompilato: "ddtPrecompilato"
}

