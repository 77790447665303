import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";

@Component({
  selector: 'app-nota-testata-oda',
  templateUrl: './nota-testata-oda.component.html',
  styleUrls: ['./nota-testata-oda.component.css']
})
export class NotaODAComponent {
  @Output() confirm = new EventEmitter<string>();
  nota:string=''
  titolo:string = ''
  constructor(  public activeModal: NgbActiveModal,
    private modalservice: NgbModal) { }

  ngOnInit(): void {
    console.log("lotto",this.nota)
  }

  onSubmit(form: NgForm): void {
    const isvalid = form.invalid;
    const ctrinvalid = this.findInvalidControls(form);
    if (form.invalid === true) {
      alert("Dati mancanti!");
      this.onClose();
      return;
    }

    this.nota= form.value.nota;
    console.log("nota submit",form.value.nota)
    this.onClose()
   this.confirm.emit(this.nota.toString().trim());
     

  }

  error(err: any): void {
    console.log("errore", err.error);
    //alert('Si è verificato un errore nella scrittura dei dati!');
    const modalRefErrore = this.modalservice.open(
      PopUpIstanzaEsistenteComponent,
      {
        size: "sm",
        windowClass: "animated FadeIn",
      }
    );
    modalRefErrore.componentInstance.codiceErrore = err.error.codEsito;
    // modalRefErrore.componentInstance.messaggioEventuale = err.error.dati;

    this.onClose();
  }

  onClose(): void {
    this.activeModal.close("close");
  }

  public findInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  onDelete(){

  }
  ngOnDestroy() {}
}
