import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { ItemFilter } from "src/app/_filters/ItemFilter";
import { RecapitiDto } from "src/app/_models/recapiti/recapiti-dto";
import { AService } from "src/app/_services/AService";
import { State } from "src/app/_models/State";
import { RecapitiState } from "../state/recapitiState";
import { Province } from "src/app/_models/localizzazioni/province";
import { Nazioni } from "src/app/_models/localizzazioni/nazioni";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import {
  NazioniService,
  ProvinceService,
} from "src/app/_services/global List Services/GlobalList.service";
import { HttpClient } from "@angular/common/http";
import { JsonDataSource } from "src/app/_datasources/JsonDataSource";


class RecapitiMapper implements IDTOMapper<RecapitiDto> {
  map(data: any): RecapitiDto {
    let recapito: RecapitiDto = new RecapitiDto();

    recapito.extCode = data.extCode;
    recapito.idAzienda = data.idAzienda;
    recapito.idRecapito = data.idRecapito;
    recapito.nomeRecapito = data.nomeRecapito;
    recapito.tipoRecapito = data.tipoRecapito;
    recapito.validUntil = data.validUntil;
    recapito.dettagliRecapito = data.dettagliRecapito;

    return recapito;
  }
}

@Injectable({
  providedIn: "root",
})
export class RecapitiService extends AService<RecapitiDto> {
  private _listaProvince: Province[];
  private _listaNazioni: Nazioni[];

  nazioniDataSource: JsonDataSource;
  provinceDataSource: JsonDataSource;
  constructor(
    public nazioniSrv: NazioniService,
    public provinceSrv: ProvinceService,
    private http: HttpClient
  ) {
    super();
    this.nazioniDataSource = new JsonDataSource("nazioni.json",this.http);
  
    this.nazioniSrv.connectDataSource(this.nazioniDataSource);

    this.provinceDataSource = new JsonDataSource("province.json",this.http);
   
    this.provinceSrv.connectDataSource(this.provinceDataSource);
  }


  override loadState(): Observable<this> {
    this.nazioniSrv
      .loadState()
      .subscribe((service) => (this._listaNazioni = service.state().items));
    this.provinceSrv
      .loadState()
      .subscribe((service) => (this._listaProvince = service.state().items));
    return super.loadState();
  }

  state(): RecapitiState {
    let currentState: RecapitiState = new RecapitiState();

    currentState.listaNazioni=this._listaNazioni
    currentState.listaProvince=this._listaProvince
    currentState.currentSize = this._resultItems.length;
    currentState.totalSize = this._items.length;
    currentState.items = this._resultItems;
    currentState.paginatedItems = this._paginatedItems;

    return currentState;
  }

  mapper: IDTOMapper<RecapitiDto> = new RecapitiMapper();
}
