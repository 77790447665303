import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export  enum LogLevel {

  NONE=0,
  ERROR=1,
  WARN=2,
  LOG=3,
  INFO=4
  
    
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  logLevelDev:LogLevel  = LogLevel.LOG;

  logLevelProd:LogLevel  = LogLevel.ERROR;


  constructor() { }

  setLogLevelDev(ll:LogLevel){

    this.logLevelDev= ll;
  }

  setLogLevelProd(ll:LogLevel){

    this.logLevelProd= ll;
  }

  info(data:any,formatString:string="INFO: ",caller:string=(new Error()).stack.split("\n")[2].trim().split(" ")[1]){

    let currentLogLevel = environment.production == false ? this.logLevelDev : this.logLevelProd;
   
    
    if(currentLogLevel >= LogLevel.LOG)
      console.info(caller+" "+ formatString,data);  
      
  }

  log(data:any,formatString:string="LOG: ",caller:string=(new Error()).stack.split("\n")[2].trim().split(" ")[1]){

    let currentLogLevel = environment.production == false ? this.logLevelDev : this.logLevelProd;
     
    if(currentLogLevel >= LogLevel.LOG)
      console.log(caller + " " + formatString,data);  
      
  }

   warn(data:any,formatString:string="WARN: ",caller:string=(new Error()).stack.split("\n")[2].trim().split(" ")[1]){

    let currentLogLevel = environment.production == false ? this.logLevelDev : this.logLevelProd;
   
    if(currentLogLevel >= LogLevel.WARN)
      console.warn(caller+" "+ formatString,data);  
      
  }

  error(data:any,formatString:string="ERROR: ",caller:string=(new Error()).stack.split("\n")[2].trim().split(" ")[1]){

    let currentLogLevel = environment.production == false ? this.logLevelDev : this.logLevelProd;
   
    if(currentLogLevel >= LogLevel.ERROR)
      console.error(caller+" "+ formatString,data);  
      
  }



}
