import { Component, OnInit } from "@angular/core";
import { Subject, Observable } from "rxjs";

import { TipoSearch } from "src/app/_models/TipoSearch";


import { AllComboboxDataDto } from "../../../_models/allcomboboxdata/allcomboboxdata-dto";
// import { FabbisogniDataSource } from "src/app/_datasources/allDataSources";

@Component({
  selector: "app-calcolo-fabbisogni",
  templateUrl: "./calcolo-fabbisogni.component.html",
  styleUrls: ["./calcolo-fabbisogni.component.css"],
})
export class CalcoloFabbisogniComponent implements OnInit {
  search: TipoSearch;
  alltype: AllComboboxDataDto;
  // datasource: FabbisogniDataSource;
  datasource$;
  rows;
  displayedRows;
  total$: Observable<number>;
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";
  destroy: Subject<boolean> = new Subject<boolean>();

/*   constructor(
    private allComboData: ApiAllComboBoxdataService,
    private fabbisogniSrv: FabbisogniService,
    private tableexcelService: GestioneFileService
  ) {
    this.search = new TipoSearch();
    this.search.searchFieldList = ["codPorto", "descPorto"];

    if (environment.useLocalData == true) {
      this.fabbisogniSrv.setVariablesApi(
        "../../assets/data/porti.json",
        "porto",

        "http://localhost:4200"
      );
    }

    this.datasource = new FabbisogniDataSource(this.fabbisogniSrv, this.search);
  } */

  set searchTerm(searchString: string) {
    this.search.searchString = searchString;
    this.search.items = this.rows;
    // this.datasource.loadPaggedData(
    //   this._page,
    //   this._pageSize,
    //   this._sortColumn,
    //   this._sortDirection
    // );
  }

  get searchTerm() {
    return this.search.searchString;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;
    this.reloadData();
  }
  get pageSize() {
    return this._pageSize;
  }

  set page(newPage: number) {
    this._page = newPage;
    this.reloadData();
  }

  get page() {
    return this._page;
  }

/*   loadData() {
    this.allComboData.getAllComboboxData().subscribe((response) => {
      this.alltype = response;
    });

    this.datasource$ = this.datasource.connect(null);

    this.reloadData();
    this.datasource$.subscribe((items) => {
      this.displayedRows = items;
      if (this.searchTerm.trim().length == 0) {
        this.rows = items;
      }
    });
    this.total$ = this.datasource.total$;
  }
 */
  ngOnInit(): void {
 //   this.loadData();
  }

  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");

    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.reloadData();
  }

  reloadData() {
    // this.datasource.loadPaggedData(
    //   this._page,
    //   this._pageSize,
    //   this._sortColumn,
    //   this._rotateDirection
    // );
  }

  ngOnDestroy(): void {
    // this.datasource.disconnect(null);
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

/*   getExcelData(): void {
    this.tableexcelService.exportAsExcelFile(this.rows, "Tabella Porti EXCELL");
  } */
}
