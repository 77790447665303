import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserDto } from 'src/app/_models/utenti/usersDto';
import { CrudService } from 'src/app/_services/crud.service';
import { UserMapper } from '../mappers/usersMapper';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class UsersCRUDService extends CrudService<UserDto>{

 
  constructor(httpClient: HttpClient,
    public alertSrv: AlertService,
    ) { 
    super(httpClient,alertSrv);
    this.serverPort=environment.userRolePort
    this.endpoint= 'user'
    this.endpointAll='users';
    this.mapper= new UserMapper()
  
  }
}
