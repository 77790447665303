import { Ability, AbilityClass } from "@casl/ability";

type Actions = "create" | "read" | "update" | "delete";

type SubjectsComponenti = "CREALANCIO";

type SubjectsMenu =
  | "ANAGRAFICHE"
  | "ORDINI"
  | "CONFIGURAZIONE"
  | "LANCIO"
  | "ORDINIACQUISTO"
  | "AZIENDE"
  | "IMBALLI"
  | "SCALATURE"
  | "SVILUPPITAGLIE"
  | "TIPIORDINE"
  | "PORTI"
  | "VISUALIZZA"
  | "CARICAFILEORDINE"
  | "IMPORTAZIONI"
  | "GESTIONEIMPORTAZIONI"
  | "ANAGRAFICHESNELLE"
  | "REPORTAVV"
  | "LAVORAZIONEARTICOLI"
  | "ARTICOLI"
  | "MATERIALI";

type Subjects = "All" | SubjectsMenu | SubjectsComponenti;

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
