import {
  Component,
  OnInit,
  OnDestroy,
  ViewChildren,
  ElementRef,
  AfterViewInit,
  QueryList,
  ViewChild,
  Directive,
} from "@angular/core";
import {
  Subject,
  Observable,
  combineLatest,
  throwError,
  BehaviorSubject,
  merge,
} from "rxjs";
import {
  catchError,
  map,
  mergeAll,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { AlertService } from "src/app/_services/alert.service";
import { Ordine, OrdiniDto } from "src/app/featureOrdini/models/ordiniDto";
import { Router } from "@angular/router";
import { OrdiniEditComponent } from "../ordini-edit/ordini-edit.component";

import { FabbisogniService } from "../../service/fabbisogni.service";
import { ScalatureDto } from "src/app/featureScalature/models/scalature-dto";
import { PopUpIstanzaEsistenteComponent } from "src/app/_popups/pop-up-istanza-esistente/pop-up-istanza-esistente.component";
import { OrdiniProduzioneService } from "../../service/ordini-produzione.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
import { AndFilter } from "src/app/_filters/AndFilter";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { OrFilter } from "src/app/_filters/OrFilter";
import { OrdiniState } from "../state/ordiniState";
import { LoggerService } from "src/app/shared/services/logger.service";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { OrdiniAcquistoService } from "src/app/featureOrdiniAcquisto/service/ordini-acquisto.service";
import { ordiniAcquistoDto } from "src/app/_models/ordiniAcquisto/ordiniAcquistoDto";
import { PopUpConfermaComponent } from "src/app/_popups/pop-up-conferma/pop-up-conferma.component";
import { PopUpUploadOrdiniComponent } from "src/app/_popups/pop-up-upload-ordini/pop-up-upload-ordini.component";
import { AvviaLancioProduzioneComponent } from "src/app/featureLancioProd/component/avvia-lancio-produzione/avvia-lancio-produzione.component";
import { LancioProdEditComponent } from "src/app/featureLancioProd/component/lancio-prod-edit/lancio-prod-edit.component";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { environment } from "src/environments/environment";
import { AbilityService } from "@casl/angular";
import { AppAbility } from "src/app/shared/services/AppAbility";
import { OrdiniProdSearchService } from "../../service/ordini-prod-search.service";
import {
  NgbdSortableHeader,
  SortColumn,
  SortEvent,
} from "src/app/_directives/sortable.directive";
import { CrudService } from "src/app/_services/crud.service";
import { compare } from "src/app/_services/search.service";
import { AziendeSearchService } from "src/app/featureMain/service/aziende-search.service";
import { OrdiniProdCRUDService } from "../../service/ordini-prod-crud.service";
import { SviluppiTaglieCRUDService } from "src/app/featureSviluppiTaglia/service/sviluppi-taglie-crud.service";
import { RigaOrdineDto } from "../../models/rigaOrdineDto";
import { JsReportService } from "src/app/shared/services/jsReport/js-report.service";
import * as _ from "lodash-es";
import { FabbisogniDto } from "../../models/fabbisogniDto";
import { ErroriService } from "src/app/_services/errori-service.service";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { DistintaBase } from "src/app/_models/articoli/articoliDto";
import { dibaCRUDService } from "../../service/diba-crud.service";
import { isThisQuarter } from "date-fns";
import { SearchComponent } from "src/app/_components/search/search.component";
import { TypeaheadService } from "src/app/_services/typeahead.service";
import { TypeaheadOracleComponent } from "src/app/shared/component/typeahead-oracle/typeahead-oracle.component";
import { TipiOrdineService } from "src/app/featureTipoOrdine/service/tipi-ordine.service";
import { TipiOrdineSearchService } from "src/app/featureTipoOrdine/service/tipi-ordine-search.service";
import { TipiOrdineDto } from "src/app/featureTipoOrdine/models/tipiordine-dto";
import { Variables } from "src/assets/data/variables";
import { TipoAziendaDto } from "src/app/_models/tipoAzienda/tipoAziendaDto";
import { PrevisioneProduzioneDTO } from "../../models/previsioneProduzione";
import moment from "moment";
import { PrevisioneVenditaComponent } from "../previsione-vendita/previsione-vendita.component";

@Component({
  selector: "app-ordini-page-list",
  templateUrl: "./ordini-page-list.component.html",
  styleUrls: ["./ordini-page-list.component.css"],
})
export class OrdiniPageListComponent implements OnInit, AfterViewInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @ViewChildren("checkboxes") listaCheckbox: QueryList<ElementRef> | undefined =
    undefined;
  @ViewChild(SearchComponent) searchInput: SearchComponent;
  @ViewChild("myT") typeAheadTipologiaOrdine: TypeaheadOracleComponent;

  readonly ability$: Observable<AppAbility>;

  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  ordiniProd$: Observable<OrdiniDto[]> | undefined =
    this.ordiniSearchSrv.items$;
  total$: Observable<number> | undefined = this.ordiniSearchSrv.total$;
  ordiniTotali$: Observable<OrdiniDto[]> | undefined = undefined;
  ordiniToSendLancio$: Observable<OrdiniDto[]> | undefined = undefined;
  ordiniEstesiDataSource: RestDataSource;
  oDaDataSource: RestDataSource;
  fabbisogniDataSource: RestDataSource;

  scalatureDataSource: RestDataSource;
  state: OrdiniState = new OrdiniState();
  showFabbisogni = true;

  selectedStato: Boolean[] = [];
  selectedStatoMap: Map<number, boolean> = new Map();
  listaTipiOrdine: TipiOrdineDto[] = [];
  flagStato: boolean = false;

  listaIdOrdiniSelezionati = [];
  stringaIdOrdiniSelezionati: string;
  lottiSelezionati = [];
  selectAll: boolean = false;
  ordiniSelezionati: OrdiniDto[] = [];
  lancioEnabled: boolean;
  onPrintLoading$: Observable<boolean>;
  _onPrintLoadingBSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  onSimulatoEnabled$: Observable<boolean>;
  _onSimulatoEnabledBSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  listaIdObs$: Observable<number[]>;
  searchingTipoOrdine;
  searchFailedTipoOrdine;
  search;
  displayTipoSelezionato;
  tipoSelezionato = null;

  listaTipiOrdine$: Observable<TipiOrdineDto[]> = this.tipiOrdineSrv.loadAll();

  constructor(
    private modalservice: NgbModal,
    private ordiniService: OrdiniProduzioneService,
    private alertSrv: AlertService,
    private readonly router: Router,
    private fabbisogniSrv: FabbisogniService,
    private reportSrv: JsReportService,
    private http: HttpClient,
    private loggerService: LoggerService,
    private oDaService: OrdiniAcquistoService,
    private ordiniSearchSrv: OrdiniProdSearchService,
    public sviluppiCRUD: SviluppiTaglieCRUDService,
    public ordiniProdCRUD: OrdiniProdCRUDService,
    abilityService: AbilityService<AppAbility>,
    public errorSrv: ErroriService,
    public dibaSrv: dibaCRUDService,
    public typeaheadSrv: TypeaheadService,
    public tipiOrdineSrv: TipiOrdineSearchService
  ) {
    this.searchingTipoOrdine = this.typeaheadSrv.searchingTipoOrdine;
    this.searchFailedTipoOrdine = this.typeaheadSrv.searchFailedTipoOrdine;
    this.search = this.typeaheadSrv.searchTipiOrdine;
    this.typeaheadSrv.listaTipiOrdine$ = tipiOrdineSrv.loadAll();
    this.listaTipiOrdine$.subscribe((res) => (this.listaTipiOrdine = res));

    this.onPrintLoading$ = this._onPrintLoadingBSubject.asObservable();
    this.onSimulatoEnabled$ = this._onSimulatoEnabledBSubject.asObservable();

    this.ordiniTotali$ = this.ordiniProdCRUD.getAll().pipe(
      map((res) => {
        console.log(JSON.parse(res.dati));
        return JSON.parse(res.dati);
      })
    );
    console.log("", this.lancioEnabled);
    this.ordiniToSendLancio$ = this.ordiniTotali$.pipe(
      tap((res) => console.log("tap ordiniTotali", res)),
      map((res: OrdiniDto[]) => {
        let arrayordiniPerLancio = [];
        this.listaIdOrdiniSelezionati.forEach((id) => {
          let ordine = res.find((ord) => ord.ordine.idOrdine === id);
          if (ordine !== undefined) {
            arrayordiniPerLancio.push(ordine);
          }
        });

        return arrayordiniPerLancio;
      })
    );

    this.ability$ = abilityService.ability$;

    /*  this.ordiniEstesiDataSource = new RestDataSource(this.http);
    this.ordiniEstesiDataSource.setVariablesApi(
      "allOrdiniEstesi",
      "ordineEsteso",
      "ordineEsteso",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );

    this.ordiniEstesiDataSource.setByIdField("idOrdine");
    this.ordiniService.connectDataSource(this.ordiniEstesiDataSource); */

    this.oDaDataSource = new RestDataSource(this.http);
    this.oDaDataSource.setVariablesApi(
      "",
      "creaLottoAcquistoAutomatico",
      "creaLottoAcquistoAutomatico",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );

    this.oDaDataSource.setByIdField("listaOrdini");
    this.oDaService.connectDataSource(this.oDaDataSource);

    this.fabbisogniDataSource = new RestDataSource(this.http);
    this.fabbisogniDataSource.setVariablesApi(
      "",
      "fabbisogniOrdini",
      "fabbisogniOrdini",
      `${environment.baseDomain}:${environment.ordiniPort}`
    );

    this.fabbisogniDataSource.setByIdField("listaOrdini");
    this.fabbisogniSrv.connectDataSource(this.fabbisogniDataSource);

    this.ordiniSearchSrv.filterItemFunction = this.filterOrdiniProd;
    this.ordiniSearchSrv.sortItemFunction = this.sortOrdiniProd;
  }

  onShowTipiOrdine(lista: TipiOrdineDto[], id: number): string {
    let match = lista.find((to) => to.idTipoOrdine == id);

    if (match) {
      return match.descTipoOrdine;
    } else {
      return "";
    }
  }

  listaTipiOrdineSelezionati = [];

  onChangeCheckbox(evento, idOrdine, lotto, idTipoOrdine, idstagione) {
    console.log("evento", evento.target.checked);
    console.log("evento", idstagione);
    if (evento.target.checked == true) {
      this.listaIdOrdiniSelezionati.push(idOrdine);

      this.listaTipiOrdineSelezionati.push({
        idTipoOrdine: idTipoOrdine,
        idOrdine: idOrdine,
        idStagione: idstagione,
      });
    } else {
      let index = this.listaIdOrdiniSelezionati.indexOf(idOrdine);
      // let indexTipo = this.listaTipiOrdineSelezionati.indexOf(idTipoOrdine);
      //this.listaTipiOrdineSelezionati.splice(indexTipo, 1);

      let indexTipo = this.listaTipiOrdineSelezionati.indexOf({
        idTipoOrdine: idTipoOrdine,
        idOrdine: idOrdine,
        idStagione: idstagione,
      });
      this.listaTipiOrdineSelezionati.splice(indexTipo, 1);
      this.listaIdOrdiniSelezionati.splice(index, 1);
    }

    if (
      evento.target.checked == true &&
      lotto !== null &&
      lotto !== undefined
    ) {
      this.lottiSelezionati.push(lotto);
    }
    if (
      evento.target.checked == false &&
      lotto !== null &&
      lotto !== undefined
    ) {
      let index = this.lottiSelezionati.indexOf(lotto);

      this.lottiSelezionati.splice(index, 1);
    }
    console.log("listaIdOrdiniSelezionati", this.listaIdOrdiniSelezionati);

    this.stringaIdOrdiniSelezionati = this.listaIdOrdiniSelezionati.join(",");
    console.log("lista", this.stringaIdOrdiniSelezionati);
    if (this.listaIdOrdiniSelezionati.length == 0) {
      this.lancioEnabled = false;
      this._onSimulatoEnabledBSubject.next(false);
    } else {
      this.lancioEnabled = true;

      let listaSimulati = this.listaTipiOrdineSelezionati.filter(
        (tipo: TipiOrdineDto) =>
          tipo.idTipoOrdine == parseInt(Variables.idSimulato)
      );

      console.log(
        "listaTipiOrdineSelezionati",
        this.listaTipiOrdineSelezionati
      );
      console.log("lf", listaSimulati);

      console.log(
        "listaTipiOrdineSelezionati",
        this.listaTipiOrdineSelezionati
      );

      if (
        listaSimulati.length == this.listaTipiOrdineSelezionati.length &&
        listaSimulati.length == 1
      ) {
        this._onSimulatoEnabledBSubject.next(true);
        this.lancioEnabled = false;
      } else {
        if (listaSimulati.length > 1) {
          this.alertSrv.warning(
            "Seleziona un solo Ordine Simulato per stampare Previsione di Produzione"
          );
          this.lancioEnabled = false;

          this._onSimulatoEnabledBSubject.next(false);
        }
      }
    }
  }
  onChangeAll(event) {
    this.listaIdObs$ = this.ordiniProd$.pipe(
      map((res: OrdiniDto[]) => {
        let listaId: number[] = [];
        if (event.target.checked == true) {
          res.forEach((ord) => {
            if (
              ord.ultimaVariazioneStato &&
              ord.ultimaVariazioneStato.descrizione !==
                Variables.annullato.toUpperCase()
            ) {
              listaId.push(ord.ordine.idOrdine);
              this.listaTipiOrdineSelezionati.push({
                idTipoOrdine: ord.ordine.idTipoOrdine,
                idOrdine: ord.ordine.idOrdine,
                idStagione: ord.ordine.idStagione,
              });
            }
          });
        } else {
          listaId = [];
          this.listaTipiOrdineSelezionati = [];
        }
        return listaId;
      })
    );

    if (event.target.checked == true) {
      this.listaCheckbox.forEach((element) => {
        element.nativeElement.checked = true;
      });
    } else {
      this.listaCheckbox.forEach((element) => {
        element.nativeElement.checked = false;
      });
    }
    this.listaIdObs$.subscribe((res) => {
      this.listaIdOrdiniSelezionati = res;
      if (res.length == 0) {
        this.lottiSelezionati = [];
        this.listaTipiOrdineSelezionati = [];
      }
      this.stringaIdOrdiniSelezionati = this.listaIdOrdiniSelezionati.join(",");

      if (this.listaIdOrdiniSelezionati.length == 0) {
        this.lancioEnabled = false;
        this._onSimulatoEnabledBSubject.next(false);
      } else {
        this.lancioEnabled = true;
        this._onSimulatoEnabledBSubject.next(false);

        /*  let lF = this.listaTipiOrdineSelezionati.filter(
          (tipo) =>
            tipo.descTipoOrdine.toUpperCase() ==
            Variables.simulato.toLocaleUpperCase()
        );
        console.log(
          "listaTipiOrdineSelezionati",
          this.listaTipiOrdineSelezionati
        );
        console.log("lF", lF);

        if (lF.length == this.listaTipiOrdineSelezionati.length) {
          this._onSimulatoEnabledBSubject.next(true);
        } else {
          this._onSimulatoEnabledBSubject.next(false);
        }
 */
      }
    });
    console.log("idArray", this.listaIdOrdiniSelezionati);
    console.log("stringa", this.stringaIdOrdiniSelezionati);
  }

  ngOnDestroy(): void {
    // this.ordiniSearchSrv.searchTerms = [""];
  }

  onSearchTermChanged(searchTerm: string) {
    //qui assegno il filtro specifico alla funzione generica che c'è sul service di search

    this.ordiniSearchSrv.searchTerms = [searchTerm];
  }

  filterOrdiniProd(ordine: OrdiniDto, searchTerms: string[]): boolean {
    let hasTagLancio: boolean =
      ordine.ordine.tagLancio !== undefined && ordine.ordine.tagLancio !== null
        ? true
        : false;

    let hasTipoOrdine: boolean =
      ordine.ordine.idTipoOrdine !== undefined &&
      ordine.ordine.idTipoOrdine !== null
        ? true
        : false;

    let match: boolean;
    if (
      (hasTagLancio &&
        ordine.ordine.tagLancio
          .toString()
          .toLowerCase()
          .includes(searchTerms[0])) ||
      Ordine.getCodiceOrdine(ordine.ordine)
        .toString()
        .toLowerCase()
        .includes(searchTerms[0]) ||
      ordine.stagione.anno.toString().toLowerCase().includes(searchTerms[0]) ||
      (ordine.lotto !== null &&
        ordine.lotto.toString().toLowerCase().includes(searchTerms[0])) ||
      ordine.stagione.numero
        .toString()
        .toLowerCase()
        .includes(searchTerms[0]) ||
      (hasTipoOrdine &&
        ordine.ordine.idTipoOrdine
          .toString()
          .toLowerCase()
          .includes(searchTerms[1])) ||
      searchTerms[0] == ""
    ) {
      match = true;
    } else {
      match = false;
    }

    return match;
  }

  getOrdineLabel(ordine: OrdiniDto) {
    return Ordine.getCodiceOrdine(ordine.ordine);
  }

  sortOrdiniProd(items: OrdiniDto[], column: SortColumn, direction: string) {
    if (direction === "" || column === "") {
      return items;
    } else {
      return [...items].sort((a, b) => {
        let res: number = 0;
        if (column.toLowerCase() == "stagione") {
          let stagioneA =
            a.stagione.anno.toString() + a.stagione.numero.toString();
          let stagioneB =
            b.stagione.anno.toString() + b.stagione.numero.toString();
          res = compare(stagioneA, stagioneB);
        } else if (column == "lotto") {
          res = compare(a[column], b[column]);
        } else {
          res = compare(a.ordine[column], b.ordine[column]);
        }
        return direction === "asc" ? res : -res;
      });
    }
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    console.log("header", { column, direction });

    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.ordiniSearchSrv.sortColumn = column;
    this.ordiniSearchSrv.sortDirection = direction;
  }

  controlloScalatureSviluppi(datiAggiuntivi?) {
    const modalRef = this.modalservice.open(PopUpUploadOrdiniComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.showButton = false;

    modalRef.componentInstance.progressMessage =
      Globals.MappaMessaggiErrore.get("KO.B3");
    if (datiAggiuntivi !== undefined) {
      modalRef.componentInstance.optionalMessage =
        datiAggiuntivi.descErrore.toLowerCase();
    }
    const sub = modalRef.componentInstance.confirm.pipe(
      map((data) => {
        console.log("dati scalature", data);
      })
    );
  }

  lottoParams: string;

  onRedirectLotto(lotto) {
    if (lotto && lotto !== "") {
      let paramsString = lotto.toString();
      //console.log("lottoParams", cmp.lottoParams);
      this.router.navigate(["OrdiniAcquisto"], {
        queryParams: { paramsString: paramsString },
      });
    }
  }

  onRedirecTagLancio(tagLancio) {
    if (tagLancio && tagLancio !== "") {
      let paramsString = "" + "+" + tagLancio.toString();
      //console.log("lottoParams", cmp.lottoParams);
      this.router.navigate(["OrdiniAcquisto"], {
        queryParams: { paramsString: paramsString },
      });
    }
  }

  onOrdiniAcquisto() {
    let listaOrdini = this.stringaIdOrdiniSelezionati;
    console.log("lottiSelezionati", this.lottiSelezionati);

    if (this.lottiSelezionati.length == 0) {
      //let cmp = this;
      this.oDaService.getDataByMultipleId(listaOrdini).subscribe({
        next: (res: CindyCustomResponse) => {
          // console.log("oda", res)

          if (res && res.codEsito == "OK") {
            this.ordiniSearchSrv.searchTerms = [""];
            this.searchInput.clear();
            let listaOda: ordiniAcquistoDto[] = JSON.parse(res.dati);
            console.log("listaOda", listaOda);

            let lotto = listaOda[0].lotto.toString();
            let tagLancio = listaOda[0].tagLancio.toString();
            let paramsString = lotto + "+" + tagLancio;
            //console.log("lottoParams", cmp.lottoParams);
            this.router.navigate(["OrdiniAcquisto"], {
              queryParams: { paramsString: paramsString },
            });
          } else {
            this.controlloScalatureSviluppi();
          }
        },
        error: (err) => {
          this.ordiniSearchSrv.searchTerms = [""];
          this.listaIdOrdiniSelezionati = [];
          

          this.searchInput.clear();
          console.log("errore500", err);
          this.onErroreSubscribe(err.error);
        },
      });
    } else {
      const modalRefConferma = this.modalservice.open(PopUpConfermaComponent, {
        size: "sm",
        windowClass: "animated FadeIn",
      });
      modalRefConferma.componentInstance.codiceConferma = "cofermaLotto";
      const sub = modalRefConferma.componentInstance.confirm
        .pipe(
          switchMap((res) => {
            if (res) {
              return this.oDaService.getDataByMultipleId(listaOrdini);
            }
          })
        )
        .subscribe(
          (res: CindyCustomResponse) => {
            this.ordiniSearchSrv.searchTerms = [""];
            this.lancioEnabled = false;
            this._onSimulatoEnabledBSubject.next(false);
            this.searchInput.clear();
            if (res && res.codEsito == "OK") {
              let listaOda: ordiniAcquistoDto[] = JSON.parse(res.dati);
              if (listaOda.length !== 0) {
                let lotto = listaOda[0].lotto.toString();
                let tagLancio = listaOda[0].tagLancio.toString();
                let paramsString = lotto + "+" + tagLancio;
                //console.log("lottoParams", cmp.lottoParams);
                this.router.navigate(["OrdiniAcquisto"], {
                  queryParams: { paramsString: paramsString },
                });
              }
            }
          },
          (err) => {
            this.ordiniSearchSrv.searchTerms = [""];
            this.listaIdOrdiniSelezionati = [];
            this.lancioEnabled = false;
            this._onSimulatoEnabledBSubject.next(false);

            this.searchInput.clear();
            console.log("errore", err);
            this.onErroreSubscribe(err.error);
            console.log("dati", err.error.dati);
          }
        );
    }
  }

  onErroreSubscribe(err) {
    if (err.codEsito == "KO.B3") {
      let errore = err.dati;
      let oggettoErrore = JSON.parse(errore);
      let strigaErrore = oggettoErrore.descErrore;
      console.log("errore", strigaErrore.descErrore);

      const modalRef = this.modalservice.open(PopUpUploadOrdiniComponent, {
        size: "md",
        windowClass: "animated FadeIn",
      });
      modalRef.componentInstance.showButton = true;
      modalRef.componentInstance.progressMessage =
        "L'ordine selezionato non trova le seguenti relazioni su Cindy:";
      modalRef.componentInstance.optionalMessage = strigaErrore;
      modalRef.componentInstance.optionalMessage1 =
        "Inserire i dati mancanti e riprovare";

      const sub = modalRef.componentInstance.confirm.pipe(
        map((data) => {
          console.log(data);
        })
      );
    } else if (err.codEsito == codiciErrore.erroreLancio) {
      this.errorSrv.onToastTestata(
        Globals.MappaMessaggiErrore.get(codiciErrore.DIBAMancante)
      );
    } else if (err.codEsito == codiciErrore.erroreCalcoloFabbisogni) {
      this.errorSrv.onToastTestata(
        Globals.MappaMessaggiErrore.get(codiciErrore.erroreCalcoloFabbisogni)
      );
    } else {
      let errore = err.dati;
      console.log("errore", errore);
      this.alertSrv.error("Impossibile completare l'operazione");
    }
  }

  /*   filterOrdini(service: OrdiniProduzioneService) {
    this.state = service
      .filter(
        new OrFilter([
          new FieldsFilter({
            fieldList: ["numero", "anno", "tagLancio"],
            searchString: this._searchTerm == undefined ? "" : this._searchTerm,
            child: "ordine",
          }),
      
        ])
      )
      .sort(this._sortColumn, this._sortDirection)
      .paginate(this._page, this.pageSize)
      .state();

    this.selectedStato = new Array<Boolean>(this.state.items.length);
  } */

  ngOnInit(): void {
    let utente = JSON.parse(localStorage.getItem("userData"));

    this.ordiniSearchSrv.page$.subscribe((res) => {
      this.listaIdOrdiniSelezionati = [];
      this.lottiSelezionati = [];
    });
    this.ordiniSearchSrv.sortColumn = "idOrdine";
    this.ordiniSearchSrv.sortDirection = "desc";

    // this.ordiniSearchSrv.params = [{ variable: "usrId", value: utente.userID }];
  }

  /*  onSelezione(checked: boolean, index: number) {
    this.selectedStato[index] = checked;
    console.log(this.selectedStato);
  } */

  mappaArticoliRiga: Map<number, DistintaBase> = new Map();
  enableDIBA = true;

  onOrdiniCreateOrModify(index: number, ord?: OrdiniDto): void {
    let modalRef: any = null;

    modalRef = this.modalservice.open(OrdiniEditComponent, {
      size: "xl",
      windowClass: "animated FadeIn",
    });

    let ordine: OrdiniDto = new OrdiniDto();
    let typeop = "ins";

    if (index >= 0) {
      ordine = ord;

      typeop = "upd";
    }

    modalRef.componentInstance.currentItem = _.cloneDeep(ordine);
    modalRef.componentInstance.listaAziende = this.state.listaAziende.sort(
      (a1: AziendeDto, a2: AziendeDto) =>
        a1.ragioneSociale.trim().localeCompare(a2.ragioneSociale.trim())
    );
    let utente = JSON.parse(localStorage.getItem("userData"));

    modalRef.componentInstance.listaSviluppi$ = this.sviluppiCRUD
      .getAll([{ variable: "userId", value: utente.userID }])
      .pipe(
        map((res) => {
          return JSON.parse(res.dati);
        })
      );
    modalRef.componentInstance.typeop = typeop;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log("ordinePageLIst", data);
          if (typeop == "ins") {
            return this.ordiniProdCRUD.create(data as OrdiniDto);
          } else if (typeop == "upd") {
            if (modalRef.componentInstance.delete == false)
              return this.ordiniProdCRUD.update(data as OrdiniDto);
            else {
              typeop = "del";
              return this.ordiniProdCRUD.delete({
                variable: "idOrdine",
                value: ordine.ordine.idOrdine,
              });
            }
          }
        })
      )
      .subscribe(
        (response: CindyCustomResponse) => {
          console.log("response", response);
          /*           console.log("response", JSON.parse(response.dati));
           */ let stringaResponse: string = response.dati;
          if (!(stringaResponse.indexOf("KO") !== -1)) {
            modalRef.componentInstance.onClose();
            this.ordiniSearchSrv.searchTerms = [""];
            this._sortColumn = "numero";
            this._sortDirection = "desc";
            this._rotateDirection = "desc";
            if (typeop == "ins")
              this.alertSrv.success(
                Globals.printCreateMessageSuccess("L'Ordine")
              );
            else if (typeop == "upd") {
              this.alertSrv.success(
                Globals.printModifyMessageSuccess("L'Ordine")
              );
            } else {
              this.alertSrv.success(
                Globals.printDeleteMessageSuccess("L'Ordine")
              );
            }
          } else {
            if (
              response &&
              response.dati &&
              JSON.parse(response.dati) &&
              JSON.parse(response.dati).KO.length !== 0
            ) {
              let codiceErrore = JSON.parse(JSON.parse(response.dati).KO)[0]
                .codiceErrore;

              console.log("codice Errore", codiceErrore);

              if (
                codiceErrore.toString().toUpperCase().trim() ==
                codiciErrore.erroreStato.toString().toUpperCase().trim()
              ) {
                this.errorSrv.onToastTestata(
                  "Stato dell'ordine non riconosciuto"
                );
              } else if (
                codiceErrore.toString().toUpperCase().trim() ==
                codiciErrore.erroreStatoRiga.toString().toUpperCase().trim()
              ) {
                this.errorSrv.onToastTestata(
                  "Stato della riga non riconosciuto"
                );
              }
            } else {
              if (typeop == "ins")
                this.alertSrv.error(
                  Globals.printCreateMessageFailure("l'Ordine")
                );
              else if (typeop == "upd") {
                this.alertSrv.error(
                  Globals.printModifyMessageFailure("l'Ordine")
                );
              } else {
                this.alertSrv.error(
                  Globals.printDeleteMessageFailure("l'Ordine")
                );
              }
            }
          }
        },
        (err) => {
          modalRef.componentInstance.onClose();
          console.log(
            "errore createEditDelete in pagelist",
            err.error.codEsito
          );
          this.errorSrv.OnErrorOrdini(err.error, typeop);
        }
      );
  }

  calcQtyOrdine(odto: OrdiniDto) {
    let righe = odto.righeOrdine;

    let total = 0;
    righe.forEach((r) => {
      total += RigaOrdineDto.getQuantita(r);
    });

    return total;
  }

  onCalcoloFabbisogni() {
    // let stringOfId = this.creaListaOrdiniPerFabbisogni();

    this.generaFabbisogni(this.stringaIdOrdiniSelezionati);
    this.ordiniSearchSrv.searchTerms = [""];
    this.lancioEnabled = false;
    this._onSimulatoEnabledBSubject.next(false);
  }

  prepareFabbisognoToSend(fabbisognoToSend: FabbisogniDto) {
    fabbisognoToSend.componentiPaia.forEach((fCp) => {
      if (fCp.fornitore == null) {
        fCp.fornitore = "";
      }
      if (fCp.fornitore.toString().includes("null")) {
        fCp.fornitore = fCp.fornitore.replaceAll("null", "");
      }
      if (fCp.codice_componente_forn == null) {
        fCp.codice_componente_forn = "";
      }
      if (fCp.codice_componente_forn.toString().includes("null")) {
        fCp.codice_componente_forn = fCp.codice_componente_forn.replaceAll(
          "null",
          ""
        );
      }
      if (fCp.colore == null) {
        fCp.colore = "";
      }
      if (fCp.colore.toString().includes("null")) {
        fCp.colore = fCp.colore.replaceAll("null", "");
      }
    });
    fabbisognoToSend.componentiSviluppo.forEach((fCp) => {
      if (fCp.fornitore == null) {
        fCp.fornitore = "";
      }
      if (fCp.fornitore.toString().includes("null")) {
        fCp.fornitore = fCp.fornitore.replaceAll("null", "");
      }
      if (fCp.codice_componente_forn == null) {
        fCp.codice_componente_forn = "";
      }
      if (fCp.codice_componente_forn.toString().includes("null")) {
        fCp.codice_componente_forn = fCp.codice_componente_forn.replaceAll(
          "null",
          ""
        );
      }
      if (fCp.colore == null) {
        fCp.colore = "";
      }
      if (fCp.colore.toString().includes("null")) {
        fCp.colore = fCp.colore.replaceAll("null", "");
      }
      console.log("col", fCp.colore);
    });
    fabbisognoToSend.componentiScalatura.forEach((fCp) => {
      if (fCp.fornitore == null) {
        fCp.fornitore = "";
      }
      if (fCp.fornitore.toString().includes("null")) {
        fCp.fornitore = fCp.fornitore.replaceAll("null", "");
      }
      if (fCp.codice_componente_forn == null) {
        fCp.codice_componente_forn = "";
      }
      if (fCp.codice_componente_forn.toString().includes("null")) {
        fCp.codice_componente_forn = fCp.codice_componente_forn.replaceAll(
          "null",
          ""
        );
      }
      if (fCp.colore == null || fCp.codice_componente_forn == null) {
        fCp.colore = "";
      }
      if (fCp.colore.toString().includes("null")) {
        fCp.colore = fCp.colore.replaceAll("null", "");
      }
      console.log("col", fCp.colore);
    });
    console.log("fb", fabbisognoToSend);

    return fabbisognoToSend;
  }

  generaFabbisogni(stringOfId: string) {
    this.fabbisogniSrv
      .getDataByMultipleId(stringOfId)
      .pipe(
        map((res: CindyCustomResponse) => {
          this._onPrintLoadingBSubject.next(true);
          this.ordiniSearchSrv.searchTerms = [""];
          this.searchInput.clear();
          let fabbisognoToSend = JSON.parse(res.dati) as FabbisogniDto;
          // console.log("fb", fabbisognoToSend);
          return this.prepareFabbisognoToSend(fabbisognoToSend);
        }),
        mergeMap((res: FabbisogniDto) => {
          let reportToSend = {
            template: { name: "Report_Fabbisogni" },
            data: res,
          };

          return this.reportSrv.getReport(reportToSend);
        })
      )
      .subscribe({
        next: (report) => {
          this._onPrintLoadingBSubject.next(false);

          var newBlob = new Blob([report], { type: "application/pdf" });
          const data = window.URL.createObjectURL(newBlob);
          var link = document.createElement("a");
          link.href = data;
          link.download = "Report Fabbisogni";
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
          this.ordiniSearchSrv.searchTerms = [""];
        },
        error: (err) => {
          this._onPrintLoadingBSubject.next(false);

          this.ordiniSearchSrv.searchTerms = [""];
          this.lancioEnabled = false;
          this._onSimulatoEnabledBSubject.next(false);
          this.listaIdOrdiniSelezionati = [];
          this.searchInput.clear();
          let response: CindyCustomResponse = err.error;
          console.log("errore calcolo fabbisogni", response);

          if (response.codEsito == codiciErrore.erroreOperazione) {
            let datiAggiuntivi = JSON.parse(response.dati);
            this.controlloScalatureSviluppi(datiAggiuntivi);
            console.log("scalature", datiAggiuntivi);
          } else if (
            response.codEsito == codiciErrore.dibaVUOTA ||
            response.codEsito == codiciErrore.erroreLancio
          ) {
            this.errorSrv.onToastTestata(
              Globals.MappaMessaggiErrore.get(codiciErrore.DIBAMancante)
            );
          } else if (
            response.codEsito == codiciErrore.erroreCalcoloFabbisogni
          ) {
            this.errorSrv.onToastTestata(
              Globals.MappaMessaggiErrore.get(
                codiciErrore.erroreCalcoloFabbisogni
              )
            );
          } else {
            this.errorSrv.onToastTestata(
              Globals.MappaMessaggiErrore.get(codiciErrore.assistenza)
            );
          }
        },
      });
  }

  onOpenPrevisioneProduzione() {
    const modalRefLancioEdit = this.modalservice.open(
      PrevisioneVenditaComponent,
      {
        size: "xl",
        windowClass: "animated FadeIn",
      }
    );
    if (this.listaIdOrdiniSelezionati.length == 1) {
    } else {
      this.alertSrv.warning("Selezionare un solo ordine Simulato");
    }
    modalRefLancioEdit.componentInstance.idStagione = parseInt(
      this.listaTipiOrdineSelezionati[0].idStagione
    );
    modalRefLancioEdit.componentInstance.inizioSettimanaPrecedente =
      this.onData()[0];
    modalRefLancioEdit.componentInstance.fineSettimanaPrecedente =
      this.onData()[1];
    modalRefLancioEdit.componentInstance.setListaPrevisioni(
      this.listaTipiOrdineSelezionati[0].idStagione,
      this.onData()[0],
      this.onData()[1]
    );
    const sub = modalRefLancioEdit.componentInstance.confirm
      .pipe(
        map((data: string) => {
          console.log("PrevisioneVenditaComponent", data);
          return data;
        })
      )
      .subscribe((res) => {
        this.listaIdOrdiniSelezionati = [];
        this.listaTipiOrdineSelezionati = [];
        this.searchInput.clear();
        this.ordiniSearchSrv.searchTerms = [""];
        this.lancioEnabled = undefined;
        this._onSimulatoEnabledBSubject.next(false);
      });
  }

  onPrevisioneProduzione(
    idStagione,
    inizioSettimanaPrecedente,
    fineSettimanaPrecedente
  ) {
    /*     let idStagione = parseInt(this.listaTipiOrdineSelezionati[0].idStagione);
    let inizioSettimanaPrecedente = this.onData()[0];
    let fineSettimanaPrecedente = this.onData()[1]; */
    this.ordiniProdCRUD
      .onPrevisioneProduzione(
        idStagione,
        inizioSettimanaPrecedente,
        fineSettimanaPrecedente
      )
      .pipe(
        mergeMap((res: CindyCustomResponse) => {
          this._onPrintLoadingBSubject.next(true);
          let previsioneToSend = JSON.parse(res.dati);
          console.log("previsioneToSend", previsioneToSend.previsioni);

          let reportToSend = {
            template: { name: "Report_Previsione_Produzione" },
            data: previsioneToSend,
          };
          if (previsioneToSend.previsioni.length !== 0) {
            return this.reportSrv.getReport(reportToSend);
          }
        }),
        catchError((err) => {
          console.log("catchError", err);
          // this.errorSrv.onToastTestata("Non ci sono Previsioni per il periodo selezionato")
          this.errorSrv.onToastTestata(
            Globals.MappaMessaggiErrore.get("ERRORBOLLE")
          );
          this.listaIdOrdiniSelezionati = [];
          this.listaTipiOrdineSelezionati = [];
          this.searchInput.clear();
          this.ordiniSearchSrv.searchTerms = [""];
          this.lancioEnabled = undefined;
          this._onSimulatoEnabledBSubject.next(false);

          return throwError(err);
        })
      )
      .subscribe({
        next: (report) => {
          console.log("report", report);
          this._onPrintLoadingBSubject.next(false);

          var newBlob = new Blob([report], { type: "application/pdf" });
          const data = window.URL.createObjectURL(newBlob);
          var link = document.createElement("a");
          link.href = data;
          link.download = "Report_Previsione_Produzione";
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
          this.listaIdOrdiniSelezionati = [];
          this.listaTipiOrdineSelezionati = [];
          this.searchInput.clear();
          this.ordiniSearchSrv.searchTerms = [""];
          this.lancioEnabled = undefined;
          this._onSimulatoEnabledBSubject.next(false);
        },
        error: (err) => {
          this._onPrintLoadingBSubject.next(false);

          this.ordiniSearchSrv.searchTerms = [""];
          this.listaIdOrdiniSelezionati = [];
          this.listaTipiOrdineSelezionati = [];
          this.searchInput.clear();
          this.lancioEnabled = undefined;
          this._onSimulatoEnabledBSubject.next(false);
          let response: CindyCustomResponse = err.error;
          console.log("errore previsione", response);

          if (response != undefined) {
            this.errorSrv.onToastTestata(
              Globals.MappaMessaggiErrore.get("ERRORBOLLE")
            );
          }
        },
      });
  }

  onData() {
    let currentDate = new Date();
    let delta = 7 - +currentDate.getDay();
    let inizioSettimanaCorrente = moment().subtract(delta, "days");
    let lunedìPrecedente = inizioSettimanaCorrente
      .subtract(7, "days")
      .format()
      .split("T");

    console.log("lunedìPrecedente", lunedìPrecedente);
    let inizioSettimanaPrecedente = `${lunedìPrecedente[0]}+00:00:00`;
    console.log("dataPrecedenteToSend", inizioSettimanaPrecedente);
    let domenicaPrecedente = inizioSettimanaCorrente
      .add(6, "days")
      .format()
      .split("T");

    console.log("inizioSettimanaPrecedente", inizioSettimanaPrecedente);

    let fineSettimanaPrecedente = `${domenicaPrecedente[0]}+23:59:59`;

    console.log("fineSettimanaPrecedente", fineSettimanaPrecedente);

    let arrayDate = [];
    arrayDate.push(inizioSettimanaPrecedente);
    arrayDate.push(fineSettimanaPrecedente);

    return arrayDate;
  }

  onErroreDIBA() {
    this.alertSrv.error(
      Globals.MappaMessaggiErrore.get("DIBA"),
      Globals.MappaMessaggiErrore.get("DIBA_dettaglio")
    );
  }

  ngAfterViewInit(): void {
    console.log("ciao", this.typeAheadTipologiaOrdine);
    //this.typeAheadTipologiaOrdine.setSearchFn(this.search);
  }

  onChangetypeAhead(evt) {
    this.displayTipoSelezionato = evt.display;
    console.log("tipo ordine", evt);
    if (evt.id !== 0) {
      this.ordiniSearchSrv.searchTerms = [evt.display, evt.id];

      if (
        evt.display &&
        evt.display.toString().toLowerCase().trim() == Variables.simulato
      ) {
        this._onSimulatoEnabledBSubject.next(true);
      } else {
        this._onSimulatoEnabledBSubject.next(false);
      }
    } else {
      this.ordiniSearchSrv.searchTerms = [""];
    }
  }

  onChangeTipoOrdine(evt) {
    console.log("evt", evt);
    if (evt !== "null") {
      let arrayValue = evt.split("&");
      this.ordiniSearchSrv.searchTerms = [arrayValue[1], arrayValue[0]];

      console.log("arrayValue", arrayValue);
      if (arrayValue[1].toString().toLowerCase().trim() == Variables.simulato) {
        this._onSimulatoEnabledBSubject.next(true);
      } else {
        this._onSimulatoEnabledBSubject.next(false);
      }
    } else {
      this.ordiniSearchSrv.searchTerms = [""];
    }
  }

  tagLancioSelezionato: string;

  onLancioProduzione() {
  
    

    const modalRefLancioEdit = this.modalservice.open(LancioProdEditComponent, {
      size: "xl",
      windowClass: "animated FadeIn",
    });
    let ordiniSelezionati$ = this.ordiniProd$.pipe(
      map((res: OrdiniDto[]) => {
        res.filter((ord) => {
          return ord;
        });
      })
    );
/*     modalRefLancioEdit.componentInstance.setOrdiniSelezionati(
      this.ordiniToSendLancio$
    ); */

    modalRefLancioEdit.componentInstance.listaIdOrdini =
      this.listaIdOrdiniSelezionati;
    modalRefLancioEdit.componentInstance.tagLancio = this.tagLancioSelezionato;
    modalRefLancioEdit.componentInstance.listaSviluppiTotali =
      this.state.listaSviluppi;
    const sub = modalRefLancioEdit.componentInstance.confirm.pipe(
      tap((res)=> {
        this.ordiniSearchSrv.searchTerms = [""];
        this.lancioEnabled = false;
        this._onSimulatoEnabledBSubject.next(false);
        this.listaIdOrdiniSelezionati=[]
        this.listaTipiOrdineSelezionati=[]
        this.searchInput.clear();}),
      switchMap((data: string) => {
        return data
      })
    ).subscribe((res)=>{

    })
  }
}
