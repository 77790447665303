import { Component, OnInit, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { PopUpDeleteComponent } from "src/app/_popups/pop-up-delete/pop-up-delete.component";
import { Globals } from "src/app/_models/Globals";

import { AziendeEditComponent } from "../aziende-edit/aziende-edit.component";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { AlertService } from "src/app/_services/alert.service";
import { AziendeDto } from "src/app/featureMain/models/aziende-dto";
import { AziendeService } from "src/app/_services/allServices";
import { RecapitiDto } from "src/app/_models/recapiti/recapiti-dto";
import { AziendeServiceNew, AziendeState } from "../../service/aziende.service";
import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { HttpClient } from "@angular/common/http";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { RecapitiState } from "src/app/featureRecapiti/state/recapitiState";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";
import { LoggerService } from "src/app/shared/services/logger.service";
import { AndFilter } from "src/app/_filters/AndFilter";
import { environment } from "src/environments/environment";
import { PopUpUploadOrdiniComponent } from "src/app/_popups/pop-up-upload-ordini/pop-up-upload-ordini.component";
import { AziendeCRUDService } from "../../service/aziende-crud.service";
import { codiciErrore } from "src/assets/data/codiciErrore";
import { ErroriService } from "src/app/_services/errori-service.service";
@Component({
  selector: "app-aziende-page-list",
  templateUrl: "./aziende-page-list.component.html",
  styleUrls: ["./aziende-page-list.component.css"],
})
export class AziendePageListComponent implements OnInit, OnDestroy {
  //search: TipoSearch;
  //alltype: AllComboboxDataDto;

  total$: Observable<number>;
  _page = 1;
  _pageSize = 10;
  _sortColumn = "";
  _sortDirection: string;
  _rotateDirection = "";
  state: AziendeState = new AziendeState();
  private _searchTerm: string;
  private _searchTermTipoAzienda: string;

  constructor(
    private modalservice: NgbModal,

    private serviceAziende: AziendeService,
    private alertSrv: AlertService,

    private aziendeService: AziendeServiceNew,
    private aziendeCRUD: AziendeCRUDService,
    private httpClient: HttpClient,
    private errorSrv: ErroriService
  ) {
    let aziendeDataSource = new RestDataSource(this.httpClient);

    aziendeDataSource.setVariablesApi(
      "allAziende",
      "azienda",
      "azienda",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    aziendeDataSource.getDataByIdField = "idAzienda";

    this.aziendeService.connectDataSource(aziendeDataSource);
  }

  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this.filterAziende(this.aziendeService);
  }

  set searchTermTipoAzienda(searchString: string) {
    this._searchTermTipoAzienda = searchString;

    this.filterAziende(this.aziendeService);
  }

  get searchTermTipoAzienda() {
    return this._searchTermTipoAzienda;
  }

  get searchTerm() {
    return this._searchTerm;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.filterAziende(this.aziendeService);
  }
  get pageSize() {
    return this._pageSize;
  }

  set page(newPage: number) {
    this._page = newPage;

    this.filterAziende(this.aziendeService);
  }

  get page() {
    return this._page;
  }

  loadData() {
    //  this.state = null;

    this.aziendeService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterAziende(service);
      },
    });
  }

  tipoAziendaSelezionato;

  onChangeTipoAzienda(tipoAzienda) {
    console.log(tipoAzienda);
    this._searchTermTipoAzienda = tipoAzienda;
    this.filterAziende(this.aziendeService);
  }

  filterAziende(service: AziendeServiceNew) {
    console.log("search", this._searchTermTipoAzienda);
    this.state = service
      .filter(
        new AndFilter([
          new FieldsFilter({
            fieldList: ["indirizzo", "ragioneSociale", "citta", "partitaIvaEstero", "extCode"],
            searchString: this._searchTerm == undefined ? "" : this._searchTerm,
          }),
          new FieldsFilter({
            fieldList: ["tipoAzienda"],
            searchString:
              this._searchTermTipoAzienda == undefined
                ? ""
                : this._searchTermTipoAzienda,
          }),
        ])
      )
      .sort(this._sortColumn, this._sortDirection)
      .paginate(this._page, this.pageSize)
      .state();

    // console.log(this.state, "this.state");
  }

  ngOnInit(): void {
    this.searchTermTipoAzienda = "";
    this.loadData();
    this._sortColumn = "ragioneSociale";
    this._sortDirection = "asc";
    this._rotateDirection = "asc";
  }

  onSort(event): void {
    // this.loggerService.log("event",event.target.getAttribute('sortable'))
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");
    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else {
      event.target.className = this._rotateDirection;
    }
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.filterAziende(this.aziendeService);
  }

  typeop = "";

  insertIstanza(idAzienda) {
    this.aziendeCRUD.registraIstanza(idAzienda).subscribe((res) => {
      console.log("registrazioneIstanza", res);
      if (res) {
      } else {
        this.errorSrv.onToastTestata(
          `${Globals.MappaMessaggiErrore.get(
            codiciErrore.erroreResiduale
          )}. ${Globals.MappaMessaggiErrore.get("KO.GENERICO_dettaglio")}`
        );
      }
    },
    (err)=>{
      console.log("ErroreRegistrazioneIstanza", err);

      this.errorSrv.onToastTestata(
        `${Globals.MappaMessaggiErrore.get(
          codiciErrore.erroreResiduale
        )}. ${Globals.MappaMessaggiErrore.get("KO.GENERICO_dettaglio")}`
      );
    });
  }

  onAziendeModify(index): void {
    this.typeop = "ins";
    let item: AziendeDto = new AziendeDto();

    if (index >= 0) {
      item = this.state.paginatedItems[index];

      this.typeop = "upd";
    }

    const modalRef = this.modalservice.open(AziendeEditComponent, {
      size: "lg",
      windowClass: "animated FadeIn",
    });
    modalRef.componentInstance.currentItem = { ...item };
    modalRef.componentInstance.typeop = this.typeop;

    let recapitiState: RecapitiState =
      this.aziendeService.recapitiService.state();

    modalRef.componentInstance.nazioni = recapitiState.listaNazioni;
    modalRef.componentInstance.province = recapitiState.listaProvince;
    modalRef.componentInstance.recapiti = this.state.getRecapitiByIdAzienda(
      item.idAzienda
    );
    modalRef.componentInstance.listaTipiAzienda = this.state.listaTipiAzienda;
    modalRef.componentInstance.listaTipoEsenzione =
      this.state.listaTipoEsenzione;
    modalRef.componentInstance.listaAziende = this.state.listaAziende;
    modalRef.componentInstance.listaTipiPorto = this.state.listaPorti;
    modalRef.componentInstance.listaProvince = this.state.listaProvince;
    modalRef.componentInstance.listaNazioni = this.state.listaNazioni;

    const sub = modalRef.componentInstance.confirm.subscribe(
      (response: AziendeDto) => {
        let deleteOP = modalRef.componentInstance.delete;

        modalRef.componentInstance.onClose();

        if (deleteOP == true) {
          this.typeop = "del";
        }

        let recapiti = response.recapitiDaCreare;

        switch (this.typeop) {
          case "ins":
            this.aziendeService.create(response).subscribe({
              next: (az: CindyCustomResponse) => {
                let azienda: AziendeDto = JSON.parse(az.dati);
                recapiti.forEach((recapito) => {
                  this.creaRecapito(azienda.idAzienda, recapito);
                });
                if (az && azienda !== undefined) {
                  this.insertIstanza(azienda.idAzienda);
                }

                this.loadData();
                this._sortColumn = "idAzienda";
                this._sortDirection = "desc";
                this._rotateDirection = "desc";
                this.alertSrv.success(
                  //Globals.printCreateMessageSuccess("L'Azienda")
                  "L'azienda è stata creata con successo"
                );
              },

              error: (err) => {
                if (
                  err &&
                  err.error &&
                  err.error.codEsito == codiciErrore.codiceDuplicato
                ) {
                  this.errorSrv.onToastTestata(
                    "La ragione sociale inserita è già esistente"
                  );
                } else {
                  this.errorSrv.onToastTestata(
                    `${Globals.MappaMessaggiErrore.get(
                      codiciErrore.erroreResiduale
                    )}. ${Globals.MappaMessaggiErrore.get(
                      "KO.GENERICO_dettaglio"
                    )}`
                  );
                }
              },
            });

            break;
          case "upd":
            this.aziendeService.update(response).subscribe({
              next: (res: CindyCustomResponse) => {
                let azienda = JSON.parse(res.dati);
                if (res.codEsito == "OK") {
                  recapiti.forEach((recapito) => {
                    if (recapito.idRecapito <= 0) {
                      this.creaRecapito(azienda.idAzienda, recapito);
                    } else {
                      this.aggiornaRecapito(recapito);
                    }
                  });

                  console.log("tipo upd sub", azienda.tipoAzienda);

                  this.loadData();

                  this.alertSrv.success(
                    //Globals.printModifyMessageSuccess("l'Azienda")
                    "L'azienda è stata modificata con successo"
                  );
                } else {
                  this.alertSrv.error(
                    Globals.printModifyMessageFailure("l'Azienda")
                  );
                }
              },

              error: (err) => {
                console.log("errore azienda", err);
                this.alertSrv.error(
                  Globals.printModifyMessageFailure("l'Azienda")
                );
              },
            });

            break;
          case "del":
            this.aziendeCRUD
              .delete({ variable: "idAzienda", value: response.idAzienda })
              .subscribe({
                next: (az: CindyCustomResponse) => {
                  if (az.codEsito == "OK") {
                    recapiti.forEach((recapito) => {
                      if (recapito.idRecapito > 0) {
                        this.cancellaRecapito(recapito);
                      }
                    });
                    this.alertSrv.success(
                      //Globals.printDeleteMessageSuccess("L'Azienda")
                      "L'azienda è stata cancellata con successo"
                    );

                    this.loadData();
                  }
                },

                error: (err) => {
                  let errore: CindyCustomResponse = err.error;
                  console.log("errore", errore);
                  if (errore.codEsito == "KO.A13") {
                    this.alertSrv.error(
                      Globals.MappaMessaggiErrore.get("KO.DELETEAZIENDA")
                    );
                    /*                   const modalRef = this.modalservice.open(
                    PopUpUploadOrdiniComponent,
                    {
                      size: "md",
                      windowClass: "animated FadeIn",
                    }
                  );
                  modalRef.componentInstance.showButton = true;
                  modalRef.componentInstance.progressMessage = Globals.MappaMessaggiErrore.get("KO.DELETEAZIENDA")
                    ; */
                  }

                  if (errore.codEsito == "KO.A14") {
                    this.alertSrv.error(
                      Globals.MappaMessaggiErrore.get(errore.codEsito)
                    );

                    /*  const modalRef = this.modalservice.open(
                    PopUpUploadOrdiniComponent,
                    {
                      size: "md",
                      windowClass: "animated FadeIn",
                    }
                  );
                  modalRef.componentInstance.showButton = true;
                  modalRef.componentInstance.progressMessage = Globals.MappaMessaggiErrore.get(errore.codEsito)
                    ; */
                  }

                  /*      this.alertSrv.error(
                  Globals.printDeleteMessageFailure("Azienda")
                ); */
                },
              });
            break;

          default:
            break;
        }

        this.clearSearchTerm();

        // this.alertSrv.success(Globals.printModifyMessageSuccess("Azienda"));
      },
      (err) => {
        modalRef.componentInstance.error(err);
        this.alertSrv.error(Globals.printModifyMessageFailure("l'Azienda"));
      }
    );
  }

  /* 
  private aggiornaRecapiti(
    typeop: string,
    az: AziendeDto,
    recapiti: RecapitiDto[]
  ) {
    recapiti.forEach((recapito) => {
      switch (typeop) {
        case "ins":
          break;

        case "upd":

        case "del":

        default:
          break;
      }
    });
  } */

  private cancellaRecapito(recapito: RecapitiDto) {
    this.aziendeService.recapitiService.delete(recapito.idRecapito).subscribe(
      (res) => {
        console.log("del recapito", res);
        this.alertSrv.success(Globals.printDeleteMessageSuccess("Il Recapito"));
        this.loadData();
      },
      (err) => {
        this.alertSrv.error(Globals.printDeleteMessageFailure("il Recapito"));
        console.log("del recapito", err);
      }
    );
  }

  private aggiornaRecapito(recapito: RecapitiDto) {
    this.aziendeService.recapitiService
      .update(recapito as RecapitiDto)
      .subscribe(
        (res) => {
          this.loadData();
          //  this.alertSrv.success(
          //    Globals.printCreateMessageSuccess("Recapito")
          //  );
        },
        (err) => {
          console.log("errore recapito", err);

          this.alertSrv.error(Globals.printModifyMessageFailure("il Recapito"));
        }
      );
  }

  private creaRecapito(idAzienda: number, recapito: RecapitiDto) {
    recapito.idAzienda = idAzienda;
    this.aziendeService.recapitiService
      .create(recapito as RecapitiDto)
      .subscribe(
        (res) => {
          this.loadData();
          //  this.alertSrv.success(
          //    Globals.printCreateMessageSuccess("Recapito")
          //  );
        },
        (err) => {
          console.log("errore recapito", err);
          this.alertSrv.error(Globals.printCreateMessageFailure("il Recapito"));
        }
      );
  }

  private clearSearchTerm() {
    this.searchTerm = "";
  }

  /*   onAziendeDelete(index): void {
    const modalRef = this.modalservice.open(PopUpDeleteComponent, {
      size: "sm",
      windowClass: "animated FadeIn",
    });
    const item = this.state.getItem(index);
    modalRef.componentInstance.currentItem = { ...item };
    // modalRef.componentInstance.alltype = this.alltype;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          return this.serviceAziende.deleteData(item.idAzienda, "idAzienda");
        })
      )
      .subscribe(
        (response) => {
          console.log("delete", response);
          modalRef.componentInstance.onClose();
          this.filterAziende(this.aziendeService);
          this.alertSrv.success(Globals.printDeleteMessageSuccess("Azienda"));
        },
        (err) => {
          modalRef.componentInstance.error(err);
          this.alertSrv.error(Globals.printDeleteMessageFailure("Azienda"));
          this.loggerService.log(err);
          modalRef.componentInstance.onClose();
        }
      );
  } */

  ngOnDestroy(): void {}
}
