import { RecapitiDto } from "src/app/_models/recapiti/recapiti-dto";
import { State } from "src/app/_models/State";
import { Nazioni } from "src/app/_models/localizzazioni/nazioni";
import { Province } from "src/app/_models/localizzazioni/province";

export class RecapitiState extends State<RecapitiDto>{

    listaNazioni: Nazioni[]=[];
    listaProvince: Province[]=[];
}
