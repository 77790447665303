import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, timer } from "rxjs";

import { environment } from "../..//environments/environment";
import { switchMap, takeUntil } from "rxjs/operators";
import { Ability, AbilityBuilder } from "@casl/ability";
import { AppAbility } from "../shared/services/AppAbility";
import { OggettoMenu } from "../_models/allcomboboxdata/allcomboboxdata-dto";

@Injectable()
export class AuthService {
  private stopPolling = new Subject();
  checkSessionIntervalMillis = 1000 * 60; // ogni minuto
  constructor(public http: HttpClient, private ability: AppAbility) {}

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      const data = {
        username: value.email,
        password: value.password,
      };

      localStorage.clear();

      if (environment.useLocalData == false) {
        const domain = `${environment.baseDomain}:${environment.authPort}`;
        const url = domain + "/authUser";
        this.http.post(url, data).subscribe(
          (response) => {
            const result = response as any;
            // console.log("result login",result)
            localStorage.setItem("userData", JSON.stringify(result));
            localStorage.setItem("token", result.token);

            console.log("userData", result.token);

            const domainroles = `${environment.baseDomain}:${environment.userRolePort}`;
            const urlroles =
              domainroles + `/roleFunctions?roleId=${result.cindyRole}`;

             const anasnelleLoginUrl = "https://anasnelle.quadrif.it:8081/AnaSnelle-BE/auth/login";
           console.log("data login",data)

            // this.http
            //   .post(anasnelleLoginUrl, data/* {headers:{["Authorization"]:`Bearer ${result.token}`}} */)
            //   .subscribe((res)=>{
                
            //     console.log("res",res)
            //     localStorage.setItem('authInfo', JSON.stringify(res))
              
            //   });

            this.http.get(urlroles).subscribe((response) => {
              localStorage.setItem("userMenu", JSON.stringify(response));
              this.updateAbility(result);
              resolve(true);
            });
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        const domain = environment.baseDomainApiLocal;

        const url = domain + "/assets/data/users.json";
        this.http.get(url).subscribe(
          (response) => {
            const result = response as any;
            localStorage.setItem("cindyRole", result.admin.cindyRole);
            localStorage.setItem("token", result.admin.token);
            resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
      }
    });
  }

  public updateAbility(user) {
    const { can, cannot, rules } = new AbilityBuilder(AppAbility);

    if (user.cindyRole === "AMM") {
      can("read", "ANAGRAFICHE");
     // can("read", "ORDINI");
      // can("read", "ORDINIACQUISTO");
      can("read", "AZIENDE");
      // can("read", "IMBALLI");
      can("read", "SCALATURE");
      can("read", "SVILUPPITAGLIE");
      // can("read", "TIPIORDINE");
      // can("read", "PORTI");
      //can("read", "VISUALIZZA");
      cannot("read", "CREALANCIO");
    }

    if (user.cindyRole === "RDP") {
      can("read", "ANAGRAFICHE");
      can("read", "ORDINI");
      can("read", "AZIENDE");
      //can("read", "ORDINIACQUISTO")
      // can("read", "IMBALLI");
      can("read", "SCALATURE");
      can("read", "SVILUPPITAGLIE");
      // can("read", "TIPIORDINE");
      // can("read", "PORTI");
      can("read", "VISUALIZZA");
      can("read", "CARICAFILEORDINE");
      can("read", "IMPORTAZIONI");
      can("read", "GESTIONEIMPORTAZIONI");
       can("read", "LANCIO");
      can("read", "CREALANCIO");
     // can("read", "All");
     can("read","ANAGRAFICHESNELLE")
     can ("read", "REPORTAVV")
     can ("read", "LAVORAZIONEARTICOLI")
     can ("read", "ARTICOLI")
     can ("read", "MATERIALI")

    }

    if (user.cindyRole === "SUPER") {
      can("read", "All");
    }
    this.ability.update(rules);
  }

  doLogout() {
    this.ability.update([]);
    return new Promise((resolve, reject) => {
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("cindyRole");
      localStorage.removeItem("userMenu");
      localStorage.removeItem("currentUser");

      localStorage.clear();
      this.stopCheckSession();
      resolve(null);
    });
  }

  validateToken() {
    let token = localStorage.getItem("token");

    let data = `{\"token\":\"${token}\"}`;

    const domain = `${environment.baseDomain}:${environment.authPort}`;
    const url = domain + "/validateToken";

    return this.http.post(url, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    });
  }

  startCheckSession() {
    this.stopPolling = new Subject();
    return timer(1, this.checkSessionIntervalMillis).pipe(
      switchMap(() => this.validateToken()),
      takeUntil(this.stopPolling)
    );
  }

  stopCheckSession() {
    this.stopPolling.next(null);
  }

  isUserSuper() {
    let role: any = localStorage.getItem("userData");

    return role.cindyRole == "SUPER";
  }
}
