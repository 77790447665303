import { Component } from '@angular/core';

@Component({
  selector: 'app-ddtpagelist',
  templateUrl: './ddtpagelist.component.html',
  styleUrls: ['./ddtpagelist.component.css']
})
export class DDTPagelistComponent {

}
