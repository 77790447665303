import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';
import { MaterialiAnasnelleComponent } from './components/materiali-anasnelle/materiali-anasnelle.component';

@NgModule({
  declarations: [
    MaterialiAnasnelleComponent
  ],

  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule
  ]
})
export class MaterialiAnasnelleModule { }