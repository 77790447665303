export const codiciErrore = {

scalaturaCollegataASviluppo:"KO.A1",
ordineCollegatoLancio:"KO.A18",
formatoFileInvadio: "A11",
codiceDuplicato:"KO.DUPKEY",
erroreCaricamentoDati: "KO.GENEX",
erroreResiduale:"KO.GENERICO",
dibaIncompleta: "DIBA",
koGenerico: "KO_GENERICO",
componenteDuplicato: "DUPCOMPONENT",
elaborazioneInCorso:"BUFFERING",
erroreSubmit:"ERRORESALVATAGGIO",
erroreElaborazione: "A10",
importazioneDuplicata: "KO.B5",
impossibileImportare:"ERRORIMPORT",
erroreLancio:"KO.A16",
erroreOperazione: "KO.B3",
erroreCalcoloFabbisogni: "KO.B4",
aziendaCollegataOrdine:"KO.DELETEAZIENDA",
aziendaCollegataArticolo: "KO.A14",
scalaturaCollegataMateriale: "KO.A15",
articoliMancanti: "KO.ARTMANCANTI",
selezioneErrata: "DUP_RIGA_ODA",
sviluppoDuplicato:"DUP_SVILUPPO",
sviluppoMancante:"SVILUPPOMANCANTE",
aziendaMancante:"AZIENDAMANCANTE",
tipiRigaOrdineMancanti:"TIPIMANCANTI",
componentiMancanti:"COMPONENTIMANCANTI",
assistenza:"ASSISTENZA",
dibaVUOTA:"KO.A17",
DIBAMancante: "KO.A16_inOda",
erroreSistema:"SYSERR",
erroreStato:"A19",
erroreStatoRiga:"A20"

}