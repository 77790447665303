import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';

import { TipiEsenzionePageListComponent } from './component/tipi-esenzione-page-list/tipi-esenzione-page-list.component';
import { TipiEsenzioneEditComponent } from './component/tipi-esenzione-edit/tipi-esenzione-edit.component';
@NgModule({
  declarations: [
    TipiEsenzionePageListComponent,
    TipiEsenzioneEditComponent,
  ],

  imports: [
    NgbModule,
    SharedModule,
    BlockUIModule
  ]
})
export class FeatureTipiEsenzioneModule { }
