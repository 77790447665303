import { IDTOMapper } from "src/app/_helpers/IDTOMapper";
import { CapobolleDto, FasiBolle, BolleDto, ComponentiFaseBolle } from "../models/bolleDto";

export class CapobolleMapper implements IDTOMapper<CapobolleDto>{
    bolleMapper:BolleMapper= new BolleMapper();
    fasiMapper:FasiperBolleMapper = new FasiperBolleMapper();
    map(data: any): CapobolleDto {
      
      let item:CapobolleDto = new CapobolleDto();
      item.idCapobolla= data.idCapobolla
      item.numeroCapobolla= data.numeroCapobolla
      item.idOrdine= data.idOrdine
      item.ente= data.ente
      item.anno= data.anno
      item.numero= data.numero
      item.consegna= data.consegna
      item.tagLancio= data.tagLancio
      item.paia= data.paia
      item.idRiga= data.idRiga
      item.numeroRiga= data.numeroRiga
      item.modello= data.modello
      item.parte= data.parte
      item.colore= data.colore
      item.idArticolo=data.idArticolo
      item.forma=data.forma
      item.marchio=data.marchio
      item.barcode=data.barcode
      item.stagione=data.stagione
      item.fasi= [...data.fasi.map((r)=>this.fasiMapper.map(r))] as FasiBolle[]
      item.note=data.note
      item.idSviluppo= data.idSviluppo
      item.codiceSviluppo=data.codiceSviluppo;
      item.progressioneSviluppo=data.progressioneSviluppo;
      item.quantitaPerTaglia=data.quantitaPerTaglia;
      item.validUntil= data.validUntil;
      item.bolle=[...data.bolle.map((r)=>this.bolleMapper.map(r))] as BolleDto[]
     
      return item;
    }
  
  }
  
  export class BolleMapper implements IDTOMapper<BolleDto>{
    fasiMapper:FasiperBolleMapper = new FasiperBolleMapper();  
    map(data: any): BolleDto {
      let item:BolleDto = new BolleDto();
      item.idBolla= data.idBolla
      item.numeroBolla= data.numeroBolla
      item.idOrdine= data.idOrdine
      item.ente= data.ente
      item.anno= data.anno
      item.numero= data.numero
      item.consegna= data.consegna
      item.tagLancio= data.tagLancio
      item.paia= data.paia
      item.idRiga= data.idRiga
      item.numeroRiga= data.numeroRiga
      item.modello= data.modello
      item.parte= data.parte
      item.colore= data.colore
      item.idArticolo=data.idArticolo
      item.forma=data.forma
      item.marchio=data.marchio
      item.barcode=data.barcode
      item.stagione=data.stagione
      item.fasi= [...data.fasi.map((r)=>this.fasiMapper.map(r))] as FasiBolle[]
      item.note=data.note
      item.idSviluppo= data.idSviluppo
      item.codiceSviluppo=data.codiceSviluppo;
      item.progressioneSviluppo=data.progressioneSviluppo;
      item.distribuzione=data.distribuzione;
      item.validUntil= data.validUntil;   
      return item;
    }
  
  }
  
  export class FasiperBolleMapper implements IDTOMapper<FasiBolle>{
   
   componentiMapper: ComponentiBolleMapper = new ComponentiBolleMapper()
    map(data: any): FasiBolle {
      
      let item:FasiBolle = new FasiBolle();
      item.descrizioneFase= data.descrizioneFase;
      item.codiceFase= data.codiceFase;
      item.componenti= [...data.componenti.map((r)=>this.componentiMapper.map(r))] as ComponentiFaseBolle[];
      return item;
    }
  
  }
  
  export class ComponentiBolleMapper implements IDTOMapper<ComponentiFaseBolle>{
    map(data: any): ComponentiFaseBolle {
      
      let item:ComponentiFaseBolle = new ComponentiFaseBolle();
  
      item.tipo= data.tipo;
      item.codice= data.codice;
      item.descMateriaPrima= data.descMateriaPrima;
      item.unMis= data.unMis;
      item.consumo= data.consumo;
      return item;
    }
  
  }