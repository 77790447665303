import { ItemFilter } from "./ItemFilter";

// Contenitore generico di un gruppo di filtri
export abstract class AGroupFilter extends ItemFilter{

   childFilters:ItemFilter[] = [];

   constructor(children:ItemFilter[]){

    super(null);
    this.childFilters = children;
   }

    
}
