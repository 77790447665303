export class ArticoliDto {
  numeroOrdine: string;
  articolo: string;
  pellame: string;
  ordineCliente: string;
  sviluppo: number;
  quantita: number;
  prezzo: number;
  consegna: Date;
}

export class ArticoloEstesoDto {
  idArticolo: number = 0;
  modello: string = "";
  parte: string = "";
  colore: string = "";
  descrizione: string = "";
  descrizioneMateriale: string = "";
  coloreMateriale: string = "";
  extCode: string = "";
  linea: string = "";
  timestampModifica: string = "";
  stagione: string = "";
  codiceFornitore: string = "";
  image: string = "";
}

export class DistintaBase {
  articolo: ArticoloDistintaBase;
  macrofasi: Macrofasi[];
  componenti: ComponentiDistintaBase[];
}

export class ArticoloDistintaBase {
  idArticolo: number;
  modello: string;
  parte: string;
  colore: string;
  descrizione: string;
  descrizioneMateriale: string;
  coloreMateriale: string;
  extCode: string;
  linea: string;
  stagione: string;
  codiceFornitore: string;
  image: string;
}
export class Macrofasi {
  idMacrofase: string;
  descrizioneMacrofase: string;
}
export class ComponentiDistintaBase {
  codice: string = "";
  descrizione: string;
  codiceColore: string = "";
  descrizioneColore: string = "";
  codiceFamiglia: number;
  famiglia: string = "";
  extCode: string = "";
  groupingCode: string = "";
  flagTaglie: boolean;
  timestampModifica: string = "";
  prezzo: number;
  codiceFornitore: number;
  unMisura: string = "";
  iva: number;
  codiceLavorazione: string = "";
  descrizioneLavorazione: string = "";
  extCodeFase: string = "";
  extCodeDescrizioneFase: string = "";
  qtConsumi: number;
  orderConsumi: string = "";
  noteConsumi: string = "";
  idPosizioneFase: number;
  labelPosizioneFase: string = "";
  divisore: string = "";
  codiceGruppoMerceologico: number;
  descrizioneGruppoMerceologico: string = "";
  idScalatura: number = 0;
  codFornitore: string = "";
  descrizioneFornitore: string = "";
}
