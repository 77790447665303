import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-session-expired-modal",
  templateUrl: "./session-expired-modal.component.html",
  styleUrls: ["./session-expired-modal.component.css"],
})
export class SessionExpiredModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _router: Router,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {}

  onClose() {
    this.activeModal.close("close");
    this.modal.dismissAll();
    this._router.navigateByUrl("/login");
  }
}
