import { AGroupFilter } from "./AGroupFilter";
import { ItemFilter } from "./ItemFilter";


// Filtro di gruppo AND logico: 
// esegue la somma delle filter() dei suoi children 
export class AndFilter extends AGroupFilter{

    constructor(children:ItemFilter[]){

        super(children);
    }

    filter(i: any, filterParams: any=null): boolean { return false }

    override execute(items: any[], _filterParams: any=null): any[] {
        
        let results:any[]=items;

        this.childFilters.forEach((f)=>{

            results = f.execute(results);
        });

        return results;
    }
}