import { Utility } from "src/app/_zCore/utility";

export class LancioProdDto{

    idLancio:number;
    tagLancio:string;
    lancio:Lancio;
    extCode:string;
    validUntil:string;
    dataLancio:string
}

export class Lancio {
    ordini:OrdiniPerLancio[];
}

export class OrdiniPerLancio{
    idOrdine:number;
    idRiga:number;
    idSviluppo:number;
    qtPerTaglia: QuantitaPerTaglia[];
    ente:string;
    anno:number;
    numero:number;

    get quantita() {
        let sum: number = 0;
        this.qtPerTaglia.forEach((qtTg: QuantitaPerTaglia) => {
          let qt: number = Utility.toInteger(qtTg.quantita);
    
          sum += qt;
        });
    
        return sum;
      }
}

export class QuantitaPerTaglia{
    codTaglia:number;
    quantita:number;
    sku:string;
}
