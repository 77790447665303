import { Component, OnInit } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Globals } from "src/app/_models/Globals";

import { ApiAllComboBoxdataService } from "src/app/_services/apiAllComboBoxdata.service";

import { FasiLavorazioneEditComponent } from "../fasi-lavorazione-edit/fasi-lavorazione-edit.component";

import { AlertService } from "src/app/_services/alert.service";
import { FasiLavorazioneDto } from "src/app/_models/fasilavorazione/fasilavorazione-dto";
import { FasiLavorazioneService } from "../../service/fasi-lavorazione.service";

import { RestDataSource } from "src/app/_datasources/RestDataSource";
import { State } from "src/app/_models/State";
import { HttpClient } from "@angular/common/http";
import { AndFilter } from "src/app/_filters/AndFilter";
import { FieldsFilter } from "src/app/_filters/FieldsFilter";
import { environment } from "src/environments/environment";
import { CindyCustomResponse } from "src/app/_models/esitoServer/CindyCustomResponse";

@Component({
  selector: "app-fasi-lavorazione-page-list",
  templateUrl: "./fasi-lavorazione-page-list.component.html",
  styleUrls: ["./fasi-lavorazione-page-list.component.css"],
})
export class FasiLavorazionePageListComponent implements OnInit {
  _page: number = 1;
  _pageSize: number = 10;
  _sortColumn: string = "";
  _sortDirection: string;
  _rotateDirection: string = "";

  FasiLavorazioneDataSource: RestDataSource;
  state: State<FasiLavorazioneDto> = new State();

  constructor(
    private modalservice: NgbModal,
    private allComboData: ApiAllComboBoxdataService,
    private fasiLavorazioneService: FasiLavorazioneService,
    private alertSrv: AlertService,
    private http: HttpClient
  ) {
    this.FasiLavorazioneDataSource = new RestDataSource(this.http);

    this.FasiLavorazioneDataSource.setVariablesApi(
      "allFasiLavorazione",
      "faseLavorazione",
      "faseLavorazione",
      `${environment.baseDomain}:${environment.anaPort}`
    );

    this.FasiLavorazioneDataSource.setByIdField("idFaseLavorazione");
    this.fasiLavorazioneService.connectDataSource(
      this.FasiLavorazioneDataSource
    );
  }

  _searchTerm: string;
  set searchTerm(searchString: string) {
    this._searchTerm = searchString;

    this.loadData();
  }
  get searchTerm() {
    return this._searchTerm;
  }

  set pageSize(newPageSize: number) {
    this._pageSize = newPageSize;

    this.loadData();
  }
  get pageSize() {
    return this._pageSize;
  }
  set page(newPage: number) {
    this._page = newPage;

    this.loadData();
  }

  get page() {
    return this._page;
  }

  loadData() {
    this.fasiLavorazioneService.loadState().subscribe({
      next: (service) => {
        let cmp = this;

        this.filterFasiLavorazione(service);

        console.log(this._page + " " + this._pageSize);
      },
    });
  }

  filterFasiLavorazione(service: FasiLavorazioneService) {
    console.log(this._searchTerm);

    this.state = service
      .filter(
        new AndFilter([
          new FieldsFilter({
            fieldList: ["codFaseLavorazione", "descFaseLavorazione"],
            searchString: this._searchTerm == undefined ? "" : this._searchTerm,
          }),
          //   new TipiPagamentoiFilter(null),
        ])
      )
      .sort(this._sortColumn, this._sortDirection)
      .paginate(this._page, this.pageSize)
      .state();
  }

  ngOnInit(): void {
    this._sortColumn = "idFaseLavorazione";
    this._sortDirection = "desc";
    this._rotateDirection = "desc";
    this.loadData();
  }

  onSort(event): void {
    this._sortColumn = event.target.getAttribute("sortable");
    this._sortDirection = event.target.getAttribute("sortDirection");

    this._rotateDirection =
      this._sortDirection == ""
        ? "asc"
        : this._sortDirection == "asc"
        ? "desc"
        : "";
    if (this._rotateDirection == "") {
      event.target.classList.remove(this._sortDirection);
    } else event.target.className = this._rotateDirection;
    event.target.setAttribute("sortDirection", this._rotateDirection);
    this.filterFasiLavorazione(this.fasiLavorazioneService);
  }

  onFasiLavorazioneCreateOrModify(index: number): void {
    let modalRef: any = null;

    modalRef = this.modalservice.open(FasiLavorazioneEditComponent, {
      size: "md",
      windowClass: "animated FadeIn",
    });

    let faseLavorazione: FasiLavorazioneDto = new FasiLavorazioneDto();
    let typeop = "ins";

    if (index >= 0) {
      faseLavorazione = this.state.paginatedItems[index];

      typeop = "upd";
    }

    modalRef.componentInstance.currentItem = faseLavorazione;

    modalRef.componentInstance.typeop = typeop;
    const sub = modalRef.componentInstance.confirm
      .pipe(
        switchMap((data) => {
          console.log(data);

          if (typeop == "ins") {
            return this.fasiLavorazioneService.create(
              data as FasiLavorazioneDto
            );
          } else if (typeop == "upd") {
            if (modalRef.componentInstance.delete == false)
              return this.fasiLavorazioneService.update(
                data as FasiLavorazioneDto
              );
            else {
              typeop = "del";
              console.log("data", data);
              return this.fasiLavorazioneService.delete(
                faseLavorazione.idFaseLavorazione
              );
            }
          }
        })
      )
      .subscribe(
        (response: CindyCustomResponse) => {
          if (response.codEsito == "OK") {
            modalRef.componentInstance.onClose();
            this.searchTerm = "";
            this.loadData();

            this.onSuccessPrint(typeop);
          } else {
            this.onErrorPrint(typeop);
          }
        },
        (err) => {
          modalRef.componentInstance.error(err);
          console.log(err);
          if (typeop == "ins")
            this.alertSrv.error(
              Globals.printCreateMessageFailure("Fase di Lavorazione")
            );
          else if (typeop == "upd") {
            this.alertSrv.error(
              Globals.printModifyMessageFailure("Fase di Lavorazione")
            );
          } else {
            this.alertSrv.error(
              Globals.printDeleteMessageFailure("Fase di Lavorazione")
            );
          }
        }
      );
  }

  onErrorPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.error(
        Globals.printCreateMessageFailure("la Fase di Lavorazione")
      );
    else if (typeop == "upd") {
      this.alertSrv.error(
        Globals.printModifyMessageFailure("la Fase di Lavorazione")
      );
    } else {
      this.alertSrv.error(
        Globals.printDeleteMessageFailure("la Fase di Lavorazione")
      );
    }
  }

  onSuccessPrint(typeop: string) {
    if (typeop == "ins")
      this.alertSrv.success(
        Globals.printCreateMessageSuccess("La Fase di Lavorazione")
      );
    else if (typeop == "upd") {
      this.alertSrv.success(
        Globals.printModifyMessageSuccess("La Fase di Lavorazione")
      );
    } else {
      this.alertSrv.success(
        Globals.printDeleteMessageSuccess("Fase di Lavorazione")
      );
    }
  }
}
