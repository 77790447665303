import { Injectable } from '@angular/core';
import { OrdiniDto } from '../models/ordiniDto';
import { CrudService } from 'src/app/_services/crud.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { OrdiniEstesiProduzioneMapper, RigaOrdineDTOMapper } from '../mappers/ordiniProdMapper';
import { RigaOrdineDto } from '../models/rigaOrdineDto';
import { AlertService } from 'src/app/_services/alert.service';
import { Variables, stringUrl } from 'src/assets/data/variables';

@Injectable({
  providedIn: 'root'
})
export class OrdiniProdCRUDService extends CrudService<OrdiniDto>{

  constructor(httpClient: HttpClient,
    public alertSrv: AlertService,
    ) { 
    super(httpClient, alertSrv);
    this.serverPort=environment.ordiniPort
    this.endpoint= 'ordineEsteso'
    this.endpointAll='allOrdiniEstesi';
    this.mapper= new OrdiniEstesiProduzioneMapper()
  
  }

  preCancellazione(id){
    let url = `${environment.baseDomain}:${this.serverPort}/${stringUrl.preCancellazioneOrdine}?idOrdine=${id}`

    return this.httpClient.get(url)
  }

  onAnnullaOrdine(idOrdine,ordine){
    let url = `${environment.baseDomain}:${this.serverPort}/${stringUrl.annullaOrdineCliente}?idOrdine=${idOrdine}`
    return this.httpClient.post(url,null)

  }


  onAnnullaRigaOrdine(idriga){
    let url = `${environment.baseDomain}:${this.serverPort}/${stringUrl.annullaRiga}?idRigaOrdine=${idriga}`
    return this.httpClient.post(url,null)

  }

  onPrevisioneProduzione(idStagione, inizioSettimanaPrecedente, fineSettimanaPrecedente){
    let url = `${environment.baseDomain}:${this.serverPort}/${stringUrl.previsioniProduzione}?idStagione=${idStagione}&dal=${inizioSettimanaPrecedente}&al=${fineSettimanaPrecedente}`
    console.log( "url previsione Produzione",url)

    return this.httpClient.get(url)
  }
}

@Injectable({
  providedIn: 'root'
})
export class RigheOrdineCRUDService extends CrudService<RigaOrdineDto>{

  constructor(httpClient: HttpClient,
    public alertSrv: AlertService,
    ) { 
    super(httpClient, alertSrv);
    this.serverPort=environment.ordiniPort
    this.endpoint= 'rigaOrdine'
    this.endpointAll='allOrdiniEstesi';
    this.mapper= new RigaOrdineDTOMapper()
  
  }
}
