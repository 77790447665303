// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.
export const environment = {
  production: false,
  environment_name:'DEV',
  authPort:9098,
  anaPort:9095,
  userRolePort:9097,
  ordiniPort:9094,
  baseDomain:"https://cindy.iamboo.net",
  anasnelleArticoli: "https://anasnelle.quadrif.it:8081/AnaSnelle-FE/#/articoli",
  anasnelleLavorazioneArticoli: "https://anasnelle.quadrif.it:8081/AnaSnelle-FE/#/lavorazione",
  anasnelleMateriali:"https://anasnelle.quadrif.it:8081/AnaSnelle-FE/#/materiali",
  anasnelleDashboard: "https://anasnelle.quadrif.it:8081/AnaSnelle-FE/#/",
  
  

  useLocalData: false,
  baseDomainApiLocal: "http://localhost:4200",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
